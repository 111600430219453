import PropTypes from "prop-types";
import SearchTable from "../../Search/SearchTable";
import { gatewayColumnHeaders, gatewayKeyValue } from "./gatewayTableHelpers";
import SearchPaging from "../../Search/SearchPaging";

function GatewayTable({
  data,
  getActionsComponent,
  resultsPerPage,
  resultsTotal,
  handleChange,
  handleResultsPerPageOnChange,
}) {
  return (
    <div className="gateway-table-wrapper">
      <SearchTable
        getActionsComponent={getActionsComponent}
        searchResults={data?.length > 0 ? data : null}
        getEntityKeyValue={gatewayKeyValue}
        fieldList={gatewayColumnHeaders}
      />
      <SearchPaging
        resultsPerPage={resultsPerPage}
        resultsTotal={resultsTotal}
        handleChange={handleChange}
        handleResultsPerPageOnChange={handleResultsPerPageOnChange}
      />
    </div>
  );
}

GatewayTable.propTypes = {
  data: PropTypes.array,
  resultsTotal: PropTypes.number,
  resultsPerPage: PropTypes.number,
  getActionsComponent: PropTypes.func,
  handleChange: PropTypes.func,
  handleResultsPerPageOnChange: PropTypes.func,
};

export default GatewayTable;
