import React from "react";
import "./physicalTerminal.scss";
import PropTypes from "prop-types";
const TransactionStepper = ({ stepper, stepperFlow }) => {
  //   const [stepper, setStepper] = useState(0);

  return (
    <div className="pt-stepper-wrapper">
      <div className="pt-step">
        <div className="dots-wrapper">
          {Object.values(stepperFlow).map((step, i) => {
            return (
              <div
                key={i}
                title={step?.description}
                className={
                  "stepper-dot " +
                  (stepper === i ? "active" : "") +
                  (stepper > i ? "complete" : "")
                }
              ></div>
            );
          })}
        </div>
        <div className="timeline-bar"></div>
      </div>
      {/* <button
        onClick={() => {
          setStepper((prevCount) => prevCount + 1);
        }}
      >
        click
      </button> */}
    </div>
  );
};

TransactionStepper.propTypes = {
  stepper: PropTypes.number,
  stepperFlow: PropTypes.object,
};

export default TransactionStepper;
