import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import PropTypes from "prop-types";
import "./dragAndDrop.scss";

/**     Drag and Drop Component     */

/**
Component needs index order, a function for setting order state, and children which is html.
The purpose of the component is to receive html and reorder the list of items based on user input.
*/

/**  
* const moveListItems = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = customFields[dragIndex];
      const hoverItem = customFields[hovexrIndex];
      setCustomFields((customFields) => {
        const updatedItems = [...customFields];
        updatedItems[dragIndex] = hoverItem;
        updatedItems[hoverIndex] = dragItem;
        return updatedItems;
      });
    },
    [customFields]
  ); */

/** 
   * Example of implementation
   *   <div className="drag-custom-field-wrapper">
        {customFields.map((item, index) => (
          <DragListItem
            key={item.id}
            index={index}
            moveListItem={moveListItems}
          >
            <p>{item.label}</p>
          </DragListItem>
        ))}
      </div>
   */

const DragListItem = ({ index, moveListItem, children }) => {
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (order) {
      moveListItem(order.dragIndex, order.hoverIndex);
    }
  }, [order]);
  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  /* eslint-disable no-unused-vars */
  const [spec, dropRef] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
      setOrder({ dragIndex: dragIndex, hoverIndex: hoverIndex });
      item.index = hoverIndex;
    },
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  // Make items being dragged transparent, so it's easier to see where we drop them
  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={dragDropRef} className="drag-list-item" style={{ opacity }}>
      {children}
    </div>
  );
};
DragListItem.propTypes = {
  index: PropTypes.number,
  moveListItem: PropTypes.any,
  children: PropTypes.array.isRequired,
};

export default DragListItem;
