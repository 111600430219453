import React, { useState } from "react";
import { Drawer as MuiDrawer } from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import "./customFields.scss";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import CancelIcon from "@mui/icons-material/Cancel";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import { FormProvider, useForm } from "react-hook-form";
import AlertDialog from "../../Dialog/AlertDialog";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { stringFormat } from "../../../utils/stringHelpers";
import { sassEndpoints } from "../../../constants/endpoints";
import { useContext } from "react";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { validationRules } from "../../../constants/validationRules";

const LabelsDrawer = ({
  closeModal,
  open,
  type,
  fields,
  handleSubmit,
  selectedFieldId,
  setAlertMessage,
  setErrorModal,
}) => {
  const methods = useForm();
  const theme = useTheme();
  const { userSettings } = useContext(UserSettingsContext);
  const { dirtyFields } = methods.formState;
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteOptionIndex, setDeleteOptionIndex] = useState(null);

  const [totalCreatedRows, setTotalCreatedRows] = useState(
    fields ? fields.length - 1 : 0,
  );
  const [labels, setLabels] = useState(
    fields
      ? fields.map((f, i) => {
          f.index = i;
          return f;
        })
      : [{ index: 0, label: "", value: "" }],
  );

  const addRow = () => {
    let newObject = { index: totalCreatedRows + 1 };
    setLabels((prevLabels) => [...prevLabels, newObject]);
    setTotalCreatedRows(totalCreatedRows + 1);
  };

  const collectFormElements = async () => {
    let triggerList = [];
    labels.forEach((label) => {
      triggerList.push(`${label?.index}.label`);
      triggerList.push(`${label?.index}.value`);
    });

    //Is true when there are no errors
    let errorVer = await methods.trigger(triggerList);

    if (Object.keys(methods.formState.errors).length === 0 && errorVer) {
      const values = methods.getValues();
      let formArray = [];
      Object.values(values).forEach((v, i) => {
        let touchedField =
          dirtyFields[i]?.label || dirtyFields[i]?.value || false;

        if (labels[i]) {
          const obj = {
            label: v.label,
            value: v.value,
            isTouched: touchedField,
            ...(labels[i].customFieldOptionId && {
              customFieldOptionId: labels[i].customFieldOptionId,
            }),
          };
          formArray.push(obj);
        }
      });
      handleSubmit(formArray);
    } else {
      setAlertMessage(["Please correct validation errors highlighted in red"]);
      setErrorModal(true);
    }
  };

  const GenerateFieldType = () => {
    switch (type.toLowerCase()) {
      case "radio":
        return <Radio disabled={true} size="small" className="labels-icon" />;
      case "checklist":
        return <Checkbox disabled={true} className="labels-icon" />;
      default:
        return <div></div>;
    }
  };

  const handleDeleteRow = (index) => {
    let optionId = labels.find(
      (option) => option.index === index,
    ).customFieldOptionId;
    if (optionId && selectedFieldId !== null) {
      setDeleteOptionIndex(index);
      setAlertOpen(true);
    } else {
      methods.unregister(`${index}.label`);
      methods.unregister(`${index}.value`);
      setLabels(
        labels.filter((label) => {
          return label.index !== index;
        }),
      );
    }
  };

  const callDeleteOptionRow = async (index) => {
    let optionId = labels.find(
      (option) => option.index === index,
    ).customFieldOptionId;

    const deleteUrl =
      stringFormat(sassEndpoints.customFields.options, [
        userSettings.gatewayId,
        selectedFieldId,
      ]) + `/${optionId}`;

    axios
      .delete(deleteUrl)
      .then(() => {
        setTimeout(() => {
          methods.unregister(`${index}.label`);
          methods.unregister(`${index}.value`);
        });
        setLabels(
          labels.filter((label) => {
            return label.index !== index;
          }),
        );
      })
      .catch((error) => {
        setAlertMessage(error.response.data.statusDetails);
        setErrorModal(true);
      });
  };
  return (
    <MuiDrawer
      className={`custom-labels-container ${theme.palette.mode}`}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={closeModal}
      PaperProps={{
        sx: {
          width: { sm: "500px", xs: "100%" },
        },
      }}
    >
      <FormProvider {...methods}>
        <form>
          <div className="custom-labels-wrapper">
            <div className="modal-labels-header">
              <div>
                <h3>Field Type: {type}</h3>
                <p>Set the label and value</p>
              </div>
              <div>
                {" "}
                <IconButton color="inherit" onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className="modal-body">
              <div className="table-header">
                <span>LABEL</span>
                <span style={{ paddingLeft: "10%" }}>VALUE</span>
                <span>ACTIONS</span>
              </div>
              <div>
                {labels.map((label) => {
                  return (
                    <>
                      <AlertDialog
                        alertOpen={alertOpen}
                        alertTitle="Are you sure?"
                        alertLevel="info"
                        alertMessages={[
                          "You are permanently deleting this option",
                        ]}
                        actionButtons={[
                          {
                            text: "Yes, Delete this option",
                            color: "secondary",
                            onclick: () => {
                              callDeleteOptionRow(deleteOptionIndex);
                              setAlertOpen(false);
                            },
                          },
                        ]}
                        closeButtonText="Cancel"
                        closeButtonColor="neutrals"
                        onCloseButtonClick={() => {
                          setAlertOpen(false);
                        }}
                      />
                      <div key={label?.index} className="labels-row">
                        <GenerateFieldType />
                        <RegisteredTextField
                          name={`${label?.index}.label`}
                          label="Label"
                          defaultValue={label?.label}
                          className="labels-input"
                          sx={{ width: "40%", marginRight: "5%" }}
                          rules={{
                            required: true,
                            maxLength: validationRules.maxLength25,
                          }}
                          inputProps={{ "data-cy": `label${label.index}` }}
                        />
                        <RegisteredTextField
                          name={`${label?.index}.value`}
                          label="Value"
                          defaultValue={label?.value}
                          className="labels-input-value"
                          sx={{ width: "40%" }}
                          rules={{
                            required: true,
                            maxLength: validationRules.maxLength25,
                          }}
                          inputProps={{ "data-cy": `value${label.index}` }}
                        />
                        <IconButton
                          variant="container"
                          sx={{ padding: 0.5, marginLeft: "5px" }}
                          onClick={() => handleDeleteRow(label.index)}
                          color={"error"}
                          fontSize="small"
                        >
                          <CancelIcon
                            fontSize="small"
                            className="wide-screen-delete-label"
                          />
                          <Button className="mobile-delete-label">
                            Delete Row
                          </Button>
                        </IconButton>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="add-row-border">
                <Button className="add-row-btn" onClick={() => addRow()}>
                  + Add Row
                </Button>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => collectFormElements()}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </MuiDrawer>
  );
};

LabelsDrawer.propTypes = {
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string,
  fields: PropTypes.array,
  handleSubmit: PropTypes.func,
  deleteOptionCall: PropTypes.func,
  selectedFieldId: PropTypes.number,
  setAlertMessage: PropTypes.func,
  setErrorModal: PropTypes.func,
};
export default LabelsDrawer;
