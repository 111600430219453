import { useEffect } from "react";
import axios from "axios";
import { useMsal, useAccount } from "@azure/msal-react";
import { getAccessToken } from "../utils/msalHelpers";

export const useMSALInterceptor = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      async (config) => {
        const token = await getAccessToken(instance, account);
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, []);
};

export default useMSALInterceptor;
