import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const CustomerSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Stack spacing={1} direction="row" justifyContent={"space-between"}>
        <Skeleton variant="rectangular" height={40} width={"50%"} />
        <Skeleton variant="circular" height={40} width={"10%"} />
      </Stack>
      <Skeleton variant="rectangular" height={100} width={"100%"} />
      <Skeleton variant="rectangular" height={75} width={"100%"} />
      <Skeleton variant="rectangular" height={75} width={"100%"} />
      <Skeleton variant="rectangular" height={75} width={"100%"} />
    </Stack>
  );
};

export default CustomerSkeleton;
