import React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionCard = ({ title, children, defaultExpanded }) => {
  return (
    <MuiAccordion defaultExpanded={defaultExpanded} disableGutters>
      <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography>{title}</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>{children}</MuiAccordionDetails>
    </MuiAccordion>
  );
};

AccordionCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  defaultExpanded: PropTypes.bool.isRequired,
};

export default AccordionCard;
