import React, { useEffect } from "react";
import HelpContactUs from "./HelpContactUs";
import useScript from "../../../hooks/useScript";
import ContentComponent from "../../Content/Content";
import { clickedHelpContactEvent } from "../../../services/mixpanel/merchant/helpEvents";

const feedbackCleanupElements = [
  "atlwdg-trigger",
  "atlwdg-blanket",
  "atlwdg-container",
];
const HelpContactUsContainer = () => {
  useEffect(() => {
    clickedHelpContactEvent();
  }, []);
  useScript(
    "https://basysiqpro.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-dtzt95/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=af874ddb",
    feedbackCleanupElements,
  );
  return (
    <ContentComponent title="Contact Us" bodyContent={<HelpContactUs />} />
  );
};

export default HelpContactUsContainer;
