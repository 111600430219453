import React, { useEffect } from "react";
import HelpTOS from "./HelpTOS";
import ContentComponent from "../Content/Content";
import { clickedHelpTosEvent } from "../../services/mixpanel/merchant/helpEvents";

const HelpTOSContainer = () => {
  useEffect(() => {
    clickedHelpTosEvent();
  }, []);
  return (
    <ContentComponent title="Terms of Service" bodyContent={<HelpTOS />} />
  );
};

export default HelpTOSContainer;
