import { useState, useRef, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import ContentComponent from "../../Content/Content";
import Button from "@mui/material/Button";
import { partnerRoutes } from "../../../constants/routes";
import { basysEndpoints, sassEndpoints } from "../../../constants/endpoints";
import { useSnackbar } from "../../../hooks";
import { FormProvider, useForm } from "react-hook-form";
import StyledTabs from "../../Tabs/StyledTabs";
import GateWayUserList from "./GatewayUserList/GatewayUserList";
import GatewayUserDetail from "./GatewayUserList/GatewayUserDetail";
import GatewayUserDetailContainer from "./GatewayUserList/GatewayUserDetail/GatewayUserDetailContainer";
import GatewayDetails from "./GatewayDetails";
import GatewayCreateUser from "./GatewayUserList/CreateGatewayUser";
import GatewaySettings from "./GatewaySettings";
import {
  CONTACT_SUPPORT,
  GATEWAY_STATUS,
  USER_ROLES,
} from "../../../constants/global";
import { GatewaySettingsContext } from "./gatewaySettingsContext";
import { SnackbarContext } from "../../../contexts/SnackbarContext";
import GatewayProcessorsContainer from "./Processors/GatewayProcessorsContainer";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { stringFormat } from "../../../utils/stringHelpers";
import Terminal from "./TerminalContainer";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
export const IUserStatus = {
  TABLE: "DEFAULT",
  CREATE: "CREATE",
  VIEW: "VIEW",
};

const defaultPropsState = {
  usersState: IUserStatus.TABLE,
  isDisabled: true,
  selectedUser: null,
  copySettings: null,
  isLoading: false,
};

const GatewayView = () => {
  const { id } = useParams();
  const gatewayForm = useRef();
  const createUserForm = useRef();
  const editUserForm = useRef();
  const history = useHistory();
  const { ...methods } = useForm();
  const { snackbarOpen, snackbarProps, showSnackbar } = useSnackbar();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [gatewayInfo, setGatewayInfo] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [migrationError, setMigrationError] = useState(null);
  const [editAddress, setEditAddress] = useState(false);
  const [callAddressSuccess, setCallAddressSuccess] = useState(null);
  const [gatewayInfoEdit, setGatewayInfoEdit] = useState(false);

  const [gatewayProps, setGatewayProps] = useState(defaultPropsState);
  const [gatewayPerms, setGatewayPerms] = useState(null);
  const [refreshUsers, setRefreshUsers] = useState();
  const [userInfo, setUserInfo] = useState(null);
  const { setSnackbarProps } = useContext(SnackbarContext);
  const { userSettings } = useContext(UserSettingsContext);

  useEffect(() => {
    loadGatewayInfo();
    getUserInfo();

    return () => {
      setSnackbarProps({});
    };
  }, []);

  useEffect(() => {
    if (editAddress !== false) {
      editGateway(
        editAddress,
        gatewayInfo.gatewayId,
        Object.keys(methods.formState.errors).length === 0,
      );
    }
  }, [editAddress]);

  const getUserInfo = () => {
    let payload = {
      userId: {
        operator: "Equal", // Guid => Operators = Equal
        value: userSettings?.userId,
      },
      includeUserPermissions: true,
      includeUserRole: true,
    };

    const newurl = `${sassEndpoints.users.viewUser}`;
    axios
      .post(newurl, payload)
      .then((res) => {
        if (res.status === 204) {
          showAlertDialogError("Failed to get user info.");
        } else {
          setUserInfo(res?.data?.data?.results[0]);
        }
      })
      .catch(() => {
        showAlertDialogError("Failed to get user info.");
      });
  };

  const getGatewayUserRoles = () => {
    return axios
      .get(`${sassEndpoints.users.userRoles}/${id}`)
      .then((response) => {
        return response.data.data;
      })
      .catch(() => {
        showAlertDialogError("Failed to get roles.");
        return [];
      });
  };

  const getGatewayUserPermissions = () => {
    return axios
      .get(`${sassEndpoints.users.userPermissions}/${id}`)
      .then((response) => {
        setGatewayPerms(response.data.data);
        return response.data.data;
      })
      .catch(() => {
        setGatewayPerms(false);
        showAlertDialogError("Failed to get permissions.");
        return [];
      });
  };

  const getRolesAndPermissions = async (allowInvoicing) => {
    const roles = await getGatewayUserRoles();
    const permissions = await getGatewayUserPermissions();
    setGatewayProps({
      ...gatewayProps,
      gatewayPermissions: permissions.filter(
        (p) =>
          (p.code.includes("INVOICE") && allowInvoicing) ||
          !p.code.includes("INVOICE"),
      ),
      gatewayRoles: roles,
    });
  };

  useEffect(() => {
    setShowBackdrop(gatewayProps.isLoading);
  }, [gatewayProps.isLoading]);

  const loadGatewayInfo = () => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.gateways.gateway, [id]);
    axios
      .get(url)
      .then((res) => {
        const allowInvoicing =
          res?.data?.data?.gatewaySettings
            .find((p) => p.code === "ALLOW_INVOICING")
            ?.value.toLowerCase() === "true";

        setGatewayInfo(res?.data?.data);
        getRolesAndPermissions(allowInvoicing);
      })
      .catch(() => {
        showAlertDialogError([
          "Gateway information failed to load",
          CONTACT_SUPPORT,
        ]);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };
  const editGateway = async (payload, gatewayId, isValidForm) => {
    setShowBackdrop(true);
    if (isValidForm) {
      const url = stringFormat(sassEndpoints.gateways.gateway, [gatewayId]);

      axios
        .patch(url, payload)
        .then(() => {
          showSnackbar("Success");
          loadGatewayInfo();
          setGatewayProps({
            ...gatewayProps,
          });
          if (gatewayInfoEdit) {
            setGatewayInfoEdit(false);
          }
          if (editAddress) {
            setEditAddress(false);
            setCallAddressSuccess(true);
          }
        })
        .catch((error) => {
          showAlertDialogError(error?.response?.data?.statusDetails);
          if (editAddress) {
            setCallAddressSuccess(false);
          }
        })
        .finally(() => {
          setShowBackdrop(false);
        });
    } else {
      setShowBackdrop(false);
      showAlertDialogError("Please fix all required fields.");
    }
  };

  const createGatewayUser = (payload) => {
    setRefreshUsers(Date.now());
    let url = stringFormat(sassEndpoints.gatewayUsers.user, [id]);
    setShowBackdrop(true);
    axios
      .post(url, payload)
      .then(function (response) {
        setGatewayProps({
          ...gatewayProps,
          usersState: "VIEW",
          selectedUser: response.data.data.gatewayUserId,
          isDisabled: true,
        });
        showSnackbar("Success");
      })
      .catch(function (error) {
        showAlertDialogError(error?.response?.data?.statusDetails);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const editGatewayUser = (payload, gatewayUserId) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [id]) +
      "/" +
      gatewayUserId +
      "?isSystemUserId=false";
    setShowBackdrop(true);
    axios
      .put(url, payload)
      .then(function () {
        setGatewayProps({
          ...gatewayProps,
          isDisabled: true,
        });
        editUserForm?.current?.onRefresh();
        showSnackbar("Success");
      })
      .catch(function (error) {
        console.log(error);
        errorMessage("Please correct validation errors highlighted in red");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const deleteUser = (gatewayUserId) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [id]) +
      "/" +
      gatewayUserId +
      "?isSystemUserId=false";
    setShowBackdrop(true);

    axios
      .delete(url)
      .then(function () {
        showSnackbar("Deleted User");
        setRefreshUsers(Date.now());
      })
      .catch(function (error) {
        showAlertDialogError(error);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const handleDeleteClick = (data) => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: ["You are permanently deleting this user"],
      actionButtons: [
        {
          text: "Yes, delete user",
          color: "secondary",
          onclick: () => {
            deleteUser(data.gatewayUserId);
            handleClosePromptDialog();
          },
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: handleClosePromptDialog,
    });
    setAlertDialogOpen(true);
  };

  const handleClosePromptDialog = () => {
    setAlertDialogProps({});
    setAlertDialogOpen(false);
  };

  const warnStatusChange = (state) => {
    if (state === GATEWAY_STATUS.DISABLED) {
      setAlertDialogProps({
        alertTitle: "Disable",
        alertLevel: "info",
        alertMessages: ["Are you sure you want to disable this gateway?"],
        actionButtons: [
          {
            text: "Accept",
            color: "primary",
            onclick: () => changeGatewayStatus(state),
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: () => setAlertDialogOpen(false),
      });
      setAlertDialogOpen(true);
    } else {
      setAlertDialogProps({
        alertTitle: "Enable",
        alertLevel: "info",
        alertMessages: ["Are you sure you want to enable this gateway?"],
        actionButtons: [
          {
            text: "Accept",
            color: "primary",
            onclick: () => changeGatewayStatus(GATEWAY_STATUS.ACTIVE),
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: () => setAlertDialogOpen(false),
      });
      setAlertDialogOpen(true);
    }
  };

  const deleteProcessorWarning = (deleteFunction) => {
    setAlertDialogProps({
      alertTitle: "Delete",
      alertLevel: "info",
      alertMessages: ["Are you sure you want to delete this processor?"],
      actionButtons: [
        {
          text: "Accept",
          color: "primary",
          onclick: () => {
            deleteFunction();
            setAlertDialogOpen(false);
          },
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const resendEmail = (userId) => {
    let url = `${basysEndpoints.users}/${userId}/invite`;
    axios
      .get(url)
      .then(() => {
        showSnackbar("Invite resent");
      })
      .catch((err) => {
        showAlertDialogError("Failed to resend invite", err);
      });
  };

  const changeGatewayStatus = (state) => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.gateways.status, [id, state]);
    axios
      .put(url)
      .then(() => {
        setAlertDialogOpen(false);
        loadGatewayInfo();
        showSnackbar(
          state === "active"
            ? "Gateway has been enabled!"
            : "Gateway has been disabled!",
        );
      })
      .catch((err) => {
        setAlertDialogOpen(false);
        showAlertDialogError(err?.response?.data);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const reQueueMigration = (utcDate, recipientEmail) => {
    setShowBackdrop(true);
    const payload = {
      MigrationDateTime: utcDate,
      Recipients: [recipientEmail, "product.development@basyspro.com"],
      RetryGatewayMigration: {
        gatewayId: gatewayInfo.gatewayId,
        sourceGatewayId: gatewayInfo.sourceReferenceId,
      },
    };
    axios
      .post(sassEndpoints.migrationQueue, payload)
      .then(() => {
        setMigrationError(null);
        loadGatewayInfo();
      })
      .catch(() => {
        setMigrationError("Error queueing migration process.");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const insertAddress = (type, payload) => {
    setShowBackdrop(true);
    payload.isPrimary = type === "primary_contact";
    payload.isInvoice = type === "invoice_contact";
    payload.isBilling = type === "billing_contact";
    const url = stringFormat(sassEndpoints.gateways.address, [
      gatewayInfo.gatewayId,
    ]);
    axios
      .post(url, payload)
      .then(() => {
        showSnackbar("Success");
        loadGatewayInfo();
        setCallAddressSuccess(true);
      })
      .catch((err) => {
        showAlertDialogError(err?.response?.data?.statusDetails);
        setCallAddressSuccess(false);
      })
      .finally(() => setShowBackdrop(false));
  };

  const handleClick = () => {
    if (activeTab === 0) {
      gatewayForm.current.onSubmit();
    } else {
      editUserForm.current.onSubmit();
    }
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const errorMessage = (message) => {
    showAlertDialogError(message);
  };

  const backToGateway = () => history.push(partnerRoutes.gateway);

  const isButtonViewable = () => {
    if (activeTab === 0) {
      return gatewayInfoEdit;
    } else {
      return gatewayProps.usersState === IUserStatus.VIEW;
    }
  };

  const openMigrationNotes = (noteDescription, status) => {
    const snackbarType = status === "migrationFailed" ? "error" : "warning";
    showSnackbar(noteDescription, snackbarType, true);
  };
  const handleMigrationClose = () => {
    setMigrationError(null);
  };

  return (
    <GatewaySettingsContext.Provider
      value={{
        gatewayPerms,
        setGatewayPerms,
        gatewayProps,
        setGatewayProps,
      }}
    >
      <FormProvider {...methods}>
        <ContentComponent
          spinnerOpen={showBackdrop}
          useFixedWidth={false}
          title={gatewayInfo?.name}
          headerContent={
            <Button
              onClick={() => backToGateway()}
              color="primary"
              className="gateway-backlist-button"
              sx={{ fontSize: "1rem" }}
              data-cy="back-to-list-button"
            >
              <ChevronLeft sx={{ fontSize: "1.5rem", marginLeft: "-10px" }} />
              Back to List
            </Button>
          }
          bodyContent={
            <div className="styled-tabs">
              <StyledTabs
                hidePanels={true}
                label="gateway details"
                defaultTabIndex={0}
                onChange={handleTabClick}
                tabs={[
                  {
                    props: { label: "Info" },
                    panel: (
                      <div className="gateway-details-container">
                        {gatewayInfo && (
                          <GatewayDetails
                            clickEvent={gatewayForm}
                            gatewayInfo={gatewayInfo}
                            editGateway={editGateway}
                            changeStatus={(status) => {
                              warnStatusChange(status);
                            }}
                            isButtonViewable={isButtonViewable}
                            backToGateway={backToGateway}
                            handleClick={handleClick}
                            openSnackbar={openMigrationNotes}
                            reQueueMigration={reQueueMigration}
                            migrationError={migrationError}
                            handleModalClose={handleMigrationClose}
                            insertAddress={insertAddress}
                            setEditAddress={setEditAddress}
                            callAddressSuccess={callAddressSuccess}
                            setCallAddressSuccess={setCallAddressSuccess}
                            showError={showAlertDialogError}
                            gatewayInfoEdit={gatewayInfoEdit}
                            setGatewayInfoEdit={setGatewayInfoEdit}
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    props: {
                      label: "Users",
                      disabled: [
                        "migrating",
                        "migrationFailed",
                        "InMigrationQueue",
                      ].includes(gatewayInfo?.status),
                    },
                    panel: (
                      <div>
                        {gatewayProps.usersState === IUserStatus.TABLE ? (
                          <GateWayUserList
                            id={id}
                            refreshList={refreshUsers}
                            showSnackbar={showSnackbar}
                            handleDeleteClick={handleDeleteClick}
                          />
                        ) : (
                          <div>
                            {gatewayProps.usersState === IUserStatus.VIEW &&
                            (userSettings?.userRole === "SU" ||
                              userSettings?.userRole === "SA") ? (
                              <div>
                                <GatewayUserDetailContainer
                                  gatewayInfo={gatewayInfo}
                                  gatewayId={id}
                                  warningMessage={errorMessage}
                                  resendEmail={resendEmail}
                                  showSnackbar={showSnackbar}
                                  setShowBackdrop={setShowBackdrop}
                                  setAlertDialogOpen={setAlertDialogOpen}
                                  setAlertDialogProps={setAlertDialogProps}
                                />
                                {/* <GatewayUserDetail
                                  gatewayInfo={gatewayInfo}
                                  warningMessage={errorMessage}
                                  gatewayId={id}
                                  onSubmission={editUserForm}
                                  editGatewayUser={editGatewayUser}
                                  resendEmail={resendEmail}
                                  backToGateway={backToGateway}
                                  handleClick={handleClick}
                                /> */}
                              </div>
                            ) : gatewayProps.usersState === IUserStatus.VIEW ? (
                              <div>
                                <GatewayUserDetail
                                  gatewayInfo={gatewayInfo}
                                  warningMessage={errorMessage}
                                  gatewayId={id}
                                  onSubmission={editUserForm}
                                  editGatewayUser={editGatewayUser}
                                  resendEmail={resendEmail}
                                  backToGateway={backToGateway}
                                  handleClick={handleClick}
                                />
                              </div>
                            ) : (
                              <div>
                                <GatewayCreateUser
                                  gatewayInfo={gatewayInfo}
                                  createGatewayUser={createGatewayUser}
                                  onSubmission={createUserForm}
                                  gatewayId={id}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    props: {
                      label: "Processors",
                      disabled: [
                        "migrating",
                        "migrationFailed",
                        "InMigrationQueue",
                      ].includes(gatewayInfo?.status),
                    },
                    panel: (
                      <div>
                        <GatewayProcessorsContainer
                          showError={showAlertDialogError}
                          showSnackbar={showSnackbar}
                          gatewayId={id}
                          gatewayInfo={gatewayInfo}
                          setShowBackdrop={setShowBackdrop}
                          deleteProcessorWarning={deleteProcessorWarning}
                        />
                      </div>
                    ),
                  },
                  {
                    props: {
                      label: "Settings",
                      disabled: [
                        "migrating",
                        "migrationFailed",
                        "InMigrationQueue",
                      ].includes(gatewayInfo?.status),
                    },
                    panel: (
                      <div>
                        <GatewaySettings
                          showError={showAlertDialogError}
                          showSnackbar={showSnackbar}
                          gatewayInfo={gatewayInfo}
                          setShowBackdrop={setShowBackdrop}
                        />
                      </div>
                    ),
                  },
                  ...(userInfo?.userRole === USER_ROLES.SUPER_USER ||
                  (userInfo?.userRole === USER_ROLES.SYSTEM_ADMIN &&
                    userInfo?.userPermissions?.find(
                      (p) => p?.code === "MANAGE_TERMINAL",
                    ))
                    ? [
                        {
                          props: {
                            label: "Terminal",
                          },
                          panel: (
                            <div>
                              <Terminal
                                activeTab={activeTab}
                                showError={showAlertDialogError}
                                showSnackbar={showSnackbar}
                                gatewayInfo={gatewayInfo}
                                showBackdrop={showBackdrop}
                                setShowBackdrop={setShowBackdrop}
                                setAlertDialogProps={setAlertDialogProps}
                                setAlertDialogOpen={setAlertDialogOpen}
                              ></Terminal>
                            </div>
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          }
          snackbarOpen={snackbarOpen}
          snackbarProps={snackbarProps}
          alertDialogOpen={alertDialogOpen}
          alertDialogProps={alertDialogProps}
        />
      </FormProvider>
    </GatewaySettingsContext.Provider>
  );
};

export default GatewayView;
