import colors from "../../assets/colors.module.scss";

const styles = (theme) => ({
  paper: {
    maxWidth: colors.paper_mw,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  inputRight: {
    textAlign: "right",
  },
  grid: {
    height: "100%",
  },
});

export default styles;
