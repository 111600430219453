import PropTypes from "prop-types";
import { grey } from "@mui/material/colors";

const miniIconProps = {
  fontSize: "small",
  sx: {
    mt: 0.15,
    mr: 0.5,
    color: grey[700],
  },
};

const miniDivProps = {
  style: {
    display: "inline-block",
    verticalAlign: "top",
  },
};

const IconCell = ({ Icon, text, helper }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Icon {...miniIconProps} />
      <div {...miniDivProps}>
        {text}
        {helper && (
          <>
            <br />
            {helper}
          </>
        )}
      </div>
    </div>
  );
};

IconCell.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string,
  helper: PropTypes.string,
};

IconCell.defaults = {
  text: "",
  helper: "",
};

export default IconCell;
