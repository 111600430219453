import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { protectedResources } from "../authConfig";

export const getAccessToken = async (msalInstance, account) => {
  if (!account) return null;

  return await msalInstance
    .acquireTokenSilent({
      scopes: protectedResources.iqpro.scopes,
      account: account,
    })
    .then((response) => {
      return response.accessToken;
    })
    .catch((error) => {
      // in case if silent token acquisition fails, fallback to an interactive method
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance
          .acquireTokenRedirect({
            scopes: protectedResources.iqpro.scopes,
          })
          .then((response) => {
            return response.accessToken;
          })
          .catch((error) => {
            console.log(error);
            return null;
          });
      }
    });
};
