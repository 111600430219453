import React, { useContext, useState } from "react";
import "../../../assets/v2/main.scss";
import "./terminal.scss";
import { useWatch } from "react-hook-form";
// Hidden for 10/1
// import CreditCardIcon from "@mui/icons-material/CreditCard";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import { GatewayContext } from "../../../contexts/GatewayContext";
import { TerminalContext } from "./TerminalContext";
import Dialog from "@mui/material/Dialog";
import PhysicalTerminalContainer from "./PhysicalTerminal/PhysicalTerminalContainer";
import { useMsal, useAccount } from "@azure/msal-react";
import { getAccessToken } from "../../../utils/msalHelpers";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
const PaymentMethodSelector = ({ resetState }) => {
  const gatewayInfo = useContext(GatewayContext);
  const { paymentType } = useContext(TerminalContext);
  const amount = useWatch({ name: "amount", defaultValue: 0 });
  const [open, setOpen] = useState(false);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [jwtToken, setToken] = useState(null);
  const [isFullWidth, setFullWidth] = useState(true);
  const theme = useTheme();

  // Hidden for 10/1
  // const showACHPayment = () => {
  //   return gatewayInfo.processors.some((p) => {
  //     return p.processorType.type === "Ach";
  //   });
  // };
  const showPhysical = () => {
    return gatewayInfo.processors.some((p) => {
      return p.processorType.certificationCategory === "PhysicalTerminal";
    });
  };

  const handlePaymentSelection = () => {
    getAccessToken(instance, account).then((token) => {
      setToken(token);
      setOpen(true);
    });
  };

  const onSuccess = () => {
    resetState();
    setOpen(false);
  };

  return (
    <>
      <div>
        <h2 className="h4">Payment Methods</h2>
        <div>
          {/* Hidden for 10/1 */}
          {/* <button
            className="terminal-option"
            disabled={amount === 0 && paymentType !== "verification"}
          >
            <span>
              <CreditCardIcon />
            </span>
            <div>
              <p>Enter card information</p>
              <p>Process Card-Not Present payments</p>
            </div>
          </button> */}
          {paymentType !== "verification" && (
            <>
              {/* {showACHPayment() && (
                <button
                  className="terminal-option"
                  disabled={amount === 0 && paymentType !== "verification"}
                >
                  <span>
                    <AccountBalanceIcon />
                  </span>
                  <div>
                    <p>Enter bank information</p>
                    <p>Process ACH payments</p>
                  </div>
                </button>
              )} */}
              {/* {gatewayInfo.gatewaySettings
                .find((s) => s.code === "ALLOW_CUSTOMER_VAULT_ACCESS")
                ?.value.toLowerCase() === "true" && (
                <button
                  className="terminal-option"
                  disabled={amount === 0 && paymentType !== "verification"}
                >
                  <span>
                    <AccountBoxOutlinedIcon />
                  </span>
                  <div>
                    <p>Select saved customer</p>
                    <p>Charge a customer with a payment method on file</p>
                  </div>
                </button>
              )} */}

              {showPhysical() && (
                <button
                  className="terminal-option"
                  disabled={amount === 0 && paymentType !== "verification"}
                  onClick={() => {
                    handlePaymentSelection();
                  }}
                >
                  <span>
                    <PointOfSaleOutlinedIcon />
                  </span>
                  <div>
                    <p>Select terminal</p>
                    <p>Select terminal for Card Present payments</p>
                  </div>
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <Dialog
        className={`payment-selection-modal v2 ${theme.palette.mode}-theme ${isFullWidth}`}
        maxWidth="lg"
        open={open}
      >
        {jwtToken && (
          <PhysicalTerminalContainer
            handleClose={() => setOpen(false)}
            token={jwtToken}
            setFullWidth={setFullWidth}
            onSuccess={onSuccess}
          />
        )}
      </Dialog>
    </>
  );
};
PaymentMethodSelector.propTypes = {
  resetState: PropTypes.func,
};

export default PaymentMethodSelector;
