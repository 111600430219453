import mixpanel from "mixpanel-browser";
import { flattenedObject } from "../../../utils/flattenedObject";

/***** Merchant Properties *****/

// called when user successfully logs in and sets merchant id as group identifier
export function setGatewayProviderGroupProp(payload, providerInfo) {
  mixpanel.set_group("gateway_id", payload.sourceReferenceId);
  let permissions = payload.gatewaySettings.reduce((perms, p) => {
    perms[p.code.toLowerCase()] = p.value.toLowerCase();
    return perms;
  }, {});
  let mpPayload = {
    gateway_name: payload.name,
    gateway_provider_name: providerInfo?.gateway_provider?.description,
    gateway_provider_id: providerInfo?.gateway_provider?.gateway_provider_id,
    gateway_id: payload.sourceReferenceId,
    basys_mid: payload.merchantId,
    allow_advanced_fields: permissions.allow_advanced_fields === "true",
    allow_defaults_population: permissions.allow_defaults_population === "true",
    allow_surcharge: permissions.allow_surcharge === "true",
    allow_customer_vault_access:
      permissions.allow_customer_vault_access === "true",
  };
  Object.assign(mpPayload, { $name: payload?.name });
  const flattenedPayload = flattenedObject(mpPayload);
  mixpanel
    .get_group("gateway_id", payload.sourceReferenceId)
    .set(flattenedPayload);
}
