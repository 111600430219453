import React, { useState, useEffect } from "react";
import GatewayProcessor from "./GatewayProcessor";
import GatewayProcessorList from "./GatewayProcessorList";
import Button from "@mui/material/Button";
import axios from "axios";
import PropTypes from "prop-types";
import { sassEndpoints } from "../../../../constants/endpoints";
import { stringFormat } from "../../../../utils/stringHelpers";
export const validProcessors = [
  "rapidhc",
  "rapidotc",
  "sage_ach",
  "tsys_sierra",
  "paya",
  "fiserv_v33",
  "rapid_connect_basys_card_present",
];
function GatewayProcessorsContainer({
  gatewayId,
  showSnackbar,
  showError,
  gatewayInfo,
  setShowBackdrop,
  deleteProcessorWarning,
}) {
  const [view, setView] = useState("TABLE");
  const [processors, setProcessors] = useState([]);
  const [selectedProcessor, setSelectedProcessor] = useState(null);
  const [configTypes, setConfigTypes] = useState(null);
  const rootUrl = stringFormat(sassEndpoints.processors.processor, [gatewayId]);
  useEffect(() => {
    retrieveProcessors();
  }, []);

  useEffect(() => {
    if (gatewayInfo) {
      retrieveConfigs();
    }
  }, [gatewayInfo]);

  const createProcessor = (payload) => {
    setShowBackdrop(true);
    axios
      .post(rootUrl, payload)
      .then(() => {
        showSnackbar("Successfully created processor");
        retrieveProcessors();
        setView("TABLE");
      })
      .catch((error) => {
        showError(error?.response?.data?.statusDetails);
        setShowBackdrop(false);
      });
  };

  const editProcessor = (id, payload) => {
    setShowBackdrop(true);
    axios
      .put(rootUrl + `/${id}`, payload)
      .then(() => {
        showSnackbar("Successfully updated processor");
        retrieveProcessors();
        setView("TABLE");
      })
      .catch((error) => {
        showError(error?.response?.data?.statusDetails);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const retrieveProcessors = () => {
    setShowBackdrop(true);
    axios
      .get(rootUrl)
      .then((res) => {
        setProcessors(res?.data?.data || []);
      })
      .catch((error) => {
        showError(error?.response?.data?.statusDetails);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const retrieveConfigs = () => {
    setShowBackdrop(true);
    const url = `${rootUrl}/ProcessorTypes`;
    axios
      .get(url)
      .then((res) => {
        let configs = [];
        const sourceSystem = gatewayInfo.preference.find((p) => {
          return p.metadataName.toUpperCase() === "SOURCE_SYSTEM";
        });
        const providerProcessers = res?.data?.data?.find((p) => {
          return sourceSystem.metadataValue.toUpperCase() === "FP"
            ? p.providerName.toUpperCase() === "IQPRO"
            : p.providerName.toUpperCase() === "BASYSIQ";
        });
        providerProcessers?.processorTypeConfigs.forEach((d) => {
          if (validProcessors.includes(d.name)) {
            configs.push(d);
          }
        });
        setConfigTypes(configs || []);
      })
      .catch((error) => {
        showError(error?.response?.data?.statusDetails);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const deleteProcessor = (id) => {
    setShowBackdrop(true);
    axios
      .delete(rootUrl + `/${id}`)
      .then(() => {
        showSnackbar("Successfully deleted processor");
        retrieveProcessors();
      })
      .catch((error) => {
        showError(error?.response?.data?.statusDetails);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const deleteActionBtn = (id) => {
    deleteProcessorWarning(() => deleteProcessor(id));
  };

  const retrieveProcessor = (processor, isEdit) => {
    setSelectedProcessor({ processor: processor, isEdit: isEdit });
    setView("FORM");
  };

  return (
    <div>
      {view === "TABLE" ? (
        <div>
          <Button
            onClick={() => {
              setSelectedProcessor(null);
              setView("FORM");
            }}
            variant="contained"
            color="primary"
            data-cy="create-processor-button"
          >
            Create Processor
          </Button>
          <GatewayProcessorList
            processors={processors}
            deleteProcessor={deleteActionBtn}
            retrieveProcessor={retrieveProcessor}
          />
        </div>
      ) : (
        <GatewayProcessor
          goBack={() => {
            setSelectedProcessor(null);
            setView("TABLE");
          }}
          createProcessor={createProcessor}
          editProcessor={editProcessor}
          gatewayInfo={gatewayInfo}
          selectedProcessor={selectedProcessor?.processor}
          canEdit={selectedProcessor?.isEdit}
          showError={showError}
          configTypes={configTypes}
        />
      )}
    </div>
  );
}

GatewayProcessorsContainer.propTypes = {
  gatewayId: PropTypes.string,
  showSnackbar: PropTypes.func,
  showError: PropTypes.func,
  gatewayInfo: PropTypes.object,
  setShowBackdrop: PropTypes.func,
  deleteProcessorWarning: PropTypes.func,
};

export default GatewayProcessorsContainer;
