import React from "react";
import Grid from "@mui/material/Grid";
import { useFormContext } from "react-hook-form";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import ControllerTextField from "../../../ReactHookForm/ControllerTextField";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import { timezones } from "../../../../constants/general";
import { regexPatterns } from "../../../../constants/regexPatterns";
import { removeNonNumeric } from "../../../../utils/stringHelpers";
import PropTypes from "prop-types";
function GatewayProcessorsSierra({ gatewayInfo, editDetails, isEdit }) {
  const methods = useFormContext();

  let primaryAddress = gatewayInfo.addresses.find(
    (address) => address.isPrimary,
  )?.addressModel;

  function formatSierrraPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = removeNonNumeric(value);

    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 10)}`;
  }
  return (
    <div className="sierra-block">
      <div className="merchant-block">
        <Grid container>
          <Grid item xs={12}>
            <h4>Merchant Info</h4>
          </Grid>

          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="merchant_number"
              name={"setting.tsys_sierra.mid"}
              label="MID /Merchant Number (12 digits)"
              sx={{ marginBottom: "10px" }}
              defaultValue={editDetails?.mid || ""}
              fullWidth
              viewOnly={!isEdit}
              errors={methods.errors}
              rules={{
                required: true,
                minLength: { value: 12, message: "Requires 12 digits" },
                maxLength: { value: 12, message: "Requires 12 digits" },
                pattern: regexPatterns.numericSpace,
              }}
            />
            <RegisteredTextField
              id="merchant_name"
              name={"setting.tsys_sierra.merchant_name"}
              label="Merchant Name (25 characters)"
              sx={{ marginBottom: "10px" }}
              fullWidth
              errors={methods.errors}
              viewOnly={!isEdit}
              defaultValue={
                editDetails?.merchantName.replace(/[^\w\s]/gi, "") ||
                gatewayInfo.name.replace(/[^\w\s]/gi, "")
              }
              rules={{
                required: true,
                maxLength: { value: 25, message: "Requires 25 characters" },
                pattern: regexPatterns.alphanumericSpace,
              }}
            />
            <RegisteredTextField
              id="merchant_state"
              name={"setting.tsys_sierra.merchant_state"}
              label="Merchant State (2 characters)"
              sx={{ marginBottom: "10px" }}
              fullWidth
              viewOnly={!isEdit}
              defaultValue={editDetails?.merchantState || primaryAddress?.state}
              errors={methods.errors}
              rules={{
                required: true,
                minLength: { value: 2, message: "Requires 2 characters" },
                maxLength: { value: 2, message: "Requires 2 characters" },
                pattern: regexPatterns.alphaSpace,
              }}
            />
            <RegisteredTextField
              id="city_code"
              name={"setting.tsys_sierra.city_code"}
              label="City Code / Postal Code"
              sx={{ marginBottom: "10px" }}
              fullWidth
              viewOnly={!isEdit}
              defaultValue={editDetails?.cityCode || primaryAddress?.postalCode}
              errors={methods.errors}
              rules={{
                required: true,
                pattern: regexPatterns.numeric,
              }}
            />
            <ControllerTextField
              id="merchant_location_number"
              name={"setting.tsys_sierra.merchant_location"}
              label="Merchant Location / Customer Service Number"
              control={methods.control}
              sx={{ marginBottom: "10px" }}
              fullWidth
              viewOnly={!isEdit}
              defaultValue={editDetails?.merchantLocation || gatewayInfo?.phone}
              transform={{
                input: (value) => formatSierrraPhoneNumber(value),
                output: (value) => formatSierrraPhoneNumber(value),
              }}
              required={true}
            />

            <Select
              control={methods.control}
              sx={{ marginBottom: "10px", marginTop: "10px" }}
              name="setting.tsys_sierra.timezone"
              fullWidth
              defaultValue={editDetails?.timezone || gatewayInfo.timezone}
              id="timezone"
              label="Timezone"
              viewOnly={!isEdit}
            >
              {timezones.map((timezone) => (
                <MenuItem key={`${timezone.value}`} value={timezone.value}>
                  {timezone.display}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h4>Terminal Information</h4>
          </Grid>
          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="terminal_number"
              name={"setting.tsys_sierra.terminal_number"}
              label="Terminal Number (4 digits)"
              sx={{ marginBottom: "10px" }}
              fullWidth
              viewOnly={!isEdit}
              errors={methods.errors}
              defaultValue={editDetails?.terminalNumber || ""}
              rules={{
                required: true,
                minLength: { value: 4, message: "Requires 4 digits" },
                maxLength: { value: 4, message: "Requires 4 digits" },
                pattern: regexPatterns.numericSpace,
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="merchant_location_number"
              name={"setting.tsys_sierra.merchant_location_number"}
              label="Merchant Location Number (5 digits)"
              sx={{ marginBottom: "10px" }}
              defaultValue={editDetails?.merchantLocationNumber || ""}
              fullWidth
              viewOnly={!isEdit}
              errors={methods.errors}
              rules={{
                required: true,
                minLength: { value: 5, message: "Requires 5 digits" },
                maxLength: { value: 5, message: "Requires 5 digits" },
                pattern: regexPatterns.numericSpace,
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="terminal_identification_number"
              name={"setting.tsys_sierra.terminal_identification_number"}
              label="Terminal ID Number / V Number (8 digits)"
              sx={{ marginBottom: "10px" }}
              defaultValue={editDetails?.terminalId || ""}
              fullWidth
              viewOnly={!isEdit}
              errors={methods.errors}
              rules={{
                required: true,
                minLength: { value: 8, message: "Requires 8 digits" },
                maxLength: { value: 8, message: "Requires 8 digits" },
                pattern: regexPatterns.numericSpace,
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <Select
              control={methods.control}
              sx={{ marginBottom: "10px" }}
              name="setting.tsys_sierra.classification"
              defaultValue={editDetails?.classification.toLowerCase() || ""}
              fullWidth
              errors={methods.errors}
              rules={{ required: true }}
              viewOnly={!isEdit}
              id="classification"
              label="Classification / Industry"
            >
              <MenuItem value={"ecomm"}>Ecommerce</MenuItem>
              <MenuItem value={"moto"}>MOTO</MenuItem>
              <MenuItem value={"retail"}>Retail</MenuItem>
              <MenuItem value={"ivr"}>IVR</MenuItem>
              <MenuItem value={"healthcare"}>Healthcare</MenuItem>
            </Select>
          </Grid>
          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="mcc"
              name={"setting.tsys_sierra.mcc"}
              label="MCC / SIC - Merchant Category Code (4 digits)"
              sx={{ marginBottom: "10px" }}
              defaultValue={editDetails?.mcc || ""}
              fullWidth
              viewOnly={!isEdit}
              errors={methods.errors}
              rules={{
                required: true,
                minLength: { value: 4, message: "Requires 4 digits" },
                maxLength: { value: 4, message: "Requires 4 digits" },
                pattern: regexPatterns.numericSpace,
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="store_number"
              name={"setting.tsys_sierra.store_number"}
              label="Store Number (4 digits)"
              sx={{ marginBottom: "10px" }}
              defaultValue={editDetails?.storeNumber || ""}
              viewOnly={!isEdit}
              fullWidth
              errors={methods.errors}
              rules={{
                required: true,
                minLength: { value: 4, message: "Requires 4 digits" },
                maxLength: { value: 4, message: "Requires 4 digits" },
                pattern: regexPatterns.numericSpace,
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="agent_bank_number"
              name={"setting.tsys_sierra.agent_bank_number"}
              label="Agent Bank Number (6 digits)"
              sx={{ marginBottom: "10px" }}
              fullWidth
              viewOnly={!isEdit}
              errors={methods.errors}
              defaultValue={editDetails?.agentBankNumber || ""}
              rules={{
                required: true,
                minLength: { value: 6, message: "Requires 6 digits" },
                maxLength: { value: 6, message: "Requires 6 digits" },
                pattern: regexPatterns.numericSpace,
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="agent_chain_number"
              name={"setting.tsys_sierra.agent_chain_number"}
              label="Agent Chain Number (6 digits)"
              sx={{ marginBottom: "10px" }}
              defaultValue={editDetails?.agentChainNumber || ""}
              fullWidth
              viewOnly={!isEdit}
              errors={methods.errors}
              rules={{
                required: true,
                minLength: { value: 6, message: "Requires 6 digits" },
                maxLength: { value: 6, message: "Requires 6 digits" },
                pattern: regexPatterns.numericSpace,
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="bin"
              name={"setting.tsys_sierra.bin"}
              label="BIN / Acquirer BIN (6 digits)"
              defaultValue={editDetails?.bin || ""}
              viewOnly={!isEdit}
              sx={{ marginBottom: "10px" }}
              fullWidth
              errors={methods.errors}
              rules={{
                required: true,
                minLength: { value: 6, message: "Requires 6 digits" },
                maxLength: { value: 6, message: "Requires 6 digits" },
                pattern: regexPatterns.numericSpace,
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <Select
              control={methods.control}
              sx={{ marginBottom: "10px" }}
              name="setting.tsys_sierra.industry_code"
              fullWidth
              viewOnly={!isEdit}
              id="industry_code"
              defaultValue={editDetails?.industryCode || "D"}
              label="Industry Code"
            >
              <MenuItem value={"0"}>Unknown (0)</MenuItem>
              <MenuItem value={"A"}>Auto (A)</MenuItem>
              <MenuItem value={"B"}>Bank / Financial Institution (B)</MenuItem>
              <MenuItem value={"D"}>Direct Marketing (D)</MenuItem>
              <MenuItem value={"F"}>Food / Restaurant (F)</MenuItem>
              <MenuItem value={"G"}>Grocery Store / Supermarket (G)</MenuItem>
              <MenuItem value={"H"}>Hotel (H)</MenuItem>
              <MenuItem value={"L"}>Limited Amount Terminal (L)</MenuItem>
              <MenuItem value={"O"}>
                Oil Company / Automated Fueling System (O)
              </MenuItem>
              <MenuItem value={"P"}>Passenger Transport (P)</MenuItem>
              <MenuItem value={"R"}>Retail (R)</MenuItem>
            </Select>
          </Grid>
          <Grid item md={5} xs={12}>
            <Select
              control={methods.control}
              sx={{ marginBottom: "10px" }}
              name="setting.tsys_sierra.country_code"
              defaultValue={editDetails?.countryCode || "840"}
              fullWidth
              viewOnly={!isEdit}
              id="country_code"
              label="Country Code"
            >
              <MenuItem value={"840"}>US</MenuItem>
            </Select>
          </Grid>
          <Grid item md={5} xs={12}>
            <Select
              control={methods.control}
              sx={{ marginBottom: "10px" }}
              name="setting.tsys_sierra.currency_code"
              defaultValue={editDetails?.currencyCode || "840"}
              fullWidth
              viewOnly={!isEdit}
              id="currency_code"
              label="Currency Code"
            >
              <MenuItem value={"840"}>US</MenuItem>
            </Select>
          </Grid>
          <Grid item md={5} xs={12}>
            <RegisteredTextField
              id="mvv"
              name={"setting.tsys_sierra.mvv"}
              label="MVV - Merchant Verification Value (10 characters)"
              sx={{ marginBottom: "10px" }}
              defaultValue={editDetails?.mvv || ""}
              fullWidth
              viewOnly={!isEdit}
              errors={methods.errors}
              rules={{
                minLength: { value: 10, message: "Requires 10 characters" },
                maxLength: { value: 10, message: "Requires 10 characters" },
                pattern: regexPatterns.alphanumeric,
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <Select
              control={methods.control}
              sx={{ marginBottom: "10px" }}
              name="setting.tsys_sierra.language_indicator"
              fullWidth
              viewOnly={!isEdit}
              id="language_indicator"
              defaultValue={"00"}
              label="Language Code"
            >
              <MenuItem value={"00"}>English</MenuItem>
              <MenuItem value={"01"}>Spanish</MenuItem>
              <MenuItem value={"02"}>Portuguese</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
GatewayProcessorsSierra.propTypes = {
  gatewayInfo: PropTypes.object,
  editDetails: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default GatewayProcessorsSierra;
