import React, { useState, useEffect } from "react";
import { roundToEven } from "./transactionHelpers";
import { formatTransactionCurrency } from "../../../utils/stringHelpers";
import { DialogContent, Typography, Box, Divider } from "@mui/material";
import PropTypes from "prop-types";
import FormattedTextField from "../../ReactHookForm/FormattedTextField";
import { useFormContext, useWatch } from "react-hook-form";

const IncreaseAuthForm = ({ transactionData, setError, gatewayInfo }) => {
  const [error, setErrorState] = useState("");

  const methods = useFormContext();
  const originalAmount = parseFloat(transactionData.amountAuthorized) || 0;
  const originalBaseAmount = originalAmount - transactionData.surcharge;
  const originalSurcharge = transactionData.surcharge;

  const surchargeTips =
    gatewayInfo.gatewaySettings
      .find((setting) => setting.code === "SURCHARGE_TIPS")
      ?.value?.toLowerCase() === "true";

  const allowSurcharge =
    gatewayInfo.gatewaySettings
      .find((setting) => setting.code === "ALLOW_SURCHARGE")
      ?.value?.toLowerCase() === "true";

  const shouldDisplaySurcharge =
    transactionData.surcharge > 0 && surchargeTips && allowSurcharge;

  const increase = useWatch({
    control: methods.control,
    name: "increaseAmount",
    defaultValue: 0,
  });

  const total = useWatch({
    control: methods.control,
    name: "totalAmount",
    defaultValue: originalAmount,
  });

  const surchargeIncrease = useWatch({
    control: methods.control,
    name: "surchargeIncrease",
    defaultValue: 0,
  });

  useEffect(() => {
    const increaseAmount = increase / 100 || 0;

    let newSurchargeIncrease = 0;
    if (shouldDisplaySurcharge) {
      // Calculate new surcharge only if conditions are met
      newSurchargeIncrease = roundToEven(
        (increaseAmount * originalSurcharge) / originalBaseAmount,
      );
    }

    const newTotalAmount =
      originalAmount + increaseAmount + newSurchargeIncrease;

    // Update the surcharge increase if it changes
    if (
      Math.round(newSurchargeIncrease * 100) !==
      Math.round(surchargeIncrease * 100)
    ) {
      methods.setValue("surchargeIncrease", newSurchargeIncrease);
    }

    // Update total amount in form state
    methods.setValue("totalAmount", (newTotalAmount * 100).toFixed(0));

    // Validate the increase amount
    if (increaseAmount <= 0) {
      setErrorState("Tip Amount must be greater than zero.");
      setError("Tip Amount must be greater than zero.");
    } else {
      setErrorState("");
      setError("");
    }
  }, [
    increase,
    transactionData,
    setError,
    originalAmount,
    surchargeIncrease,
    methods,
    originalBaseAmount,
    originalSurcharge,
    shouldDisplaySurcharge,
  ]);

  const handleKeyDown = (e) => {
    let currentValue = methods.getValues("increaseAmount");
    let parsedValue = Number.parseFloat(currentValue);

    if (e.key === "ArrowUp" && parsedValue >= 0.0) {
      currentValue = parsedValue + 100;
    }

    if (e.key === "ArrowDown" && parsedValue > 0.0) {
      currentValue = parsedValue - 100;
    }

    methods.setValue("increaseAmount", currentValue);
  };

  return (
    <DialogContent>
      <Box display="flex" flexDirection="column" padding="15px 15px" gap="10px">
        <Box display="flex" width="100%">
          <Box
            flex={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            pr={2}
          >
            <Typography variant="body1" align="right" justifyContent="flex-end">
              Original Amount:
            </Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <Typography variant="body1">
              {formatTransactionCurrency(originalAmount)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" width="100%">
          <Box
            flex={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            pr={2}
          >
            <Typography
              variant="body1"
              fontWeight="bold"
              justifyContent="flex-end"
              align="right"
            >
              Amount to Tip:
            </Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end" ml={1}>
            <FormattedTextField
              type="auth"
              name="increaseAmount"
              onKeyDown={handleKeyDown}
              highlightError={increase <= 0}
              justifyContent="flex-end"
              alignItems="right"
            />
          </Box>
        </Box>
        <Divider sx={{ margin: "10px 0", backgroundColor: "#ccc" }} />
        {shouldDisplaySurcharge && (
          <Box display="flex" width="100%">
            <Box flex={1} display="flex" justifyContent="flex-end" pr={2}>
              <Typography variant="body1" align="right">
                Surcharge:
              </Typography>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Typography variant="body1">
                {formatTransactionCurrency(surchargeIncrease)}
              </Typography>
            </Box>
          </Box>
        )}
        <Box display="flex" width="100%">
          <Box flex={1} display="flex" justifyContent="flex-end" pr={2}>
            <Typography variant="h6" align="right" fontWeight="bold">
              Total Amount:
            </Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <Typography variant="h6" fontWeight="bold">
              {formatTransactionCurrency(total / 100)}
            </Typography>
          </Box>
        </Box>
        {error && (
          <Typography
            variant="body2"
            color="error"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            {error}
          </Typography>
        )}
      </Box>
    </DialogContent>
  );
};
IncreaseAuthForm.propTypes = {
  transactionData: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  gatewayInfo: PropTypes.object.isRequired,
};

export default IncreaseAuthForm;
