import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormContext, useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import MultipleSelectNative from "../../ReactHookForm/MultipleSelectNative";
import Checkbox from "../../ReactHookForm/Checkbox";
import Radio from "../../ReactHookForm/Radio";
import Select from "../../ReactHookForm/Select";
import { FormControl } from "@mui/material";
import { validationRules } from "../../../constants/validationRules";

export const handleCustomFieldMultipleValues = (customerFieldValue) => {
  let fieldsWithMultipleValues = {};

  customerFieldValue.forEach((field) => {
    if (field.type === "Multiselect" || field.type === "Checklist") {
      if (!Object.keys(fieldsWithMultipleValues).includes(field.name)) {
        fieldsWithMultipleValues[field.name] = [field.value];
      } else {
        fieldsWithMultipleValues[field.name].push(field.value);
      }
    }
  });

  return fieldsWithMultipleValues;
};

export const customFieldFactory = (
  selectedGroup,
  customField,
  control,
  errors,
  customerFieldValue,
) => {
  let fieldsWithMultipleValues = {};
  if (customerFieldValue) {
    fieldsWithMultipleValues =
      handleCustomFieldMultipleValues(customerFieldValue);
  }

  const name = `custom_fields.${selectedGroup}.${customField.customFieldId}`;
  let customFieldComponent;
  let fieldOptions =
    customField.customFieldOption.length > 0
      ? customField.customFieldOption.map((option) => {
          return { name: option.label, value: option.value };
        })
      : [];
  switch (customField.type) {
    case "Text":
      customFieldComponent = (
        <RegisteredTextField
          id={customField.customFieldId.toString()}
          name={name}
          label={customField.name}
          defaultValue={
            customerFieldValue
              ? customerFieldValue.find(
                  (element) => element.name === customField.name,
                )?.value
              : ""
          }
          fullWidth
          rules={{
            required:
              customField.isRequired && `${customField.name} is required`,
            maxLength: validationRules.maxLength255,
            pattern:
              customField.dataType === "System.UInt32"
                ? validationRules.numericSpace
                : validationRules.alphanumericSpace,
          }}
        />
      );
      break;
    case "Checklist":
      customFieldComponent = (
        <Checkbox
          name={name}
          label={customField.name}
          control={control}
          defaultValues={
            customerFieldValue
              ? Object.keys(fieldsWithMultipleValues).includes(customField.name)
                ? fieldsWithMultipleValues[customField.name]
                : []
              : []
          }
          errors={errors}
          rules={{
            required:
              customField.isRequired && `${customField.name} is required`,
          }}
          options={fieldOptions}
        />
      );
      break;

    case "Radio":
      customFieldComponent = (
        <Radio
          name={name}
          label={customField.name}
          defaultValue={
            customerFieldValue
              ? customerFieldValue.find(
                  (element) => element.name === customField.name,
                )?.value
              : ""
          }
          control={control}
          errors={errors}
          rules={{
            required:
              customField.isRequired && `${customField.name} is required`,
          }}
          options={fieldOptions}
        />
      );
      break;

    case "Select":
      customFieldComponent = (
        <Select
          name={name}
          label={customField.name}
          defaultValue={
            customerFieldValue
              ? customerFieldValue.find(
                  (element) => element.name === customField.name,
                )
                ? customerFieldValue.find(
                    (element) => element.name === customField.name,
                  ).value
                : [fieldOptions[0].value]
              : ""
          }
          control={control}
          errors={errors}
          rules={{
            required:
              customField.isRequired && `${customField.name} is required`,
          }}
          options={fieldOptions}
          returnType="array"
          fullWidth
        />
      );
      break;
    case "Multiselect":
      customFieldComponent = (
        <MultipleSelectNative
          name={name}
          label={customField.name}
          defaultValue={
            customerFieldValue
              ? Object.keys(fieldsWithMultipleValues).includes(customField.name)
                ? fieldsWithMultipleValues[customField.name]
                : [fieldOptions[0].value]
              : [fieldOptions[0].value]
          }
          control={control}
          errors={errors}
          rules={{
            required:
              customField.isRequired && `${customField.name} is required`,
          }}
          options={fieldOptions}
        />
      );
      break;
    default:
      customFieldComponent = (
        <Typography color="red">{`Error: custom field ${customField.customFieldId} contains unknown '${customField.type}' type.`}</Typography>
      );
      break;
  }

  return (
    <Grid item xs={12} key={customField.customFieldId}>
      {customFieldComponent}
    </Grid>
  );
};

export const CustomFields = ({
  customFieldGroups,
  chosenCustomFields,
  customerFieldNameAndValues,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const groupValue = useWatch({
    control,
    name: "customer_field_group_select",
    defaultValue: customerFieldNameAndValues
      ? customerFieldNameAndValues[0]
      : customFieldGroups
        ? customFieldGroups[0]
        : null,
  });

  if (customerFieldNameAndValues) {
    useEffect(() => {
      if (customerFieldNameAndValues && customerFieldNameAndValues[2]) {
        setValue("customer_field_group_select", customerFieldNameAndValues[0]);
      }
    }, [customerFieldNameAndValues[2]]);
  }

  const [chosenGroupFields, setChosenGroupFields] = useState(
    customerFieldNameAndValues
      ? chosenCustomFields[
          customFieldGroups.indexOf(customerFieldNameAndValues[0])
        ]
      : chosenCustomFields
        ? chosenCustomFields[0]
        : null,
  );

  return (
    <>
      {customFieldGroups === null || chosenCustomFields === null ? (
        <></>
      ) : (
        <Grid container spacing={2}>
          {customFieldGroups && chosenCustomFields && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    control={control}
                    id="custom-field-group-select"
                    label="Custom Field Group"
                    name={"customer_field_group_select"}
                    value={groupValue}
                    defaultValue={customFieldGroups[0]}
                    onChange={(e) => {
                      setValue("customer_field_group_select", e);
                      setChosenGroupFields(
                        chosenCustomFields[customFieldGroups.indexOf(e)],
                      );
                    }}
                  >
                    {customFieldGroups?.map((groupName, i) => (
                      <MenuItem key={i} value={groupName}>
                        {groupName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {customerFieldNameAndValues &&
                chosenGroupFields.map((field) => {
                  return customFieldFactory(
                    groupValue,
                    field,
                    control,
                    errors,
                    customerFieldNameAndValues[1],
                  );
                })}
              {!customerFieldNameAndValues &&
                groupValue &&
                chosenGroupFields.map((field) => {
                  return customFieldFactory(groupValue, field, control, errors);
                })}
            </>
          )}
        </Grid>
      )}
    </>
  );
};

CustomFields.propTypes = {
  defaults: PropTypes.object,
  chosenCustomFields: PropTypes.array,
  customFieldGroups: PropTypes.array,
  customerFieldNameAndValues: PropTypes.array,
};

export default CustomFields;
