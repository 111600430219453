import { convertPropertyToName } from "../../../../../utils/stringHelpers";
import { Grid } from "@mui/material";
import MUISwitch from "@mui/material/Switch";
import PropTypes from "prop-types";
import { useContext } from "react";
import { GatewayUserDetailContext } from "../../../../../contexts/GatewayUserDetailContext";

const GatewayUserPermissions = ({ displayedPermissions }) => {
  const { userData, handlePayloadPermissions, editGatewayUser } = useContext(
    GatewayUserDetailContext,
  );
  const handlePermissions = (property) => {
    let found = false;
    userData?.gatewayUserPermissions?.forEach((p) => {
      if (p.code === property.code) {
        found = true;
      }
    });
    return found;
  };

  const changePermission = async (permissionName, checked) => {
    const payload = {
      gatewayUserStatus: userData?.gatewayUserStatus,
      userStatus: userData?.user?.status,
      firstName: userData?.user?.firstName,
      lastName: userData?.user?.lastName,
      phone: userData?.phone,
      roleId: userData?.role?.roleId,
      permissionIds: handlePayloadPermissions(permissionName, checked),
    };
    editGatewayUser(payload, userData.gatewayUserId);
  };
  return (
    <Grid
      container
      flexDirection={"row"}
      justifyContent="space-between"
      className="permission-switches"
    >
      {displayedPermissions
        .slice(0, Math.floor(displayedPermissions.length / 2))
        .map((p, i) => {
          {
            return (
              <Grid key={i} item xs={12} md={5.5}>
                <Grid container justifyContent={"space-between"}>
                  {convertPropertyToName(p?.code?.toLowerCase())}
                  <MUISwitch
                    onChange={(e) => {
                      changePermission(e.target.name, e.target.checked);
                    }}
                    name={p?.code}
                    checked={handlePermissions(p)}
                  />
                </Grid>
              </Grid>
            );
          }
        })}

      {displayedPermissions
        .slice(
          Math.ceil(displayedPermissions.length / 2),
          displayedPermissions.length,
        )
        .map((p, i) => {
          return (
            <Grid key={i} item xs={12} md={5.5}>
              <Grid container justifyContent={"space-between"}>
                {convertPropertyToName(p?.code?.toLowerCase())}
                <MUISwitch
                  name={p?.code}
                  onChange={(e) => {
                    changePermission(e.target.name, e.target.checked);
                  }}
                  checked={handlePermissions(p)}
                />
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

GatewayUserPermissions.propTypes = {
  displayedPermissions: PropTypes.array,
};

export default GatewayUserPermissions;
