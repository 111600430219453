import { useState } from "react";

export const useSnackbar = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});

  const showSnackbar = (message, severity = "success", banner = false) => {
    setSnackbarProps({
      message: message,
      severity: severity,
      banner: banner,
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };

  return {
    snackbarOpen,
    snackbarProps,
    showSnackbar,
  };
};
export default useSnackbar;
