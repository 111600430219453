import React from "react";
import { useFormContext } from "react-hook-form";
import "./simplePay.scss";
import "../../../assets/v2/main.scss";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { InputLabel } from "@mui/material";

const TwoTabsInput = ({
  name,
  leftValue,
  rightValue,
  leftLabel,
  rightLabel,
  leftHelperText,
  rightHelperText,
  disabled,
}) => {
  const { ...methods } = useFormContext();

  const handleTabChange = (event, newValue) => {
    newValue === 1
      ? methods.setValue(name, leftValue)
      : methods.setValue(name, rightValue);
  };

  return (
    <>
      <div className={"tabs-container"}>
        {/* Figure out margins */}
        <InputLabel sx={{ marginTop: "8px" }}>Logo</InputLabel>
        <Controller
          control={methods.control}
          name={name}
          disabled={disabled}
          render={({ field }) => (
            <>
              {disabled ? (
                <Tabs
                  aria-label="basic tabs example"
                  value={field.value === leftValue ? 1 : 2}
                  onChange={handleTabChange}
                  className={"primary"}
                >
                  {field.value === leftValue ? (
                    <Tab iconPosition="start" value={1} label={leftLabel} />
                  ) : (
                    <Tab iconPosition="start" value={2} label={rightLabel} />
                  )}
                </Tabs>
              ) : (
                <Tabs
                  aria-label="basic tabs example"
                  value={field.value === leftValue ? 1 : 2}
                  onChange={handleTabChange}
                  className={"primary"}
                >
                  <Tab iconPosition="start" value={1} label={leftLabel} />
                  <Tab iconPosition="start" value={2} label={rightLabel} />
                </Tabs>
              )}
            </>
          )}
        />
        {!disabled && (
          <div className={"tab-helper-text"}>
            <p>{leftHelperText}</p>
            <p>{rightHelperText}</p>
          </div>
        )}
      </div>
    </>
  );
};

TwoTabsInput.propTypes = {
  name: PropTypes.string.isRequired,
  leftValue: PropTypes.string.isRequired,
  rightValue: PropTypes.string.isRequired,
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
  leftHelperText: PropTypes.string,
  rightHelperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TwoTabsInput;
