import React from "react";
import Header from "./Header";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "../../contexts/ColorModeContext";

const HeaderContainer = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <Header
      handleClose={handleClose}
      handleClick={handleClick}
      open={open}
      anchorEl={anchorEl}
      theme={theme}
      colorMode={colorMode}
      {...props}
    />
  );
};

HeaderContainer.propTypes = {
  history: PropTypes.object,
};
export default withRouter(HeaderContainer);
