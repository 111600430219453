import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  getTransactionStatusDisplayInfo,
  currencyInputOnKeyDown,
  currencyInputOnChange,
} from "./transactionHelpers";
import ActionButton from "../../Buttons/ActionButton";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertDialog from "../../Dialog/AlertDialog";
import * as mp from "../../../services/mixpanel/merchant/reportingTransactionsEvents";
import { validationRules } from "../../../constants/validationRules";
import { getProperty } from "../../../utils/objectHelpers";

const DetailStatusActions = ({
  transactionData,
  voidTransaction,
  captureTransaction,
  refundTransaction,
  vaultTransaction,
  onIncreaseAuthClick,
  emailTransaction,
  printTransaction,
  fieldBoldness,
  userSettings,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const email_errors = getProperty("email_addresses", errors);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "",
  });

  let mixpanelPayload = {
    page: "transaction details",
  };

  const closeSnackBar = () => {
    setOpenSnackBar(false);
  };

  const [actionButtonsDisabled, setActionButtonsDisabled] = useState(false);
  const [showCaptureRefundCommand, setShowCaptureRefundCommand] = useState("");
  const [showVoidCommand, setShowVoidCommand] = useState(false);
  const [showEmailCommand, setShowEmailCommand] = useState(false);
  const [showVaultCommand, setShowVaultCommand] = useState(false);

  const handleVoidButtonOnClick = () => {
    setActionButtonsDisabled(true);
    setShowVoidCommand(true);
  };

  const handleCaptureButtonOnClick = () => {
    setActionButtonsDisabled(true);

    setShowCaptureRefundCommand("capture");
  };

  const handleRefundButtonOnClick = () => {
    setActionButtonsDisabled(true);
    setShowCaptureRefundCommand("refund");
  };

  const handlePrintButtonOnClick = () => {
    printTransaction(transactionData.transactionId);
  };

  const handleEmailButtonOnClick = () => {
    setActionButtonsDisabled(true);
    setShowEmailCommand(true);
  };

  const handleVaultButtonOnClick = () => {
    setActionButtonsDisabled(true);
    setShowVaultCommand(true);
  };

  const resetActionButtons = () => {
    setActionButtonsDisabled(false);

    //Reset visible commands
    if (showCaptureRefundCommand) setShowCaptureRefundCommand(null);
    if (showVoidCommand) setShowVoidCommand(false);
    if (showVaultCommand) setShowVaultCommand(false);
    if (showEmailCommand) setShowEmailCommand(false);
  };

  const handleActionButtonCancelOnClick = () => {
    resetActionButtons();
  };

  const handleVoidProcessButtonOnClick = async () => {
    let errorMsg;

    // Void transaction and wait for response
    errorMsg = await voidTransaction(transactionData.transactionId);
    Object.assign(mixpanelPayload, {
      transaction_id: transactionData.transactionId,
      action_type: "voided",
    });

    if (errorMsg !== null) {
      setAlertMessage([errorMsg]);
      setAlertOpen(true);

      mp.reportingTransactionsActionFailed(mixpanelPayload);
    } else {
      resetActionButtons();
      setSnackBarState({ message: "Request has been voided!" });
      setOpenSnackBar(true);
      Object.assign(mixpanelPayload, {
        error: errorMsg,
      });
      mp.reportingTransactionsActionSuccess(mixpanelPayload);
    }
  };

  const handleIncrementAuthButtonOnClick = () => {
    onIncreaseAuthClick();
  };

  const handleVaultProcessButtonOnClick = () => {
    resetActionButtons();
    vaultTransaction(transactionData.transactionId);
  };

  const onCaptureRefundSubmit = async (data) => {
    let errorMsg;

    if (data.command_type === "refund") {
      Object.assign(mixpanelPayload, {
        transaction_id: transactionData.transactionId,
        action_type: "refunded",
        amount: data.amount,
      });
      //Refund transaction and wait for response
      errorMsg = await refundTransaction(
        transactionData.transactionId,
        data.amount,
      );

      //Check for error response message
      if (errorMsg !== null) {
        setAlertMessage([errorMsg]);
        setAlertOpen(true);
      } else {
        resetActionButtons();

        setSnackBarState({
          severity: "success",
          message: "Your request has been refunded!",
        });

        mp.reportingTransactionsActionSuccess(mixpanelPayload);
        setOpenSnackBar(true);
      }
    }

    if (data.command_type === "capture") {
      Object.assign(mixpanelPayload, {
        transaction_id: transactionData.transactionId,
        action_type: "captured",
        amount: data.amount,
      });
      //Capture transaction and wait for response
      errorMsg = await captureTransaction(
        transactionData.transactionId,
        data.amount,
      );

      if (errorMsg !== null) {
        setAlertMessage([errorMsg]);
        setAlertOpen(true);
        Object.assign(mixpanelPayload, {
          error: errorMsg,
        });
        mp.reportingTransactionsActionFailed(mixpanelPayload);
      } else {
        resetActionButtons();

        setSnackBarState({
          severity: "success",
          message: "Your request has been captured!",
        });
        mp.reportingTransactionsActionSuccess(mixpanelPayload);

        setOpenSnackBar(true);
      }
    }
  };

  const onEmailSubmit = async (data) => {
    let emailAddressesArray = data.email_addresses.split(",");

    Object.assign(mixpanelPayload, {
      action_type: "emailed",
      email: data.email_addresses,
    });

    let errorMsg = await emailTransaction(
      transactionData.transactionId,
      emailAddressesArray,
    );

    if (errorMsg?.length) {
      Object.assign(mixpanelPayload, {
        error: errorMsg,
      });
      mp.reportingTransactionsActionFailed(mixpanelPayload);

      setAlertMessage(errorMsg.split(", "));
      setAlertOpen(true);
    } else {
      resetActionButtons();

      setSnackBarState({
        severity: "success",
        message: "Successfully sent email(s)",
      });
      setOpenSnackBar(true);
      mp.reportingTransactionsActionSuccess(mixpanelPayload);
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  const renderVoidCommand = () => {
    return (
      <>
        <Typography
          variant="h6"
          color="textSecondary"
          display="inline"
          sx={{ margin: 1 }}
        >
          Void Transaction?
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{ margin: 0.5 }}
          onClick={handleVoidProcessButtonOnClick}
        >
          Process
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ margin: 0.5 }}
          onClick={handleActionButtonCancelOnClick}
        >
          Cancel
        </Button>
      </>
    );
  };

  const renderVaultCommand = () => {
    return (
      <>
        <Typography
          variant="h6"
          color="textSecondary"
          display="inline"
          sx={{ margin: 1 }}
        >
          Process Vault?
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{ margin: 0.5 }}
          data-cy="process-vault"
          onClick={handleVaultProcessButtonOnClick}
        >
          Process
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ margin: 0.5 }}
          onClick={handleActionButtonCancelOnClick}
        >
          Cancel
        </Button>
      </>
    );
  };

  const renderEmailCommand = () => {
    return (
      <form onSubmit={handleSubmit(onEmailSubmit, onError)}>
        <FormControl sx={{ margin: 0.5, width: 370 }}>
          <TextField
            autoFocus
            fullWidth
            autoComplete="off"
            size="small"
            fontSize="12"
            error={email_errors !== undefined}
            helperText={email_errors && email_errors.message}
            placeholder="email@example.com, email2@example.com"
            FormHelperTextProps={{
              sx: { ml: 0 },
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              type: "text",
              maxLength: 500,
              style: { fontSize: 12 },
            }}
            defaultValue=""
            {...register("email_addresses", {
              required: {
                value: true,
                message: "A valid email address is required",
              },
              pattern: validationRules.multipleEmail,
            })}
          />
        </FormControl>
        <Button
          variant="contained"
          size="small"
          color="primary"
          type="submit"
          sx={{ margin: 0.5 }}
        >
          Send
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ margin: 0.5 }}
          onClick={handleActionButtonCancelOnClick}
        >
          Cancel
        </Button>
      </form>
    );
  };

  const renderCaptureRefundCommand = () => {
    let defaultAmount = 0;

    if (showCaptureRefundCommand === "capture") {
      defaultAmount = transactionData.remit.amountAuthorized.toFixed(2);
    } else if (showCaptureRefundCommand === "refund") {
      defaultAmount = transactionData.remit.amountCaptured.toFixed(2);
    }

    return (
      <form onSubmit={handleSubmit(onCaptureRefundSubmit, onError)}>
        <FormControl
          size="small"
          sx={{ margin: 0.5, width: 175 }}
          onChange={currencyInputOnChange}
          onKeyDown={currencyInputOnKeyDown}
        >
          <OutlinedInput
            autoFocus
            autoComplete="off"
            inputProps={{ type: "text", maxLength: 12, size: "small" }}
            defaultValue={defaultAmount}
            {...register("amount")}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <input
          {...register("command_type")}
          type="hidden"
          value={showCaptureRefundCommand}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ margin: 0.5 }}
        >
          Process
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ margin: 0.5 }}
          onClick={handleActionButtonCancelOnClick}
        >
          Cancel
        </Button>
      </form>
    );
  };

  return (
    <>
      <AlertDialog
        alertOpen={alertOpen}
        alertTitle="Error!"
        alertLevel="error"
        alertMessages={alertMessage}
        closeButtonText="Ok"
        closeButtonColor="neutrals"
        onCloseButtonClick={() => setAlertOpen(false)}
      />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackBar}
        onClose={closeSnackBar}
        autoHideDuration={5000}
      >
        <Alert onClose={closeSnackBar} severity={snackBarState.severity}>
          {snackBarState.message}
        </Alert>
      </Snackbar>

      <Grid item xs={12} md={8}>
        <ActionButton
          action="transaction_void"
          renderType="button"
          userSettings={userSettings}
          data={transactionData}
          disabled={actionButtonsDisabled}
          onClick={handleVoidButtonOnClick}
        />
        <ActionButton
          action="transaction_capture"
          renderType="button"
          userSettings={userSettings}
          data={transactionData}
          disabled={actionButtonsDisabled}
          onClick={handleCaptureButtonOnClick}
        />
        <ActionButton
          action="transaction_refund"
          renderType="button"
          userSettings={userSettings}
          data={transactionData}
          disabled={actionButtonsDisabled}
          onClick={handleRefundButtonOnClick}
        />
        <ActionButton
          action="transaction_print"
          renderType="button"
          userSettings={userSettings}
          data={transactionData}
          disabled={actionButtonsDisabled}
          onClick={handlePrintButtonOnClick}
        />
        <ActionButton
          action="transaction_email"
          renderType="button"
          userSettings={userSettings}
          data={transactionData}
          disabled={actionButtonsDisabled}
          onClick={handleEmailButtonOnClick}
        />
        <ActionButton
          action="transaction_vault"
          renderType="button"
          userSettings={userSettings}
          data={transactionData}
          disabled={actionButtonsDisabled}
          onClick={handleVaultButtonOnClick}
        />
        <ActionButton
          action="transaction_increment_auth"
          renderType="button"
          userSettings={userSettings}
          data={transactionData}
          disabled={actionButtonsDisabled}
          onClick={handleIncrementAuthButtonOnClick}
        />
      </Grid>
      <Grid item xs={12} md={4} textAlign="right" paddingRight={2}>
        <Typography
          variant="h6"
          color={getTransactionStatusDisplayInfo(transactionData.status).color}
          sx={{ fontWeight: fieldBoldness }}
          data-cy="transaction_status_display_info"
        >
          {getTransactionStatusDisplayInfo(transactionData.status).display}
        </Typography>
      </Grid>
      {/* Action Forms */}
      <Grid item xs={12} md={12} paddingRight={2}>
        {showVoidCommand && renderVoidCommand()}
        {showCaptureRefundCommand && renderCaptureRefundCommand()}
        {showEmailCommand && renderEmailCommand()}
        {showVaultCommand && renderVaultCommand()}
      </Grid>
    </>
  );
};

DetailStatusActions.propTypes = {
  transactionData: PropTypes.object.isRequired,
  voidTransaction: PropTypes.func.isRequired,
  captureTransaction: PropTypes.func.isRequired,
  refundTransaction: PropTypes.func.isRequired,
  vaultTransaction: PropTypes.func.isRequired,
  emailTransaction: PropTypes.func.isRequired,
  printTransaction: PropTypes.func.isRequired,
  fieldBoldness: PropTypes.string.isRequired,
  userSettings: PropTypes.object.isRequired,
  onIncreaseAuthClick: PropTypes.func.isRequired,
};

export default DetailStatusActions;
