import { useState, useEffect } from "react";
import ResetTOS from "./ResetTOS";
import ContentComponent from "../../Content/Content";
import { FormProvider, useForm } from "react-hook-form";
import { basysEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import { useSnackbar } from "../../../hooks";
import { formatDateTime } from "../../../utils/stringHelpers";
import { getResponseErrorMessage } from "../../../utils/objectHelpers";
const ResetTOSContainer = () => {
  const { ...methods } = useForm();
  const { snackbarOpen, snackbarProps, showSnackbar } = useSnackbar();
  const [tosTime, setTosTime] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});

  useEffect(() => {
    getResetTosDate();
  }, []);

  const postResetTos = () => {
    let url = `${basysEndpoints.tos}/reset`;
    setShowBackdrop(true);

    axios
      .post(url, { reason: methods.getValues().reason })
      .then(function () {
        showSnackbar("Terms of Service reset");
        methods.resetField("reason");
        getResetTosDate();
      })
      .catch(function (error) {
        showAlertDialogError(getResponseErrorMessage(error?.response?.data));
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const handleResetClick = () => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: ["This will reset Terms of Service for all gateway users"],
      actionButtons: [
        {
          text: "Yes, Reset TOS!",
          color: "secondary",
          onclick: () => {
            postResetTos();
            handleClosePromptDialog();
          },
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: handleClosePromptDialog,
    });
    setAlertDialogOpen(true);
  };

  const handleClosePromptDialog = () => {
    setAlertDialogProps({});
    setAlertDialogOpen(false);
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const getResetTosDate = async () => {
    let url = basysEndpoints.tos;
    axios
      .get(url)
      .then(function (res) {
        setTosTime(formatDateTime(res.data?.data?.reset_at).split(" "));
      })
      .catch(function (error) {
        showAlertDialogError(error);
      });
  };
  return (
    <FormProvider {...methods}>
      <ContentComponent
        spinnerOpen={showBackdrop}
        title="Terms of Service - Gateway User"
        bodyContent={
          <ResetTOS handleResetClick={handleResetClick} tosTime={tosTime} />
        }
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
      />
    </FormProvider>
  );
};

export default ResetTOSContainer;
