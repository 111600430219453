import mixpanel from "mixpanel-browser";
import { flattenedObject } from "../../../utils/flattenedObject";
export const createCustomerEvent = async (payload) => {
  const flattenedPayload = flattenedObject(payload);
  mixpanel.track("Customers - Customer Created", flattenedPayload);
};

export const customerSearchEvent = (payload) => {
  const invalidParams = ["limit", "offset", "filter_by"];
  let searchObject = {};
  Object.keys(payload).forEach((key) => {
    if (!invalidParams.includes(key)) {
      if (key === "updatedDate" || key === "createdDate") {
        Reflect.set(
          searchObject,
          key,
          payload[key]?.start_date?.split("T")[0] +
            " - " +
            payload[key]?.start_date?.split("T")[0],
        );
      } else {
        Reflect.set(searchObject, key, payload[key]?.operator);
      }
    }
  });
  mixpanel.track("Customers - Searched", searchObject);
};
