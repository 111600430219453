import React from "react";
import PropTypes from "prop-types";
import "./physicalTerminal.scss";
import { capitalize } from "../../../../utils/stringHelpers";
const TerminalList = ({ terminals, selectTerminal, selectedTerminal }) => {
  return (
    <div className="pt-list">
      <h2>Select Terminal</h2>
      <div className="list-wrapper">
        {terminals.map((t, i) => {
          return (
            <button
              className={`terminal-button  ${t.status} ${
                selectedTerminal?.terminalId === t?.terminalId && "selected"
              }`}
              key={i}
              onClick={() => {
                if (t.status === "active") {
                  selectTerminal(t);
                }
              }}
            >
              <div className="button-header">
                <span>{t.name}</span>{" "}
                <span className="terminal-status">{capitalize(t.status)}</span>
              </div>

              <span>SIN: {t.serialNumber}</span>
              <span>
                {t.terminalType.make}/{t.terminalType.model}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

TerminalList.propTypes = {
  terminals: PropTypes.array,
  selectTerminal: PropTypes.func,
  selectedTerminal: PropTypes.object,
};

export default TerminalList;
