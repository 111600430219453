import { Grid, Select, MenuItem, Tooltip } from "@mui/material";
import { GATEWAY_USER_ROLE_CODES } from "../../../../../constants/global";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useContext } from "react";
import { GatewayUserDetailContext } from "../../../../../contexts/GatewayUserDetailContext";

const GatewayUserEditDropdowns = () => {
  const {
    userData,
    gatewayRole,
    gatewayUserStatus,
    userStatus,
    triggerAlert,
    setAlertDialogOpen,
    setAlertDialogProps,
    editGatewayUser,
    editUser,
    handlePayloadPermissions,
  } = useContext(GatewayUserDetailContext);
  let payloadModel = {
    firstName: userData?.user?.firstName,
    lastName: userData?.user?.lastName,
    phone: userData?.phone,
  };

  const handleRoleChange = (e) => {
    const payload = {
      ...payloadModel,
      gatewayUserStatus: userData?.gatewayUserStatus,
      roleId: e.target.value,
      permissionIds: handlePayloadPermissions(),
      userStatus: userStatus,
    };
    editGatewayUser(payload, userData.gatewayUserId, "role");
  };
  const handleUserStatusChange = (e) => {
    if (e.target.value === "Disabled") {
      handleDisableClick();
    } else {
      const payload = {
        ...payloadModel,
        status: e.target.value,
        bypassMfa: userData?.user?.bypassMfa,
        mfaEnabled: userData?.user?.mfaEnabled,
      };
      editUser(payload, true);
    }
  };
  const handleGatewayUserStatusChange = (e) => {
    const payload = {
      ...payloadModel,
      gatewayUserStatus: e.target.value,
      roleId: userData?.role?.roleId,
      permissionIds: handlePayloadPermissions(),
    };
    editGatewayUser(payload, userData.gatewayUserId, "status");
  };

  const disableUser = async () => {
    const payload = {
      ...payloadModel,
      status: "Disabled",
      bypassMfa: userData?.user?.bypassMfa,
      mfaEnabled: userData?.user?.mfaEnabled,
    };
    editUser(payload, true);
  };

  const handleDisableClick = () => {
    triggerAlert(
      ["You are disabling this user on all associate gateways"],
      {
        text: "Yes, disable user",
        color: "secondary",
        onclick: () => {
          disableUser();
          setAlertDialogProps({});
          setAlertDialogOpen(false);
        },
      },
      () => {
        setAlertDialogProps({});
        setAlertDialogOpen(false);
      },
    );
  };

  return (
    <>
      {userData && userStatus && gatewayRole && gatewayUserStatus ? (
        <>
          <Grid container justifyContent={"space-between"}>
            <Grid item>Role: </Grid>
            <Grid item>
              {userData?.role?.code === "GA" ||
              userData?.role?.code === "GS" ? (
                <Select
                  name="role"
                  onChange={handleRoleChange}
                  defaultValue={userData?.role?.roleId}
                  value={gatewayRole}
                >
                  <MenuItem
                    key={0}
                    value={GATEWAY_USER_ROLE_CODES.admin}
                    data-cy="gateway_user_role_select"
                  >
                    Gateway Admin
                  </MenuItem>
                  <MenuItem
                    key={1}
                    value={GATEWAY_USER_ROLE_CODES.standard}
                    data-cy="gateway_user_role_select"
                  >
                    Gateway Standard
                  </MenuItem>
                </Select>
              ) : (
                userData?.role?.description
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <Grid item>Gateway User Status: </Grid>
            <Grid item>
              <Select
                name="role"
                onChange={handleGatewayUserStatusChange}
                defaultValue={userData?.gatewayUserStatus}
                value={gatewayUserStatus}
              >
                <MenuItem
                  key={0}
                  value={"Active"}
                  data-cy="gateway_user_status_select"
                  disabled={
                    userData?.gatewayUserStatus?.toLowerCase() === "active"
                  }
                >
                  Active
                </MenuItem>
                <MenuItem
                  key={1}
                  value={"Disabled"}
                  disabled={
                    userData?.gatewayUserStatus.toLowerCase() === "disabled"
                  }
                  data-cy="gateway_user_status_select"
                >
                  Disabled
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Grid container>
                <Grid item> User Status:</Grid>
                <Grid item>
                  <Tooltip
                    title={
                      "If the user status is disabled, the user will not be able to login to any gateway that this email address is associated with."
                    }
                    placement="top"
                  >
                    <ErrorOutlineIcon style={{ color: "#1C7BC0" }} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Select
                name="role"
                onChange={handleUserStatusChange}
                defaultValue={userStatus}
                value={userStatus}
              >
                <MenuItem
                  key={0}
                  value={"Active"}
                  disabled={true}
                  data-cy="user_status_select"
                >
                  Active
                </MenuItem>
                <MenuItem
                  key={1}
                  value={"Disabled"}
                  disabled={userData.user.status.toLowerCase() === "disabled"}
                  data-cy="user_status_select"
                >
                  Disabled
                </MenuItem>
                <MenuItem
                  key={2}
                  value={"PendingTOS"}
                  disabled={
                    userData.user.status.toLowerCase() === "pendingtos" ||
                    userData.user.status.toLowerCase() === "invitepending"
                  }
                  data-cy="user_status_select"
                >
                  Pending TOS
                </MenuItem>
                <MenuItem
                  key={3}
                  value={"InvitePending"}
                  disabled={
                    userData.user.status.toLowerCase() === "invitepending" ||
                    userData.user.status.toLowerCase() === "disabled"
                  }
                  data-cy="user_status_select"
                >
                  Invite Pending
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default GatewayUserEditDropdowns;
