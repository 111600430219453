import {
  formatCurrency,
  formatTransactionCurrency,
  formatDateTime,
} from "../../../../utils/stringHelpers";
import ResultsDetailsModal from "./SettlementResultsTableModal";
import SaasResultsDetailsModal from "./SaasSettlementResultsTableModal";

export const summaryColumnHeaders = [
  {
    default_field: true,
    key: "batch_date",
    sortable: true,
  },
  {
    default_field: true,
    key: "processor_id",
    sortable: true,
  },
  {
    default_field: true,
    key: "processor_name",
    sortable: true,
  },
  {
    default_field: true,
    key: "captured",
    sortable: true,
  },
  {
    default_field: true,
    key: "# trans",
    sortable: true,
  },
  {
    default_field: true,
    key: "net",
    sortable: true,
  },
  {
    default_field: true,
    key: "base",
    sortable: true,
  },
  {
    default_field: true,
    key: "credit",
    sortable: true,
  },
];

export const resultsColumnHeaders = [
  {
    default_field: true,
    key: "action",
    sortable: false,
  },
  {
    default_field: true,
    key: "id",
    sortable: true,
  },
  {
    default_field: true,
    key: "processor_name",
    sortable: true,
  },
  {
    default_field: true,
    key: "response",
    sortable: true,
  },
  {
    default_field: true,
    key: "batch_date",
    sortable: true,
  },
  {
    default_field: true,
    key: "# trans",
    sortable: true,
  },
  {
    default_field: true,
    key: "net",
    sortable: true,
  },
  {
    default_field: true,
    key: "captured",
    sortable: true,
  },
  {
    default_field: true,
    key: "base",
    sortable: true,
  },
  {
    default_field: true,
    key: "credit",
    sortable: true,
  },
];

export const saasResultsColumnHeaders = [
  {
    default_field: true,
    key: "action",
    sortable: false,
  },
  {
    default_field: true,
    key: "id",
    sortable: false,
  },
  {
    default_field: true,
    key: "processor_name",
    sortable: true,
  },
  {
    default_field: true,
    key: "response",
    sortable: true,
  },
  {
    default_field: true,
    key: "batch_date",
    sortable: true,
  },
  {
    default_field: true,
    key: "# trans",
    sortable: true,
  },
  {
    default_field: true,
    key: "net",
    sortable: true,
  },
  {
    default_field: true,
    key: "captured",
    sortable: true,
  },
  {
    default_field: true,
    key: "base",
    sortable: true,
  },
  {
    default_field: true,
    key: "surcharge",
    sortable: true,
  },
  {
    default_field: true,
    key: "payment_adj",
    sortable: true,
  },
  {
    default_field: true,
    key: "credit",
    sortable: true,
  },
];

export const getKeyValue = (key, data) => {
  switch (key) {
    case "batch_date": {
      return formatDateTime(data.batch_date).split(" ")[0];
    }
    case "processor_id": {
      return data.processor_id;
    }
    case "processor_name": {
      return data.processor_name;
    }
    case "captured": {
      return formatCurrency(
        data?.captured?.toString() || data.amount_captured?.toString(),
      );
    }
    case "# trans": {
      return data.num_transactions;
    }
    case "net": {
      return formatCurrency(data.net_amount);
    }
    case "base": {
      return formatCurrency(data.base_amount);
    }
    case "credit": {
      return formatCurrency(data?.amount_credit || data.credit || "0");
    }
    case "surcharge": {
      return formatCurrency(data?.surcharge || data.surcharge_amount || "0");
    }
    case "payment_adj": {
      return formatCurrency(
        data?.payment_adj_amount || data.payment_adj || "0",
      );
    }
    case "action": {
      return <ResultsDetailsModal data={data} />;
    }
    case "response": {
      return <div>{data.response_code + " - " + data.response_message}</div>;
    }
    default:
      return data[key];
  }
};
export const getSaasKeyValue = (key, data) => {
  switch (key) {
    case "id": {
      return data.settlementId;
    }
    case "batch_date": {
      return formatDateTime(data?.batchDate).split(" ")[0];
    }
    case "processor_id": {
      return data.processorId;
    }
    case "processor_name": {
      return data.processorName;
    }
    case "captured": {
      return formatTransactionCurrency(data?.capturedAmount);
    }
    case "# trans": {
      return data.transactionsCount;
    }
    case "net": {
      return formatTransactionCurrency(data?.netDepositAmount);
    }
    case "base": {
      return formatTransactionCurrency(data?.baseAmount);
    }
    case "surcharge": {
      return formatTransactionCurrency(data?.surchargeAmount);
    }
    case "payment_adj": {
      return formatTransactionCurrency(data?.paymentAdjustmentAmount);
    }
    case "credit": {
      return formatTransactionCurrency(data?.creditAmount);
    }
    case "action": {
      return <SaasResultsDetailsModal data={data} />;
    }
    case "response": {
      return <div>{data?.responseCode + " - " + data?.responseMessage}</div>;
    }
    default:
      return data[key];
  }
};
export function sortingHelper(key, isResults) {
  switch (key) {
    case "base": {
      return "base_amount";
    }
    case "# trans": {
      return "num_transactions";
    }
    case "net": {
      return "net_amount";
    }
    case "captured": {
      return isResults ? "amount_captured" : "captured";
    }
    case "surcharge": {
      return isResults ? "surcharge_amount" : "surcharge";
    }
    case "credit": {
      return isResults ? "amount_credit" : "credit";
    }
    case "payment_adj": {
      return isResults ? "payment_adj_amount" : "payment_adj";
    }
    case "response": {
      return "response_message";
    }
    default:
      return key;
  }
}

export function saasSortingHelper(key) {
  switch (key) {
    case "processor_name": {
      return "processorName";
    }
    case "processor_id": {
      return "processorId";
    }
    case "batch_date": {
      return "batchDate";
    }
    case "base": {
      return "baseAmount";
    }
    case "# trans": {
      return "transactionsCount";
    }
    case "net": {
      return "netDepositAmount";
    }
    case "captured": {
      return "capturedAmount";
    }
    case "credit": {
      return "creditAmount";
    }
    case "response": {
      return "responseMessage";
    }
    case "surcharge": {
      return "surchargeAmount";
    }
    case "payment_adj": {
      return "paymentAdjustmentAmount";
    }
    default:
      return key;
  }
}

export function checkIfStringSort(val) {
  const validKeys = [
    "processor_id",
    "processor_name",
    "batch_date",
    "id",
    "response_message",
  ];
  return validKeys.includes(val);
}

export const sortTableData = (items, isAscending, value, isResults) => {
  const key = sortingHelper(value, isResults);
  const newItems = [...items];

  if (checkIfStringSort(key)) {
    return isAscending
      ? newItems.sort((a, b) => a[key].toString().localeCompare(b[key]))
      : newItems.sort((a, b) => b[key].toString().localeCompare(a[key]));
  } else {
    return isAscending
      ? newItems.sort((a, b) => +a[key] - +b[key])
      : newItems.sort((a, b) => +b[key] - +a[key]);
  }
};

export const addTableHeaders = (initialHeaders, surcharge, payment_adj) => {
  let arr = [];
  if (surcharge > 0) {
    const surchargeHeader = {
      default_field: true,
      key: "surcharge",
      sortable: true,
    };
    arr.push(surchargeHeader);
  }
  if (payment_adj > 0) {
    const paymentHeader = {
      default_field: true,
      key: "payment_adj",
      sortable: true,
    };
    arr.push(paymentHeader);
  }
  const revisedHeaders = [...initialHeaders].concat(arr);
  return revisedHeaders;
};
