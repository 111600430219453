import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { handleCustomFieldMultipleValues } from "../CustomFields/GlobalCustomFields";

const DetailCustomFields = ({ transactionData, fieldBoldness }) => {
  let fieldsWithMultipleValues = handleCustomFieldMultipleValues(
    transactionData?.customFieldEntries,
  );
  let alreadyDisplayedField = [];

  return (
    <Grid
      container
      sx={{
        wordWrap: "break-all",
        overflowWrap: "break-word",
      }}
      xs={12}
      sm={9}
    >
      <Grid
        item
        xs={12}
        padding={1}
        bgcolor="#e5e5e5"
        color="black"
        fontWeight={fieldBoldness}
      >
        {transactionData.customFieldEntries[0].customFieldCategoryName}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ borderBottom: 1, marginBottom: 1, color: "white" }}
      ></Grid>

      {Object.keys(transactionData.customFieldEntries).map(function (key) {
        if (
          !fieldsWithMultipleValues[
            transactionData.customFieldEntries[key].name
          ]
        )
          return (
            <React.Fragment key={key}>
              <Grid
                item
                padding={1}
                xs={4}
                md={4}
                sx={{ fontWeight: fieldBoldness }}
              >
                {transactionData.customFieldEntries[key].name}:
              </Grid>
              <Grid item padding={1} xs={8} md={8}>
                {transactionData.customFieldEntries[key].value.length > 0
                  ? transactionData.customFieldEntries[key].value
                  : "N/A"}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ borderBottom: 1, margin: 1, color: "#e5e5e5" }}
              ></Grid>
            </React.Fragment>
          );
        else if (
          !alreadyDisplayedField.includes(
            transactionData.customFieldEntries[key].name,
          )
        ) {
          alreadyDisplayedField.push(
            transactionData.customFieldEntries[key].name,
          );
          return (
            <React.Fragment key={key}>
              <Grid
                item
                padding={1}
                xs={4}
                md={4}
                sx={{ fontWeight: fieldBoldness }}
              >
                {transactionData.customFieldEntries[key].name}:
              </Grid>
              <Grid item padding={1} xs={8} md={8}>
                {fieldsWithMultipleValues[
                  transactionData.customFieldEntries[key].name
                ].join(", ")}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ borderBottom: 1, margin: 1, color: "#e5e5e5" }}
              ></Grid>
            </React.Fragment>
          );
        } else {
          return <></>;
        }
      })}
    </Grid>
  );
};

DetailCustomFields.propTypes = {
  transactionData: PropTypes.object.isRequired,
  fieldBoldness: PropTypes.string.isRequired,
};

export default DetailCustomFields;
