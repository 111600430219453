import { regexPatterns } from "../constants/regexPatterns";

const formatDateTime = (dateString) => {
  var { locale, timeZone } = Intl.DateTimeFormat().resolvedOptions();
  var date = new Date(Date.parse(dateString));

  return date.toLocaleString(locale, { timeZone }).replace(",", "");
};

export const formatDate = (date) => {
  return date.substring(0, date.length - 1) + ".000";
};

const formatPhone = (phoneString) => {
  var cleaned = ("" + phoneString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneString;
};

const formatTransactionCurrency = (amountValue) => {
  var parts = amountValue.toFixed(2).split(".");
  parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return "$" + parts.join(".");
};

const formatCurrency = (amountValue) => {
  amountValue = (amountValue / 100).toFixed(2);
  var parts = amountValue.toString().split(".");
  parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return "$" + parts.join(".");
};

const removeNonNumericAuth = (str) =>
  str.replace(regexPatterns.nonNumericAuth, "");
const removeNonNumeric = (str) => str.replace(regexPatterns.nonNumeric, "");
const removePlusMinus = (str) => str.replace(regexPatterns.plusMinus, "");

const convertPropertyToName = (property) => {
  return property
    .split("_")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

//to be refactored possibly depending on technical discussion
const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const stringFormat = (str, args) => {
  let regexExp = /{([a-zA-Z]+)}/g;
  let count = 0;
  return str.replace(regexExp, function () {
    count++;
    return args[count - 1] || "";
  });
};

const capitalize = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export {
  capitalize,
  formatDateTime,
  formatPhone,
  formatCurrency,
  formatTransactionCurrency,
  removeNonNumericAuth,
  removeNonNumeric,
  removePlusMinus,
  convertPropertyToName,
  generateUUID,
  stringFormat,
};
