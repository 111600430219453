import React, { useState, useEffect } from "react";
import axios from "axios";
import { partnerRoutes } from "../../../../constants/routes";
import { Link } from "react-router-dom";
import ContentComponent from "../../../Content/Content";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import UserCreate from "./UserCreate";
import { FormProvider, useForm } from "react-hook-form";
import "./createUser.scss";
import { useHistory } from "react-router-dom";
import { sassEndpoints } from "../../../../constants/endpoints";
import { SYSTEM_PERMISSION_CODES } from "../../../../constants/global";

const UserCreateContainer = () => {
  const { ...methods } = useForm();
  const history = useHistory();

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [roleDetails, setRoleDetails] = useState();
  const [permissions, setPermissions] = useState({});
  useEffect(() => {
    loadRoles();
    getAllPermissions();
  }, []);

  const loadRoles = async () => {
    let url = `${sassEndpoints.users.userRoles}`;
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (response) {
        let filteredData = response.data.data.filter((role) => {
          return !role.isGatewayRole;
        });
        setRoleDetails(filteredData);
      })
      .catch(function (error) {
        showAlertDialogError("Failed to get roles");
        console.log("Failed to get roles", error);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const getAllPermissions = () => {
    const url = `${sassEndpoints.users.userPermissions}`;
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (response) {
        setPermissions(response.data.data);
      })
      .catch(function () {
        showAlertDialogError("Failed to get permissions");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const handlePermissions = (
    createGatewayBool,
    editGatewayBool,
    gatewayStatusBool,
  ) => {
    let permissionsList = [];
    if (createGatewayBool) {
      let createGatewayId = permissions.find(
        (permission) => permission.code === SYSTEM_PERMISSION_CODES.create,
      ).permissionId;
      permissionsList.push(createGatewayId);
    }
    if (editGatewayBool) {
      let editGatewayId = permissions.find(
        (permission) => permission.code === SYSTEM_PERMISSION_CODES.edit,
      ).permissionId;
      permissionsList.push(editGatewayId);
    }
    if (gatewayStatusBool) {
      let gatewayStatusId = permissions.find(
        (permission) =>
          permission.code === SYSTEM_PERMISSION_CODES.editGatewayStatus,
      ).permissionId;
      permissionsList.push(gatewayStatusId);
    }
    return permissionsList;
  };

  const createUser = () => {
    const payload = createPayload();
    let url = `${sassEndpoints.users.user}`;
    setShowBackdrop(true);
    axios
      .post(url, payload)
      .then(function (response) {
        history.push(
          `${partnerRoutes.manage.users}/${response.data.data.userId}`,
        );
      })
      .catch(function (error) {
        showAlertDialogError(
          error?.response?.data?.statusDetails
            ? error?.response?.data?.statusDetails
            : "Failed to create user",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const createPayload = () => {
    let newUserData = {
      email: methods.getValues().email,
      firstName: methods.getValues().first_name,
      lastName: methods.getValues().last_name,
      roleId: methods.getValues().role,
      permissionIds: handlePermissions(
        methods.getValues().create_gateway,
        methods.getValues().edit_gateway,
        methods.getValues().edit_gateway_status,
      ),
    };
    return newUserData;
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const onError = () => {
    showAlertDialogError("Please correct form.");
  };
  return (
    <div>
      <FormProvider {...methods}>
        <ContentComponent
          spinnerOpen={showBackdrop}
          useFixedWidth={false}
          title="Create User"
          headerContent={
            <div>
              {" "}
              <Link to={partnerRoutes.manage.users} className="back-arrow">
                <ChevronLeftIcon />
                Back to List
              </Link>
            </div>
          }
          bodyContent={
            <form>
              <div>
                <UserCreate
                  roleDetails={roleDetails}
                  createUser={createUser}
                  onError={onError}
                  methods={methods}
                />
              </div>
            </form>
          }
          alertDialogOpen={alertDialogOpen}
          alertDialogProps={alertDialogProps}
        />
      </FormProvider>
    </div>
  );
};

export default UserCreateContainer;
