import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import ContentComponent from "../../Content/Content";
import axios from "axios";
import useAlertDialog from "../../../hooks/useAlertDialog";
import Header from "./Header/Header";
import { CONTACT_SUPPORT } from "../../../constants/global";
import { sassEndpoints } from "../../../constants/endpoints";
import "./invoices.scss";
import useAxios from "../../../hooks/useAxios";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import InvoiceStepOne from "./StepOne/InvoiceStepOne";
import InvoiceStepTwo from "./StepTwo/InvoiceStepTwo";
import InvoiceStepThree from "./StepThree/InvoiceStepThree";
import PreventNavigationDialog from "./PreventNavigation/PreventNavigationDialog";
import { useHistory } from "react-router-dom";
import { stringFormat } from "../../../utils/stringHelpers";
import { CountriesContext } from "../../../contexts/CountriesContext";
import { GatewayContext } from "../../../contexts/GatewayContext";
import { noProcessors } from "../../../constants/routes";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { TokenizerConfigContext } from "../../../contexts/TokenizerConfigContext";
import { iqProVer } from "../../../constants/global";

const InvoicesCreateEditForm = ({
  defaultValues,
  onSubmit,
  invoiceLoading,
  isCloning,
}) => {
  const { displayMessages, isAlertOpen, setIsAlertOpen, alertMessages } =
    useAlertDialog();
  const {
    userSettings: { gatewayId },
  } = useContext(UserSettingsContext);
  const countries = useContext(CountriesContext);
  const tokenizerConfig = useContext(TokenizerConfigContext);
  const gateway = useContext(GatewayContext);
  const [invoice, setInvoice] = useState(null);
  const [processors, setProcessors] = useState([]);
  const [processorsLoading, setProcessorsLoading] = useState(false);
  const [customFieldGroups, setCustomFieldGroups] = useState(null);
  const [chosenCustomFields, setChosenCustomFields] = useState(null);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(false);
  const [remittanceAddress, setRemitAddress] = useState(
    invoice?.invoiceAddresses?.find((a) => a?.isRemittance) || {
      company: gateway?.contactCompany,
      isRemittance: true,
      ...(gateway?.addresses.find((a) => a.isInvoice)?.addressModel ||
        gateway?.addresses.find((a) => a.isPrimary)?.addressModel),
    },
  );
  const [copyBillingAddress, setCopyBillingAddress] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [vaultCustomer, setVaultCustomer] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const { data: unitsOfMeasure, loading: unitsLoading } = useAxios(
    {
      method: "GET",
      url: sassEndpoints.invoices.unitsOfMeasure,
    },
    { initialData: [], dataTransform: (data) => data.data },
  );
  const stepStyle = {
    "& .MuiStepIcon-root": {
      fontSize: "1.5rem",
    },
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "primary.main",
      },
      "&.MuiStepLabel-label": {
        fontWeight: "bold",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "primary.main",
      },
    },
    "& .MuiStepLabel-label": {
      fontWeight: "bold",
    },
  };

  useEffect(() => {
    loadProcessors();
    loadCustomFields();

    if (
      defaultValues &&
      Object.keys(defaultValues)?.filter((k) => k !== "customer").length !== 0
    ) {
      setInvoice(defaultValues);
      setRemitAddress(
        defaultValues?.invoiceAddresses?.find((a) => a?.isRemittance),
      );
    }

    if (defaultValues?.customer) {
      handleSelectCustomer(defaultValues.customer.customerId);
    }
  }, [defaultValues]);

  const saveCustomerInfo = (i) => {
    setActiveStep(1);
    setInvoice({
      ...invoice,
      ...i,
    });
  };

  const saveInvoiceInfo = (i) => {
    setInvoice(Object.assign(invoice, i));
  };

  const saveLineItems = (i) => {
    setInvoice(Object.assign(invoice, i));
    setActiveStep(1);
  };

  const handleSelectCustomer = (customerId) => {
    if (!customerId) {
      setCustomer(null);
      return null;
    }

    const url = stringFormat(sassEndpoints.customers.customerId, [
      gatewayId,
      customerId,
    ]);

    setCustomerLoading(true);

    return axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setCustomer(response.data.data);
          setCustomerLoading(false);

          return response.data.data;
        }
      })
      .catch(() => {
        setCustomerLoading(false);
        displayMessages([
          "Failed to load customer information.",
          CONTACT_SUPPORT,
        ]);
      });
  };

  const loadProcessors = () => {
    const url = stringFormat(sassEndpoints.processors.processor, [gatewayId]);

    setProcessorsLoading(true);

    axios
      .get(url)
      .then((response) => {
        if (response.status === 204) history.push(noProcessors);
        else {
          setProcessors(response?.data?.data || []);
          setProcessorsLoading(false);
        }
      })
      .catch(() => {
        setProcessorsLoading(false);
        displayMessages(["Failed to load processors.", CONTACT_SUPPORT]);
      });
  };

  const loadCustomFields = () => {
    const url = stringFormat(sassEndpoints.customFields.category, [gatewayId]);

    setCustomFieldsLoading(true);

    axios
      .get(url)
      .then((response) => {
        if (response.status !== 204) {
          let invoiceCustomFields = response.data?.data?.filter(
            (cf) => cf.invoice,
          );
          let groupNames = invoiceCustomFields.map((group) => group.name);
          let customFields = invoiceCustomFields.map(
            (fields) => fields.customFields,
          );
          setCustomFieldGroups(groupNames);
          setChosenCustomFields(customFields);
        }
      })
      .catch(() => {
        displayMessages(["Failed to load custom fields", CONTACT_SUPPORT]);
      })
      .finally(() => {
        setCustomFieldsLoading(false);
      });
  };

  const handleSubmit = (i) => {
    const index = invoice.invoiceAddresses.findIndex((a) => a.isRemittance);

    if (index === -1) invoice.invoiceAddresses.push(remittanceAddress);
    else invoice.invoiceAddresses[index] = remittanceAddress;
    invoice.vaultCustomer = vaultCustomer;

    onSubmit(Object.assign(invoice, i));
  };

  const steps = [
    <InvoiceStepOne
      key={0}
      countries={countries}
      onPageChange={saveCustomerInfo}
      copyBillingAddress={copyBillingAddress}
      setCopyBillingAddress={setCopyBillingAddress}
      vaultCustomer={vaultCustomer}
      setVaultCustomer={setVaultCustomer}
      showVaultCustomer={
        gateway?.gatewaySettings
          .find((s) => s.code === "ALLOW_CUSTOMER_VAULT_ACCESS")
          .value.toLowerCase() === "true" &&
        tokenizerConfig.cardTokenizer.toUpperCase() !== iqProVer.v1 &&
        tokenizerConfig.achTokenizer.toUpperCase() !== iqProVer.v1
      }
      disableRequireBillingInfo={
        gateway?.gatewaySettings
          .find((s) => s.code === "ALLOW_SURCHARGE")
          ?.value.toLowerCase() === "true"
      }
      customer={customer}
      onCustomerSelect={handleSelectCustomer}
      invoice={invoice}
    />,
    <InvoiceStepTwo
      key={1}
      processors={processors}
      setActiveStep={setActiveStep}
      onPageChange={saveInvoiceInfo}
      customFieldGroups={customFieldGroups}
      chosenCustomFields={chosenCustomFields}
      invoice={invoice}
    />,
    <InvoiceStepThree
      key={2}
      invoice={invoice}
      remittanceAddress={remittanceAddress}
      unitsOfMeasure={unitsOfMeasure}
      onPageChange={saveLineItems}
      onSubmit={handleSubmit}
      isCloning={isCloning}
    />,
  ];

  return (
    <ContentComponent
      title="Create Invoice"
      useFixedWidth={false}
      spinnerOpen={
        unitsLoading ||
        customerLoading ||
        invoiceLoading ||
        processorsLoading ||
        customFieldsLoading
      }
      bodyContent={
        <>
          {remittanceAddress ? (
            <Header
              countries={countries}
              remittanceAddress={remittanceAddress}
              setRemitAddress={setRemitAddress}
              displayMessages={displayMessages}
            />
          ) : null}
          <Box className="invoice-container">
            <Stepper activeStep={activeStep} sx={stepStyle}>
              <Step>
                <StepLabel>Add A Customer</StepLabel>
              </Step>
              <Step>
                <StepLabel>Set Up Invoice</StepLabel>
              </Step>
              <Step>
                <StepLabel>Add Line Items</StepLabel>
              </Step>
            </Stepper>
            {!unitsLoading && steps[activeStep]}
          </Box>
          <PreventNavigationDialog
            when={!invoiceLoading}
            navigate={history.push}
            shouldBlockNavigation={(nextLocation) =>
              !invoiceLoading &&
              nextLocation.pathname !== "/error" &&
              nextLocation.pathname !== noProcessors
            }
          />
        </>
      }
      alertDialogOpen={isAlertOpen}
      alertDialogProps={{
        alertTitle: "Error",
        alertLevel: "error",
        alertMessages,
        onCloseButtonClick: () => setIsAlertOpen(false),
      }}
    />
  );
};

InvoicesCreateEditForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  invoiceLoading: PropTypes.bool,
  isCloning: PropTypes.bool,
};

export default InvoicesCreateEditForm;
