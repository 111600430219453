import { useState, useContext, useEffect } from "react";
import ContentComponent from "../../../Content/Content";
import ManageUserCreate from "./ManageUsersCreate";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import { useSnackbar } from "../../../../hooks";
import { sassEndpoints } from "../../../../constants/endpoints";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../../constants/routes";
import { stringFormat } from "../../../../utils/stringHelpers";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { GatewayContext } from "../../../../contexts/GatewayContext";
const ManageUsersCreateContainer = () => {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [permissions, setPermissions] = useState(null);
  const [roles, setRoles] = useState(null);
  const { snackbarOpen, snackbarProps, showSnackbar } = useSnackbar();
  const { userSettings } = useContext(UserSettingsContext);
  const gatewayInfo = useContext(GatewayContext);
  const history = useHistory();
  const allowInvoicing =
    gatewayInfo.gatewaySettings
      .find((p) => p.code === "ALLOW_INVOICING")
      ?.value.toLowerCase() === "true";

  useEffect(() => {
    getAllPermissions();
    getGatewayUserRoles();
  }, []);

  const createGatewayUser = (payload) => {
    let url = stringFormat(sassEndpoints.gatewayUsers.user, [
      userSettings.gatewayId,
    ]);
    setShowBackdrop(true);
    axios
      .post(url, payload)
      .then(function (response) {
        routeToNewUser(response.data.data.gatewayUserId);
        showSnackbar("Success");
      })
      .catch(function (error) {
        console.log(error);
        showAlertDialogError(error?.response?.data?.statusDetails);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const getAllPermissions = () => {
    const url = `${sassEndpoints.users.userPermissions}/${userSettings.gatewayId}`;
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (response) {
        const permissions = response.data.data.filter(
          (p) =>
            (p.code.includes("INVOICE") && allowInvoicing) ||
            !p.code.includes("INVOICE"),
        );
        setPermissions(permissions);
      })
      .catch(function () {
        showAlertDialogError("Failed to retrieve permissions");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const getGatewayUserRoles = () => {
    const url = `${sassEndpoints.users.userRoles}/${userSettings.gatewayId}`;
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (response) {
        setRoles(response.data.data);
      })
      .catch(function () {
        showAlertDialogError("Failed to retrieve roles");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const routeToNewUser = (id) => {
    history.push({
      pathname: `${merchantRoutes.manage.users}/${id}`,
    });
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };
  return (
    <div className="gateway-user-list">
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="Create User"
        headerContent={
          <div>
            <Tooltip title="Return to List">
              <IconButton
                variant="container"
                color="primary"
                fontSize="small"
                sx={{ fontSize: "16px", marginLeft: "-15px" }}
                onClick={() => history.push(merchantRoutes.manage.users)}
              >
                <ChevronLeftIcon />
                <span>Back To List</span>
              </IconButton>
            </Tooltip>
          </div>
        }
        bodyContent={
          <div>
            <ManageUserCreate
              createGatewayUser={createGatewayUser}
              permissions={permissions}
              roles={roles}
            />
          </div>
        }
        footerContent={<></>}
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
      />
    </div>
  );
};

export default ManageUsersCreateContainer;
