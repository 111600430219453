import ExpandCollapse from "./Accordion";
import PropTypes from "prop-types";
// import CloseIcon from "@mui/icons-material/Close";
// import AddIcon from "@mui/icons-material/Add";
// import React, { useState } from "react";
// import { useTheme } from "@mui/material/styles";
export const AccordionGroup = ({ accordionDetails }) => {
  // const [isExpandAll, setExpandAll] = useState(true);
  // const theme = useTheme();
  return (
    <div className="accordion-group-container">
      {/* <div className="toggle-row">
        <div className={`toggle-button ${theme.palette.mode}-theme`}>
          {isExpandAll ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                setExpandAll(!isExpandAll);
              }}
            >
              <span>Close All </span>
              <CloseIcon />
            </button>
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                setExpandAll(!isExpandAll);
              }}
            >
              <span> Expand All</span> <AddIcon />
            </button>
          )}
        </div>
      </div> */}
      {accordionDetails.map((details, i) => {
        return (
          <ExpandCollapse
            key={i}
            //isExpanded={isExpandAll}
            isExpanded={true}
            title={details.title}
            body={details.body}
          />
        );
      })}
    </div>
  );
};
AccordionGroup.propTypes = {
  accordionDetails: PropTypes.array,
};

export default AccordionGroup;
