import React, { useEffect, useContext } from "react";
import colors from "../../assets/colors.module.scss";
import PropTypes from "prop-types";
import "./Content.css";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "../Dialog/AlertDialog";
import PromptDialog from "../Dialog/AlertDialog";
import { SnackbarContext } from "../../contexts/SnackbarContext";
function Content({
  title,
  headerContent,
  bodyContent,
  footerContent,
  spinnerOpen,
  useFixedWidth,
  alertDialogOpen,
  alertDialogProps,
  promptDialogOpen,
  promptDialogProps,
  snackbarOpen,
  snackbarProps,
}) {
  const fixedWidthSize = 1000;
  const { setSnackbarProps } = useContext(SnackbarContext);
  /* Start - Temporary fix for padding issue until all pages start using this component - Remove after */
  useEffect(() => {
    let main = document.getElementsByTagName("main");
    if (main.length >= 1) main[0].style.padding = "0px";
    return () => (main[0].style.padding = "20px");
  }, []);
  /* End - Temporary fix for padding issue until all pages start using this component - Remove after */

  useEffect(() => {
    if (snackbarOpen) {
      const snackbar = { ...snackbarProps, snackbarOpen: snackbarOpen };
      setSnackbarProps(snackbar);
    }
  }, [snackbarOpen, snackbarProps]);

  return (
    <Box
      className="content-container"
      sx={{
        maxWidth: useFixedWidth ? fixedWidthSize : null,
      }}
    >
      <Typography variant="h1" align="left" data-cy="developer-hub-header">
        {title}
      </Typography>
      <Paper sx={{ marginTop: 1, boxShadow: "none" }} className="paper-section">
        {headerContent && <div className="header-content">{headerContent}</div>}

        <div className="body-content">{bodyContent}</div>

        {footerContent && <div className="footer-content">{footerContent}</div>}
      </Paper>
      <Backdrop
        open={spinnerOpen}
        transitionDuration={0}
        sx={{ color: colors.system_white, zIndex: 9000, opacity: 0.5 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertDialog
        alertOpen={alertDialogOpen}
        alertTitle={alertDialogProps.alertTitle}
        alertLevel={alertDialogProps.alertLevel}
        alertMessages={alertDialogProps.alertMessages}
        contentElement={alertDialogProps.contentElement}
        actionButtons={alertDialogProps.actionButtons}
        closeButtonText={alertDialogProps.closeButtonText}
        closeButtonColor={alertDialogProps.closeButtonColor}
        closeButtonVariant={alertDialogProps.closeButtonVariant}
        onCloseButtonClick={alertDialogProps.onCloseButtonClick}
      />
      <PromptDialog
        alertOpen={promptDialogOpen}
        alertTitle={promptDialogProps.alertTitle}
        alertLevel={promptDialogProps.alertLevel}
        alertMessages={promptDialogProps.alertMessages}
        contentElement={promptDialogProps.contentElement}
        actionButtons={promptDialogProps.actionButtons}
        closeButtonText={promptDialogProps.closeButtonText}
        closeButtonColor={promptDialogProps.closeButtonColor}
        onCloseButtonClick={promptDialogProps.onCloseButtonClick}
      />
    </Box>
  );
}

Content.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headerContent: PropTypes.element,
  bodyContent: PropTypes.element,
  footerContent: PropTypes.element,
  spinnerOpen: PropTypes.bool,
  useFixedWidth: PropTypes.bool,
  alertDialogOpen: PropTypes.bool,
  alertDialogProps: PropTypes.object,
  promptDialogOpen: PropTypes.bool,
  promptDialogProps: PropTypes.object,
  snackbarOpen: PropTypes.bool,
  snackbarProps: PropTypes.object,
};

Content.defaultProps = {
  useFixedWidth: true,
  spinnerOpen: false,
  alertDialogOpen: false,
  alertDialogProps: {},
  promptDialogOpen: false,
  promptDialogProps: {},
  snackbarOpen: false,
  snackbarProps: {},
};

export default Content;
