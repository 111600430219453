import React, { useContext, useState } from "react";
import { merchantRoutes } from "../../../../constants/routes";
import PropTypes from "prop-types";
import axios from "axios";
import { sassEndpoints } from "../../../../constants/endpoints";
import { stringFormat } from "../../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import success from "./assets/success-pt.png";
import { useHistory } from "react-router";
const TerminalSuccess = ({ transactionId, returnToTransaction }) => {
  const [loading, setLoading] = useState(false);
  const { userSettings } = useContext(UserSettingsContext);
  const history = useHistory();
  const handleNavigation = () => {
    history.push(
      `${merchantRoutes.reporting.transaction_detail}/${transactionId}`,
    );
  };
  const printTransaction = async (transactionId) => {
    let outErrorMsg = null;
    setLoading(true);
    const url = stringFormat(sassEndpoints.transactions.receipt, [
      userSettings.gatewayId,
      transactionId,
    ]);

    await axios
      .get(url, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        window.open(url, "receipt-" + transactionId);
      })
      .catch(function (error) {
        outErrorMsg = error.response.data.statusDetails.join(", ");
        return false;
      })
      .finally(function () {
        setLoading(false);
      });

    return outErrorMsg;
  };

  return (
    <div className="pt-success-wrapper">
      <div>
        <img src={success} />
        <h2>Transaction Complete</h2>
        <button
          disabled={loading}
          onClick={handleNavigation}
          className="btn btn--primary"
        >
          View Transaction Details
        </button>
        <button
          onClick={printTransaction}
          disabled={loading}
          className="btn btn--secondary"
        >
          Print Receipt
        </button>
        <button
          onClick={returnToTransaction}
          disabled={loading}
          className="btn btn--secondary"
        >
          Run Another Transaction
        </button>
      </div>
    </div>
  );
};
TerminalSuccess.propTypes = {
  transactionId: PropTypes.string,
  returnToTransaction: PropTypes.func,
};

export default TerminalSuccess;
