import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import styles from "../../Content/style";
import BasicTable from "../../Table/BasicTable";

function Partners(props) {
  const { classes, tableColumns, tableRows } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <BasicTable
          tableColumns={tableColumns}
          tableRows={tableRows}
        ></BasicTable>
      </div>
    </Paper>
  );
}

Partners.propTypes = {
  classes: PropTypes.object.isRequired,
  tableColumns: PropTypes.any,
  tableRows: PropTypes.any,
};

export default withStyles(styles)(Partners);
