import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { getProperty } from "../../utils/objectHelpers";
import { isRequired } from "./formHelpers";

const handleChangeMultiple = (event) => {
  const { options } = event.target;
  const value = [];
  for (let i = 0, l = options.length; i < l; i += 1) {
    if (options[i].selected) {
      value.push(options[i].value);
    }
  }
  return value;
};

const MultipleSelectNative = ({
  name,
  label,
  defaultValue,
  control,
  rules,
  errors,
  options,
}) => {
  const error = getProperty(name, errors);
  return (
    <>
      <FormControl
        required={isRequired(rules)}
        error={error !== undefined}
        fullWidth
      >
        <InputLabel shrink htmlFor="select-multiple-native">
          {label}
        </InputLabel>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              multiple
              native
              onChange={(e) => onChange(handleChangeMultiple(e))}
              label={label}
              inputProps={{
                id: "select-multiple-native",
              }}
              sx={{ height: "100%" }}
            >
              {options.map((option) => (
                <option key={option.name} value={option.value}>
                  {option.name}
                </option>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormHelperText sx={{ ml: 0 }} error>
        {error && error.message}
      </FormHelperText>
    </>
  );
};

export default MultipleSelectNative;

MultipleSelectNative.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  errors: PropTypes.object,
  options: PropTypes.array.isRequired,
};

MultipleSelectNative.defaultProps = {
  defaultValue: "",
};
