import React, { useState, useContext, useEffect } from "react";
import InvoicesCreateEditForm from "./InvoicesEditCreateForm";
import axios from "axios";
import { useAlertDialog } from "../../../hooks";
import { CONTACT_SUPPORT, INVOICE_STATUSES } from "../../../constants/global";
import { sassEndpoints } from "../../../constants/endpoints";
import { useHistory, useLocation } from "react-router-dom";
import { generalError, merchantRoutes } from "../../../constants/routes";
import AlertDialog from "../../Dialog/AlertDialog";
import { stringFormat } from "../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";

const InvoicesCreateContainer = () => {
  const {
    userSettings: { gatewayId },
  } = useContext(UserSettingsContext);
  const [invoice, setInvoice] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const { displayMessages, isAlertOpen, setIsAlertOpen, alertMessages } =
    useAlertDialog();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      !invoice &&
      location.state &&
      !Object.keys(location?.state).includes("customerId")
    ) {
      const url = stringFormat(
        `${sassEndpoints.invoices.invoice}/${location.state.invoiceId}`,
        [gatewayId],
      );

      setInvoiceLoading(true);

      axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            var invoice = response.data.data;

            if (
              invoice.invoiceNotifications.length > 0 &&
              invoice.invoiceNotifications[0].notificationStatus.name !==
                "Cancelled"
            )
              invoice.invoiceNotifications[0].invoiceNotificationMethodId =
                invoice.invoiceNotifications[0].notificationMethod.invoiceNotificationMethodId;
            else invoice.invoiceNotifications = [];

            setInvoice(invoice);
            setInvoiceLoading(false);
          } else history.push(generalError);
        })
        .catch(() => {
          setInvoiceLoading(false);
          history.push(generalError);
        });
    }
    if (location?.state?.customerId) {
      setInvoice({
        ...invoice,
        customer: { customerId: location?.state?.customerId },
      });
    }

    if (location?.state?.isCloning) {
      setIsCloning(location.state.isCloning);
    }
  }, []);

  const submitInvoice = (invoice) => {
    const url = sassEndpoints.invoices.invoice.replace(
      "{gatewayId}",
      gatewayId,
    );

    setInvoiceLoading(true);

    axios
      .post(url, invoice)
      .then((response) => {
        if (response.status === 201) {
          let success = "Invoice Created";

          if (invoice.invoiceStatusId === INVOICE_STATUSES.SENT)
            success +=
              ": Please allow up to an hour for notification to be sent";

          history.push(merchantRoutes.invoices.search, {
            success,
          });
        }
      })
      .catch((error) => {
        displayMessages(
          error?.response?.data?.statusDetails || [
            "Failed to create invoice",
            CONTACT_SUPPORT,
          ],
        );
        setInvoiceLoading(false);
      });
  };

  return (
    <>
      <InvoicesCreateEditForm
        onSubmit={submitInvoice}
        invoiceLoading={invoiceLoading}
        defaultValues={invoice}
        isCloning={isCloning}
      />
      <AlertDialog
        alertTitle="Error"
        alertLevel="error"
        alertOpen={isAlertOpen}
        alertMessages={alertMessages}
        onCloseButtonClick={() => setIsAlertOpen(false)}
      />
    </>
  );
};

export default InvoicesCreateContainer;
