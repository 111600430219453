import React, { useState, useContext, useEffect } from "react";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import Select from "../../../ReactHookForm/Select";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import Switch from "../../../ReactHookForm/Switch";
import MailIcon from "@mui/icons-material/Mail";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import PropTypes from "prop-types";
import { USER_ROLES } from "../../../../constants/global";
import { regexPatterns } from "../../../../constants/regexPatterns";
import { partnerRoutes } from "../../../../constants/routes";
import Tooltip from "@mui/material/Tooltip";
import { Grid } from "@mui/material";

const USER_STATUS = {
  active: "active",
  disabled: "disabled",
  pending: "invite_pending",
};

const UserDetails = ({
  viewType,
  userDetails,
  viewState,
  editUser,
  onError,
  methods,
  resendEmail,
  roles,
  permissions,
}) => {
  const { control, setValue } = useFormContext();
  const history = useHistory();
  const [isView, setView] = useState();
  const [statuses, setStatuses] = useState([]);
  const { userSettings } = useContext(UserSettingsContext);
  useEffect(() => {
    setAvailableStatus(userDetails.status);
    setValue("status", userDetails.status);
  }, [userDetails]);

  useEffect(() => {
    setView(viewState);
  }, [viewState]);

  const setAvailableStatus = (state) => {
    if (state === USER_STATUS.pending) {
      setStatuses([USER_STATUS.pending, USER_STATUS.disabled]);
    } else {
      setStatuses([USER_STATUS.disabled, USER_STATUS.active]);
    }
  };

  const roleHelper = (userRole) => {
    if (userRole === roles[0].code) {
      userDetails.role = roles[0];
    } else if (userRole === roles[1].code) {
      userDetails.role = roles[1];
    }
    return userDetails.role.roleId;
  };
  const handleEditState = () => {
    viewType(!isView);
    setView(!isView);
  };

  const createCopyUser = () => {
    const copyUser = {
      role: userDetails.role.roleId,
      permissions: userDetails.permissions,
    };
    history.push({
      pathname: `${partnerRoutes.manage.users_create}`,
      state: { copyUser },
    });
  };
  return (
    <div className="user-detail-wrapper">
      <div className="detail-header">
        <h2> User Info & Permissions</h2>
        {userSettings?.userRole === USER_ROLES.SUPER_USER && (
          <IconButton
            title={"Edit"}
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => handleEditState()}
            color={"warning"}
            fontSize="small"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <div className="details-body">
        <div className="form-elements">
          <RegisteredTextField
            id="username"
            name="userName"
            label="Username (email)"
            defaultValue={userDetails.email}
            fullWidth
            viewOnly={true}
          />
          <div className="name-fields">
            <RegisteredTextField
              id="firstName"
              name="firstName"
              label="First Name"
              defaultValue={userDetails.firstName}
              rules={{ required: true, pattern: regexPatterns.name }}
              viewOnly={isView}
            />
            <RegisteredTextField
              id="lastName"
              name="lastName"
              label="Last Name"
              defaultValue={userDetails.lastName}
              rules={{ required: true, pattern: regexPatterns.name }}
              viewOnly={isView}
            />
          </div>
          {statuses.length > 0 && (
            <Select
              control={control}
              name="status"
              label="Status"
              fullWidth
              rules={{ required: true }}
              viewOnly={
                isView || userSettings?.userRole === USER_ROLES.SYSTEM_ADMIN
              }
              defaultValue={userDetails.status}
            >
              {statuses.map((status, i) => {
                return (
                  <MenuItem key={i} value={status}>
                    <span style={{ textTransform: "capitalize" }}>
                      {status === "invite_pending" ? "Invite Pending" : status}
                    </span>
                  </MenuItem>
                );
              })}
            </Select>
          )}

          {roles && (
            <Select
              control={control}
              name="role_id"
              label="Role"
              fullWidth
              rules={{ required: true }}
              viewOnly={
                isView || userSettings?.userRole === USER_ROLES.SYSTEM_ADMIN
              }
              defaultValue={roleHelper(userDetails?.role) || roles[0].roleId}
            >
              {roles.map((role, i) => {
                return (
                  <MenuItem key={i} value={role.roleId}>
                    {role.description}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </div>
        {(userSettings?.userId !== userDetails.user_id ||
          userSettings?.userRole === USER_ROLES.SUPER_USER) && (
          <div className="permissions-wrapper">
            {permissions.map((permission, i) => {
              return (
                <Switch
                  key={i}
                  control={control}
                  label={permission.description}
                  labelPlacement="start"
                  name={"permissions." + permission.permissionId}
                  defaultValue={userDetails?.permissions.some((p) => {
                    return p.permissionId === permission.permissionId;
                  })}
                  disabled={
                    isView || userSettings?.userRole === USER_ROLES.SYSTEM_ADMIN
                  }
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="action-buttons">
        {(userSettings?.userRole === USER_ROLES.SUPER_USER ||
          userDetails.status === USER_STATUS.pending) && <h3>Actions</h3>}
        {userSettings?.userRole === USER_ROLES.SUPER_USER && (
          <Tooltip title="Copy over permissions, role, and default processor to new user.">
            <Button
              onClick={() => createCopyUser()}
              size="small"
              variant="contained"
              color="primary"
              data-cy="sys-copy-user-button"
            >
              <ContentCopyIcon />{" "}
              <span style={{ paddingLeft: "5px" }}> Copy User Permissions</span>
            </Button>
          </Tooltip>
        )}

        {userDetails.status === USER_STATUS.pending && (
          <Button
            onClick={() => resendEmail()}
            size="small"
            variant="contained"
            color="primary"
          >
            <MailIcon />{" "}
            <span style={{ paddingLeft: "5px" }}>Resend Welcome Email</span>
          </Button>
        )}
      </div>
      {!viewState && (
        <Grid className="system-user-btn-row">
          <Button
            size="small"
            variant="contained"
            color="neutrals"
            onClick={() => history.push(partnerRoutes.manage.users)}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(editUser, onError)}
          >
            Submit
          </Button>
        </Grid>
      )}
    </div>
  );
};

UserDetails.propTypes = {
  viewType: PropTypes.func,
  userDetails: PropTypes.object,
  viewState: PropTypes.bool,
  resendEmail: PropTypes.func,
  editUser: PropTypes.func,
  onError: PropTypes.func,
  methods: PropTypes.object,
  roles: PropTypes.array,
  permissions: PropTypes.array,
};

export default UserDetails;
