import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useWatch } from "react-hook-form";
import { formatCurrency } from "../../../utils/dineroFormatters";
import Alert from "@mui/material/Alert";

const Submit = ({ handleSubmit, control, showSurchargeMsg }) => {
  const total = useWatch({ control, name: "total", defaultValue: 0 });

  return (
    <>
      {showSurchargeMsg && (
        <Grid item xs={12}>
          <Alert severity="warning" style={{ marginBottom: "10px" }}>
            There will be a surcharge on credit cards that is not greater than
            our cost of acceptance. This surcharge rate is {showSurchargeMsg}%.
            This surcharge is not applied on debit card or ACH transactions.
          </Alert>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={5}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            data-cy="vt-submit"
          >
            Submit
          </Button>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            align="right"
            color="textSecondary"
            noWrap={true}
          >
            Total: ${formatCurrency(parseFloat(total))}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Submit;

Submit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  showSurchargeMsg: PropTypes.any,
};
