import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import SearchTable from "../../../Search/SearchTable";
import {
  userListColumnHeaders,
  userListKeyValue,
} from "./gatewayUsersTableHelpers";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import axios from "axios";
import { sassEndpoints } from "../../../../constants/endpoints";
import { GatewaySettingsContext } from "../gatewaySettingsContext";
import { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import SearchPaging from "../../../Search/SearchPaging";
import { b2cPolicies } from "../../../../authConfig";
import { stringFormat } from "../../../../utils/stringHelpers";

function GateWayUserList({ id, refreshList, handleDeleteClick }) {
  const [gatewaysUser, setGatewaysUser] = useState([]);
  const { gatewayProps, setGatewayProps } = useContext(GatewaySettingsContext);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);
  const { userSettings } = useContext(UserSettingsContext);
  const [resultsPerPage, setResultsPerPage] = useState(20);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const msal = useMsal();

  useEffect(() => {
    retrieveUserList();
  }, [refreshList, resultOffset, resultsPerPage]);

  const retrieveUserList = async () => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [id]) +
      `?offset=${resultOffset}&limit=${resultsPerPage}`;
    setGatewayProps({
      ...gatewayProps,
      isLoading: true,
    });
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (response) {
        if (response.data.length === 0) {
          setGatewaysUser([]);
          setResultsTotal(0);
        } else if (response?.data?.data.results.length > 0) {
          setGatewaysUser(
            response.data.data.results.filter((row) => {
              return { ...row.user, role: row.role };
            }),
          );
          setResultsTotal(response.data.data.rowCount);
        }
      })
      .catch(function () {
        showAlertDialogError("Failed to retrieve list of gateway users");
      })
      .finally(function () {
        setGatewayProps({
          ...gatewayProps,
          isLoading: false,
        });
        setShowBackdrop(false);
      });
  };

  const getUserData = (gatewayUserId) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [id]) +
      "/" +
      gatewayUserId +
      "?isSystemUserId=false";
    axios
      .get(url)
      .then(function (response) {
        if (response.status === 204) {
          showAlertDialogError("Failed to get gateway user details");
        } else {
          setGatewayProps({
            ...gatewayProps,
            usersState: "CREATE",
            copySettings: {
              permissions: response?.data?.data?.gatewayUserPermissions,
              role: response.data.data.role?.roleId,
            },
          });
        }
      })
      .catch(function (error) {
        showAlertDialogError("Failed to get gateway user details");
        setShowBackdrop(false);
        console.log("Failed to get gateway user details", error);
      });
  };

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultOffset(pageIndex * resultsPerPage);
  };

  const handleResultsPerPageOnChange = (event) => {
    setResultOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };
  const loginAs = (userData) => {
    const user_id = userData.user.userId;
    msal.instance
      .logout({
        onRedirectNavigate: () => {
          return false;
        },
      })
      .then(() => {
        msal.instance.loginRedirect({
          authority: b2cPolicies.authorities.signInOBO.authority,
          scopes: b2cPolicies.scopes,
          prompt: "select_account",
          extraQueryParameters: {
            impersonatedUserId: user_id,
            impersonatorEmail: userSettings.email,
          },
        });
      });
  };

  const getActionsComponent = (data) => {
    return (
      <ButtonGroup>
        <Tooltip title="View">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() =>
              setGatewayProps({
                ...gatewayProps,
                usersState: "VIEW",
                selectedUser: data.gatewayUserId,
                isDisabled: true,
              })
            }
            color={"primary"}
            fontSize="small"
            data-cy="view-gateway-user"
          >
            <VisibilityOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() =>
              setGatewayProps({
                ...gatewayProps,
                usersState: "VIEW",
                selectedUser: data.gatewayUserId,
                isDisabled: false,
              })
            }
            color={"warning"}
            fontSize="small"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy User">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => getUserData(data.gatewayUserId)}
            color={"primary"}
            fontSize="small"
          >
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {data.user.status !== "disabled" && (
          <Tooltip title="Login as">
            <IconButton
              variant="container"
              sx={{ padding: 0.5 }}
              onClick={() => loginAs(data)}
              color={"primary"}
              fontSize="small"
              data-cy="sys-login-as-icon"
            >
              <PersonIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Delete">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => handleDeleteClick(data)}
            color={"error"}
            fontSize="small"
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    );
  };
  return (
    <div className="gateway-table-wrapper">
      <div>
        {" "}
        <Button
          onClick={() =>
            setGatewayProps({
              ...gatewayProps,
              usersState: "CREATE",
            })
          }
          variant="contained"
          color="primary"
          data-cy="sys-gateway-create-user-button"
          sx={{ marginBottom: "10px" }}
        >
          Create New User
        </Button>
      </div>
      <SearchTable
        showBackdrop={showBackdrop}
        searchResults={gatewaysUser}
        getEntityKeyValue={userListKeyValue}
        fieldList={userListColumnHeaders}
        getActionsComponent={getActionsComponent}
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
      />
      <div className="pagination">
        {" "}
        <SearchPaging
          resultsPerPage={resultsPerPage}
          resultsTotal={resultsTotal}
          handleChange={handlePageChange}
          defaultPerPage={20}
          handleResultsPerPageOnChange={handleResultsPerPageOnChange}
        />
      </div>
    </div>
  );
}
GateWayUserList.propTypes = {
  id: PropTypes.string,
  refreshList: PropTypes.number,
  handleDeleteClick: PropTypes.func,
};
export default GateWayUserList;
