import React, { useEffect, useState, useContext } from "react";
import PaymentDetails from "./PaymentDetails";
import PaymentMethodSelector from "./PaymentMethodSelector";
import { FormProvider } from "react-hook-form";
import { useForm } from "react-hook-form";
import "../../../assets/v2/main.scss";
import "./terminal.scss";
import { TerminalContext } from "./TerminalContext";
import { GatewayContext } from "../../../contexts/GatewayContext";
import { noProcessors } from "../../../constants/routes";
import { useHistory } from "react-router";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";
import { GatewayConfigContext } from "../../../contexts/GatewayConfigContext";
const TerminalContainer = () => {
  const methods = useForm();
  const [paymentType, setPaymentType] = useState("sale");

  const gatewayInfo = useContext(GatewayContext);
  const gatewayConfig = useContext(GatewayConfigContext);
  const history = useHistory();

  const defaultTaxToTotal =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Virtual Terminal",
      "add_tax_to_total",
    ) || null;

  const [taxRules, setTaxRules] = useState({
    taxExempt: false,
    taxToTotal: defaultTaxToTotal.toLowerCase() === "true",
  });

  useEffect(() => {
    if (gatewayInfo.processors.length === 0) {
      history.push(noProcessors);
    }
  }, []);

  const resetMainState = () => {
    setTaxRules({
      taxExempt: false,
      taxToTotal: defaultTaxToTotal.toLowerCase() === "true",
    });
    methods.setValue("type", "sale");
    setPaymentType("sale");
    methods.setValue("amount", 0);
  };

  return (
    <TerminalContext.Provider
      value={{
        paymentType,
        setPaymentType,
        taxRules,
        setTaxRules,
      }}
    >
      <div className="v2 temporary-wrapper">
        <div className="sub-wrapper">
          <FormProvider {...methods}>
            <h1>Terminal</h1>
            <PaymentDetails />
            <PaymentMethodSelector resetState={resetMainState} />
          </FormProvider>
        </div>
      </div>
    </TerminalContext.Provider>
  );
};

export default TerminalContainer;
