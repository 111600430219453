import { React, useContext } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import SearchTable from "../../Search/SearchTable";
import {
  gatewayUserColumnHeaders,
  gatewayUserKeyValue,
} from "./usersTableHelpers";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useHistory } from "react-router-dom";
import { partnerRoutes } from "../../../constants/routes";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { USER_ROLES } from "../../../constants/global";
import SearchPaging from "../../Search/SearchPaging";
function ManageUsers({
  users,
  handleDeleteClick,
  createCopyUser,
  resultsPerPage,
  resultsTotal,
  handleChange,
  handleResultsPerPageOnChange,
}) {
  const history = useHistory();
  const { userSettings } = useContext(UserSettingsContext);
  const viewUser = (data) => {
    history.push(`${partnerRoutes.manage.users}/${data.user_id}`);
  };

  const editViewUser = (data) => {
    history.push({
      pathname: `${partnerRoutes.manage.users}/${data.user_id}`,
      state: {
        editMode: true,
      },
    });
  };
  const getActionsComponent = (row) => {
    return (
      <ButtonGroup>
        <Tooltip title="View">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => viewUser(row)}
            color={"primary"}
            fontSize="small"
          >
            <VisibilityOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        {userSettings?.userRole === USER_ROLES.SUPER_USER && (
          <Tooltip title="Edit">
            <IconButton
              variant="container"
              sx={{ padding: 0.5 }}
              onClick={() => editViewUser(row)}
              color={"warning"}
              fontSize="small"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {userSettings?.userRole === USER_ROLES.SUPER_USER && (
          <Tooltip title="Copy User">
            <IconButton
              variant="container"
              sx={{ padding: 0.5 }}
              onClick={() => {
                createCopyUser(row);
              }}
              color={"primary"}
              fontSize="small"
            >
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {userSettings?.userRole === USER_ROLES.SUPER_USER && (
          <Tooltip title="Delete">
            <IconButton
              variant="container"
              sx={{ padding: 0.5 }}
              onClick={() => handleDeleteClick(row)}
              color={"error"}
              fontSize="small"
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </ButtonGroup>
    );
  };
  return (
    <div className="gateway-table-wrapper">
      <SearchTable
        searchResults={users}
        getEntityKeyValue={gatewayUserKeyValue}
        fieldList={gatewayUserColumnHeaders}
        getActionsComponent={getActionsComponent}
      />
      <SearchPaging
        resultsPerPage={resultsPerPage}
        resultsTotal={resultsTotal}
        handleChange={handleChange}
        handleResultsPerPageOnChange={handleResultsPerPageOnChange}
      />
    </div>
  );
}

ManageUsers.propTypes = {
  users: PropTypes.array,
  resultsPerPage: PropTypes.number,
  resultsTotal: PropTypes.number,
  handleChange: PropTypes.func,
  handleResultsPerPageOnChange: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  createCopyUser: PropTypes.func,
};

export default ManageUsers;
