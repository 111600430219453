import { FormProvider, useForm } from "react-hook-form";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import axios from "axios";
import { useEffect, useState, useImperativeHandle } from "react";
import { sassEndpoints } from "../../../../constants/endpoints";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useTheme } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import { GatewaySettingsContext } from "../gatewaySettingsContext";
import { useContext } from "react";
import PropTypes from "prop-types";
import PhoneNumber from "../../../Contact/PhoneNumber";
import Switch from "../../../ReactHookForm/Switch";
import { convertPropertyToName } from "../../../../utils/stringHelpers";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button } from "@mui/material";
import { regexPatterns } from "../../../../constants/regexPatterns";
import { validationRules } from "../../../../constants/validationRules";
import MailIcon from "@mui/icons-material/Mail";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import {
  USER_ROLES,
  USER_STATUS,
  USER_STATUS_CODES,
} from "../../../../constants/global";
import { Grid } from "@mui/material";
import { stringFormat } from "../../../../utils/stringHelpers";

function GatewayUserDetail({
  gatewayInfo,
  gatewayId,
  warningMessage,
  onSubmission,
  editGatewayUser,
  resendEmail,
  backToGateway,
  handleClick,
}) {
  useImperativeHandle(onSubmission, () => ({
    onSubmit: () => {
      assemblePayload();
    },
    onRefresh: () => {
      getUserData(gatewayProps.selectedUser, true);
    },
  }));
  const { ...form } = useForm();
  const theme = useTheme();
  const { gatewayProps, setGatewayProps } = useContext(GatewaySettingsContext);
  const [statuses, setStatuses] = useState([]);
  const [viewOnly, setViewOnly] = useState(true);
  const [userData, setUserData] = useState(null);
  const allowCustomerPayable =
    gatewayInfo?.gatewaySettings
      ?.find((obj) => obj.code.toLowerCase() === "customer_payable")
      ?.value.toLowerCase() === "true";

  useEffect(() => {
    setViewOnly(gatewayProps.isDisabled);
    getUserData(gatewayProps.selectedUser, false);
  }, []);

  useEffect(() => {
    if (userData) {
      setAvailableStatus(userData.user.status);
      form.setValue("user_status", userData.user.status);
    }
  }, [userData]);

  useEffect(() => {
    if (userData) {
      setGatewayProps({
        ...gatewayProps,
        isDisabled: !form.formState.isValid,
      });
    }
  }, [form.formState.isValid]);

  const setAvailableStatus = (state) => {
    if (state === USER_STATUS.tos) {
      setStatuses([USER_STATUS.tos, USER_STATUS.disabled]);
    } else if (state === USER_STATUS.active) {
      setStatuses([USER_STATUS.active, USER_STATUS.tos, USER_STATUS.disabled]);
    } else if (state === USER_STATUS.disabled) {
      setStatuses([USER_STATUS.tos, USER_STATUS.disabled]);
    } else {
      setStatuses([USER_STATUS.pending, USER_STATUS.disabled]);
    }
  };

  const handlePermissions = (property) => {
    let found = false;
    userData.gatewayUserPermissions.forEach((p) => {
      if (p.code === property.code) {
        found = true;
      }
    });
    return found;
  };

  const getUserData = (id, refresh) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [gatewayId]) +
      "/" +
      id +
      "?isSystemUserId=false";
    setGatewayProps({
      ...gatewayProps,
      isLoading: true,
    });
    if (refresh) {
      setViewOnly(true);
    }
    axios
      .get(url)
      .then(function (response) {
        if (response.status === 204) {
          warningMessage("Failed to get gateway details");
        }
        setGatewayProps({
          ...gatewayProps,
          isDisabled: refresh || gatewayProps.isDisabled,
        });
        setUserData(response.data.data);
      })
      .catch(function (error) {
        warningMessage("Failed to get gateway details");
        console.log("Failed to get gateway details", error);
      })
      .finally(() => {
        setGatewayProps({
          ...gatewayProps,
          isLoading: false,
        });
      });
  };

  const handlePayloadPermissions = (permissionsList) => {
    let permissionCodes = [];
    Object.keys(permissionsList).forEach((name) => {
      if (permissionsList[name]) {
        let foundP = gatewayProps.gatewayPermissions.find((p) => {
          return p?.code?.toLowerCase() === name;
        });
        if (foundP) {
          permissionCodes.push(foundP.permissionId);
        }
      }
    });
    return permissionCodes;
  };

  const assemblePayload = async () => {
    let triggerList = ["firstname", "lastname", "gateway_user_status", "phone"];

    //Is true when there are no errors
    let errorVer = await form.trigger(triggerList);

    if (Object.keys(form.formState.errors).length === 0 && errorVer) {
      const payload = {
        gatewayUserStatus: form.getValues().gateway_user_status,
        firstName: form.getValues().firstname,
        lastName: form.getValues().lastname,
        userStatus: USER_STATUS_CODES[form.getValues().user_status],
        roleId: form.getValues().role,
        phone: form.getValues().phone,
        permissionIds: handlePayloadPermissions(form.getValues().permissions),
      };
      editGatewayUser(payload, userData.gatewayUserId);
    } else {
      warningMessage("Please correct validation errors highlighted in red ");
    }
  };
  const gatewayUserStatus = ["Active", "Disabled"];
  return (
    <div className="gateway-user-detail">
      <FormProvider {...form}>
        {userData && (
          <div className="user-details-wrapper">
            <div className="details-section">
              <div className="gateway-user-header-system">
                <Tooltip title="Return to List">
                  <IconButton
                    variant="container"
                    color="primary"
                    fontSize="small"
                    onClick={() =>
                      setGatewayProps({
                        ...gatewayProps,
                        usersState: "DEFAULT",
                      })
                    }
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                </Tooltip>
                <div className="user-info-header">
                  <h2>User Info</h2>
                </div>
                <IconButton
                  title={"Edit"}
                  variant="container"
                  sx={{ padding: 0.5 }}
                  onClick={() => {
                    setViewOnly(!viewOnly);
                    setGatewayProps({ ...gatewayProps, isDisabled: !viewOnly });
                  }}
                  color={"warning"}
                  fontSize="small"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </div>
              <Grid item sx={{ marginBottom: "10px" }}>
                <RegisteredTextField
                  id="email"
                  name="email"
                  label="Email"
                  defaultValue={userData.user.email}
                  fullWidth
                  viewOnly={true}
                />
              </Grid>
              <div className="form-row">
                <Grid container>
                  <Grid
                    sx={{
                      marginBottom: "10px",
                      marginRight: { sm: "10px" },
                    }}
                    item
                    xs={12}
                    sm={3}
                  >
                    <RegisteredTextField
                      id="firstname"
                      name="firstname"
                      label="First Name"
                      errors={form.errors}
                      defaultValue={userData.user.firstName}
                      rules={{
                        pattern: regexPatterns.name,
                        required: true,
                        maxLength: validationRules.maxLength100,
                      }}
                      viewOnly={viewOnly}
                    />
                  </Grid>
                  <Grid
                    sx={{ marginBottom: "10px", marginRight: { sm: "10px" } }}
                    item
                    xs={12}
                    sm={3}
                  >
                    <RegisteredTextField
                      id="lastname"
                      name="lastname"
                      label="Last Name"
                      defaultValue={userData.user.lastName}
                      errors={form.errors}
                      rules={{
                        pattern: regexPatterns.name,
                        required: true,
                        maxLength: validationRules.maxLength100,
                      }}
                      viewOnly={viewOnly}
                    />
                  </Grid>
                  <Grid
                    sx={{ marginBottom: "10px", marginRight: { sm: "10px" } }}
                    item
                    xs={12}
                    sm={5}
                  >
                    <PhoneNumber
                      name="phone"
                      id="phone"
                      label="Phone"
                      errors={form.errors}
                      defaultValue={userData?.phone}
                      required={true}
                      viewOnly={viewOnly}
                    />
                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid container>
                  <Grid
                    item
                    sx={{ marginBottom: "10px", marginRight: { sm: "10px" } }}
                    xs={12}
                    sm={5}
                  >
                    <Select
                      control={form.control}
                      name="user_status"
                      label={
                        <div>
                          User Status
                          <Tooltip title="If the user status is disabled, the user will not be able to login to any gateway that this email address is associated with">
                            <ErrorIcon
                              fontSize="medium"
                              variant="container"
                              sx={{
                                padding: 0.3,
                                alignItems: "center",
                                verticalAlign: "middle",
                                display: "inline-flex",
                                spacing: "2px",
                                color: `${theme.palette.warning.dark}`,
                              }}
                            />
                          </Tooltip>
                        </div>
                      }
                      errors={form.errors}
                      viewOnly={viewOnly}
                      defaultValue={userData.user.status}
                      data-cy="system-user-status-select"
                    >
                      {statuses.map((status, i) => {
                        return (
                          <MenuItem key={i} value={status}>
                            <span style={{ textTransform: "capitalize" }}>
                              {status === USER_STATUS.pending
                                ? "Invite Pending"
                                : status === USER_STATUS.tos
                                  ? "Pending TOS"
                                  : status}
                            </span>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Select
                      control={form.control}
                      name="gateway_user_status"
                      label="Gateway User Status"
                      errors={form.errors}
                      rules={{ required: true }}
                      viewOnly={viewOnly}
                      defaultValue={userData.gatewayUserStatus}
                      data-cy="gateway-user-status-select"
                    >
                      {gatewayUserStatus.map((status, i) => {
                        return (
                          <MenuItem key={i} value={status}>
                            <span style={{ textTransform: "capitalize" }}>
                              {status}
                            </span>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </div>
              <Grid item xs={12} sm={5}>
                {gatewayProps.gatewayRoles && (
                  <Select
                    control={form.control}
                    name="role"
                    fullWidth
                    id="role"
                    viewOnly={viewOnly}
                    defaultValue={userData.role.roleId}
                    label="Role"
                  >
                    {gatewayProps.gatewayRoles
                      .filter(
                        (role) =>
                          role.code === USER_ROLES.GATEWAY_STANDARD ||
                          role.code === USER_ROLES.GATEWAY_ADMIN,
                      )
                      .map((role, i) => {
                        return (
                          <MenuItem key={i} value={role.roleId}>
                            {role.code === USER_ROLES.GATEWAY_STANDARD
                              ? "Standard"
                              : "Admin"}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              </Grid>
            </div>
            {gatewayProps.gatewayPermissions && (
              <div className="permissions-section">
                <h3>Permissions</h3>
                <div
                  className="toggle-container"
                  data-cy="gateway-user-permissions"
                >
                  <ul>
                    {gatewayProps.gatewayPermissions.map((p, i) => {
                      if (
                        p?.code?.toLowerCase() !== "vault_read" &&
                        p?.code?.toLowerCase() !== "manage_users" &&
                        p?.code?.toLowerCase().slice(0, 3) !== "pro" &&
                        !(
                          (p?.code?.toLowerCase() ===
                            "manage_cust_payable_token" ||
                            p?.code?.toLowerCase() ===
                              "view_cust_payable_token") &&
                          !allowCustomerPayable
                        )
                      ) {
                        return (
                          <Switch
                            key={i}
                            control={form.control}
                            label={convertPropertyToName(
                              p?.code?.toLowerCase(),
                            )}
                            labelPlacement="start"
                            name={"permissions." + p?.code?.toLowerCase()}
                            defaultValue={handlePermissions(p)}
                            disabled={viewOnly}
                          />
                        );
                      }
                    })}
                  </ul>
                  <ul>
                    {gatewayProps.gatewayPermissions.map((p, i) => {
                      if (p?.code?.toLowerCase().slice(0, 3) === "pro") {
                        return (
                          <Switch
                            key={i}
                            control={form.control}
                            label={convertPropertyToName(
                              p?.code?.toLowerCase(),
                            )}
                            labelPlacement="start"
                            name={"permissions." + p?.code?.toLowerCase()}
                            defaultValue={handlePermissions(p)}
                            disabled={viewOnly}
                          />
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            )}
            <div className="button-row">
              <Tooltip title="Copy over permissions and role to new user.">
                <Button
                  className="copy-btn"
                  variant="contained"
                  onClick={() => {
                    setGatewayProps({
                      ...gatewayProps,
                      usersState: "CREATE",
                      gatewayRoles: gatewayProps.gatewayRoles,
                      copySettings: {
                        permissions: userData.gatewayUserPermissions,
                        role: userData.role.roleId,
                      },
                    });
                  }}
                  color="primary"
                  data-cy="sys-copy-user-button"
                >
                  <ContentCopyIcon />
                  Copy User Permissions
                </Button>
              </Tooltip>
              {userData.user.status === "InvitePending" && (
                <Button
                  variant="contained"
                  onClick={() => resendEmail(userData.user.userId)}
                  color="primary"
                  data-cy="resend-welcome-button"
                >
                  <MailIcon />
                  Resend Welcome Email
                </Button>
              )}
              {!viewOnly && (
                <Grid container className="user-details-save">
                  <Button
                    variant="contained"
                    size="small"
                    color="neutrals"
                    onClick={() => {
                      backToGateway();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-cy="gateway-view-submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => handleClick()}
                  >
                    Submit
                  </Button>
                </Grid>
              )}
            </div>
          </div>
        )}
      </FormProvider>
    </div>
  );
}

GatewayUserDetail.propTypes = {
  gatewayInfo: PropTypes.object,
  gatewayId: PropTypes.string,
  warningMessage: PropTypes.func,
  onSubmission: PropTypes.object,
  editGatewayUser: PropTypes.func,
  resendEmail: PropTypes.func,
  backToGateway: PropTypes.func,
  handleClick: PropTypes.func,
};

export default GatewayUserDetail;
