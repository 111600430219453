import PropTypes from "prop-types";
import SearchTable from "../../../Search/SearchTable";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { merchantRoutes } from "../../../../constants/routes";
import { useHistory } from "react-router";
import "../customFields.scss";
import { capitalize } from "../../../../utils/stringHelpers";
export const customTableHeaders = [
  {
    default_field: false,
    key: "category_name",
    sortable: false,
  },
  {
    default_field: false,
    key: "location_used",
    sortable: false,
  },
];

const CustomFieldsList = ({ customFields, expandRow, deleteCategory }) => {
  const getCustomKeyValue = (key, data) => {
    switch (key) {
      case "category_name": {
        return <div>{data?.name}</div>;
      }
      case "location_used": {
        let types = ["transaction", "customer", "invoice"];
        let location = "";
        let validCount = 0;
        types.forEach((t) => {
          if (data[t]) {
            if (validCount < 1) {
              location =
                location +
                `${t === "transaction" ? "Virtual Terminal" : capitalize(t)}`;
            } else {
              location =
                location +
                `, ${t === "transaction" ? "Virtual Terminal" : capitalize(t)}`;
            }
            validCount++;
          }
        });
        return <div>{validCount > 0 ? location : "No Location Used"}</div>;
      }
      default:
        return data[key];
    }
  };

  const expandedTable = (data) => {
    return (
      <Box className="sub-custom-field-table">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Custom Field Name</TableCell>
              <TableCell>Required</TableCell>
              <TableCell>Validation Type</TableCell>
              <TableCell>Field Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.customFields.map((customField, i) => (
              <TableRow key={i}>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {customField.name}
                </TableCell>
                <TableCell>{customField.isRequired ? "Yes" : "No"}</TableCell>
                <TableCell>
                  {parseValidationType(customField.dataType)}
                </TableCell>
                <TableCell>{customField.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  };

  const parseValidationType = (type) => {
    switch (type) {
      case "System.String": {
        return "All Characters";
      }
      case "System.UInt32": {
        return "Numbers Only";
      }
      default:
        //Lookup CustomField
        return type;
    }
  };

  const getActionsComponent = (row) => {
    const history = useHistory();
    return (
      <ButtonGroup>
        <IconButton
          title={"Edit"}
          variant="container"
          sx={{ padding: 0.5 }}
          onClick={() => {
            history.push(
              `${merchantRoutes.manage.custom_fields.update}/${row.customFieldCategoryId}`,
              row.customFieldCategoryId,
            );
          }}
          color={"warning"}
          fontSize="small"
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          title={"Delete"}
          variant="container"
          sx={{ padding: 0.5 }}
          onClick={() => deleteCategory(row?.customFieldCategoryId)}
          color={"error"}
          fontSize="small"
        >
          <CancelIcon fontSize="small" />
        </IconButton>
        <div className="expand-toggle">
          <IconButton
            title={row.isExpanded ? "Open" : "Close"}
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => {
              expandRow(row);
            }}
            fontSize="small"
          >
            {row.isExpanded ? (
              <RemoveIcon fontSize="small" />
            ) : (
              <AddIcon fontSize="small" />
            )}
          </IconButton>
        </div>
      </ButtonGroup>
    );
  };
  return (
    <div className="custom-fields-table">
      <SearchTable
        searchResults={customFields}
        getEntityKeyValue={getCustomKeyValue}
        fieldList={customTableHeaders}
        getActionsComponent={getActionsComponent}
        expandData={expandedTable}
      />
    </div>
  );
};

CustomFieldsList.propTypes = {
  customFields: PropTypes.array,
  expandRow: PropTypes.func,
  deleteCategory: PropTypes.func,
};

export default CustomFieldsList;
