import * as React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

export default function BasicTable(props) {
  const { tableColumns, tableRows } = props;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>{tableColumns}</TableHead>
        {tableRows}
      </Table>
    </TableContainer>
  );
}
BasicTable.propTypes = {
  tableColumns: PropTypes.any,
  tableRows: PropTypes.any,
};
