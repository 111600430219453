import { parseISO } from "date-fns";

const addDaysToDate = (date, days) => {
  var d = parseISO(date);
  d.setDate(d.getDate() + days);
  return d;
};

const differenceInDays = (date1, date2) => {
  const differenceInTime = parseISO(date2) - parseISO(date1);

  return Math.round(differenceInTime / (1000 * 60 * 60 * 24));
};

export { addDaysToDate, differenceInDays };
