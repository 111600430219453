function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export const getTransactionFeatureDisplay = (feature) => {
  var out = feature.replace("_", " ").replace("-", " ");

  out = out.split(" ").map(capitalize).join(" ");

  return out;
};

export const getTransactionTypeDisplayInfo = (type) => {
  type = capitalize(type);
  switch (type) {
    case "Credit":
      return { display: "credit", color: "primary" };

    case "Verification":
      return { display: "Verification", color: "secondary" };

    case "Sale":
      return { display: "sale", color: "primary" };

    case "Authorize":
      return { display: "Authorize", color: "warning" };

    case "Refund":
      return { display: "Refund", color: "error" };

    default:
      return { display: "Unknown", color: "error" };
  }
};

export const getTransactionStatusDisplayInfo = (status) => {
  status = capitalize(status);
  switch (status) {
    case "Unknown":
      return { display: "Unknown", color: "primary.main" };

    case "Authorized":
      return { display: "Authorized", color: "primary" };

    case "Approved":
      return { display: "Authorized", color: "primary" };

    case "Settled":
      return { display: "Settled", color: "primary" };

    case "Pending_Settlement":
      return { display: "Pending Settlement", color: "warning.main" };

    case "PendingSettlement":
      return { display: "Pending Settlement", color: "warning.main" };

    case "Partially_Refunded":
      return { display: "Partially Refunded", color: "warning.main" };

    case "PartiallyRefunded":
      return { display: "Partially Refunded", color: "warning.main" };

    case "Declined":
      return { display: "Declined", color: "error.main" };

    case "Voided":
      return { display: "Voided", color: "error.main" };

    case "Refunded":
      return { display: "Refunded", color: "error.main" };

    case "Returned":
      return { display: "Returned", color: "error.main" };

    case "LateReturn":
      return { display: "Late Return", color: "error.main" };

    default:
      return { display: "<Default>", color: "primary.main" };
  }
};

export const currencyInputOnKeyDown = (e) => {
  var validKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
    "Delete",
    "Home",
    "End",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];
  //Prevent invalid key presses
  validKeys.includes(e).length === 0 && e.preventDefault();

  if (e.key === "ArrowUp" && Number.parseFloat(e.target.value) > 0.0) {
    e.target.value = Number.parseFloat(
      Number.parseFloat(e.target.value) + 1,
    ).toFixed(2);
  }

  if (e.key === "ArrowDown" && Number.parseFloat(e.target.value) > 1.0) {
    e.target.value = Number.parseFloat(e.target.value - 1).toFixed(2);
  }
};

export const currencyInputOnChange = (e) => {
  var targetInput = e.target;
  var start_caret_pos = targetInput.selectionStart;
  var original_len = targetInput.value.length;
  var fixedInput = targetInput.value.replace(/[A-Za-z!@,#$%^&*()-]/g, "");

  //Strip out decimal and commas
  fixedInput = fixedInput.replace(".", "").replace(",", "");

  //De-normalize amount
  fixedInput = fixedInput / 100;

  //Revert to 0.00 if for some reason NAN
  if (isNaN(fixedInput)) fixedInput = "0.00";

  //Format and add commas
  fixedInput = Number.parseFloat(fixedInput).toFixed(2);

  //Set formatted input value
  targetInput.value = fixedInput.toString();

  //Set caret
  var updated_len = fixedInput.length;
  start_caret_pos = updated_len - original_len + start_caret_pos;
  targetInput.setSelectionRange(start_caret_pos, start_caret_pos);
};

//this function implementes midpoint rounding to even, or bankers rounding to two decimal places
export const roundToEven = (num) => {
  const factor = 100; // To handle rounding to two decimal places
  const scaledNum = num * factor;
  const integralPart = Math.floor(scaledNum);
  const fractionalPart = scaledNum - integralPart;

  if (fractionalPart === 0.5) {
    return integralPart % 2 === 0
      ? integralPart / factor
      : (integralPart + 1) / factor;
  } else {
    return Math.round(scaledNum) / factor;
  }
};
