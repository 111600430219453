import { add, parseISO, isWithinInterval } from "date-fns";
import {
  BILLING_PERIODS,
  SUBSCRIPTION_STATUSES,
} from "../../../../constants/global";

export const statusTag = (status) => {
  let tagClass;
  switch (status) {
    case SUBSCRIPTION_STATUSES.DRAFT:
      tagClass = "tag__grey";
      break;
    case SUBSCRIPTION_STATUSES.SCHEDULED:
      tagClass = "tag__warning";
      break;
    case SUBSCRIPTION_STATUSES.TRIALING:
      tagClass = "tag__blue";
      break;
    case SUBSCRIPTION_STATUSES.ACTIVE:
      tagClass = "tag__success";
      break;
    case SUBSCRIPTION_STATUSES.OVERDUE:
      tagClass = "tag__yellow";
      break;
    case SUBSCRIPTION_STATUSES.PAUSED:
      tagClass = "tag__purple";
      break;
    case SUBSCRIPTION_STATUSES.CANCELLED:
      tagClass = "tag__failed";
      break;
    case SUBSCRIPTION_STATUSES.COMPLETE:
      tagClass = "tag__pink";
      break;
  }

  return (
    <div className={`tag subscription-status ${tagClass}`}>
      {status?.toUpperCase()}
    </div>
  );
};

export const getLastInterval = (billingPeriod, startDate) => {
  let dates = {
    start: parseISO(startDate),
    end: getNextDate(billingPeriod, startDate),
  };

  if (isWithinInterval(new Date(), dates)) return dates;
  else {
    return getLastInterval(
      billingPeriod,
      getNextDate(billingPeriod, startDate).toISOString(),
    );
  }
};

export const getNextDate = (billingPeriod, startDate) => {
  let date;

  switch (billingPeriod) {
    case BILLING_PERIODS.DAILY:
      date = add(parseISO(startDate), {
        days: 1,
      });
      break;
    case BILLING_PERIODS.WEEKLY:
      date = add(parseISO(startDate), {
        weeks: 1,
      });
      break;
    case BILLING_PERIODS.BIWEEKLY:
      date = add(parseISO(startDate), {
        weeks: 2,
      });
      break;
    case BILLING_PERIODS.MONTHLY:
      date = add(parseISO(startDate), {
        months: 1,
      });
      break;
    case BILLING_PERIODS.QUARTERLY:
      date = add(parseISO(startDate), {
        months: 3,
      });
      break;
    case BILLING_PERIODS.YEARLY:
      date = add(parseISO(startDate), {
        years: 1,
      });
      break;
  }

  return date;
};
