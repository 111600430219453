import { formatDateTime, formatPhone } from "../../../utils/stringHelpers";
import { GATEWAY_STATUS } from "../../../constants/global";
import "./gateway.scss";
import {
  sassFilterOperators,
  filterOperators,
} from "../../Search/searchHelpers";
export const gatewayColumnHeaders = [
  {
    default_field: true,
    key: "status",
    sortable: false,
  },
  {
    default_field: true,
    key: "name",
    sortable: false,
  },
  {
    default_field: true,
    key: "company",
    sortable: false,
  },
  {
    default_field: true,
    key: "contact",
    sortable: false,
  },
  {
    default_field: true,
    key: "created",
    sortable: false,
  },
];

export const gatewayKeyValue = (key, data) => {
  switch (key) {
    case "created": {
      return formatDateTime(data.createdDateTime).split(" ")[0];
    }
    case "company": {
      return data.contactCompany;
    }
    case "name": {
      return data.name;
    }
    case "status": {
      return (
        <div className={`${data.status} gateway-badge`}>{data.status}</div>
      );
    }
    case "contact": {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{data.contactName}</div>
          <div>{formatPhone(data.contactPhoneNumber)}</div>
          <div>{data.contactEmail}</div>
        </div>
      );
    }
    default:
      //Lookup CustomField
      return data[key];
  }
};

const generalGroup = "General";

export const gatewaySearchFields = [
  {
    group: generalGroup,
    displayName: "Status",
    fieldName: "status",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "gateway_status",
    selected: true,
    savedValues: {
      operator: sassFilterOperators.is.value,
      value: "active",
    },
  },
  {
    group: generalGroup,
    displayName: "Name",
    fieldName: "name",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.isLike,
      sassFilterOperators.notEquals,
      sassFilterOperators.isNotLike,
    ],
    fieldType: "text",
    selected: true,
    savedValues: {
      operator: sassFilterOperators.isLike.value,
      value: "",
    },
  },
  {
    group: generalGroup,
    displayName: "Company",
    fieldName: "contactCompany",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.isLike,
      sassFilterOperators.notEquals,
      sassFilterOperators.isNotLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Contact",
    fieldName: "contactName",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.isLike,
      sassFilterOperators.notEquals,
      sassFilterOperators.isNotLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Phone",
    fieldName: "contactPhoneNumber",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.isLike,
      sassFilterOperators.notEquals,
      sassFilterOperators.isNotLike,
    ],
    fieldType: "phone",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Email",
    fieldName: "contactEmail",
    operators: [
      sassFilterOperators.is,
      sassFilterOperators.isLike,
      sassFilterOperators.notEquals,
      sassFilterOperators.isNotLike,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Created Date",
    fieldName: "createdDate",
    operators: [sassFilterOperators.between],
    fieldType: "date_range",
    selected: false,
  },
];
export const gatewayLinkedColumnHeaders = [
  {
    default_field: true,
    key: "status",
    sortable: false,
  },
  {
    default_field: true,
    key: "name",
    sortable: false,
  },
  {
    default_field: true,
    key: "company",
    sortable: false,
  },
  {
    default_field: true,
    key: "contact",
    sortable: false,
  },
  {
    default_field: true,
    key: "created",
    sortable: false,
  },
];

export const gatewayLinkedKeyValue = (key, data) => {
  switch (key) {
    case "created": {
      return formatDateTime(data.created).split(" ")[0];
    }
    case "company": {
      return data.company;
    }
    case "name": {
      return data.name;
    }
    case "status": {
      return (
        <div className={`${data.status} gateway-badge`}>
          {data.status === GATEWAY_STATUS.PENDING ? "pending" : data.status}
        </div>
      );
    }
    case "contact": {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{data.contact}</div>
          <div>{formatPhone(data.phone)}</div>
          <div>{data.email}</div>
        </div>
      );
    }
    default:
      //Lookup CustomField
      return data[key];
  }
};
export const gatewayLinkSearchFields = [
  {
    group: generalGroup,
    displayName: "Name",
    fieldName: "name",
    operators: [
      filterOperators.isLike,
      filterOperators.is,
      filterOperators.isNotLike,
    ],
    fieldType: "text",
    selected: true,
  },
  {
    group: generalGroup,
    displayName: "Status",
    fieldName: "status",
    operators: [filterOperators.is, filterOperators.notEquals],
    fieldType: "gateway_linked_status",
  },
  {
    group: generalGroup,
    displayName: "Merchant ID",
    fieldName: "merchant_id",
    operators: [filterOperators.is, filterOperators.notEquals],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "Created Date",
    fieldName: "created_at",
    operators: [filterOperators.between],
    fieldType: "date_range",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "First Name",
    fieldName: "primary_contact.first_name",
    operators: [
      filterOperators.isLike,
      filterOperators.is,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "Last Name",
    fieldName: "primary_contact.last_name",
    operators: [
      filterOperators.isLike,
      filterOperators.is,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "Company",
    fieldName: "primary_contact.company",
    operators: [
      filterOperators.isLike,
      filterOperators.is,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "Address Line 1",
    fieldName: "primary_contact.address_line_1",
    operators: [
      filterOperators.isLike,
      filterOperators.is,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "Address Line 2",
    fieldName: "primary_contact.address_line_2",
    operators: [
      filterOperators.isLike,
      filterOperators.is,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "City",
    fieldName: "primary_contact.city",
    operators: [
      filterOperators.is,
      filterOperators.isLike,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "Country",
    fieldName: "primary_contact.country",
    operators: [filterOperators.is, filterOperators.notEquals],
    fieldType: "country",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "State",
    fieldName: "primary_contact.state",
    operators: [filterOperators.is, filterOperators.notEquals],
    fieldType: "state",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Postal Code",
    fieldName: "primary_contact.postal_code",
    operators: [
      filterOperators.isLike,
      filterOperators.is,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "Email",
    fieldName: "primary_contact.email",
    operators: [
      filterOperators.is,
      filterOperators.isLike,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "Phone",
    fieldName: "primary_contact.phone",
    operators: [
      filterOperators.isLike,
      filterOperators.is,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
  {
    group: generalGroup,
    displayName: "Fax",
    fieldName: "primary_contact.fax",
    operators: [
      filterOperators.isLike,
      filterOperators.is,
      filterOperators.notEquals,
    ],
    fieldType: "text",
  },
];
