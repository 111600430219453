import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import DrawerContainer from "../Drawer/DrawerContainer";
import HeaderContainer from "../Header/HeaderContainer";
import { drawerWidth, styles } from "./style";
import withStyles from "@mui/styles/withStyles";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import UserDrawer from "../Drawer/UserProfileDrawer";
import { useWindowSize } from "../../hooks/useWindowSize";
export const LayoutContext = React.createContext({});

const Layout = (props) => {
  const {
    classes,
    handleLogout,
    handleDrawerToggle,
    mobileOpen,
    toggleStyle,
    userRole,
    userName,
    children,
  } = props;
  const theme = useTheme();
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [snackbarProps, setSnackbarProps] = useState({
    snackbarOpen: false,
  });
  const [userProfileOpen, setUserProfileOpen] = useState(false);

  const [isMiniMenu, setMiniMenu] = useState(false);

  const size = useWindowSize();

  const handleCloseSnack = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarProps({ snackbarOpen: false });
  };

  const handleUserProfileToggle = () => {
    setUserProfileOpen(!userProfileOpen);
  };

  useEffect(() => {
    if (isMiniMenu && size.width < 768) {
      setMiniMenu(false);
    }
  }, [size]);

  return (
    <div className={snackbarProps.banner && "banner-alert"}>
      <div className={classes.root}>
        <CssBaseline />
        <LayoutContext.Provider
          value={{
            isMiniMenu,
            setMiniMenu,
          }}
        >
          <nav
            className={classes.drawer}
            style={{
              width: !isMiniMenu ? 275 : 80,
              transition: "width .5s",
            }}
          >
            {size?.width > 768 ? (
              <DrawerContainer
                PaperProps={{ style: { width: drawerWidth } }}
                userRole={userRole}
                handleLogout={handleLogout}
              />
            ) : (
              <DrawerContainer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                userRole={userRole}
                handleLogout={handleLogout}
              />
            )}
            <UserDrawer
              userRole={userRole}
              open={userProfileOpen}
              handleUserProfileToggle={handleUserProfileToggle}
              handleLogout={handleLogout}
            />
          </nav>
        </LayoutContext.Provider>
        <div className={classes.app}>
          <HeaderContainer
            onDrawerToggle={handleDrawerToggle}
            handleLogout={handleLogout}
            toggleStyle={toggleStyle}
            userName={userName}
            userRole={userRole}
            handleUserProfileToggle={handleUserProfileToggle}
          />

          <SnackbarContext.Provider value={{ snackbarProps, setSnackbarProps }}>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackbarProps.snackbarOpen}
              onClose={handleCloseSnack}
              autoHideDuration={snackbarProps.banner ? 20000 : 5000}
            >
              <Alert
                onClose={() => {
                  setSnackbarProps({ snackbarOpen: false });
                }}
                severity={snackbarProps.severity}
              >
                {snackbarProps.message}
              </Alert>
            </Snackbar>
            <main
              className={
                theme.palette.mode + "-theme " + (isMiniMenu && "miniMenu")
              }
            >
              {children}
            </main>
          </SnackbarContext.Provider>
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  toggleStyle: PropTypes.func,
  userName: PropTypes.string,
  userRole: PropTypes.string,
  children: PropTypes.element,
};

export default withStyles(styles)(Layout);
