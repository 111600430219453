import { useState, useEffect } from "react";
import axios from "axios";
import { useIsMounted } from "./useIsMounted";

export const useAxios = (axiosParams, options) => {
  const [data, setData] = useState(options?.initialData || {});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let isMounted = useIsMounted();

  const request = async (params, options) => {
    try {
      const result = await axios.request(params, {
        cancelToken: source.token,
      });

      if (isMounted) {
        setData(options?.dataTransform?.(result.data) || result.data);
        setError(null);
      }

      if (options && options.success) {
        options.success(result.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setData({});
      }
      if (options && options.error) {
        options.error(error);
      }
    } finally {
      isMounted && setLoading(false);
    }
  };

  useEffect(() => {
    request(axiosParams, options);

    const cleanUp = () => {
      source.cancel();
    };
    return cleanUp;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, error, loading };
};

export default useAxios;
