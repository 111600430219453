import React, { useRef } from "react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormContext } from "react-hook-form";
import { convertToInt, dineroFormatter } from "../../utils/dineroFormatters";
import ControllerTextField from "./ControllerTextField";

const getTypeSettings = (type) => {
  let inputAdornment, formatInput;
  switch (type.toLowerCase()) {
    case "currency":
      inputAdornment = "$";
      formatInput = dineroFormatter("currency");
      break;
    case "percentage":
      inputAdornment = "%";
      formatInput = dineroFormatter("percentage");
      break;
    case "auth":
      inputAdornment = "$";
      formatInput = dineroFormatter("currencyAuth");
      break;
    default:
      inputAdornment = "";
      formatInput = (str) => str;
  }

  return { inputAdornment, formatInput };
};

const FormattedTextField = ({
  v2,
  type,
  name,
  label,
  onChange,
  onBlur,
  disabled,
  defaultValue,
  maxLength,
  rules,
  transformOutput,
  viewOnly,
  onKeyDown,
  highlightError,
}) => {
  const inputRef = useRef();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { inputAdornment, formatInput } = getTypeSettings(type);

  const moveInputPositionToEnd = () => {
    inputRef.current.setSelectionRange(
      Number.MAX_SAFE_INTEGER,
      Number.MAX_SAFE_INTEGER,
    );
  };

  return (
    <ControllerTextField
      v2={v2}
      control={control}
      errors={errors}
      rules={rules}
      transform={{
        input: (value) => formatInput(value),
        output: (value) =>
          transformOutput === true ? convertToInt(value) : formatInput(value),
      }}
      id={`outlined-adornment-${name}`}
      name={name}
      label={label}
      defaultValue={defaultValue}
      fullWidth
      autoComplete={`outlined-adornment-${name}`}
      variant="outlined"
      sx={{ input: { textAlign: "right" } }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{inputAdornment}</InputAdornment>
        ),
      }}
      inputProps={{ maxLength: maxLength }}
      inputRef={inputRef}
      onClick={moveInputPositionToEnd}
      onKeyDown={(e) => {
        moveInputPositionToEnd();
        if (onKeyDown) {
          onKeyDown(e);
        }
      }}
      onKeyUp={moveInputPositionToEnd}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      viewOnly={viewOnly}
      highlightError={highlightError}
    />
  );
};

FormattedTextField.propTypes = {
  v2: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  rules: PropTypes.object,
  transformOutput: PropTypes.bool,
  viewOnly: PropTypes.bool,
  onKeyDown: PropTypes.func,
  highlightError: PropTypes.bool,
};

FormattedTextField.defaultProps = {
  maxLength: 12,
  transformOutput: true,
  viewOnly: false,
  v2: false,
  highlightError: false,
};

export default FormattedTextField;
