import React, { useState } from "react";
import PropTypes from "prop-types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Grid, IconButton, Link } from "@mui/material";
import { merchantRoutes } from "../../../../constants/routes";
import { useHistory } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import RemittanceAddressDrawer from "./RemittanceAddressDrawer";

const Header = ({
  countries,
  remittanceAddress,
  setRemitAddress,
  viewOnly,
  displayMessages,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();
  const methods = useForm();

  const calculateMinHeight = () => {
    let minHeight = 64;

    if (remittanceAddress.company?.length > 0) minHeight += 21;
    if (remittanceAddress.addressLine1?.length > 0) minHeight += 21;
    if (
      remittanceAddress.city?.length > 0 ||
      remittanceAddress.state?.length > 0 ||
      remittanceAddress.postalCode?.length > 0
    )
      minHeight += 21;

    return minHeight > 85 ? minHeight : 85;
  };

  return (
    <>
      <IconButton
        aria-label="Back to Invoice List"
        className="back-arrow"
        sx={{ fontSize: "1rem" }}
        onClick={() => history.push(merchantRoutes.invoices.search)}
      >
        <ChevronLeftIcon sx={{ fontSize: "1.75rem" }} />
        <span>Back to Invoice List</span>
      </IconButton>
      <Box className="gateway-info" minHeight={calculateMinHeight()}>
        <Grid container spacing={0.5}>
          <Grid item>
            <p>Pay to:</p>
          </Grid>
          <Grid item>
            <p>
              <strong>{remittanceAddress?.company}</strong>
            </p>
            {remittanceAddress && (
              <>
                <p>
                  {remittanceAddress?.addressLine1}
                  {remittanceAddress?.addressLine1 &&
                  remittanceAddress?.addressLine2
                    ? ", "
                    : null}
                  {remittanceAddress?.addressLine2}
                </p>
                <p>
                  {remittanceAddress?.city}
                  {remittanceAddress?.city && remittanceAddress.state
                    ? ", "
                    : null}
                  {remittanceAddress?.state} {remittanceAddress?.postalCode}
                </p>
              </>
            )}
            {!viewOnly && (
              <Link
                component="button"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "secondary"
                      : "primary.headerBlue",
                }}
                variant="body1"
                className="update-address-button"
                onClick={() => setOpenDrawer(true)}
                data-cy="header-update-address"
              >
                Update Address
              </Link>
            )}
          </Grid>
        </Grid>
      </Box>
      <FormProvider {...methods}>
        <form>
          <RemittanceAddressDrawer
            open={openDrawer}
            setOpenDrawer={setOpenDrawer}
            countries={countries}
            remittanceAddress={remittanceAddress}
            setRemitAddress={setRemitAddress}
            displayMessages={displayMessages}
          />
        </form>
      </FormProvider>
    </>
  );
};

Header.propTypes = {
  remittanceAddress: PropTypes.object,
  setRemitAddress: PropTypes.func,
  countries: PropTypes.array,
  viewOnly: PropTypes.bool,
  displayMessages: PropTypes.func,
};

export default Header;
