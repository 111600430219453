import mixpanel from "mixpanel-browser";
import { flattenedObject } from "../../../utils/flattenedObject";

/***** Events -> Virtual Terminal Events *****/

// called when the user submits a successful or failed transaction
export function transactionEvent({
  transactionDetails,
  selectedPaymentTab,
  secCode,
  customFields,
}) {
  let groupName = "";
  let customFieldsVals = [];
  if (transactionDetails.custom_fields) {
    Object.entries(transactionDetails.custom_fields).forEach((field, i) => {
      const fieldName = customFields.find((name) => {
        return name.id === field[0];
      });
      field[1].forEach((val) => {
        customFieldsVals.push(`"${fieldName?.name}: ${val}"`);
      });

      let name = fieldName?.group_name;
      if (i === 0) {
        groupName = name;
      } else {
        groupName = groupName !== name ? groupName + " " + name : groupName;
      }
    });
    customFieldsVals = customFieldsVals.join(", ");
  }
  let transaction = {
    type: transactionDetails?.type,
    amount: transactionDetails.amount,
    custom_field_group_name: groupName,
    custom_field_values: customFieldsVals,
    customer_vaulted: transactionDetails?.create_vault_record,
    email_receipt: transactionDetails?.email_receipt,
    transaction_type: selectedPaymentTab,
    processor_id: transactionDetails?.processor_id,
    payment_adjustment: transactionDetails?.payment_adjustment
      ? transactionDetails?.payment_adjustment?.type
      : "none",
    payment_adjustment_amount: transactionDetails?.payment_adjustment
      ? transactionDetails?.payment_adjustment?.value
      : "",
    description: transactionDetails?.description,
    order_id: transactionDetails?.order_id,
    po_number: transactionDetails?.po_number,
    billing: transactionDetails.billing_address,
    shipping: transactionDetails?.shipping_address,
  };
  if (transactionDetails?.payment_method?.customer) {
    let customer = transactionDetails?.payment_method?.customer;
    Reflect.set(transaction, "customer_id", customer?.id);
    Reflect.set(transaction, "payment_method_id", customer?.payment_method_id);
    Reflect.set(
      transaction,
      "billing_address_id",
      customer?.billing_address_id,
    );
    Reflect.set(
      transaction,
      "shipping_address_id",
      customer?.shipping_address_id,
    );
  }
  if (selectedPaymentTab.toLowerCase() === "ach") {
    Reflect.set(transaction, "sec_code", secCode);
  }

  const flattenedDetails = flattenedObject(transaction);
  mixpanel.track(`Virtual Terminal - Submitted`, flattenedDetails);
  mixpanel.people.increment("Number of Transactions Submitted", 1);
}

export function failedTransactionEvent(
  transactionDetails,
  transactionStatus,
  selectedPaymentTab,
) {
  // add selected payment tab object
  Object.assign(transactionDetails, { payment_type: selectedPaymentTab });
  const flattenedDetails = flattenedObject(transactionDetails);
  mixpanel.track(`${transactionStatus} Transaction`, flattenedDetails);
}

// called when the user attempts to submit transaction but does not fufill validation requirements
export function tokenizerValidationErrorEvent(error) {
  // track how many times user gets the error
  mixpanel.people.increment("Tokenizer Validation Error Displayed", 1);
  mixpanel.track("User Encountered Tokenizer Validation Errors", {
    invalid: error.invalid,
    status: error.status,
  });
}
