import mixpanel from "mixpanel-browser";
import { flattenedObject } from "../../../utils/flattenedObject";
// Called when user clicks CSV or PDF tab on download button
export const downloadSettlementsEvent = (fileType) => {
  mixpanel.track("Reporting - Settlements - Download", {
    download_type: fileType,
  });
};
// called when user clicks on the summary or results tab
export const clickedSettlementsTab = (type) => {
  mixpanel.track(`Reporting - Settlements - ${type}`);
};

export const settlementsSearched = (mpPayload, type) => {
  Reflect.set(mpPayload, "selected_tab", type);
  const flattenedPayload = flattenedObject(mpPayload);
  mixpanel.track("Reporting - Settlements - Searched", flattenedPayload);
};
