import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "../../../utils/dineroFormatters";

const PaymentLabel = ({ variant, label, value, datacy }) => {
  return (
    <>
      <Grid item xs={6}>
        <Typography
          sx={{
            "&.MuiTypography-h6": {
              display: "flex",
              justifyContent: "flex-end",
            },
          }}
          variant={variant}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          variant={variant}
          align="right"
          noWrap={true}
          data-cy={datacy}
        >
          ${formatCurrency(parseFloat(value))}
        </Typography>
      </Grid>
    </>
  );
};

PaymentLabel.propTypes = {
  variant: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  datacy: PropTypes.string,
};

export default PaymentLabel;
