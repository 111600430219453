import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import RegisteredTextField from "../../../../ReactHookForm/RegisteredTextField";
import { validationRules } from "../../../../../constants/validationRules";
import { SUBSCRIPTION_STATUSES } from "../../../../../constants/global";

const SetUp = ({ subscription, mode }) => {
  return (
    <Grid container marginTop={2}>
      <Grid item marginY={2}>
        <Typography variant="h3">
          <strong>Set Up Subscription</strong>
        </Typography>
      </Grid>
      <Grid
        item
        container
        className="subscription-details"
        rowSpacing={2}
        columnSpacing={1}
      >
        <Grid item xs={12} sm={6}>
          <RegisteredTextField
            v2
            name="name"
            label="Subscription Name"
            fullWidth
            defaultValue={subscription?.name}
            rules={{
              maxLength: validationRules.maxLength100,
              required: "Subscription Name is required",
            }}
            disabled={
              mode === "edit" &&
              subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
            }
          />
          <Typography variant="body1">
            This will be included in invoice titles.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <RegisteredTextField
            v2
            name="number"
            label="Subscription Number"
            fullWidth
            defaultValue={subscription?.number}
            rules={{
              maxLength: validationRules.maxLength50,
            }}
            disabled={
              mode === "edit" &&
              subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
            }
          />
        </Grid>
        <Grid item xs={12}>
          <RegisteredTextField
            v2
            name="note"
            label="Note"
            className="invoice-message"
            fullWidth
            defaultValue={subscription?.note}
            multiline
            rows={3}
            rules={{
              maxLength: validationRules.maxLength500,
            }}
          />
          <Typography variant="body1">
            This is an internal note for your records.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

SetUp.propTypes = {
  subscription: PropTypes.object,
  mode: PropTypes.oneOf(["view", "edit", "create"]),
};

export default SetUp;
