import React from "react";
import PropTypes from "prop-types";
import Select from "../../../ReactHookForm/Select";
import { MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

const SelectAlternateAddress = ({
  type,
  addresses,
  onChange,
  defaultValue,
}) => {
  const { control } = useFormContext();

  return (
    <Select
      control={control}
      name={`${type}AlternateAddress`}
      label={`Choose alternative ${type} address`}
      onChange={(value) => onChange(type, value)}
      defaultValue={defaultValue}
      fullWidth
    >
      {addresses.map((address) => (
        <MenuItem
          key={address.customerAddressId}
          value={address.customerAddressId}
        >
          {address.addressLine1}
          {address.addressLine1 &&
            (address.city || address.state || address.postalCode) &&
            ", "}
          {address.city}
          {address.city && (address.state || address.postalCode) && ", "}
          {address.state}
          {address.state && address.postalCode && " "}
          {address.postalCode}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectAlternateAddress.propTypes = {
  type: PropTypes.string,
  addresses: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default SelectAlternateAddress;
