import axios from "axios";
import { sassEndpoints } from "../../../../../constants/endpoints";
import { stringFormat } from "../../../../../utils/stringHelpers";
import { useContext, useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from "@mui/material";
import emptyState from "../../../../../assets/images/empty-state.png";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDateTime } from "../../../../../utils/stringHelpers";
import PropTypes from "prop-types";
import { GatewayUserDetailContext } from "../../../../../contexts/GatewayUserDetailContext";

const GatewayUserSigninLogs = ({
  warningMessage,
  callSignInLogs,
  setCallSignInLogs,
}) => {
  const { userData } = useContext(GatewayUserDetailContext);
  const [signinData, setSigninData] = useState(null);

  useEffect(() => {
    if (callSignInLogs) {
      let url = `${stringFormat(sassEndpoints.users.signinHistory, [
        userData?.user?.userId,
      ])}?start=${
        new Date().toISOString().split("T")[0]
      }T00:00:00.000Z&end=${new Date().toISOString()}`;
      axios
        .get(url)
        .then((response) => {
          if (response.status === 204) {
            setSigninData([]);
          } else {
            setSigninData(response?.data?.data);
          }
        })
        .catch(() => {
          warningMessage("Failed to retrieve sign in logs");
          setSigninData([]);
        });
      setCallSignInLogs(false);
    }
  }, [callSignInLogs]);
  return (
    <div>
      {signinData === null && <CircularProgress sx={{ marginLeft: "50%" }} />}
      {signinData !== null && signinData.length === 0 && (
        <div style={{ textAlign: "center" }}>
          <img src={emptyState}></img>
          <p>There are no sign in logs for this user.</p>
        </div>
      )}
      {signinData !== null && signinData.length > 0 && (
        <TableContainer className="table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Date/Time</TableCell>
                <TableCell>Tags</TableCell>
                <TableCell>IP Address</TableCell>
                <TableCell>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {signinData.map((row) => {
                let timeString = formatDateTime(row.loginDateTime);
                let formattedTime =
                  timeString
                    .split(" ")[0]
                    .slice(0, timeString.split(" ")[0].length - 5) +
                  " " +
                  timeString
                    .split(" ")[1]
                    .slice(0, timeString.split(" ")[1].length - 3) +
                  timeString.split(" ")[2];
                return (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {`...${row.correlationId.slice(
                        row.correlationId.length - 6,
                        row.correlationId.length,
                      )}`}
                    </TableCell>

                    <TableCell align="right">{formattedTime}</TableCell>
                    <TableCell align="right">
                      {
                        <div
                          className={
                            row.success ? "tag tag__success" : "tag tag__failed"
                          }
                        >
                          {row.success ? "SUCCESS" : "FAILED"}
                        </div>
                      }
                    </TableCell>
                    <TableCell>{row.ipAddress}</TableCell>
                    <TableCell align="right">{row.reason}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
GatewayUserSigninLogs.propTypes = {
  warningMessage: PropTypes.func,
  callSignInLogs: PropTypes.bool,
  setCallSignInLogs: PropTypes.func,
};
export default GatewayUserSigninLogs;
