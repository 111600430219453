import colors from "../../assets/colors.module.scss";

const styles = (theme) => ({
  categoryHeader: {
    margin: ".5em",
    paddingBottom: theme.spacing(0.7),
    paddingTop: theme.spacing(0.7),
  },
  selectedCategoryHeader: {
    paddingTop: theme.spacing(0.7),
    paddingBottom: theme.spacing(0.7),
    borderRadius: "5px",
  },
  categoryHeaderPrimary: {
    fontSize: "14px",
  },
  item: {
    fontSize: "14px",
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: "3em",
    width: "79%",
    transition: "border-left .07s",
    "&:hover": {
      color: theme.palette.secondary.headerBlue,
    },
  },

  itemCategory: {
    color: "theme.palette.neutrals.grey800",
    height: "48px",
    backgroundColor: theme.palette.secondary.headerBlue,
    boxShadow: "none",
  },

  firebase: {
    fontSize: colors.font_size_24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    transition: "border-left ease-out",
    borderLeft: "2px solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.neutrals.item,
    paddingLeft: "10px",
  },
  itemPrimary: {
    fontSize: "inherit",
  },
  selectedItemIcon: {
    color: theme.palette.primary.main,
  },
  divider: {
    marginTop: theme.spacing(1),
  },
});

export default styles;
