export const IncrementalAuthIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 158 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.5616 109.9V101.204C63.915 101.025 58.2667 99.4099 55.0375 97.2593L57.4582 88.8308C60.9542 90.8936 65.9761 92.7758 71.4438 92.7758C76.9115 92.7758 80.946 89.9972 80.946 85.6943C80.946 81.6598 77.8095 79.0602 71.1754 76.6395C61.7611 73.233 55.6654 68.9301 55.6654 60.5927C55.6654 52.8833 61.0453 46.9649 70.1001 45.3511V36.5664H77.4516V44.9021C83.0999 45.0811 86.9538 46.4265 89.8235 47.8613L87.4028 56.1092C85.2505 55.1234 81.3055 53.0606 75.2116 53.0606C69.1176 53.0606 66.6936 56.2882 66.6936 59.3368C66.6936 63.0117 69.9212 65.0746 77.5411 68.1232C87.5834 71.8893 92.0653 76.729 92.0653 84.7085C92.0653 92.688 86.7765 99.052 76.9165 100.666V109.9H69.5633H69.5616Z"
        fill="#0885C9"
      />
      <path
        d="M123.929 64.4012V46.9394H112.223L134.861 19.2812L157.499 46.9394H145.793V64.4012H123.929Z"
        fill="#0885C9"
      />
      <path
        d="M0.498535 72.4012C0.498535 32.5805 33.0791 0 72.8997 0C93.1099 0 111.455 8.39231 124.623 21.8641L114.422 32.0645C103.89 21.2254 89.165 14.4802 72.8997 14.4802C40.9708 14.4802 14.9788 40.4723 14.9788 72.4012C14.9788 104.33 40.9708 130.322 72.8997 130.322C104.829 130.322 130.821 104.33 130.821 72.4012H145.301C145.301 112.222 112.72 144.802 72.8997 144.802C33.0791 144.802 0.498535 112.222 0.498535 72.4012Z"
        fill="#0885C9"
      />
    </svg>
  );
};

export const CreditCardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 256 256"
    >
      <path d="M224,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48Zm0,16V88H32V64Zm0,128H32V104H224v88Zm-16-24a8,8,0,0,1-8,8H168a8,8,0,0,1,0-16h32A8,8,0,0,1,208,168Zm-64,0a8,8,0,0,1-8,8H120a8,8,0,0,1,0-16h16A8,8,0,0,1,144,168Z"></path>
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 256 256"
    >
      <path d="M201.54,54.46A104,104,0,0,0,54.46,201.54,104,104,0,0,0,201.54,54.46ZM88,192a16,16,0,0,1,32,0v23.59a88,88,0,0,1-32-9.22Zm48,0a16,16,0,0,1,32,0v14.37a88,88,0,0,1-32,9.22Zm-28.73-56h41.46l11.58,25.1A31.93,31.93,0,0,0,128,170.87a31.93,31.93,0,0,0-32.31-9.77Zm7.39-16L128,91.09,141.34,120Zm75.56,70.23c-2,2-4.08,3.87-6.22,5.64V176a7.91,7.91,0,0,0-.74-3.35l-48-104a8,8,0,0,0-14.52,0l-48,104A7.91,7.91,0,0,0,72,176v19.87c-2.14-1.77-4.22-3.64-6.22-5.64a88,88,0,1,1,124.44,0Z"></path>
    </svg>
  );
};
