import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Link, Grid, Typography } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import LineItem from "./LineItem";
import { sassEndpoints } from "../../../../constants/endpoints";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import axios from "axios";
import { useAlertDialog } from "../../../../hooks";
import Dinero from "dinero.js";
import AlertDialog from "../../../Dialog/AlertDialog";
import { merchantRoutes } from "../../../../constants/routes";
import { CONTACT_SUPPORT } from "../../../../constants/global";

const LineItemTotals = ({
  v2,
  object,
  defaultLineItem,
  defaultFees,
  unitsOfMeasure,
  onFeesChange,
  mode,
  onDelete,
  formatValues,
  totalSuffix,
  isCloning,
}) => {
  const {
    userSettings: { gatewayId },
  } = useContext(UserSettingsContext);
  const history = useHistory();
  const { isAlertOpen, setIsAlertOpen, alertMessages } = useAlertDialog();
  const [fees, setFees] = useState(defaultFees);
  const [alertDialogProps, setAlertDialogProps] = useState({
    alertTitle: "Error",
    alertLevel: "error",
    alertMessages,
    onCloseButtonClick: () => setIsAlertOpen(false),
  });
  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "lineItems",
  });

  useEffect(() => {
    if (object?.remainingBalance !== undefined) {
      methods.trigger();

      calculateFees();
    }
  }, []);

  useEffect(() => {
    setFees(defaultFees);
  }, [defaultFees]);

  const handleDelete = (index) => {
    if (mode === "edit" && onDelete)
      onDelete(formatValues(methods.getValues()).lineItems[index], index);

    remove(index);

    calculateFees();
  };

  const handleClear = () => {
    if (mode === "edit" && onDelete)
      methods
        .getValues("lineItems")
        .forEach((li, index) => onDelete(li, index));
    methods.resetField("lineItems", { defaultValue: [defaultLineItem] });

    calculateFees();
  };

  const calculateFees = () => {
    const { lineItems } = methods.getValues();
    const payload = {
      lineItems: lineItems.map((lineItem) => ({
        quantity: lineItem.quantity,
        unitPrice: Dinero({ amount: lineItem.unitPrice }).toUnit(),
        discount: Dinero({ amount: lineItem.discount }).toUnit(),
        freightAmount: lineItem.advancedFieldsEnabled
          ? Dinero({
              amount: lineItem.freightAmount || 0,
            }).toUnit()
          : null,
        isTaxable: lineItem.isTaxable,
        localTaxPercent: lineItem.isTaxable
          ? Dinero({
              amount: lineItem.localTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
        nationalTaxPercent: lineItem.isTaxable
          ? Dinero({
              amount: lineItem.nationalTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
      })),
      shippingState:
        object?.invoiceAddresses?.find((a) => a.isShipping)?.state ||
        object?.addresses?.find((a) => a.isShipping)?.state,
    };
    const url = sassEndpoints.invoices.calculateFees.replace(
      "{gatewayId}",
      gatewayId,
    );

    axios
      .post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          let fees = response.data.data;

          if (mode !== "view") fees.remainingBalance = fees.total;
          else fees.remainingBalance = object.remainingBalance;

          // Default to pretax as that is more common
          if (fees.shipping?.amount > 0 && !fees.shipping?.taxPlacement)
            fees.shipping.taxPlacement = "PRE";

          setFees(fees);
          if (onFeesChange) onFeesChange(fees);
        }
      })
      .catch((e) => {
        setAlertDialogProps({
          ...alertDialogProps,
          alertMessages: e?.response?.data?.statusDetails || [
            "Failed to calculate totals",
            CONTACT_SUPPORT,
          ],
        });
        setIsAlertOpen(true);
      });
  };

  return (
    <React.Fragment>
      {fields.map((_field, index) => (
        <LineItem
          v2={v2}
          key={index}
          index={index}
          viewOnly={mode === "view"}
          unitsOfMeasure={unitsOfMeasure}
          showDelete={fields.length > 1}
          onDelete={handleDelete}
          onBlur={calculateFees}
        />
      ))}
      <Grid item container className="invoice-actions-container">
        <Grid
          item
          container
          xs={12}
          sm={v2 ? 12 : 6}
          spacing={1}
          marginBottom={2}
        >
          {mode !== "view" && (
            <>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  data-cy="add-line-item"
                  onClick={() => append(defaultLineItem)}
                  className={v2 && "btn--primary"}
                >
                  Add Line Item
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClear}
                  className={v2 && "btn--secondary"}
                >
                  Clear All Line Items
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          item
          container
          columnSpacing={3}
          justifyContent={v2 && "space-between"}
          className="invoice-calculation-container"
        >
          <Grid item className="invoice-totals-labels">
            <p>Subtotal:</p>
            <p>Discounts applied:</p>
            {fees.shipping?.amount > 0 &&
              fees.shipping?.taxPlacement === "PRE" && <p>Shipping: </p>}
            <p>Taxes:</p>
            {fees.shipping?.amount > 0 &&
              fees.shipping?.taxPlacement === "POST" && <p>Shipping: </p>}
            {(object?.fees || 0) > 0 && <p>Fees:</p>}
            {!isCloning &&
              object?.total &&
              object?.total !== object?.remainingBalance && <p>Amount Paid:</p>}
          </Grid>
          <Grid item className="invoice-totals-amounts">
            <p>${fees.subtotal?.toFixed(2)}</p>
            <p>-${fees.discounts?.toFixed(2)}</p>
            {fees.shipping?.amount > 0 &&
              fees.shipping?.taxPlacement === "PRE" && (
                <p>${fees.shipping?.amount.toFixed(2)}</p>
              )}
            <p>${fees.tax?.toFixed(2)}</p>
            {fees.shipping?.amount > 0 &&
              fees.shipping?.taxPlacement === "POST" && (
                <p>${fees.shipping?.amount.toFixed(2)}</p>
              )}
            {(object?.fees || 0) > 0 && <p>${object?.fees?.toFixed(2)}</p>}
            {!isCloning &&
              object?.total &&
              object?.total !== object?.remainingBalance && (
                <p>
                  -$
                  {object?.amountCaptured?.toFixed(2)}
                </p>
              )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        className="invoice-total-container"
        justifyContent="space-between"
      >
        <Grid
          item
          container
          spacing={2}
          xs={12}
          sm={!v2 ? "auto" : 12}
          justifyContent={!v2 ? "flex-end" : "space-between"}
        >
          <Grid item>
            {!isCloning &&
              object?.total &&
              object?.total !== object?.remainingBalance && (
                <p className="original-balance">Original Balance Due:</p>
              )}
            <Typography
              color={mode !== "view" ? "secondary" : ""}
              className="invoice-total"
            >
              {!isCloning &&
              object?.total &&
              object?.total !== object?.remainingBalance
                ? "Remaining Balance Due:"
                : "Balance Due:"}
            </Typography>
          </Grid>
          <Grid item>
            {!isCloning &&
              object?.total &&
              object?.total !== object?.remainingBalance && (
                <p className="original-balance">${fees.total?.toFixed(2)}</p>
              )}

            <Typography
              color={mode !== "view" ? "secondary" : ""}
              className="invoice-total"
            >
              {!isCloning
                ? `$${
                    fees.remainingBalance?.toFixed(2) || fees.total?.toFixed(2)
                  }`
                : `$${fees.total?.toFixed(2)}`}
              {totalSuffix.length > 0 && totalSuffix}
            </Typography>
          </Grid>
        </Grid>
        {!isCloning && object?.transactions?.length > 0 && (
          <Grid
            item
            container
            xs={12}
            sm="auto"
            alignSelf="flex-end"
            className="transaction-history-link"
          >
            <Link
              color="primary"
              onClick={() =>
                history.push(merchantRoutes.reporting.transactions, {
                  invoiceId: object.invoiceId,
                })
              }
            >
              View Transaction History
            </Link>
          </Grid>
        )}
      </Grid>
      <AlertDialog {...alertDialogProps} alertOpen={isAlertOpen} />
    </React.Fragment>
  );
};

LineItemTotals.propTypes = {
  v2: PropTypes.bool,
  object: PropTypes.object,
  defaultLineItem: PropTypes.object,
  defaultFees: PropTypes.object,
  totalSuffix: PropTypes.string,
  onDelete: PropTypes.func,
  onFeesChange: PropTypes.func,
  formatValues: PropTypes.func,
  unitsOfMeasure: PropTypes.arrayOf(PropTypes.object),
  mode: PropTypes.oneOf(["create", "edit", "view"]),
  isCloning: PropTypes.bool,
};

LineItemTotals.defaultProps = {
  v2: false,
  mode: "create",
  totalSuffix: "",
  formatValues: (values) => values,
};

export default LineItemTotals;
