import { useState, useEffect, useContext, useImperativeHandle } from "react";
import { sassEndpoints } from "../../../constants/endpoints";
import {
  Button,
  Grid,
  Typography,
  MenuItem,
  Box,
  IconButton,
  useTheme,
  capitalize,
  Card,
  CardContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import PhoneNumber from "../../Contact/PhoneNumber";
import Select from "../../ReactHookForm/Select";
import { validationRules } from "../../../constants/validationRules";
import { timezones } from "../../../constants/general";
import { GATEWAY_STATUS, USER_ROLES } from "../../../constants/global";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { GatewaySettingsContext } from "./gatewaySettingsContext";
import "./gateway.scss";
import axios from "axios";
import MigrationScheduler from "./MigrationScheduler";
import ContactContainer from "../../Contact/ContactContainer";
import primary from "../../../assets/images/primary.png";
import invoice from "../../../assets/images/invoice.png";
import billing from "../../../assets/images/billing.png";
import {
  deepCopyFunction,
  userHasPermission,
} from "../../../utils/objectHelpers";
import { CountriesContext } from "../../../contexts/CountriesContext";
import { SYSTEM_PERMISSION_CODES } from "../../../constants/global";

function GatewayDetails({
  editGateway,
  gatewayInfo,
  clickEvent,
  changeStatus,
  handleClick,
  isButtonViewable,
  openSnackbar,
  reQueueMigration,
  migrationError,
  handleMigrationClose,
  insertAddress,
  setEditAddress,
  callAddressSuccess,
  setCallAddressSuccess,
  showError,
  gatewayInfoEdit,
  setGatewayInfoEdit,
}) {
  const theme = useTheme();
  const { ...methods } = useForm();
  const { userSettings } = useContext(UserSettingsContext);
  const [contacts, setContacts] = useState({});
  const [edit, setEdit] = useState(null);
  const [insert, setInsert] = useState(null);
  const [submitEdit, setSubmitEdit] = useState(null);
  const [callInsertAddress, setCallInsertAddress] = useState(false);
  const [callEditAddress, setCallEditAddress] = useState(false);
  const { gatewayProps, setGatewayProps } = useContext(GatewaySettingsContext);
  const countries = useContext(CountriesContext);

  const migrationStatuses = [
    "migrating",
    "migrationFailed",
    "InMigrationQueue",
  ];
  const addressRequiredList = [
    "first_name",
    "last_name",
    "company",
    "address_line_1",
    "country",
    "state",
    "city",
    "postal_code",
    "email",
    "phone",
  ];

  const addressObjectToField = {
    addressLine1: "address_line_1",
    addressLine2: "address_line_2",
    city: "city",
    company: "company",
    country: "country",
    email: "email",
    fax: "fax",
    firstName: "first_name",
    lastName: "last_name",
    phone: "phone",
    postalCode: "postal_code",
    state: "state",
  };

  const addressTypes = ["primary", "invoice", "billing"];

  useImperativeHandle(clickEvent, () => ({
    onSubmit: async () => {
      let triggerList = [];
      //inserting new address
      if (insert) {
        addressRequiredList.forEach((field) => {
          triggerList.push(`${insert}.${field}`);
        });
        const noErrors = await methods.trigger(triggerList);
        if (noErrors) {
          setCallInsertAddress(true);
        } else {
          setInsert(null);
          showError("Please fix all required fields.");
        }
        //editing existing address
      } else if (submitEdit) {
        addressRequiredList.forEach((field) => {
          triggerList.push(`${submitEdit}_contact.${field}`);
        });
        const noErrors = await methods.trigger(triggerList);
        if (noErrors) {
          setCallEditAddress(true);
        } else {
          setSubmitEdit(null);
          showError("Please fix all required fields.");
        }
      } else {
        //editing gateway info (not address)
        triggerList = [
          "ContactPhoneExt",
          "ContactPhoneNumber",
          "Name",
          "ReceiptEmail",
          "Timezone",
          "Website",
          "basys_mid",
          "CategoryCode",
        ];
        const noErrors = await methods.trigger(triggerList);
        if (noErrors) {
          const payload = assemblePayload();
          editGateway(
            payload,
            gatewayInfo.gatewayId,
            Object.keys(methods.formState.errors).length === 0,
          );
        } else {
          showError("Please fix all required fields.");
        }
      }
    },
  }));

  useEffect(() => {
    if (insert || submitEdit) {
      handleClick();
    }
  }, [insert, submitEdit]);

  useEffect(() => {
    if (callInsertAddress) {
      if (!Object.keys(methods.formState.errors)?.includes(insert)) {
        insertAddress(insert, formAddressModel(methods.getValues(insert)));
        setInsert(null);
        setEdit(null);
        setCallInsertAddress(false);
      }
    }
  }, [callInsertAddress]);

  useEffect(() => {
    if (callEditAddress) {
      if (!Object.keys(methods.formState.errors)?.includes(`${edit}_contact`)) {
        let payloadStructure = [
          {
            op: "replace",
            path: "/",
            value: {
              Addresses: [
                assembleEditAddress(
                  "is" +
                    submitEdit.charAt(0).toUpperCase() +
                    submitEdit.slice(1),
                  formAddressModel(methods.getValues(`${submitEdit}_contact`)),
                ),
              ],
            },
          },
        ];
        setEditAddress(payloadStructure);
      }
    }
  }, [callEditAddress]);

  const [isOpen, setIsOpen] = useState(false);

  function setAddressContacts(addressObject) {
    let newContacts = deepCopyFunction(contacts);

    Object.keys(addressObject).forEach((key) => {
      if (addressObject[key] && Object.keys(addressObject[key]).length > 0) {
        newContacts[key] = {
          first_name: addressObject[key]?.firstName || "",
          last_name: addressObject[key]?.lastName || "",
          company: addressObject[key]?.company || "",
          address_line_1: addressObject[key]?.addressLine1 || "",
          address_line_2: addressObject[key]?.addressLine2 || "",
          country: addressObject[key]?.country || "",
          state: addressObject[key]?.state || "",
          city: addressObject[key]?.city || "",
          postal_code: addressObject[key]?.postalCode || "",
          email: addressObject[key]?.email || "",
          phone: addressObject[key]?.phone || "",
          fax: addressObject[key]?.fax || "",
        };
      }
    });
    setContacts(newContacts);
  }

  const editSaveClicked = (type) => {
    if (edit === type) {
      if (contacts[type]) {
        setSubmitEdit(type);
      } else {
        setInsert(`${type}_contact`);
      }
    } else {
      resetFields("edit");
      setEdit(type);
    }
  };

  const resetFields = (type) => {
    Object.keys(addressObjectToField).forEach((field) => {
      methods.setValue(
        `${type}_contact.${addressObjectToField[field]}`,
        contacts[type] ? contacts[type][field] : "",
      );
    });
  };

  useEffect(() => {
    if (gatewayInfo || !callAddressSuccess) {
      if (isOpen) {
        handleClose();
      }

      let primaryAddress = gatewayInfo?.addresses?.find(
        (address) => address.isPrimary,
      )?.addressModel;

      let invoiceAddress = gatewayInfo?.addresses?.find(
        (address) => address.isInvoice,
      )?.addressModel;

      let billingAddress = gatewayInfo?.addresses?.find(
        (address) => address.isBilling,
      )?.addressModel;

      setAddressContacts({
        primary: primaryAddress,
        invoice: invoiceAddress,
        billing: billingAddress,
      });
      getMigrationNotes();
    }
    setSubmitEdit(null);
    setCallEditAddress(false);
    setEdit(null);
    setCallAddressSuccess(null);
    setEditAddress(false);
  }, [gatewayInfo, callAddressSuccess]);

  const getMigrationNotes = () => {
    const url = `${sassEndpoints.migrationStatus}?gatewayId=${gatewayInfo.gatewayId}`;
    axios
      .get(url)
      .then((res) => {
        if (
          res.data.length > 0 &&
          migrationStatuses.includes(gatewayInfo.status)
        ) {
          const note = res.data.find((note) => {
            return note.status === gatewayInfo.status;
          });
          openSnackbar(note.notes, note.status);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const assemblePayload = () => {
    const { dirtyFields } = methods.formState;
    let payload = [
      {
        op: "replace",
        path: "/",
        value: {},
      },
    ];
    Object.keys(dirtyFields).forEach((key) => {
      let payloadObject = {};
      payloadObject[key] = methods.getValues()[key];
      Object.assign(payload[0].value, payloadObject);
    });
    return payload;
  };

  const formAddressModel = (formOutput) => {
    let addressModel = {};
    Object.keys(addressObjectToField).forEach(
      (field) =>
        (addressModel[field] = formOutput[addressObjectToField[field]]),
    );

    return addressModel;
  };

  const assembleEditAddress = (type, addressModel) => {
    let addressPayload = {
      GatewayAddressId: gatewayInfo?.addresses?.find((address) => address[type])
        ?.gatewayAddressId,
      IsPrimary: type.toLowerCase() === "isprimary",
      IsBilling: type.toLowerCase() === "isbilling",
      IsInvoice: type.toLowerCase() === "isinvoice",
      Address: addressModel,
    };
    return addressPayload;
  };

  const handleClose = () => {
    handleMigrationClose;
    setIsOpen(false);
  };

  const handleCancel = () => {
    //Reset the form if currently in edit mode
    setGatewayInfoEdit(!gatewayInfoEdit);

    methods.reset();

    setGatewayProps({
      ...gatewayProps,
    });
  };

  const handleMigration = (utcDate, recipientEmail) => {
    reQueueMigration(utcDate, recipientEmail);
  };
  return (
    <FormProvider {...methods}>
      <div>
        <Grid className="header-text" container spacing={2} sx={{ pr: 1 }}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", alignItems: "baseline", flexWrap: "wrap" }}
            >
              <Grid sx={{ marginRight: "40px" }}>
                <h2 style={{ display: "flex", alignItems: "center" }}>
                  Status -&nbsp;{" "}
                  <Typography
                    textTransform={"capitalize"}
                    className={`${gatewayInfo?.status} gateway-status`}
                    fontWeight="bold"
                    sx={{ fontSize: "20px" }}
                  >
                    {gatewayInfo?.status === "pending_tos"
                      ? "Pending TOS"
                      : gatewayInfo?.status}
                  </Typography>
                </h2>
                {userHasPermission(
                  SYSTEM_PERMISSION_CODES.editGatewayStatus,
                  userSettings,
                ) && (
                  <div>
                    {(gatewayInfo?.status === "Active" ||
                      migrationStatuses.includes(gatewayInfo?.status)) && (
                      <div>
                        <Button
                          variant="contained"
                          onClick={() => {
                            changeStatus(GATEWAY_STATUS.DISABLED);
                          }}
                          color="error"
                          data-cy="tos-status-disable"
                          sx={{ marginTop: "10px" }}
                        >
                          DISABLE
                        </Button>{" "}
                      </div>
                    )}
                    {gatewayInfo?.status === "Disabled" && (
                      <div>
                        <Button
                          variant="contained"
                          onClick={() => changeStatus(GATEWAY_STATUS.ACTIVE)}
                          color="info"
                          data-cy="tos-status-enable"
                          sx={{ marginTop: "10px" }}
                        >
                          ENABLE
                        </Button>{" "}
                      </div>
                    )}
                  </div>
                )}
              </Grid>

              {gatewayInfo.status === "migrationFailed" && (
                <div className="retry-migration-block">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsOpen(true)}
                  >
                    Retry Migration
                  </Button>
                  {userSettings?.userRole === USER_ROLES.SYSTEM_ADMIN && (
                    <p>Gateway migrations are scheduled to run at 9pm CST</p>
                  )}
                </div>
              )}
            </Box>
          </Grid>

          <Grid item xs={10}>
            <Grid container alignItems="center" spacing={5}>
              <Grid item>
                <Typography fontWeight="bold">
                  Tokenizer Type:{" "}
                  {
                    gatewayInfo?.preference?.find(
                      (obj) => obj.metadataName === "ACH_TOKENIZER",
                    ).metadataValue
                  }
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bold">
                  {`Gateway Provider: ${
                    gatewayInfo?.preference?.find(
                      (p) => p?.metadataName?.toUpperCase() === "SOURCE_SYSTEM",
                    )?.metadataValue
                  }`}
                  {(userHasPermission(
                    SYSTEM_PERMISSION_CODES.edit,
                    userSettings,
                  ) ||
                    userSettings?.userRole === USER_ROLES.SUPER_USER) && (
                    <IconButton
                      title={"Edit"}
                      variant="container"
                      sx={{ padding: 0.5 }}
                      onClick={handleCancel}
                      color={"warning"}
                      fontSize="small"
                      data-cy="sys-gateway-edit-button"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Typography fontWeight="bold">
              Gateway ID: {gatewayInfo.gatewayId}
            </Typography>
          </Grid>
          <div className="gateway-info">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <RegisteredTextField
                  id="gateway_name"
                  name="Name"
                  label="Gateway Name"
                  fullWidth
                  defaultValue={gatewayInfo?.name}
                  rules={{
                    maxLength: validationRules.maxLength50,
                    required: true,
                  }}
                  viewOnly={!gatewayInfoEdit}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <RegisteredTextField
                  id="basys_mid"
                  name="merchantId"
                  label="Basys MID"
                  fullWidth
                  defaultValue={gatewayInfo?.merchantId}
                  rules={{
                    pattern: validationRules.alphanumericSpace,
                    maxLength: validationRules.maxLength25,
                    required: true,
                  }}
                  viewOnly={!gatewayInfoEdit}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <RegisteredTextField
                  id="categoryCode"
                  name="categoryCode"
                  label="MCC"
                  fullWidth
                  defaultValue={gatewayInfo?.categoryCode}
                  rules={{
                    pattern: validationRules.alphanumericSpace,
                    maxLength: validationRules.maxLength5,
                    required: false,
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                  viewOnly={!gatewayInfoEdit}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <PhoneNumber
                  name="ContactPhoneNumber"
                  id="phone"
                  label="Phone"
                  defaultValue={gatewayInfo?.phone}
                  required={true}
                  viewOnly={!gatewayInfoEdit}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  control={methods.control}
                  name="Timezone"
                  label="Timezone"
                  fullWidth
                  errors={methods.errors}
                  rules={{ required: true }}
                  defaultValue={gatewayInfo?.timezone || ""}
                  viewOnly={!gatewayInfoEdit}
                >
                  {timezones.map((zone) => (
                    <MenuItem key={zone.value} value={zone.value}>
                      {zone.display}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={2}>
                <RegisteredTextField
                  id="phone_ext"
                  name="ContactPhoneExt"
                  label="Phone Ext"
                  fullWidth
                  defaultValue={gatewayInfo?.phoneExternal}
                  rules={{
                    maxLength: validationRules.maxLength100,
                  }}
                  viewOnly={!gatewayInfoEdit}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <RegisteredTextField
                  id="email"
                  name="ReceiptEmail"
                  defaultValue={gatewayInfo?.receiptEmail}
                  label="Receipt Email"
                  fullWidth
                  autoComplete="email"
                  rules={{
                    pattern: validationRules.email,
                    maxLength: validationRules.maxLength100,
                    required: true,
                  }}
                  viewOnly={!gatewayInfoEdit}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <RegisteredTextField
                  id="website"
                  name="Website"
                  fullWidth
                  defaultValue={gatewayInfo?.website}
                  label="Website"
                  viewOnly={!gatewayInfoEdit}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        {isButtonViewable() && (
          <Grid
            container
            className="gateway-info-submit"
            sx={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Button
              size="small"
              data-cy="gateway-view-submit"
              variant="contained"
              color="primary"
              onClick={() => handleClick()}
            >
              Submit
            </Button>
          </Grid>
        )}
        <Grid
          item
          style={{
            borderTopStyle: "solid",
            borderBlockColor: theme.palette.neutrals.item,
          }}
        >
          <>
            <p className="address-header">Address: </p>
            <Grid
              container
              columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 12 }}
              className="gateway-address-container"
            >
              {" "}
              {addressTypes.map((type, i) => {
                return (
                  <Grid key={i} item xs={12} md={4} xl={3}>
                    <Card>
                      <CardContent className="card-container">
                        <p className="address-header">{capitalize(type)}</p>
                        {contacts[type] || edit === type ? (
                          <ContactContainer
                            type={`${type}_contact`}
                            countries={countries}
                            country="US"
                            required={[
                              "firstName",
                              "lastName",
                              "company",
                              "address1",
                              "country",
                              "state",
                              "city",
                              "postalCode",
                              "email",
                              "phone",
                            ]}
                            defaultValues={contacts[type]}
                            viewOnly={edit !== type}
                          />
                        ) : (
                          <div>
                            <img
                              src={
                                type === "primary"
                                  ? primary
                                  : type === "invoice"
                                    ? invoice
                                    : billing
                              }
                            />
                          </div>
                        )}
                        {(userHasPermission(
                          SYSTEM_PERMISSION_CODES.edit,
                          userSettings,
                        ) ||
                          userSettings?.userRole === USER_ROLES.SUPER_USER) && (
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                              <Button
                                data-cy="edit_address"
                                size="small"
                                onClick={() => {
                                  editSaveClicked(type);
                                }}
                              >
                                {edit === type
                                  ? "Save"
                                  : contacts[type]
                                    ? "Edit"
                                    : "Add"}
                              </Button>
                            </Grid>
                            {edit === type && (
                              <Grid item>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    resetFields(type);
                                    setEdit(null);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </>
        </Grid>
      </div>
      <MigrationScheduler
        onMigration={handleMigration}
        handleClose={handleClose}
        isOpen={isOpen}
        errorMessage={migrationError}
      />
    </FormProvider>
  );
}

GatewayDetails.propTypes = {
  editGateway: PropTypes.func,
  gatewayInfo: PropTypes.object,
  clickEvent: PropTypes.object,
  changeStatus: PropTypes.func,
  handleClick: PropTypes.func,
  isButtonViewable: PropTypes.func,
  backToGateway: PropTypes.func,
  openSnackbar: PropTypes.func,
  reQueueMigration: PropTypes.func,
  migrationError: PropTypes.string,
  handleMigrationClose: PropTypes.func,
  insertAddress: PropTypes.func,
  setEditAddress: PropTypes.func,
  callAddressSuccess: PropTypes.bool,
  setCallAddressSuccess: PropTypes.func,
  showError: PropTypes.func,
  gatewayInfoEdit: PropTypes.bool,
  setGatewayInfoEdit: PropTypes.func,
};

export default GatewayDetails;
