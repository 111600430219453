import React, { useState, useEffect } from "react";
import Partners from "./Partners";
import axios from "axios";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { formatPhone } from "../../../utils/stringHelpers";

const PartnersContainer = () => {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const url = `${process.env.REACT_APP_IQPRO_API_URL}/partner/search`;
  const payload = { direct_only: false, limit: 0, offset: 0 };

  const tableColumns = (
    <TableRow>
      <TableCell>Id</TableCell>
      <TableCell align="left">Status</TableCell>
      <TableCell align="left">Type</TableCell>
      <TableCell align="left">Name</TableCell>
      <TableCell align="left">Email</TableCell>
      <TableCell align="left">Phone</TableCell>
      <TableCell align="left">Created At</TableCell>
    </TableRow>
  );

  const tableRows = (
    <TableBody>
      {rows.map((row) => (
        <TableRow
          key={row.id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell align="left">{row.status}</TableCell>
          <TableCell align="left">{row.type}</TableCell>
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align="left">{row.phone}</TableCell>
          <TableCell align="left">{row.created_at}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  function createData(id, status, type, name, email, phone, created_at) {
    return { id, status, type, name, email, phone, created_at };
  }

  const getRows = () => {
    for (var i = 0; i < data.length; i++) {
      const phone = formatPhone(data[i].primary_contact.phone);
      var m = new Date(data[i].created_at);
      var dateString =
        ("0" + (m.getUTCMonth() + 1)).slice(-2) +
        "/" +
        ("0" + m.getUTCDate()).slice(-2) +
        "/" +
        m.getUTCFullYear() +
        " " +
        ("0" + m.getUTCHours()).slice(-2) +
        ":" +
        ("0" + m.getUTCMinutes()).slice(-2);

      let item = createData(
        data[i].id,
        data[i].status,
        data[i].type,
        data[i].name,
        data[i].email,
        phone,
        dateString,
      );
      setRows((prev) => {
        return [item, ...prev];
      });
    }
  };

  useEffect(() => {
    axios
      .post(url, payload)
      .then(function (response) {
        if (response.status === 200) {
          setData(response.data.data);
          return;
        }
      })
      .catch(function (error) {
        console.log("failed to get partners", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <Partners tableColumns={tableColumns} tableRows={tableRows} />;
};

export default PartnersContainer;
