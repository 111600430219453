import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { handleCustomFieldMultipleValues } from "./GlobalCustomFields";

const CustomFieldDetails = ({ data, fieldBoldness }) => {
  let fieldsWithMultipleValues = handleCustomFieldMultipleValues(
    data?.customFieldEntries,
  );
  let alreadyDisplayedField = [];

  return (
    <Grid
      item
      container
      sx={{
        wordWrap: "break-all",
        overflowWrap: "break-word",
      }}
    >
      <Grid
        item
        xs={12}
        padding={1}
        bgcolor="#e5e5e5"
        color="black"
        fontWeight={fieldBoldness}
      >
        {data.customFieldEntries[0].customFieldCategoryName}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ borderBottom: 1, marginBottom: 1, color: "white" }}
      ></Grid>

      {Object.keys(data.customFieldEntries).map(function (key) {
        if (!fieldsWithMultipleValues[data.customFieldEntries[key].name])
          return (
            <React.Fragment key={key}>
              <Grid
                item
                padding={1}
                xs={4}
                md={4}
                sx={{ fontWeight: fieldBoldness }}
              >
                {data.customFieldEntries[key].name}:
              </Grid>
              <Grid item padding={1} xs={8} md={8}>
                {data.customFieldEntries[key].value.length > 0
                  ? data.customFieldEntries[key].value
                  : "N/A"}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ borderBottom: 1, margin: 1, color: "#e5e5e5" }}
              ></Grid>
            </React.Fragment>
          );
        else if (
          !alreadyDisplayedField.includes(data.customFieldEntries[key].name)
        ) {
          alreadyDisplayedField.push(data.customFieldEntries[key].name);
          return (
            <React.Fragment key={key}>
              <Grid
                item
                padding={1}
                xs={4}
                md={4}
                sx={{ fontWeight: fieldBoldness }}
              >
                {data.customFieldEntries[key].name}:
              </Grid>
              <Grid item padding={1} xs={8} md={8}>
                {fieldsWithMultipleValues[
                  data.customFieldEntries[key].name
                ].join(", ")}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ borderBottom: 1, margin: 1, color: "#e5e5e5" }}
              ></Grid>
            </React.Fragment>
          );
        } else {
          return <></>;
        }
      })}
    </Grid>
  );
};

CustomFieldDetails.propTypes = {
  data: PropTypes.object.isRequired,
  fieldBoldness: PropTypes.string.isRequired,
};

export default CustomFieldDetails;
