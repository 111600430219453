// SimplePayHelpers.js
export const buildPatchOperations = (formValues, metadataMapping) => {
  const stripBase64Prefix = (base64String) => {
    // Check if the base64String contains the prefix
    if (base64String && base64String.startsWith("data:image/png;base64,")) {
      return base64String.replace("data:image/png;base64,", "");
    }
    if (base64String && base64String.startsWith("data:image/jpeg;base64,")) {
      return base64String.replace("data:image/jpeg;base64,", "");
    }
    return base64String; // Return as is if no prefix
  };

  const strippedLogo = stripBase64Prefix(formValues.logo);
  const isLogoUrl = formValues.logo && formValues.logo.startsWith("https://");

  const patchSimplePay = {
    name: formValues.header_name || "Untitled Page",
    ...(formValues.logo && !isLogoUrl && formValues.logo !== ""
      ? {
          image: {
            fileName: "replaceLogo",
            data: strippedLogo || "",
          },
        }
      : {}),
    SimplePayMetadata: [
      // SimplePay Branding
      {
        MetadataID: metadataMapping["SimplePay Branding"]["BACKGROUND_COLOR"],
        MetadataValue: formValues.background_color || "",
      },
      {
        MetadataID: metadataMapping["SimplePay Branding"]["BANNER_MESSAGE"],
        MetadataValue: formValues.banner_message || "",
      },
      {
        MetadataID: metadataMapping["SimplePay Branding"]["BANNER_TYPE_ID"],
        MetadataValue: String(formValues.banner_type_id) || "2",
      },
      {
        MetadataID: metadataMapping["SimplePay Branding"]["DISPLAY_BANNER"],
        MetadataValue: formValues.display_banner?.toString() || "false",
      },
      {
        MetadataID: metadataMapping["SimplePay Branding"]["LOGO_ALIGN"],
        MetadataValue: formValues.logo_align || "left",
      },
      {
        MetadataID: metadataMapping["SimplePay Branding"]["PAYMENT_COLOR"],
        MetadataValue: formValues.payment_color || "",
      },
      {
        MetadataID: metadataMapping["SimplePay Branding"]["PRIMARY_COLOR"],
        MetadataValue: formValues.primary_color || "",
      },
      ...(formValues.logo === ""
        ? [
            {
              MetadataID: metadataMapping["SimplePay Branding"]["LOGO"],
              MetadataValue: formValues.logo || "",
            },
          ]
        : []),

      // SimplePay Card Form Settings
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "COMPANY_FIELD_LABEL"
          ],
        MetadataValue: formValues.card.company_field_label?.toString() || "",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "ORDER_ID_FIELD_LABEL"
          ],
        MetadataValue: formValues.card.order_id_field_label?.toString() || "",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "PO_NUMBER_FIELD_LABEL"
          ],
        MetadataValue: formValues.card.po_number_field_label?.toString() || "",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"]["ACCEPT_AMEX"],
        MetadataValue:
          formValues.card.accepted_cards?.includes("amex")?.toString() ||
          "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"]["ACCEPT_DISCOVER"],
        MetadataValue:
          formValues.card.accepted_cards?.includes("discover")?.toString() ||
          "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"]["ACCEPT_MASTERCARD"],
        MetadataValue:
          formValues.card.accepted_cards?.includes("mastercard")?.toString() ||
          "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"]["ACCEPT_VISA"],
        MetadataValue:
          formValues.card.accepted_cards?.includes("visa")?.toString() ||
          "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"]["ENABLE_CARD"],
        MetadataValue:
          formValues.payment_type_selector?.includes("card")?.toString() ||
          "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "INCLUDE_ADDRESS_FIELDS"
          ],
        MetadataValue:
          formValues.card.include_address_fields?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "INCLUDE_COMPANY_FIELD"
          ],
        MetadataValue:
          formValues.card.include_company_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "INCLUDE_EMAIL_FIELD"
          ],
        MetadataValue:
          formValues.card.include_email_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"]["INCLUDE_NOTE_FIELD"],
        MetadataValue:
          formValues.card.include_note_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "INCLUDE_ORDER_ID_FIELD"
          ],
        MetadataValue:
          formValues.card.include_order_id_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "INCLUDE_PHONE_FIELD"
          ],
        MetadataValue:
          formValues.card.include_phone_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "INCLUDE_PO_NUMBER_FIELD"
          ],
        MetadataValue:
          formValues.card.include_po_number_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "REQUIRE_ADDRESS_FIELDS"
          ],
        MetadataValue:
          formValues.card.require_address_fields?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "REQUIRE_COMPANY_FIELD"
          ],
        MetadataValue:
          formValues.card.require_company_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "REQUIRE_EMAIL_FIELD"
          ],
        MetadataValue:
          formValues.card.require_email_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "REQUIRE_ORDER_ID_FIELD"
          ],
        MetadataValue:
          formValues.card.require_order_id_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "REQUIRE_PHONE_FIELD"
          ],
        MetadataValue:
          formValues.card.require_phone_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "REQUIRE_PO_NUMBER_FIELD"
          ],
        MetadataValue:
          formValues.card.require_po_number_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay Card Form Settings"][
            "ADDITIONAL_INFO_HEADER"
          ],
        MetadataValue: formValues.card.additional_info_header?.toString() || "",
      },

      // SimplePay ACH Form Settings
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"]["ENABLE_ACH"],
        MetadataValue:
          formValues.payment_type_selector?.includes("ach")?.toString() ||
          "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"][
            "INCLUDE_COMPANY_FIELD"
          ],
        MetadataValue:
          formValues.ach.include_company_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"]["INCLUDE_NOTE_FIELD"],
        MetadataValue: formValues.ach.include_note_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"][
            "INCLUDE_ORDER_ID_FIELD"
          ],
        MetadataValue:
          formValues.ach.include_order_id_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"][
            "INCLUDE_PO_NUMBER_FIELD"
          ],
        MetadataValue:
          formValues.ach.include_po_number_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"][
            "REQUIRE_COMPANY_FIELD"
          ],
        MetadataValue:
          formValues.ach.require_company_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"][
            "REQUIRE_ORDER_ID_FIELD"
          ],
        MetadataValue:
          formValues.ach.require_order_id_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"][
            "REQUIRE_PO_NUMBER_FIELD"
          ],
        MetadataValue:
          formValues.ach.require_po_number_field?.toString() || "false",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"][
            "ADDITIONAL_INFO_HEADER"
          ],
        MetadataValue: formValues.ach.additional_info_header?.toString() || "",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"]["COMPANY_FIELD_LABEL"],
        MetadataValue: formValues.ach.company_field_label?.toString() || "",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"][
            "ORDER_ID_FIELD_LABEL"
          ],
        MetadataValue: formValues.ach.order_id_field_label?.toString() || "",
      },
      {
        MetadataID:
          metadataMapping["SimplePay ACH Form Settings"][
            "PO_NUMBER_FIELD_LABEL"
          ],
        MetadataValue: formValues.ach.po_number_field_label?.toString() || "",
      },
    ],
  };

  const patchOperations = [
    {
      op: "replace",
      path: "/",
      value: patchSimplePay,
    },
  ];

  return patchOperations;
};
