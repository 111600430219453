import React from "react";
import PropTypes from "prop-types";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";

const ConfirmationDialog = ({
  alertTitle,
  alertLevel,
  open,
  content,
  onClose,
  onConfirm,
  okButtonClass,
  okButtonText,
  closeButtonClass,
  closeButtonText,
}) => {
  const theme = useTheme();

  const getAlertIcon = () => {
    switch (alertLevel) {
      case "warning":
        return (
          <ErrorOutlineOutlinedIcon color="error" className="warning-icon" />
        );
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      className={`v2 ${theme.palette.mode}-theme`}
      PaperProps={{ className: "subscription-modal" }}
    >
      <DialogTitle>
        {getAlertIcon()}
        {alertTitle}
        <IconButton fontSize="18px" onClick={onClose}>
          <CloseOutlinedIcon className="close-button" />
        </IconButton>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <Grid container spacing={1} padding={2}>
        <Grid item xs={12}>
          <Button fullWidth className={okButtonClass} onClick={onConfirm}>
            {okButtonText}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth className={closeButtonClass} onClick={onClose}>
            {closeButtonText}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  alertTitle: PropTypes.string,
  alertLevel: PropTypes.string,
  open: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  okButtonClass: PropTypes.string,
  okButtonText: PropTypes.string,
  closeButtonClass: PropTypes.string,
  closeButtonText: PropTypes.string,
};

export default ConfirmationDialog;
