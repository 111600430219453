export const ExpandCollapseIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7075 11.2925C20.8005 11.3854 20.8742 11.4957 20.9246 11.6171C20.9749 11.7385 21.0008 11.8686 21.0008 12C21.0008 12.1314 20.9749 12.2615 20.9246 12.3829C20.8742 12.5043 20.8005 12.6146 20.7075 12.7075L11.7075 21.7075C11.6146 21.8004 11.5043 21.8741 11.3829 21.9244C11.2615 21.9747 11.1314 22.0006 11 22.0006C10.8686 22.0006 10.7385 21.9747 10.6171 21.9244C10.4957 21.8741 10.3854 21.8004 10.2925 21.7075C10.1996 21.6146 10.1259 21.5043 10.0756 21.3829C10.0253 21.2615 9.99944 21.1314 9.99944 21C9.99944 20.8686 10.0253 20.7385 10.0756 20.6171C10.1259 20.4957 10.1996 20.3854 10.2925 20.2925L17.5863 13H1C0.734784 13 0.48043 12.8946 0.292893 12.7071C0.105357 12.5196 0 12.2652 0 12C0 11.7348 0.105357 11.4804 0.292893 11.2929C0.48043 11.1054 0.734784 11 1 11H17.5863L10.2925 3.7075C10.1049 3.51986 9.99944 3.26536 9.99944 3C9.99944 2.73464 10.1049 2.48014 10.2925 2.2925C10.4801 2.10486 10.7346 1.99944 11 1.99944C11.2654 1.99944 11.5199 2.10486 11.7075 2.2925L20.7075 11.2925ZM24 0C23.7348 0 23.4804 0.105357 23.2929 0.292893C23.1054 0.48043 23 0.734784 23 1V23C23 23.2652 23.1054 23.5196 23.2929 23.7071C23.4804 23.8946 23.7348 24 24 24C24.2652 24 24.5196 23.8946 24.7071 23.7071C24.8946 23.5196 25 23.2652 25 23V1C25 0.734784 24.8946 0.48043 24.7071 0.292893C24.5196 0.105357 24.2652 0 24 0Z"
        fill="black"
      />
    </svg>
  );
};
