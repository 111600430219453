import { useState } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MUICheckbox from "@mui/material/Checkbox";
import { getProperty } from "../../utils/objectHelpers";
import { isRequired } from "./formHelpers";

const initialState = (options, defaultValues) => {
  let state = {};
  options.forEach((option) => {
    state[option.value] = defaultValues.includes(option.value);
  });
  return state;
};

export const Checkbox = ({
  name,
  label,
  defaultValues,
  control,
  rules,
  errors,
  options,
  disabled,
}) => {
  const error = getProperty(name, errors);

  const [checkedState, setCheckedState] = useState(
    initialState(options, defaultValues),
  );

  const handleCheckboxChange = (event) => {
    let newCheckedState = {
      ...checkedState,
      [event.target.value]: event.target.checked,
    };
    setCheckedState(newCheckedState);

    let checkedArray = [];
    for (const value in newCheckedState) {
      if (newCheckedState[value]) {
        checkedArray.push(value);
      }
    }
    return checkedArray;
  };

  return (
    <>
      <FormControl
        required={isRequired(rules)}
        error={error !== undefined}
        fullWidth
        component="fieldset"
      >
        <FormLabel component="legend">{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValues}
          rules={rules}
          render={({ field }) => (
            <FormGroup
              row
              aria-label="checkbox"
              onChange={(e) => {
                field.onChange(handleCheckboxChange(e));
              }}
            >
              {options.map((option, index) => (
                <FormControlLabel
                  key={`${name}-${option.name}-${index}`}
                  label={option.name}
                  labelPlacement={option?.placement ? option?.placement : "top"}
                  value={option.value}
                  disabled={disabled}
                  control={
                    <MUICheckbox
                      checked={checkedState[option.value] || false}
                    />
                  }
                />
              ))}
            </FormGroup>
          )}
        />
      </FormControl>
      <FormHelperText sx={{ ml: 0 }} error>
        {error && error.message}
      </FormHelperText>
    </>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValues: PropTypes.array,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  errors: PropTypes.object,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  defaultValues: [],
  disabled: false,
};
