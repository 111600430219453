import {
  sassFilterOperators,
  filterOperators,
} from "../../Search/searchHelpers";

const generalGroup = "General";

export const settlementSearchFields = [
  {
    group: generalGroup,
    displayName: "Settlement Batch Id",
    fieldName: "settlement_batch_id",
    operators: [filterOperators.is, filterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Processor Id",
    fieldName: "processor_id",
    operators: [filterOperators.is, filterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Processor Name",
    fieldName: "processor_name",
    operators: [filterOperators.is, filterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Batch Date",
    fieldName: "batch_date",
    operators: [filterOperators.between],
    fieldType: "date_range",
    selected: true,
    savedValues: {
      operator: "between",
      value:
        new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0] + //calculates to 7 days ago
        " to " +
        new Date().toISOString().split("T")[0],
    },
  },
];

export const saasSettlementSearchFields = [
  {
    group: generalGroup,
    displayName: "Settlement Batch Id",
    fieldName: "settlementId",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Processor Id",
    fieldName: "processorId",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Processor Name",
    fieldName: "processorName",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  // {
  //   group: generalGroup,
  //   displayName: "Response Code",
  //   fieldName: "responseCode",
  //   //NEW FIELD TYPE
  //   operators: [sassFilterOperators.isGreaterOrEqual],
  //   fieldType: "numeric",
  //   selected: false,
  // },
  {
    group: generalGroup,
    displayName: "Batch Date",
    fieldName: "batchDate",
    operators: [sassFilterOperators.between],
    fieldType: "date_range",
    selected: true,
    savedValues: {
      operator: "Between",
      value:
        new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0] + //calculates to 7 days ago
        " to " +
        new Date().toISOString().split("T")[0],
    },
  },
];
