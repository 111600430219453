import React, { useContext } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useFormContext } from "react-hook-form";
import { formatCurrency } from "../../../../utils/stringHelpers";
import { TerminalContext } from "../TerminalContext";
import { GatewayConfigContext } from "../../../../contexts/GatewayConfigContext";
import { GatewayContext } from "../../../../contexts/GatewayContext";
import { getGatewayConfigFieldVal } from "../../../../utils/objectHelpers";
import PropTypes from "prop-types";
import { StepperDescription } from "./physicalTerminalConstants";
import { calculateTotal } from "../PaymentDetails";
const PhysicalSummary = ({
  handleClose,
  type,
  setType,
  stepper,
  selectedTerminal,
  connectTerminal,
  isConnection,
  cancelTransaction,
  snackbarProps,
  disableCancel,
}) => {
  const form = useFormContext();
  const { taxRules } = useContext(TerminalContext);
  const gatewayConfig = useContext(GatewayConfigContext);
  const gatewayInfo = useContext(GatewayContext);
  const amount = form.getValues("amount");
  const taxRate =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Merchant",
      "tax_rate",
    ) || null;

  const EBT =
    gatewayInfo.gatewaySettings
      .find((s) => s.code === "ENABLE_SNAPFSA")
      ?.value.toLowerCase() === "true";

  const handleChange = (event, newValue) => {
    setType(newValue);
  };

  return (
    <div className="pt-summary">
      {!isConnection ? (
        <>
          <h2>Review</h2>
          {selectedTerminal && (
            <div className="card-container">
              <p>{selectedTerminal.name}</p>
            </div>
          )}
          <div className="totals-section">
            <div>
              <span>Subtotal</span>
              <span>{formatCurrency(amount)}</span>
            </div>
            {!taxRules.taxExempt && taxRate && type !== "ebt" && amount > 0 && (
              <div>
                <span>Tax %{taxRate}</span>
                <span>
                  {formatCurrency(amount * (taxRate / 100).toFixed(2))}
                </span>
              </div>
            )}
          </div>
          <div className="total">
            <span>Total</span>
            <span>
              {" "}
              {taxRules.taxToTotal && type !== "ebt"
                ? formatCurrency(calculateTotal(amount, taxRate))
                : formatCurrency(amount)}
            </span>
          </div>

          {EBT && (
            <div className="button-toggle">
              <ToggleButtonGroup value={type} exclusive onChange={handleChange}>
                <ToggleButton value="credit/debit">Credit/Debit</ToggleButton>
                <ToggleButton value="ebt">EBT</ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}

          <button
            onClick={() => {
              if (selectedTerminal) {
                connectTerminal(true);
              }
            }}
            className="btn--primary"
          >
            Send
          </button>
          <button onClick={handleClose} className="btn--secondary">
            Cancel
          </button>
        </>
      ) : (
        <div className="connection-summary">
          <p className="mobile-text-stepper">
            {StepperDescription[stepper]?.description}
          </p>
          {selectedTerminal && (
            <div className="card-container terminal-name">
              <p>{selectedTerminal.name}</p>
            </div>
          )}
          <div className="card-container">
            <div className="totals-section">
              <div>
                <span>Subtotal</span>
                <span>{formatCurrency(amount)}</span>
              </div>
              {!taxRules.taxExempt &&
                taxRate &&
                type !== "ebt" &&
                amount > 0 && (
                  <div>
                    <span>Tax %{taxRate}</span>
                    <span>
                      {formatCurrency(amount * (taxRate / 100).toFixed(2))}
                    </span>
                  </div>
                )}
            </div>
            <div className="total">
              <span>Total</span>
              <span>
                {" "}
                {taxRules.taxToTotal && type !== "ebt"
                  ? formatCurrency(calculateTotal(amount, taxRate))
                  : formatCurrency(amount)}
              </span>
            </div>
          </div>

          <button
            disabled={disableCancel()}
            onClick={() => {
              cancelTransaction(true);
            }}
            className="btn--primary__danger"
          >
            Cancel Transaction
          </button>
        </div>
      )}
      {snackbarProps && (
        <div
          style={{ marginTop: "16px", width: "100%" }}
          className={`tag tag__${snackbarProps.type}`}
        >
          <p>{snackbarProps.message}</p>
        </div>
      )}
    </div>
  );
};
PhysicalSummary.propTypes = {
  handleClose: PropTypes.func,
  selectedTerminal: PropTypes.object,
  connectTerminal: PropTypes.func,
  isConnection: PropTypes.bool,
  cancelTransaction: PropTypes.func,
  type: PropTypes.string,
  setType: PropTypes.func,
  stepper: PropTypes.number,
  snackbarProps: PropTypes.object,
  disableCancel: PropTypes.func,
};

export default PhysicalSummary;
