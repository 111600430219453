import React from "react";
import { useFormContext } from "react-hook-form";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import { regexPatterns } from "../../../../constants/regexPatterns";
import PropTypes from "prop-types";
function GatewayProcessorsFiserv({ editDetails, isEdit }) {
  const methods = useFormContext();
  methods.register("setting.CardProcessorConfiguration.ProcessorId");
  methods.setValue("setting.CardProcessorConfiguration.ProcessorId", "6");
  return (
    <div className="otc-form">
      {" "}
      <div className="otc-form-wrapper">
        <div className="fiserv-text-inputs">
          <RegisteredTextField
            id="otc-merchant_id"
            name={`setting.CardProcessorConfiguration.CategoryCode`}
            label="Category Code (Max 4 digits)"
            fullWidth
            errors={methods.errors}
            defaultValue={editDetails?.categoryCode || ""}
            viewOnly={!isEdit}
            rules={{
              required: true,
              maxLength: { value: 4, message: "Max 4 digits" },
              pattern: regexPatterns.numeric,
            }}
          />
          <RegisteredTextField
            id="otc-group_id"
            name={`setting.CardProcessorConfiguration.GroupId`}
            label="Group ID (minimum 5 digits)"
            fullWidth
            errors={methods.errors}
            viewOnly={!isEdit}
            defaultValue={editDetails?.groupId || ""}
            rules={{
              required: true,
              minLength: { value: 5, message: "Requires 5 digits" },
              pattern: regexPatterns.numeric,
            }}
          />
          <RegisteredTextField
            id="otc-terminal_id"
            name={`setting.CardProcessorConfiguration.TerminalId`}
            label="Terminal ID (minimum 8 digits)"
            fullWidth
            errors={methods.errors}
            viewOnly={!isEdit}
            defaultValue={editDetails?.terminalId || ""}
            rules={{
              required: true,
              minLength: { value: 8, message: "Requires 8 digits" },
              pattern: regexPatterns.numeric,
            }}
          />
          <RegisteredTextField
            id="did"
            name={`setting.CardProcessorConfiguration.Mid`}
            label="MID (max 20 characters)"
            fullWidth
            viewOnly={!isEdit}
            errors={methods.errors}
            defaultValue={editDetails?.merchantId || ""}
            rules={{
              maxLength: { value: 20, message: "Max 20 characters" },
              pattern: regexPatterns.alphanumericSpace,
            }}
          />
        </div>
      </div>
      <div className="form-row">
        <Select
          control={methods.control}
          name={`setting.CardProcessorConfiguration.BusType`}
          fullWidth
          id="BusType"
          label="Bus Type"
          viewOnly={!isEdit}
          defaultValue={editDetails?.classification?.toLowerCase() || "ecomm"}
          sx={{ width: "25%" }}
          rules={{
            required: true,
          }}
        >
          <MenuItem value={"ecomm"}>Ecommerce</MenuItem>
          <MenuItem value={"moto"}>MOTO</MenuItem>
          <MenuItem value={"retail"}>Retail</MenuItem>
        </Select>
      </div>
    </div>
  );
}
GatewayProcessorsFiserv.propTypes = {
  editDetails: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default GatewayProcessorsFiserv;
