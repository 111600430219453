import { useEffect, useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "../../../ReactHookForm/Select";
import { validationRules } from "../../../../constants/validationRules";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import { regexPatterns } from "../../../../constants/regexPatterns";
import { FormProvider, useForm } from "react-hook-form";
import { USER_ROLES } from "../../../../constants/global";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import PhoneNumber from "../../../Contact/PhoneNumber";
import { GatewaySettingsContext } from "../gatewaySettingsContext";
import { useContext } from "react";
import Switch from "../../../ReactHookForm/Switch";
import { IUserStatus } from "../GatewayView";
import Button from "@mui/material/Button";
import "./gatewayUser.scss";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import {
  GATEWAY_USER_ROLE_CODES,
  GATEWAY_USER_PERMISSION_CODES,
} from "../../../../constants/global";
import { sassEndpoints } from "../../../../constants/endpoints";
import { stringFormat } from "../../../../utils/stringHelpers";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgress } from "@mui/material";

function GatewayCreateUser({
  gatewayInfo,
  createGatewayUser,
  onSubmission,
  gatewayId,
}) {
  useImperativeHandle(onSubmission, () => ({
    onSubmit: () => {
      const payload = assemblePayload();
      createGatewayUser(payload);
    },
  }));
  const { ...methods } = useForm();
  const theme = useTheme();
  const { gatewayProps, setGatewayProps, gatewayPerms } = useContext(
    GatewaySettingsContext,
  );

  const [isLookUp, setIsLookUp] = useState(true);
  const [foundUser, setFoundUser] = useState(null);
  const [newEmail, setNewEmail] = useState(null);
  const [namesHidden, setNamesHidden] = useState(false);
  const [showGatewayPerms, setShowGatewayPerms] = useState(false);
  const [permsError, setPermsError] = useState(false);
  const [currentRole, setCurrentRole] = useState(
    GATEWAY_USER_ROLE_CODES.standard,
  );
  const [userError, setUserError] = useState("");
  const allowCustomerPayable =
    gatewayInfo?.gatewaySettings
      ?.find((obj) => obj.code.toLowerCase() === "customer_payable")
      ?.value.toLowerCase() === "true";

  const standardPermissions = [
    "MANAGE_USERS",
    "VIEW_SETTLEMENT_BATCHES",
    "PROCESS_CREDIT",
    "VIEW_OTHERS_TRANSACTIONS",
    "DELETE_INVOICE",
    "VIEW_ALL_INVOICES",
  ];

  useEffect(() => {
    if (gatewayPerms) {
      setShowGatewayPerms(true);
    } else if (gatewayPerms === false) {
      setPermsError(true);
    }
  }, [gatewayPerms]);

  const togglePermissions = (property) => {
    return gatewayProps.copySettings.permissions.find((p) => {
      {
        return p.code === property.code;
      }
    });
  };

  const handleDefaultPermissions = (permission) => {
    if (gatewayProps.copySettings) {
      return togglePermissions(permission);
    } else if (currentRole === GATEWAY_USER_ROLE_CODES.admin) {
      return true;
    } else if (standardPermissions.includes(permission.code)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setGatewayProps({
      ...gatewayProps,
      isDisabled: !methods.formState.isValid,
    });
  }, [methods.formState.isValid]);

  useEffect(() => {
    if (!isLookUp && foundUser) {
      methods.setValue("email", foundUser.email);
    } else if (!isLookUp && newEmail) {
      methods.setValue("email", newEmail);
    }
  }, [isLookUp]);

  const roleChanged = () => {
    setCurrentRole(methods.getValues().role);
  };

  const createUser = () => {
    const payload = assemblePayload();
    createGatewayUser(payload);
  };

  const handlePermissions = (permissionsList) => {
    let permissionCodes = [];
    Object.keys(permissionsList).forEach((name) => {
      if (permissionsList[name]) {
        let foundP = gatewayProps.gatewayPermissions.find((p) => {
          return p.code.toLowerCase() === name;
        });
        if (foundP) {
          permissionCodes.push(foundP.permissionId);
        }
      }
    });
    return permissionCodes;
  };

  const assemblePayload = () => {
    const newUserData = {
      firstName: methods.getValues().first_name || "NA",
      lastName: methods.getValues().last_name || "NA",
      email: foundUser ? foundUser.email : newEmail,
      roleId: methods.getValues().role,
      phone: methods.getValues().phone,
      permissionIds: gatewayProps.copySettings?.permissions
        ? handlePermissions(methods.getValues().permissions)
        : methods.getValues().role === GATEWAY_USER_ROLE_CODES.standard
          ? gatewayProps?.gatewayPermissions
              ?.filter((p) =>
                GATEWAY_USER_PERMISSION_CODES.standard.includes(p.code),
              )
              .map((obj) => obj.permissionId)
          : gatewayProps.gatewayPermissions
              .filter((p) =>
                GATEWAY_USER_PERMISSION_CODES.admin.includes(p.code),
              )
              .map((obj) => obj.permissionId),
      // processor_id:
      //       (copySettings && (methods?.getValues()?.default_processor === "none"
      //         ? ""
      //         : methods?.getValues()?.default_processor) )|| "",
    };
    return newUserData;
  };

  const lookupApi = () => {
    setUserError(null);
    axios
      .post(stringFormat(sassEndpoints.gatewayUsers.validate, [gatewayId]), {
        email: methods.getValues().email_lookup,
      })
      .then((response) => {
        if (!response.data.data.isCreateAllowed) {
          setUserError("User already exists on Gateway.");
        } else if (response.data.data.accountExists) {
          const user = {
            email: methods.getValues().email_lookup,
          };
          setFoundUser(user);
          setIsLookUp(false);
          setNamesHidden(true);
        } else {
          setNewEmail(methods.getValues().email_lookup);
          setIsLookUp(false);
        }
      })
      .catch(() => {
        setUserError("Failed to lookup email");
      });
  };

  const getDefaultRole = () => {
    return (
      gatewayProps?.copySettings?.role ||
      gatewayProps.gatewayRoles.find(
        (role) => role.code === USER_ROLES.GATEWAY_ADMIN,
      ).roleId
    );
  };

  return (
    <div className="create-detail-wrapper">
      <FormProvider {...methods}>
        <div className="email-lookup-container">
          <div className="email-lookup-wrapper">
            <div className="create-header">
              <IconButton
                variant="container"
                color="primary"
                fontSize="small"
                onClick={() =>
                  setGatewayProps({
                    ...gatewayProps,
                    usersState: IUserStatus.TABLE,
                    copySettings: null,
                  })
                }
              >
                <ChevronLeftIcon />
              </IconButton>
              <h2>User Info</h2>
            </div>

            <div className="lookup-form">
              <RegisteredTextField
                id="email_lookup"
                name={"email_lookup"}
                label="Email"
                fullWidth
                disabled={isLookUp ? false : true}
                errors={methods.errors}
                rules={{
                  pattern: validationRules.email,
                  maxLength: validationRules.maxLength100,
                  required: true,
                }}
              />

              {isLookUp && (
                <div className="btn-row">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => lookupApi()}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
            {userError && (
              <>
                <p
                  style={{
                    fontWeight: "bold",
                    color: theme?.palette?.mode === "dark" ? "#fff" : "#D32F2F",
                  }}
                >
                  {userError}
                </p>
                {isLookUp && (
                  <Button
                    size="small"
                    variant="contained"
                    color="neutrals"
                    onClick={() =>
                      setGatewayProps({
                        ...gatewayProps,
                        usersState: IUserStatus.TABLE,
                        copySettings: null,
                      })
                    }
                    sx={{ marginRight: "15px" }}
                  >
                    Cancel
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        {!isLookUp && (
          <div className="create-details-body">
            <div className="form-elements">
              <Grid container>
                {!namesHidden && (
                  <>
                    <Grid
                      sx={{ marginBottom: "10px", marginRight: { sm: "10px" } }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <RegisteredTextField
                        id="first_name"
                        name={"first_name"}
                        label="First Name"
                        fullWidth
                        errors={methods.errors}
                        rules={{
                          pattern: regexPatterns.name,
                          required: true,
                          maxLength: validationRules.maxLength100,
                        }}
                      />
                    </Grid>
                    <Grid
                      sx={{ marginBottom: "10px", marginRight: { sm: "10px" } }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <RegisteredTextField
                        id="last_name"
                        name={"last_name"}
                        label="Last Name"
                        errors={methods.errors}
                        fullWidth
                        rules={{
                          pattern: regexPatterns.name,
                          required: true,
                          maxLength: validationRules.maxLength100,
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  sx={{ marginBottom: "10px" }}
                  xs={12}
                  sm={namesHidden ? 6 : 5}
                >
                  <PhoneNumber
                    name="phone"
                    id="phone"
                    label="Phone"
                    required={true}
                    viewOnly={false}
                  />
                </Grid>
              </Grid>
              {gatewayProps.gatewayRoles && (
                <Grid item xs={12} sm={6}>
                  <Select
                    control={methods.control}
                    name="role"
                    fullWidth
                    id="role"
                    onChange={roleChanged}
                    defaultValue={getDefaultRole()}
                    label="Role"
                  >
                    {gatewayProps.gatewayRoles
                      .filter(
                        (role) =>
                          role.code === USER_ROLES.GATEWAY_STANDARD ||
                          role.code === USER_ROLES.GATEWAY_ADMIN,
                      )
                      .map((role, i) => {
                        return (
                          <MenuItem key={i} value={role.roleId}>
                            {role.code === USER_ROLES.GATEWAY_STANDARD
                              ? "Standard"
                              : "Admin"}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              )}
              {/* {gatewayProps?.copySettings && (
                <div className="form-row">
                  <Select
                    control={methods.control}
                    name="default_processer"
                    label="Default Processer"
                    fullWidth
                    errors={methods.errors}
                    viewOnly={false}
                    defaultValue={
                      gatewayProps?.copySettings?.default_processor || "none"
                    }
                  >
                    <MenuItem key={"none"} value={"none"}>
                      None
                    </MenuItem>
                    {gatewayProps?.copySettings?.processors?.map(
                      (processor, i) => {
                        return (
                          <MenuItem key={i} value={processor.id}>
                            {processor.name}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </div>
              )} */}
            </div>

            <div className="create-permissions-wrapper">
              {currentRole === GATEWAY_USER_ROLE_CODES.admin ? (
                <>
                  {" "}
                  <h3>Permissions</h3>
                  <div className="toggle-container">
                    {showGatewayPerms === true ? (
                      <>
                        <ul>
                          {" "}
                          {gatewayPerms.map((property, i) => {
                            if (
                              property?.code?.toLowerCase() !== "vault_read" &&
                              property?.code?.toLowerCase().slice(0, 3) !==
                                "pro" &&
                              !(
                                (property?.code?.toLowerCase() ===
                                  "manage_cust_payable_token" ||
                                  property?.code?.toLowerCase() ===
                                    "view_cust_payable_token") &&
                                !allowCustomerPayable
                              )
                            ) {
                              return (
                                <Tooltip
                                  key={i}
                                  title={
                                    property?.code?.toLowerCase() ===
                                    "view_others_transactions"
                                      ? "Turn this off to restrict user view to only their transactions"
                                      : ""
                                  }
                                >
                                  <li key={i}>
                                    <Switch
                                      control={methods.control}
                                      label={property.description}
                                      disabled={
                                        gatewayProps.copySettings ? false : true
                                      }
                                      labelPlacement="start"
                                      name={
                                        "permissions." +
                                        property?.code?.toLowerCase()
                                      }
                                      defaultValue={handleDefaultPermissions(
                                        property,
                                      )}
                                    />
                                  </li>
                                </Tooltip>
                              );
                            }
                          })}
                        </ul>
                        <ul>
                          {gatewayProps.gatewayPermissions.map(
                            (property, i) => {
                              if (
                                property?.code?.toLowerCase() !==
                                  "vault_read" &&
                                property?.code?.toLowerCase().slice(0, 3) ===
                                  "pro"
                              ) {
                                return (
                                  <li key={i}>
                                    <Switch
                                      control={methods.control}
                                      label={property.description}
                                      labelPlacement="start"
                                      name={
                                        "permissions." +
                                        property?.code?.toLowerCase()
                                      }
                                      disabled={
                                        gatewayProps.copySettings ? false : true
                                      }
                                      defaultValue={handleDefaultPermissions(
                                        property,
                                      )}
                                    />
                                  </li>
                                );
                              }
                            },
                          )}
                        </ul>
                      </>
                    ) : !permsError ? (
                      <div>
                        <CircularProgress size={20} />
                      </div>
                    ) : (
                      <div style={{ padding: "0px 0px 20px 0px" }}>
                        Error loading gateway user permissions
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <h3>Permissions</h3>
                  <div className="toggle-container">
                    {showGatewayPerms === true ? (
                      <>
                        <ul>
                          {" "}
                          {gatewayPerms.map((property, i) => {
                            if (
                              property?.code?.toLowerCase() !== "vault_read" &&
                              property?.code?.toLowerCase() !==
                                "manage_users" &&
                              property?.code?.toLowerCase().slice(0, 3) !==
                                "pro" &&
                              !(
                                (property?.code?.toLowerCase() ===
                                  "manage_cust_payable_token" ||
                                  property?.code?.toLowerCase() ===
                                    "view_cust_payable_token") &&
                                !allowCustomerPayable
                              )
                            ) {
                              return (
                                <Tooltip
                                  key={i}
                                  title={
                                    property?.code?.toLowerCase() ===
                                    "view_others_transactions"
                                      ? "Turn this off to restrict user view to only their transactions"
                                      : ""
                                  }
                                >
                                  <li>
                                    <Switch
                                      key={i}
                                      control={methods.control}
                                      label={property.description}
                                      labelPlacement="start"
                                      name={
                                        "permissions." +
                                        property?.code?.toLowerCase()
                                      }
                                      disabled={
                                        gatewayProps.copySettings ? false : true
                                      }
                                      defaultValue={handleDefaultPermissions(
                                        property,
                                      )}
                                    />
                                  </li>
                                </Tooltip>
                              );
                            }
                          })}
                        </ul>
                        <ul>
                          {gatewayPerms.map((property, i) => {
                            if (
                              property?.code?.toLowerCase().slice(0, 3) ===
                              "pro"
                            ) {
                              return (
                                <li key={i}>
                                  <Switch
                                    control={methods.control}
                                    label={property.description}
                                    labelPlacement="start"
                                    name={
                                      "permissions." +
                                      property?.code?.toLowerCase()
                                    }
                                    disabled={
                                      gatewayProps.copySettings ? false : true
                                    }
                                    defaultValue={handleDefaultPermissions(
                                      property,
                                    )}
                                  />
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </>
                    ) : !permsError ? (
                      <div>
                        <CircularProgress size={20} />
                      </div>
                    ) : (
                      <div style={{ padding: "0px 0px 20px 0px" }}>
                        Error showing gateway user permissions.
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="btn-row">
              <Button
                size="small"
                variant="contained"
                color="neutrals"
                onClick={() => {
                  methods.reset();
                  setFoundUser(null);
                  setIsLookUp(true);
                  setNamesHidden(false);
                }}
                sx={{ marginRight: "15px" }}
              >
                Cancel
              </Button>
              <Button
                size="small"
                disabled={!methods.formState.isValid}
                variant="contained"
                color="primary"
                onClick={() => createUser()}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </FormProvider>
    </div>
  );
}
GatewayCreateUser.propTypes = {
  gatewayInfo: PropTypes.object,
  createGatewayUser: PropTypes.func,
  onSubmission: PropTypes.object,
  gatewayId: PropTypes.string,
};
export default GatewayCreateUser;
