import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import "./Accordion.scss";
import { useTheme } from "@mui/material/styles";

export const ExpandCollapse = ({ title, body, isExpanded }) => {
  const [togglePanel, setTogglePanel] = useState(isExpanded);
  const theme = useTheme();
  useEffect(() => {
    setTogglePanel(isExpanded);
  }, [isExpanded]);

  const handleChange = () => {
    setTogglePanel(!togglePanel);
  };

  return (
    <div className="IQAccordion-wrapper">
      <Accordion
        className={theme.palette.mode + "-theme"}
        expanded={togglePanel}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="textSecondary">{title}</Typography>
          <div className="title-border"></div>
        </AccordionSummary>
        <AccordionDetails>{body}</AccordionDetails>
      </Accordion>
    </div>
  );
};

ExpandCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.element.isRequired,
  isExpanded: PropTypes.bool,
};

ExpandCollapse.defaultProps = {
  isExpanded: true,
};

export default ExpandCollapse;
