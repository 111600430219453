import React, { useEffect, useState, useImperativeHandle } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "../../../ReactHookForm/Checkbox";
import { useFormContext } from "react-hook-form";
import AccordionGroup from "../../../Accordion/AccordionGroup";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormLabel from "@mui/material/FormLabel";
// import FormControl from "@mui/material/FormControl";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
function GatewayProcessorsSage({ reference, editDetails, isEdit }) {
  useImperativeHandle(reference, () => ({
    onSubmit: () => {
      const payload = structureSECPayload();
      return payload;
    },
  }));
  const methods = useFormContext();
  const checkboxes = methods.watch("sage-group");
  const [secCodes, setSECCodes] = useState([]);
  const [actionTrigger, setActionTrigger] = useState(null);
  const [displayAccordions, setDisplayAccordions] = useState(false);
  const [switchAccordions, setSwitchAccordions] = useState(false);

  const [ppdButtons, setPPDButtons] = useState({
    allow_credits: false,
    check_verification: false,
    dl_required: false,
    id_required: false,
    is_gateway_terminal: false,
    sec_code: "PPD",
    type: "onetime",
  });

  const [ccdButtons, setCCDButtons] = useState({
    allow_credits: false,
    check_verification: false,
    dl_required: false,
    id_required: false,
    is_gateway_terminal: false,
    sec_code: "CCD",
    type: "onetime",
  });
  const [webButtons, setWEBButtons] = useState({
    allow_credits: false,
    check_verification: false,
    dl_required: false,
    id_required: false,
    is_gateway_terminal: false,
    sec_code: "WEB",
    type: "onetime",
  });
  const [telButtons, setTELButtons] = useState({
    allow_credits: false,
    check_verification: false,
    dl_required: false,
    id_required: false,
    is_gateway_terminal: false,
    sec_code: "TEL",
    type: "onetime",
  });

  const options = [
    { name: "PPD", value: "PPD" },
    { name: "CCD", value: "CCD" },
    { name: "WEB", value: "WEB" },
    { name: "TEL", value: "TEL" },
  ];

  const transformMapping = (terminal) => {
    return {
      terminal_id: terminal.terminalId,
      sec_code: terminal.secCode,
      type: terminal.type.toLowerCase(),
      dl_required: terminal.dlRequired,
      id_required: terminal.idRequired,
      check_verification: terminal.checkVerification,
      is_gateway_terminal: terminal.isGatewayTerminal,
      allow_credits: terminal.allowCredits,
      check_limit: terminal.checkLimit,
    };
  };

  useEffect(() => {
    if (editDetails) {
      Object.values(editDetails).map((val) => {
        const remappedVals = transformMapping(val);
        switch (remappedVals.sec_code.toUpperCase()) {
          case "PPD":
            setPPDButtons(remappedVals);
            break;
          case "CCD":
            setCCDButtons(remappedVals);
            break;
          case "WEB":
            setWEBButtons(remappedVals);
            break;
          case "TEL":
            setTELButtons(remappedVals);
            break;
        }
      });
    }
    setDisplayAccordions(true);
  }, []);

  useEffect(() => {
    if (actionTrigger) {
      updateSECState(
        actionTrigger.accordionType,
        actionTrigger.column,
        actionTrigger.value,
      );
    }
    setActionTrigger(null);
  }, [actionTrigger]);

  useEffect(() => {
    setSwitchAccordions(false);
  }, [secCodes]);

  useEffect(() => {
    if (displayAccordions) {
      const selectedBoxes = methods.getValues("sage-group");
      let chosenCodes = [];
      if (selectedBoxes.length > 0) {
        setSwitchAccordions(true);
        selectedBoxes.forEach((code) => {
          chosenCodes.push({
            title: code,
            body: accordionTemplate(code),
          });
        });
      }
      setSECCodes(chosenCodes);
    }
  }, [checkboxes, displayAccordions, isEdit]);

  const getSavedVals = () => {
    if (editDetails) {
      let secArr = [];
      Object.values(editDetails).map((val) => {
        secArr.push(val?.secCode?.toUpperCase());
      });
      return secArr;
    } else {
      return [];
    }
  };

  const structureSECPayload = () => {
    const allCodes = {
      PPD: ppdButtons,
      CCD: ccdButtons,
      WEB: webButtons,
      TEL: telButtons,
    };
    let setting = {
      sage_ach: {
        has_level2_verification: true,
        terminals: {},
      },
    };
    secCodes.forEach((code) => {
      const name = methods.getValues(`${code.title}terminal_id`);
      setting.sage_ach.terminals[name] = allCodes[code.title];
    });
    return setting;
  };

  const updateSECState = (code, column, value) => {
    switch (code) {
      case "PPD":
        setPPDButtons({
          ...ppdButtons,
          [column]: value,
        });
        break;
      case "CCD":
        setCCDButtons({
          ...ccdButtons,
          [column]: value,
        });
        break;
      case "WEB":
        setWEBButtons({
          ...webButtons,
          [column]: value,
        });
        break;
      case "TEL":
        setTELButtons({
          ...telButtons,
          [column]: value,
        });
        break;
    }
  };

  const accordionTemplate = (accordionName) => {
    // const allCodes = {
    //   PPD: ppdButtons,
    //   CCD: ccdButtons,
    //   WEB: webButtons,
    //   TEL: telButtons,
    // };
    //const codeState = allCodes[accordionName.toUpperCase()];
    let defaultName = "";
    if (editDetails) {
      Object.values(editDetails).forEach((val) => {
        if (val.secCode.toUpperCase() === accordionName.toUpperCase()) {
          defaultName = val.terminalId;
        }
      });
    }
    return (
      <div>
        <Grid container>
          <Grid item xs={12} md={4}>
            <RegisteredTextField
              id={`${accordionName}-terminal-id`}
              name={`${accordionName}terminal_id`}
              label="Terminal ID Number"
              sx={{ marginBottom: "10px" }}
              defaultValue={defaultName}
              viewOnly={!isEdit}
              fullWidth
              errors={methods.errors}
              rules={{
                required: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          {/* <Grid item xs={6} md={2}>
            <FormControl>
              <FormLabel id={`${accordionName}-type`}>Type?</FormLabel>
              <RadioGroup
                aria-labelledby={`${accordionName}-type`}
                defaultValue={codeState.type}
                name={`${accordionName}-type`}
                onChange={(e) =>
                  setActionTrigger({
                    accordionType: accordionName,
                    column: "type",
                    value: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  labelPlacement="end"
                  value={"onetime"}
                  control={<Radio />}
                  label="One time"
                  disabled={!isEdit}
                />
                <FormControlLabel
                  labelPlacement="end"
                  value={"recurring"}
                  control={<Radio />}
                  label="Recurring"
                  disabled={!isEdit}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2}>
            <FormControl>
              <FormLabel id={`${accordionName}-dl`}>DL Required?</FormLabel>
              <RadioGroup
                aria-labelledby={`${accordionName}-dl`}
                defaultValue={codeState.dl_required ? "yes" : "no"}
                name={`${accordionName}-dl`}
                onChange={(e) =>
                  setActionTrigger({
                    accordionType: accordionName,
                    column: "dl_required",
                    value: e.target.value === "yes",
                  })
                }
              >
                <FormControlLabel
                  labelPlacement="end"
                  value={"yes"}
                  control={<Radio />}
                  label="Yes"
                  disabled={!isEdit}
                />
                <FormControlLabel
                  labelPlacement="end"
                  value={"no"}
                  control={<Radio />}
                  label="No"
                  disabled={!isEdit}
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={6} md={2}>
            <FormControl>
              <FormLabel id={`${accordionName}-id`}>ID Required?</FormLabel>
              <RadioGroup
                aria-labelledby={`${accordionName}-id`}
                defaultValue={codeState.id_required ? "yes" : "no"}
                name={`${accordionName}-id`}
                onChange={(e) =>
                  setActionTrigger({
                    accordionType: accordionName,
                    column: "id_required",
                    value: e.target.value === "yes",
                  })
                }
              >
                <FormControlLabel
                  labelPlacement="end"
                  value={"yes"}
                  control={<Radio />}
                  label="Yes"
                  disabled={!isEdit}
                />
                <FormControlLabel
                  labelPlacement="end"
                  value={"no"}
                  control={<Radio />}
                  label="No"
                  disabled={!isEdit}
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={6} md={2}>
            <FormControl>
              <FormLabel id={`${accordionName}-verification`}>
                Check Verification?
              </FormLabel>
              <RadioGroup
                aria-labelledby={`${accordionName}-verification`}
                defaultValue={codeState.check_verification ? "yes" : "no"}
                name={`${accordionName}-verification`}
                onChange={(e) =>
                  setActionTrigger({
                    accordionType: accordionName,
                    column: "check_verification",
                    value: e.target.value === "yes",
                  })
                }
              >
                <FormControlLabel
                  labelPlacement="end"
                  value={"yes"}
                  control={<Radio />}
                  label="Yes"
                  disabled={!isEdit}
                />
                <FormControlLabel
                  labelPlacement="end"
                  value={"no"}
                  control={<Radio />}
                  label="No"
                  disabled={!isEdit}
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={6} md={2}>
            <FormControl>
              <FormLabel id={`${accordionName}-terminal`}>
                Gateway Terminal?
              </FormLabel>
              <RadioGroup
                aria-labelledby={`${accordionName}-terminal`}
                defaultValue={codeState.is_gateway_terminal ? "yes" : "no"}
                name={`${accordionName}-terminal`}
                onChange={(e) =>
                  setActionTrigger({
                    accordionType: accordionName,
                    column: "is_gateway_terminal",
                    value: e.target.value === "yes",
                  })
                }
              >
                <FormControlLabel
                  labelPlacement="end"
                  value={"yes"}
                  control={<Radio />}
                  label="Yes"
                  disabled={!isEdit}
                />
                <FormControlLabel
                  labelPlacement="end"
                  value={"no"}
                  control={<Radio />}
                  label="No"
                  disabled={!isEdit}
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={6} md={2}>
            <FormControl>
              <FormLabel id={`${accordionName}-credit`}>
                Allow Credits?
              </FormLabel>
              <RadioGroup
                aria-labelledby={`${accordionName}-credit`}
                defaultValue={codeState.allow_credits ? "yes" : "no"}
                name={`${accordionName}-credit`}
                onChange={(e) =>
                  setActionTrigger({
                    accordionType: accordionName,
                    column: "allow_credits",
                    value: e.target.value === "yes",
                  })
                }
              >
                <FormControlLabel
                  labelPlacement="end"
                  value={"yes"}
                  control={<Radio />}
                  label="Yes"
                  disabled={!isEdit}
                />
                <FormControlLabel
                  labelPlacement="end"
                  value={"no"}
                  control={<Radio />}
                  label="No"
                  disabled={!isEdit}
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
        </Grid>
      </div>
    );
  };

  return (
    <div className="sage-block">
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item xs={12} md={4}>
          <Checkbox
            name={"sage-group"}
            defaultValues={getSavedVals()}
            label={"Choose all SEC Codes that apply"}
            control={methods.control}
            errors={methods.errors}
            options={options}
            disabled={!isEdit}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <div className="sec-info-block">
            <InfoIcon
              color="info"
              sx={{
                fontSize: "1.5em",
              }}
            />
            <Typography
              variant="p"
              color="textSecondary"
              sx={{ paddingLeft: "5px" }}
            >
              One SEC Code must be selected.
            </Typography>
          </div>
        </Grid>
        {secCodes.length > 0 && !switchAccordions && (
          <Grid item xs={12}>
            <AccordionGroup accordionDetails={secCodes} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
GatewayProcessorsSage.propTypes = {
  reference: PropTypes.object,
  editDetails: PropTypes.array,
  isEdit: PropTypes.bool,
};
export default GatewayProcessorsSage;
