import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import empty from "../../assets/images/empty-state.png";
import "./Error.scss";
import { generateUUID } from "../../utils/stringHelpers";
import { errorBoundaryTriggered } from "../../services/mixpanel/errorEvents";
import { CONTACT_SUPPORT } from "../../constants/global";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import { RolesAndPermissionContext } from "../../contexts/RolesAndPermissionContext";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { sassEndpoints } from "../../constants/endpoints";
import axios from "axios";
const ErrorContainer = () => {
  const location = useLocation();
  const [errorInfo, setErrorInfo] = useState(null);
  const { setSnackbarProps } = useContext(SnackbarContext);
  const { userSettings } = useContext(UserSettingsContext);
  const { rolesAndPermissions, setRolesAndPermissions } = useContext(
    RolesAndPermissionContext,
  );
  useEffect(() => {
    if (location?.error) {
      const timestamp = new Date().toISOString();
      const errorPayload = {
        message: location.error?.error?.message,
        errorId: generateUUID(),
        timestamp: timestamp.split("T")[0] + " " + timestamp.split("T")[1],
      };
      setErrorInfo(errorPayload);
      errorBoundaryTriggered(errorPayload);
    }
  }, []);

  useEffect(() => {
    if (location.rolesMissing) {
      setSnackbarProps({
        snackbarOpen: true,
        message: "Settings failed to load on page, please try again.",
        severity: "error",
      });
      reloadRoles();
    }
    if (location.permissionsMissing) {
      setSnackbarProps({
        snackbarOpen: true,
        message: "Settings failed to load on page, please try again.",
        severity: "error",
      });
      reloadPermissions();
    }
  }, []);

  const reloadRoles = () => {
    axios
      .get(
        userSettings.gatewayId
          ? `${sassEndpoints.users.userRoles}/${userSettings.gatewayId}`
          : sassEndpoints.users.userRoles,
      )
      .then((res) => {
        setRolesAndPermissions({
          ...rolesAndPermissions,
          roles: res.data.data,
        });
      });
  };
  const reloadPermissions = () => {
    axios
      .get(
        userSettings.gatewayId
          ? `${sassEndpoints.users.userPermissions}/${userSettings.gatewayId}`
          : sassEndpoints.users.userPermissions,
      )
      .then((res) => {
        setRolesAndPermissions({
          ...rolesAndPermissions,
          permissions: res.data.data,
        });
      });
  };

  return (
    <div className="error-container">
      <div className="centered-block">
        <div className="centered-image">
          <img src={empty}></img>
        </div>
        <p>
          The server encountered an error and could <br /> not complete your
          request.
        </p>
        {errorInfo && (
          <div>
            <div className="error-information">
              <p>Correlation ID: {errorInfo?.errorId}</p>
              <p>Timestamp: {errorInfo?.timestamp}</p>
              <p>{CONTACT_SUPPORT}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorContainer;
