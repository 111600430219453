import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
/*
- PDF Generator -
Function expects an element reference from "useRef" along with a title to generate a downloadable PDF.
*/

export const transformToPDF = async (elementRef, title, fixedAmount) => {
  const canvas = fixedAmount
    ? await html2canvas(elementRef.current, {
        width: fixedAmount,
      })
    : await html2canvas(elementRef.current);

  const data = canvas.toDataURL("image/png");

  const pageHeight = 295;

  const pdf = new jsPDF();
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  let heightLeft = pdfHeight;
  let position = 0;
  pdf.addImage(
    data,
    "PNG",
    title.includes("settlement-results") ? 0 : 20,
    0,
    pdfWidth,
    pdfHeight,
  );
  heightLeft -= pageHeight;
  // measure total table height to determine amount of pages for pdf
  // or else it will try to all stick content on one page
  while (heightLeft >= 0) {
    position = heightLeft - pdfHeight;
    pdf.addPage();
    pdf.addImage(
      data,
      "PNG",
      title.includes("settlement-results") ? 0 : 20,
      position,
      pdfWidth,
      pdfHeight,
    );
    heightLeft -= pageHeight;
  }
  pdf.save(title);
};
