import noProcessors from "../../assets/images/no-processors.png";
const NoProcessorError = () => {
  return (
    <div className="error-container">
      <div className="centered-block">
        <div className="centered-image">
          <h1 style={{ color: "#10B2E5" }}>No Processors Defined</h1>
          <img style={{ height: "300px" }} src={noProcessors}></img>
        </div>
        <p>
          A payment cannot be processed at this time because there are no
          processors associated with this gateway.
        </p>
      </div>
    </div>
  );
};

export default NoProcessorError;
