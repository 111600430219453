import { regexPatterns } from "./regexPatterns";
import { validationMessages } from "./strings";

const getMaxLengthRule = (maxLength) => {
  return { value: maxLength, message: `Max length ${maxLength}` };
};

export const validationRules = {
  maxLength3: getMaxLengthRule(3),
  maxLength5: getMaxLengthRule(5),
  maxLength9: getMaxLengthRule(9),
  maxLength10: getMaxLengthRule(10),
  maxLength15: getMaxLengthRule(15),
  maxLength16: getMaxLengthRule(16),
  maxLength19: getMaxLengthRule(19),
  maxLength25: getMaxLengthRule(25),
  maxLength40: getMaxLengthRule(40),
  maxLength50: getMaxLengthRule(50),
  maxLength100: getMaxLengthRule(100),
  maxLength255: getMaxLengthRule(255),
  maxLength320: getMaxLengthRule(320),
  maxLength500: getMaxLengthRule(500),
  alphaSpace: {
    value: regexPatterns.alphaSpace,
    message: validationMessages.alpha_only,
  },
  numericSpace: {
    value: regexPatterns.numericSpace,
    message: validationMessages.numeric_only,
  },
  alphanumericSpace: {
    value: regexPatterns.alphanumericSpace,
    message: validationMessages.alphanumeric_only,
  },
  city: {
    value: regexPatterns.city,
    message: validationMessages.city,
  },
  email: {
    value: regexPatterns.email,
    message: validationMessages.invalid_email,
  },
  multipleEmail: {
    value: regexPatterns.multipleEmail,
    message: validationMessages.invalid_email_or_address,
  },
};
