const isRequired = (rules) => {
  if (!rules) return false;
  for (const [ruleName, ruleValue] of Object.entries(rules)) {
    if (ruleName === "required") {
      return typeof ruleValue === "boolean" ? ruleValue : true;
    }
  }
  return false;
};

export { isRequired };
