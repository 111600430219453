import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { formatPhone } from "../../../utils/stringHelpers";

const DetailAddressTables = ({ addressArray, fieldBoldness }) => {
  const cellStyle = [
    {
      padding: 0.5,
    },
  ];

  return (
    <>
      {/* Address Tables */}
      {addressArray.map((address, index) => {
        return (
          <Grid
            item
            xs={12}
            md={6}
            padding={{ xs: 0, md: 1 }}
            paddingBottom={2}
            key={index}
          >
            <TableContainer>
              <Table
                stickyHeader
                aria-label={address.title + " address table"}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6} sx={{ paddingLeft: 0 }}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        sx={{ fontWeight: fieldBoldness }}
                      >
                        {address.title}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* Don't display empty addresses */}
                {Object.values(address.data).join("") !== "" ? (
                  <TableBody>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={3} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>
                          First Name:
                        </div>
                        {address.data.firstName}
                      </TableCell>
                      <TableCell colSpan={3} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>
                          Last Name:
                        </div>
                        {address.data.lastName}
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={6} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>
                          Company:
                        </div>
                        {address.data.company}
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={3} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>
                          Address 1:
                        </div>
                        {address.data.addressLine1}
                      </TableCell>
                      <TableCell colSpan={3} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>
                          Address 2:
                        </div>
                        {address.data.addressLine2}
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={6} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>
                          Country:
                        </div>
                        {address.data.country}
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={2} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>City:</div>
                        {address.data.city}
                      </TableCell>
                      <TableCell colSpan={2} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>State:</div>
                        {address.data.state}
                      </TableCell>
                      <TableCell colSpan={2} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>
                          Postal Code:
                        </div>
                        {address.data.postalCode}
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={2} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>Email:</div>
                        {address.data.email}
                      </TableCell>
                      <TableCell colSpan={2} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>Phone:</div>
                        {formatPhone(address.data.phone)}
                      </TableCell>
                      <TableCell colSpan={2} sx={cellStyle}>
                        <div style={{ fontWeight: fieldBoldness }}>Fax:</div>
                        {formatPhone(address.data.fax)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={3} sx={cellStyle}>
                        No {address.title} was set
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
        );
      })}
    </>
  );
};

DetailAddressTables.propTypes = {
  addressArray: PropTypes.array.isRequired,
  fieldBoldness: PropTypes.string.isRequired,
};

export default DetailAddressTables;
