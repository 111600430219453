export const endpoints = {
  country: "/const/country",
  state: "/const/country/state",
  lookup_bin_protected: "/lookup/bin/protected",
  merchant: "/merchant",
  transaction_search: "/transaction/search/",
  user: "/user",
  settlement_batch_search: "settlement/batch/search",
  partner_feeschedules: "/partner/{partnerId}/feeschedules",
};

export const basysEndpoints = {
  gateway: `${process.env.REACT_APP_BASYS_API_URL}/gateways`,
  gateway_prodivders: `${process.env.REACT_APP_BASYS_API_URL}/gateway-providers`,
  gateway_native_auth: `${process.env.REACT_APP_BASYS_API_URL}/gateways/{gatewayId}/users/{userId}/native-auth`,
  gateway_provider_gateways: `${process.env.REACT_APP_BASYS_API_URL}/gateway-providers/{gatewayProviderId}/gateways`,
  gateway_provider_gateways_search: `${process.env.REACT_APP_BASYS_API_URL}/gateway-providers/{gatewayProviderId}/gateways/search`,
  gateway_transaction: `${process.env.REACT_APP_BASYS_API_URL}/gateways/{gatewayId}/transaction`,
  roles: `${process.env.REACT_APP_BASYS_API_URL}/roles`,
  users: `${process.env.REACT_APP_BASYS_API_URL}/users`,
  tos: `${process.env.REACT_APP_BASYS_API_URL}/tos`,
  ach_tokenizer: `${process.env.REACT_APP_BASYS_API_URL}/vault/api/v1/Tokenize/Ach`,
};

export const sassEndpoints = {
  gateways: {
    search: `${process.env.REACT_APP_SASS_API_URL}/gateway/search`,
    gateway: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}`,
    configuration: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/configuration`,
    address: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/address`,
    create: `${process.env.REACT_APP_SASS_API_URL}/gateway/{source}`,
    terminal: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/terminal`,
    status: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/status/{status}`,
  },
  customers: {
    customer: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/customer`,
    customerId: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/customer/{customerId}`,
    address: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/customer/{customerId}/address/{customerAddressId}`,
    payment: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/customer/{customerId}/payment/{customerPaymentId}`,
    payableURL: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/customer/{customerId}/payableToken`,
  },
  users: {
    user: `${process.env.REACT_APP_SASS_API_URL}/user`,
    viewUser: `${process.env.REACT_APP_SASS_API_URL}/user/search`,
    userRoles: `${process.env.REACT_APP_SASS_API_URL}/admin/role`,
    userPermissions: `${process.env.REACT_APP_SASS_API_URL}/admin/permission`,
    signinHistory: `${process.env.REACT_APP_SASS_API_URL}/user/{userId}/signinhistory`,
    resetMfa: `${process.env.REACT_APP_SASS_API_URL}/user/{userId}/totp`,
  },
  gatewayUsers: {
    validate: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/user/validate`,
    user: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/user`,
    configuration: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/configuration`,
    savedSearch: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/user/{gatewayUserId}/savedsearch`,
  },
  transactions: {
    receipt: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/{transactionId}/receipt`,
    emailReceipt: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/{transactionId}/receipt/email`,
    search: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/search`,
    byId: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/{transactionId}`,
    transaction: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction`,
    refund: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/{transactionId}/refund`,
    capture: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/{transactionId}/capture`,
    void: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/{transactionId}/void`,
    vaultCustomer: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/{transactionId}/customer`,
    calculateFees: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/calculatefees`,
    searchCSV: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/searchcsv`,
    incrementAuth: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/transaction/{transactionId}/IncrementAuthorization`,
  },
  customFields: {
    category: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/customfieldcategory`,
    customField: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/customfield`,
    options: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/customField/{customFieldId}/options`,
  },
  processors: {
    processor: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/processor`,
    processorId: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/processor/{processorId}`,
    sourceProcessorId: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/processor/00000000-0000-0000-0000-000000000000?sourceprocessorid={sourceProcessorId}`,
  },
  invoices: {
    unitsOfMeasure: `${process.env.REACT_APP_SASS_API_URL}/constants/units`,
    calculateFees: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/invoice/calculatefees`,
    invoice: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/invoice`,
    search: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/invoice/search`,
    pdf: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/invoice/{invoiceId}/pdf`,
    delete: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/invoice/{invoiceId}`,
  },
  subscriptions: {
    subscription: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/subscription`,
    subscriptionId: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/subscription/{subscriptionId}`,
    pause: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/subscription/{subscriptionId}/pause`,
    resume: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/subscription/{subscriptionId}/resume`,
    cancel: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/subscription/{subscriptionId}/cancel`,
    search: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/subscription/search`,
    billingPeriods: `${process.env.REACT_APP_SASS_API_URL}/constants/billingPeriod`,
  },
  settlement: {
    search: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/settlement/search`,
    searchCSV: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/settlement/searchcsv`,
  },
  developerHub: {
    application: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/appregistration`,
    appId: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/appregistration/{appRegistrationId}`,
    appSecret: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/appregistration/{appRegistrationId}/appsecret`,
  },
  terminal: {
    terminals: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/terminal`,
    transaction: `${process.env.REACT_APP_BASYS_API_URL}/iqsaas/v1/api/gateway/{gatewayId}/terminal/{terminalId}/command/payment-intent`,
    terminalId: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/processor/{processorId}/terminal/{terminalId}`,
    postTerminal: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/processor/{processorId}/terminal`,
    terminalType: `${process.env.REACT_APP_SASS_API_URL}/terminaltype`,
    restart: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/processor/{processorId}/terminal/{terminalId}/command/restart`,
    updateSoftware: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/Processor/{processorId}/terminal/{terminalId}/software`,
    activateTerminal: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/Processor/{processorId}/terminal/{terminalId}/status/active`,
    updateTerminal: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/Processor/{processorId}/terminal/{terminalId}`,
    reportStolen: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/processor/{processorId}/terminal/{terminalId}/command/reportstolen`,
    reportRecovered: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/processor/{processorId}/terminal/{terminalId}/command/reportrecovered`,
    updateConfigs: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/terminal/updateconfigs`,
  },
  simplePay: {
    search: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/simplepay/search`,
    simplePay: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/simplepay/{simplePayId}`,
    metadata: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/simplepay/metadata`,
    create: `${process.env.REACT_APP_SASS_API_URL}/gateway/{gatewayId}/simplepay`,
  },
  tokenizerConfig: `${process.env.REACT_APP_SASS_API_URL}/v1/gateway/{gatewayId}/tokenization/configuration`,
  migrationQueue: `${process.env.REACT_APP_MIGRATION_URL}/iqpromigration/queue`,
  migrationStatus: `${process.env.REACT_APP_MIGRATION_URL}/GatewayMigrationStatus`,
  signalRHub: `${process.env.REACT_APP_BASYS_API_URL}/iqpro-signalr?gatewayId={gatewayId}`,
};
