import { useState, useEffect } from "react";
import Layout from "./Layout";
import useAlertDialog from "../../hooks/useAlertDialog";
import AlertDialog from "../Dialog/AlertDialog";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { CountriesContext } from "../../contexts/CountriesContext";
import { CONTACT_SUPPORT } from "../../constants/global";
import { basysEndpoints, sassEndpoints } from "../../constants/endpoints";
import { setInitialUserProp } from "../../services/mixpanel/merchant/userProperties";
import LayoutSkeleton from "./LayoutSkeleton";
import MerchantSwitch from "../Routes/MerchantSwitch";
import PartnerSwitch from "../Routes/PartnerSwitch";
import { setGatewayProviderGroupProp } from "../../services/mixpanel/merchant/merchantProperties";
import { logout } from "../../utils/logout";
import axios from "axios";
import { useMsal, useAccount } from "@azure/msal-react";
import useMSALInterceptor from "../../hooks/useMSALInterceptor";
import { getAccessToken } from "../../utils/msalHelpers";
import jwt_decode from "jwt-decode";
import GatewaySelectorModal from "./Modals/GatewaySelectionModal";
import AcceptTosModal from "./Modals/AcceptTosModal";
import { ErrorBoundary } from "react-error-boundary";
import { RolesAndPermissionContext } from "../../contexts/RolesAndPermissionContext";
import { GatewayContext } from "../../contexts/GatewayContext";
import { GatewayConfigContext } from "../../contexts/GatewayConfigContext";
import { TokenizerConfigContext } from "../../contexts/TokenizerConfigContext";
import { stringFormat } from "../../utils/stringHelpers";
import { Country } from "country-state-city";
import { USER_ROLES } from "../../constants/global";

const LayoutContainer = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const { isAlertOpen, alertMessages, displayMessages } = useAlertDialog();
  const [userSettings, setUserSettings] = useState(null);
  const [gatewaySettings, setGatewaySettings] = useState(null);
  const [tokenSettings, setTokenSettings] = useState(null);
  const [gatewayConfigSettings, setGatewayConfigSettings] = useState(null);
  const [userInfo, setUserInfo] = useState({ name: "", role: "" });
  const [loadUser, setLoadUser] = useState(false);
  const [rolesAndPermissions, setRolesAndPermissions] = useState(null);
  const [countries, setCountries] = useState(null);

  const [gatewayModalProps, setGatewayModalProps] = useState(null);
  const [tosModalProps, setTosModalProps] = useState(null);

  useMSALInterceptor();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(async () => {
    const token = await getAccessToken(instance, account);
    setCountries(
      Country.getAllCountries().map((c) => {
        c.code = c.isoCode;
        return c;
      }),
    );
    if (token === null) {
      displayMessages(["Authentication error.", CONTACT_SUPPORT]);
    } else {
      const jwt = jwt_decode(token);
      setInitialUserProp(
        jwt?.name,
        jwt?.emails?.[0],
        jwt?.extension_RoleCode,
        jwt?.extension_ImpersonatorUserDisplayName
          ? jwt?.extension_ImpersonatorUserDisplayName
          : null,
      );
      setUserInfo({
        name: jwt?.name,
        role: jwt?.extension_RoleCode,
        ...(jwt?.extension_SystemUserId && {
          extension_SystemUserId: jwt?.extension_SystemUserId,
        }),
        ...(jwt?.extension_ImpersonatorUserDisplayName && {
          impersonator: jwt?.extension_ImpersonatorUserDisplayName,
        }),
      });
      setLoadUser(true);
    }
  }, []);

  useEffect(() => {
    if (loadUser) {
      if (
        userInfo?.role === USER_ROLES.GATEWAY_ADMIN ||
        userInfo?.role === USER_ROLES.GATEWAY_STANDARD
      ) {
        axios
          .get(`${basysEndpoints.users}/${userInfo.extension_SystemUserId}`)
          .then((res) => {
            //refactor when we get on user log in
            if (res.data.data.status === "pending_tos") {
              setTosModalProps({ isOpen: true });
            } else {
              setupGatewayUser();
            }
          })
          .catch(() => {
            displayMessages(["User doesnt exist", CONTACT_SUPPORT]);
          });
      } else {
        setBasysUserSettings(userInfo?.extension_SystemUserId);
      }
    }
  }, [loadUser]);

  const getRolesAndPermissions = async (gatewayId, basysRes) => {
    const roles = await getRoles(gatewayId);
    const permissions = await getPermissions(gatewayId);
    if (gatewayId) {
      const gatewayInfo = await getGatewayInfo(gatewayId);
      setGatewaySettings(gatewayInfo);
      setGatewayProviderGroupProp(gatewayInfo, basysRes);
      const tokenConfig = await getGatewayTokenConfig(gatewayId);
      setTokenSettings(tokenConfig);
      const gatewayConfig = await getGatewayConfig(gatewayId);
      setGatewayConfigSettings(gatewayConfig);
    }
    setRolesAndPermissions({ roles: roles, permissions: permissions });
  };

  const getRoles = (gatewayId) => {
    return axios
      .get(
        gatewayId
          ? `${sassEndpoints.users.userRoles}/${gatewayId}`
          : sassEndpoints.users.userRoles,
      )
      .then((roles) => {
        return roles.data.data;
      })
      .catch(() => {
        return null;
      });
  };

  const getPermissions = (gatewayId) => {
    return axios
      .get(
        gatewayId
          ? `${sassEndpoints.users.userPermissions}/${gatewayId}`
          : sassEndpoints.users.userPermissions,
      )
      .then((permissions) => {
        return permissions.data.data;
      })
      .catch(() => {
        return null;
      });
  };

  const getGatewayInfo = (gatewayId) => {
    return axios
      .get(stringFormat(sassEndpoints.gateways.gateway, [gatewayId]))
      .then((res) => res.data.data)
      .catch(() => null);
  };

  const getGatewayTokenConfig = (gatewayId) => {
    return axios
      .get(stringFormat(sassEndpoints.tokenizerConfig, [gatewayId]))
      .then((res) => {
        return res.data.data;
      })
      .catch(() => {
        return null;
      });
  };

  const getGatewayConfig = async (gatewayId) => {
    return axios
      .get(stringFormat(sassEndpoints.gateways.configuration, [gatewayId]))
      .then((res) => {
        return res.data.data;
      })
      .catch(() => {
        return null;
      });
  };
  const setupGatewayUser = async () => {
    const url = `${process.env.REACT_APP_SASS_API_URL}/gateway`;
    let gateways;
    axios
      .get(url)
      .then(async (resp) => {
        gateways = resp?.data?.data?.gateways?.filter((gatewayObj) => {
          return gatewayObj?.gateway?.status?.toLowerCase() !== "disabled";
        });

        if (gateways.length > 1) {
          if (window?.sessionStorage?.getItem("gateway")) {
            loadGatewayDetails(window?.sessionStorage?.getItem("gateway"));
          } else {
            setGatewayModalProps({ isOpen: true, gateways: gateways });
          }
        } else if (gateways.length === 1) {
          loadGatewayDetails(gateways[0]?.gateway?.gatewayId);
        } else {
          displayMessages(["No gateways assigned to user", CONTACT_SUPPORT]);
        }
      })
      .catch(() => {
        displayMessages(["Invalid authorization settings.", CONTACT_SUPPORT]);
      });
  };

  const loadGatewayDetails = async (id) => {
    const gateway_url = `${basysEndpoints.gateway}/${id}`;
    axios
      .get(gateway_url)
      .then((gatewayResponse) => {
        const publicApiKey =
          gatewayResponse?.data?.data?.gateway_provider_gateway?.gateway_apikey;

        setGatewayUserSettings(publicApiKey, id, gatewayResponse?.data?.data);
      })
      .catch(() => {
        displayMessages(["Failed to load user settings.", CONTACT_SUPPORT]);
      });
  };
  const setGatewayUserSettings = async (publicKey, gatewayId, basysRes) => {
    let sassUrl =
      stringFormat(sassEndpoints.gatewayUsers.user, [gatewayId]) +
      "/" +
      userInfo.extension_SystemUserId;
    axios
      .get(sassUrl)
      .then((sassUserResponse) => {
        const saasRole = sassUserResponse?.data?.data?.role?.code;
        setUserInfo({
          ...userInfo,
          role: saasRole,
        });
        if (userInfo?.impersonator) {
          sassUserResponse.data.data.impersonator = userInfo?.impersonator;
        }
        let permissionsObject =
          sassUserResponse.data.data.gatewayUserPermissions.reduce(function (
            perms,
            p,
          ) {
            perms[p.code] = true;
            return perms;
          }, {});
        sassUserResponse.data.data.gatewayUserPermissions = permissionsObject;
        Reflect.set(sassUserResponse.data.data, "publicKey", publicKey);
        Reflect.set(sassUserResponse.data.data, "gatewayId", gatewayId);
        getRolesAndPermissions(gatewayId, basysRes).finally(() => {
          setUserSettings(sassUserResponse.data.data);
        });
      })
      .catch(() => {
        displayMessages(["Failed to load user settings.", CONTACT_SUPPORT]);
      });
  };
  const setBasysUserSettings = (userId) => {
    let payload = {
      userId: {
        operator: "Equal", // Guid => Operators = Equal
        value: userId,
      },
      includeUserPermissions: true,
      includeUserRole: true,
    };
    axios
      .post(`${sassEndpoints.users.viewUser}`, payload)
      .then((res) => {
        if (res.status === 204) {
          displayMessages(["Failed to load user settings.", CONTACT_SUPPORT]);
        } else {
          getRolesAndPermissions().finally(() => {
            let userResp = res?.data?.data?.results[0];
            setUserSettings(userResp);
          });
        }
      })
      .catch(() => {
        displayMessages(["Failed to load user settings.", CONTACT_SUPPORT]);
      });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleStyle = () => {
    setActive(!isActive);
  };

  const handleModalSelection = (gatewayObj) => {
    loadGatewayDetails(gatewayObj?.gateway?.gatewayId);
  };

  const handleAcceptance = () => {
    setupGatewayUser();
  };

  const handleLogout = () => logout(instance);

  return (
    <UserSettingsContext.Provider value={{ userSettings, setUserSettings }}>
      <TokenizerConfigContext.Provider value={tokenSettings}>
        <GatewayConfigContext.Provider
          value={{ gatewayConfigSettings, setGatewayConfigSettings }}
        >
          <GatewayContext.Provider value={gatewaySettings}>
            <RolesAndPermissionContext.Provider
              value={{ rolesAndPermissions, setRolesAndPermissions }}
            >
              <AlertDialog
                alertTitle="Error"
                alertMessages={alertMessages}
                alertLevel="error"
                alertOpen={isAlertOpen}
                onCloseButtonClick={handleLogout}
              />
              <CountriesContext.Provider value={countries}>
                {userSettings === null ? (
                  <LayoutSkeleton />
                ) : (
                  <Layout
                    mobileOpen={mobileOpen}
                    handleLogout={handleLogout}
                    handleDrawerToggle={handleDrawerToggle}
                    toggleStyle={toggleStyle}
                    isActive={isActive}
                    userName={userInfo.name}
                    userRole={userInfo.role}
                  >
                    <ErrorBoundary
                      FallbackComponent={
                        userInfo.role === USER_ROLES.GATEWAY_ADMIN ||
                        userInfo.role === USER_ROLES.GATEWAY_STANDARD
                          ? MerchantSwitch
                          : PartnerSwitch
                      }
                    >
                      {userInfo.role === USER_ROLES.GATEWAY_ADMIN ||
                      userInfo.role === USER_ROLES.GATEWAY_STANDARD ? (
                        <MerchantSwitch />
                      ) : (
                        <PartnerSwitch />
                      )}
                    </ErrorBoundary>
                  </Layout>
                )}
              </CountriesContext.Provider>
              {gatewayModalProps?.gateways?.length > 1 && (
                <GatewaySelectorModal
                  onOpen={gatewayModalProps?.isOpen}
                  onClose={() => {
                    setGatewayModalProps({ isOpen: false });
                  }}
                  gateways={gatewayModalProps.gateways}
                  loadGatewayDetails={(id) => handleModalSelection(id)}
                />
              )}
              {tosModalProps && (
                <AcceptTosModal
                  onOpen={tosModalProps.isOpen}
                  gateway={tosModalProps.gateway}
                  onClose={(gateway) => {
                    setTosModalProps({ isOpen: false });
                    handleAcceptance(gateway);
                  }}
                />
              )}
            </RolesAndPermissionContext.Provider>
          </GatewayContext.Provider>
        </GatewayConfigContext.Provider>
      </TokenizerConfigContext.Provider>
    </UserSettingsContext.Provider>
  );
};

export default LayoutContainer;
