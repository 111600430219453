import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import SearchTable from "../../../Search/SearchTable";
import { payTableHeaders } from "./cvCustomerTableHelpers";
import Button from "@mui/material/Button";
import MUISwitch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import StyledTabs from "../../../Tabs/StyledTabs";
import { useTokenizer } from "../../../../hooks/useTokenizer";
import { PAYMENT_TYPE } from "../../VirtualTerminal/PaymentTabs";
import AchIcon from "@mui/icons-material/AccountBalance";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import card from "./credit-card-refresh-outline.svg";
import ACHTokenizerComponent from "../../../Tokenizer/ACHTokenizerCmp";
import TokenExComponent from "../../../Tokenizer/TokenExCmp";
import { TokenizerConfigContext } from "../../../../contexts/TokenizerConfigContext";
import { iqProVer } from "../../../../constants/global";

export const paymentTabDetails = [
  {
    props: { label: "CARD", icon: <CreditCardIcon /> },
    paymentOption: "card",
  },
  {
    props: { label: "ACH", icon: <AccountBalanceOutlinedIcon /> },
    paymentOption: "ach",
  },
];

const paymentStates = {
  CREATE: "create",
  UPDATE: "update",
  VIEW: "view",
};

const CustomerPayment = ({
  customerDetails,
  requiredDefaultFields,
  tokenizerKey,
  createPayment,
  deletePayment,
  editPayment,
  defaultPayment,
  permissions,
  showAlertDialogError,
  achToken,
  setAchToken,
  clearTokenizer,
}) => {
  const [rowData, setRowData] = useState(null);
  const [paymentState, setPaymentState] = useState(paymentStates.VIEW);
  const [activeTab, setActiveTab] = useState();
  const [currentPayment, setCurrentPayment] = useState();
  const [token, setActiveToken] = useState();
  const [isDefault, setDefault] = useState(false);
  const form = useForm();
  const tokenizerSettings = useContext(TokenizerConfigContext);
  const [achSubmit, setAchSubmit] = useState(false);
  const [clearForm, setClearForm] = useState(false);

  const tokenizer = useRef();

  useEffect(() => {
    if (achToken) {
      setAchSubmit(false);
      handleTokenizerSubmission({ status: "success", token: achToken });
    }
  }, [achToken]);

  useEffect(() => {
    if (paymentState !== paymentStates.VIEW) {
      paymentState === paymentStates.CREATE
        ? createPayment(token, isDefault, activeTab)
        : editPayment(
            currentPayment.id,
            token,
            activeTab,
            isDefault ? currentPayment.payment_method : null,
          );
    }
  }, [token]);

  useEffect(() => {
    setPaymentState(paymentStates.VIEW);
    let data = [];
    customerDetails.paymentMethods.forEach((method) => {
      if (method.card) {
        const rowData = {
          payment_method: "card",
          masked_number: method?.card?.maskedCard,
          expiration_date: method?.card.expirationDate,
          isDefault: method.isDefault,
          id: method.paymentMethodId,
        };
        data.push(rowData);
      }
      if (method.ach) {
        const rowData = {
          payment_method: "ach",
          masked_account_number: method?.ach?.maskedAccount,
          account_type: method?.ach.accountType,
          isDefault: method.isDefault,
          id: method.paymentMethodId,
        };
        data.push(rowData);
      }
    });

    setDefault(false);
    setRowData(data);
  }, [customerDetails]);

  const setupTabs = () => {
    let customerPanels = [];
    customerPanels.push({
      props: paymentTabDetails[0].props,
      value: "card",
      panel:
        tokenizerSettings.cardTokenizer === iqProVer.v1 ? (
          <div className="tokenizer-form" id="tokenizer-card"></div>
        ) : tokenizerSettings.cardTokenizer === iqProVer.v2 ? (
          <div style={{ maxWidth: "60%", marginBottom: "40px" }}>
            <TokenExComponent
              tokenizerRef={tokenizer}
              handleSubmit={handleSaasToken}
              tokenConfig={tokenizerSettings?.iframeConfiguration}
              cvvState={"hidden"}
              clearTokenizer={clearTokenizer}
            />
          </div>
        ) : (
          <></>
        ),
    });
    customerPanels.push({
      props: paymentTabDetails[1].props,
      value: "ach",
      panel:
        tokenizerSettings.achTokenizer === iqProVer.v1 ? (
          <div id="tokenizer-ach"></div>
        ) : tokenizerSettings.achTokenizer === iqProVer.v2 ? (
          <div style={{ maxWidth: "60%", marginBottom: "40px" }}>
            {activeTab?.activeTab === "ach" && (
              <ACHTokenizerComponent
                clearForm={clearForm}
                achSubmit={achSubmit}
                achToken={achToken}
                setAchToken={setAchToken}
              />
            )}
          </div>
        ) : (
          <></>
        ),
    });
    return customerPanels;
  };
  const handleTokenizerSubmission = (response) => {
    if (response.status === "success") {
      setActiveToken(response.token);
    } else {
      showAlertDialogError(response.msg || "Invalid payment information.");
    }
  };

  const cardTokenizer =
    requiredDefaultFields.payment_method_card !== "hidden" &&
    tokenizerSettings.cardTokenizer !== iqProVer.v2
      ? useTokenizer({
          paymentType: PAYMENT_TYPE.CARD,
          apikey: tokenizerKey,
          onSubmit: handleTokenizerSubmission,
          container: "#tokenizer-card",
          showCvv: false,
        })
      : null;

  const achTokenizer =
    requiredDefaultFields.payment_method_ach !== "hidden" &&
    tokenizerSettings.achTokenizer !== iqProVer.v2
      ? useTokenizer({
          paymentType: PAYMENT_TYPE.ACH,
          apikey: tokenizerKey,
          onSubmit: handleTokenizerSubmission,
          container: "#tokenizer-ach",
        })
      : null;

  const handleChange = (tabValue) => {
    const obj = {
      activeTab: tabValue === 0 ? "card" : "ach",
      tabValue: tabValue,
    };
    setActiveTab(obj);
  };

  const handleSubmit = () => {
    if (activeTab.activeTab === "card") {
      if (tokenizerSettings.achTokenizer === iqProVer.v1) {
        cardTokenizer.submit();
      } else {
        tokenizer?.current?.validate();
      }
    } else {
      if (tokenizerSettings.achTokenizer === iqProVer.v1) {
        achTokenizer.submit();
      } else {
        setAchSubmit(true);
      }
    }
  };

  const handleSaasToken = (data, exp) => {
    setActiveToken({
      token: data.token,
      expirationDate: exp,
      maskedCard: data.firstSix + "******" + data.lastFour,
    });
  };

  const getTabState = (paymentMethod) => {
    if (
      requiredDefaultFields.payment_method_card === "hidden" ||
      requiredDefaultFields.payment_method_ach === "hidden"
    ) {
      return {
        tabValue: 0,
        disabled: paymentMethod === "card" ? ["ach"] : ["card"],
      };
    } else {
      return {
        tabValue: paymentMethod === "card" ? 0 : 1,
        disabled: paymentMethod === "card" ? ["ach"] : ["card"],
      };
    }
  };

  const hasActiveSubscription = (row) =>
    customerDetails.subscriptions?.some(
      (s) => s.paymentMethod?.customerPaymentMethod?.paymentMethodId === row.id,
    );

  const getActionsComponent = (row) => {
    return (
      <ButtonGroup>
        {permissions["VAULT_UPDATE"] && (
          <Tooltip
            title={
              hasActiveSubscription(row)
                ? "This payment method cannot be edited because it is used in an active or pending subscription."
                : "Replace payment information"
            }
          >
            <span>
              <IconButton
                variant="container"
                sx={{ padding: 0.5 }}
                onClick={() => {
                  setClearForm(true);
                  setCurrentPayment(row);
                  setActiveTab({
                    activeTab: row.payment_method,
                    tabValue: getTabState(row.payment_method).tabValue,
                    disabled: getTabState(row.payment_method).disabled,
                  });
                  setDefault(row.isDefault);
                  setPaymentState(paymentStates.UPDATE);
                }}
                color={"warning"}
                fontSize="small"
                disabled={hasActiveSubscription(row)}
              >
                <img style={{ color: "#1C7BC0" }} src={card} />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {permissions["VAULT_DELETE"] && (
          <Tooltip
            title={
              hasActiveSubscription(row)
                ? "This payment method cannot be edited because it is used in an active or pending subscription."
                : ""
            }
          >
            <span>
              <IconButton
                title={"Delete"}
                variant="container"
                sx={{ padding: 0.5 }}
                onClick={() => deletePayment(row.id)}
                color={"error"}
                fontSize="small"
                disabled={row.isDefault || hasActiveSubscription(row)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </ButtonGroup>
    );
  };

  const setDefaultPayment = (event) => {
    const id = event.target.id;
    const cardType = rowData.find((data) => {
      return data.id === id;
    });
    defaultPayment(id, cardType.payment_method);
  };

  const getPayTableValue = (key, data) => {
    switch (key) {
      case "default": {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Off</Typography>
            <FormControl>
              <Controller
                render={() => (
                  <Tooltip
                    title={
                      hasActiveSubscription(data)
                        ? "This payment method cannot be edited because it is used in an active or pending subscription."
                        : ""
                    }
                  >
                    <span>
                      <MUISwitch
                        id={data.id}
                        color="primary"
                        checked={data.isDefault}
                        onChange={setDefaultPayment}
                        disabled={
                          data.isDefault ||
                          hasActiveSubscription(data) ||
                          !permissions["VAULT_UPDATE"]
                        }
                      />
                    </span>
                  </Tooltip>
                )}
                name="default_table"
                control={form.control}
              />
            </FormControl>
            <Typography>On</Typography>
          </Stack>
        );
      }
      case "payment_method": {
        if (data.payment_method === "card") {
          return (
            <div className="payment-cell">
              <CreditCardIcon />
              <div>
                <p>{data.masked_number}</p>
                <p> {data.expiration_date}</p>
              </div>
            </div>
          );
        } else {
          return (
            <div className="payment-cell">
              <AchIcon />
              <div>
                <p> {data.masked_account_number}</p>

                <p>Type: {data.account_type}</p>
              </div>
            </div>
          );
        }
      }

      default:
        return data[key];
    }
  };

  return (
    <div className="payment-body">
      <div
        className={
          "screen-wrapper" +
          (paymentState !== paymentStates.VIEW ? " new-panel" : "")
        }
      >
        <div className="origin-panel">
          {permissions["VAULT_CREATE"] && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveTab({
                  activeTab:
                    requiredDefaultFields.payment_method_card !== "hidden"
                      ? "card"
                      : "ach",
                  tabValue: 0,
                });
                setDefault(false);
                setPaymentState(paymentStates.CREATE);
              }}
              data-cy="create-payment-method-button"
            >
              Create a Payment Method
            </Button>
          )}

          <div className="sticky-table">
            <SearchTable
              searchResults={rowData?.length > 0 ? rowData : null}
              getEntityKeyValue={getPayTableValue}
              fieldList={payTableHeaders}
              getActionsComponent={getActionsComponent}
            />
          </div>
        </div>
        <div className="panel-details">
          <h3>Payment Information</h3>
          <div className="default-toggle">
            <span>Set Default Payment</span>{" "}
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Off</Typography>
              <FormControl>
                <Controller
                  render={() => (
                    <MUISwitch
                      color="primary"
                      checked={isDefault}
                      onChange={() => setDefault(!isDefault)}
                      disabled={currentPayment?.isDefault || false}
                    />
                  )}
                  name="default_form"
                  control={form.control}
                />
              </FormControl>
              <Typography>On</Typography>
            </Stack>
          </div>

          <StyledTabs
            hidePanels={true}
            label="payment"
            defaultTabIndex={0}
            updateValue={activeTab?.tabValue}
            onChange={handleChange}
            tabs={setupTabs()}
          />

          <div className="button-group">
            <Button
              size="small"
              variant="contained"
              color="neutrals"
              sx={{ marginRight: "10px" }}
              onClick={() => {
                setClearForm(false);
                setPaymentState(paymentStates.VIEW);
                setActiveTab();
                setCurrentPayment(null);
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                handleSubmit();
                setClearForm(false);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
CustomerPayment.propTypes = {
  customerDetails: PropTypes.object,
  requiredDefaultFields: PropTypes.object,
  tokenizerKey: PropTypes.string,
  isAlertOpen: PropTypes.bool,
  alertMessages: PropTypes.string,
  createPayment: PropTypes.func,
  deletePayment: PropTypes.func,
  editPayment: PropTypes.func,
  defaultPayment: PropTypes.func,
  permissions: PropTypes.object,
  showAlertDialogError: PropTypes.func,
  achToken: PropTypes.string,
  setAchToken: PropTypes.func,
  clearTokenizer: PropTypes.bool,
};
export default CustomerPayment;
