import mixpanel from "mixpanel-browser";

/***** User Properties *****/

// called when a user logs in with a user name and password and gets a 200 response
export function setInitialUserProp(name, email, userRole, impersonator) {
  if (impersonator) {
    mixpanel.register({
      impersonated: true,
      impersonatorName: impersonator,
    });
  } else {
    mixpanel.register({
      impersonated: false,
    });
    mixpanel.unregister("impersonatorName");
  }

  mixpanel.identify(email);

  mixpanel.people.set({
    $name: name,
    $email: email,
    user_role: userRole,
  });

  // start the timer on tracking how long the session is, shown as $duration in user profile
  mixpanel.track("User session time");
}

// reusable called when the router changes and user navigates to new page
export function pageViewCountIncrement(pageName) {
  mixpanel.people.increment(`${pageName} page view count`, 1);
}

// called when the user logs out and session $duration ends
export function trackUserSessionLengthEvent() {
  mixpanel.time_event("User session time");
}
