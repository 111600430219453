import { useState, useContext, useEffect } from "react";
import axios from "axios";
import CustomerSearchResults from "./CustomerSearchResults";
import ContentComponent from "../../Content/Content";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { sassEndpoints } from "../../../constants/endpoints";
import SearchManager from "../../Search/SearchManager";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../constants/routes";
import { customerSearchFields } from "./customerHelpers";
import { customerSearchEvent } from "../../../services/mixpanel/merchant/customerVaultEvents";
import { stringFormat } from "../../../utils/stringHelpers";
import { deepCopyFunction } from "../../../utils/objectHelpers";
const CustomerSearchContainer = () => {
  const searchFields = deepCopyFunction(customerSearchFields);
  const { ...methods } = useForm();
  const fieldBoldness = "600";
  const { userSettings } = useContext(UserSettingsContext);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [customerSearchResults, setCustomerSearchResults] = useState(null);

  const [searchPayload, setSearchPayload] = useState(null);
  //// eslint-disable-next-line no-unused-vars
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});

  const history = useHistory();

  const handleChangeAlertDialogProps = (props, alertOpen) => {
    if (alertOpen === false) {
      setAlertDialogOpen(false);
    } else {
      setAlertDialogProps(props);
      setAlertDialogOpen(true);
    }
  };

  useEffect(() => {
    if (searchPayload !== null) {
      loadCustomerSearchResults();
    }
  }, [searchPayload, resultOffset, resultsPerPage]);

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultOffset(pageIndex * resultsPerPage);
  };

  const handleResultsPerPageOnChange = (event) => {
    setResultOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };

  const cleanPayload = (payload) => {
    payload.limit = resultsPerPage;
    payload.offset = resultOffset;
    payload.includeDefaultAddresses = true;
    payload.includeDefaultPayment = true;
    if (payload.search_in_everything) {
      payload.globalSearch = payload?.search_in_everything?.value;
      delete payload.search_in_everything;
      return;
    }
    if (payload.createdDate) {
      payload.createdDate.operator = "Between";
    }
    if (payload.updatedDate) {
      payload.updatedDate.operator = "Between";
    }
  };

  const loadCustomerSearchResults = async () => {
    let payload = searchPayload;
    if (JSON.stringify(searchPayload) !== "{}") {
      customerSearchEvent(payload);
    }

    let url = stringFormat(sassEndpoints.customers.customer, [
      userSettings.gatewayId,
    ]);
    cleanPayload(payload);
    setShowBackdrop(true);

    await axios
      .post(url + "/Search", payload)
      .then(function (response) {
        setCustomerSearchResults(response?.data?.data?.results || []);
        setResultsTotal(response?.data?.data?.rowCount || 0);
      })
      .catch(function (error) {
        showAlertDialogError(
          error?.response?.data?.statusDetails ||
            "Failed to search on these parameters.",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const goToCreate = () => {
    history.push(merchantRoutes.customer.create);
  };

  const searchCustomer = (form) => {
    setResultOffset(0);
    setSearchPayload(form);
  };

  return (
    <FormProvider {...methods}>
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="Customers"
        headerContent={
          <SearchManager
            tableFormatAllowed={false}
            sectionName={"Customer"}
            searchButtonOnClick={searchCustomer}
            showCreateButton={
              userSettings?.gatewayUserPermissions["VAULT_CREATE"]
            }
            createButtonText="Create Customer"
            createButtonOnClick={() => goToCreate()}
            showSnackBar={showSnackBar}
            showAlertDialogError={showAlertDialogError}
            entitySearchFilterFields={searchFields}
          />
        }
        bodyContent={
          <div className="customers-wrapper">
            <CustomerSearchResults
              fieldBoldness={fieldBoldness}
              customerSearchResults={customerSearchResults}
              userSettings={userSettings}
              handleChangeAlertDialogProps={handleChangeAlertDialogProps}
              loadCustomerSearchResults={loadCustomerSearchResults}
              showSnackBar={showSnackBar}
              showAlertDialogError={showAlertDialogError}
              resultsPerPage={resultsPerPage}
              resultsTotal={resultsTotal}
              handleChange={handlePageChange}
              handleResultsPerPageOnChange={handleResultsPerPageOnChange}
            />
          </div>
        }
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
      />
    </FormProvider>
  );
};

export default CustomerSearchContainer;
