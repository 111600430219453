import React from "react";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tooltip from "@mui/material/Tooltip";
import SearchTable from "../../../Search/SearchTable";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  processorListColumnHeaders,
  processorListKeyValue,
} from "./gatewayProcessorsTableHelpers";
function GatewayProcessorList({
  processors,
  deleteProcessor,
  retrieveProcessor,
}) {
  const getActionsComponent = (data) => {
    return (
      <ButtonGroup>
        <Tooltip title="View">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => retrieveProcessor(data, false)}
            color={"primary"}
            fontSize="small"
            data-cy="view-gateway-user"
          >
            <VisibilityOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => retrieveProcessor(data, true)}
            color={"warning"}
            fontSize="small"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => deleteProcessor(data.processorId)}
            color={"error"}
            fontSize="small"
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    );
  };
  return (
    <div>
      <SearchTable
        searchResults={processors?.length > 0 ? processors : null}
        getEntityKeyValue={processorListKeyValue}
        fieldList={processorListColumnHeaders}
        getActionsComponent={getActionsComponent}
      />
    </div>
  );
}
GatewayProcessorList.propTypes = {
  processors: PropTypes.array,
  deleteProcessor: PropTypes.func,
  retrieveProcessor: PropTypes.func,
};

export default GatewayProcessorList;
