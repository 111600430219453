import React, { useState, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import GatewayProcessorDetails from "./GatewayProcessorDetails";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import GatewayProcessorsSimple from "./GatewayProcessorsSimple";
import GatewayProcessorsSierra from "./GatewayProcessorsSierra";
import GatewayProcessorsSage from "./GatewayProcessorsSage";
import GatewayProcessorsFiserv from "./GatewayProcessorsFiserv";
import GatewayProcessorsPaya from "./GatewayProcessorsPaya";
// import GatewayProcessorsHC from "./GatewayProcessorsHC";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {
  PROCESSOR_CONFIGS,
  getKeyByValue,
  simpleTemplates,
} from "./gatewayProcessorsTableHelpers";

function GatewayProcessor({
  goBack,
  createProcessor,
  editProcessor,
  gatewayInfo,
  selectedProcessor,
  canEdit,
  showError,
  configTypes,
}) {
  const { ...methods } = useForm();
  const [config, setConfig] = useState(
    selectedProcessor
      ? configTypes.find((t) => {
          return (
            selectedProcessor?.processorType?.processorTypeId ===
            t?.processorTypeId
          );
        })
      : null,
  );
  const sageRef = useRef();
  const payaRef = useRef();
  const [isEdit, setIsEdit] = useState(selectedProcessor ? canEdit : true);
  const settings =
    selectedProcessor?.settings?.[
      getKeyByValue(PROCESSOR_CONFIGS, config?.name)
    ] || null;

  const onSubmit = () => {
    let payload = methods.getValues();
    if (
      payload.paymentAdjustmentType === "NoAdj" ||
      payload.paymentAdjustmentValue === ""
    ) {
      payload.paymentAdjustmentValue = 0;
    } else if (payload.paymentAdjustmentValue !== "") {
      payload.paymentAdjustmentValue = convertPaymentValue(
        payload.paymentAdjustmentType,
        payload.paymentAdjustmentValue,
      );
    }
    if (
      payload.paymentAdjustmentType !== "NoAdj" &&
      payload.paymentAdjustmentValue !== "" &&
      Number(payload.paymentAdjustmentValue) === 0
    ) {
      showError("Payment adjustment value cannot be 0");
    } else {
      if (config?.name === PROCESSOR_CONFIGS.sageAch) {
        payload.setting = sageRef.current.onSubmit();
      }
      if (config?.name === PROCESSOR_CONFIGS.paya) {
        payload.setting = payaRef.current.onSubmit();
      }
      selectedProcessor
        ? editProcessor(selectedProcessor.processorId, payload)
        : createProcessor(payload);
    }
  };

  const convertPaymentValue = (type, value) => {
    return type === "Percentage"
      ? (value / 1000).toFixed(3)
      : (value / 100).toFixed(2);
  };

  const onError = () => {
    showError("Please fix validation errors.");
  };

  return (
    <div className="processor-form">
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs sx={{ mb: 1 }}>
            {" "}
            <div className="header-row">
              <h2>Processor</h2>
              {selectedProcessor && (
                <IconButton
                  title={"Edit"}
                  variant="container"
                  sx={{ padding: 0.5 }}
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                  color={"warning"}
                  fontSize="small"
                  data-cy="edit-processor"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </div>
            <GatewayProcessorDetails
              gatewayInfo={gatewayInfo}
              selectedProcessor={selectedProcessor}
              isEdit={isEdit}
            />
          </Grid>
        </Grid>
        {configTypes && (
          <Grid container sx={{ marginBottom: "10px" }} spacing={1}>
            <Grid item xs={12} md={3}>
              <Select
                control={methods.control}
                name="processorType"
                fullWidth
                id="processor_config"
                label="Processor Config"
                viewOnly={!isEdit}
                rules={{
                  required: true,
                }}
                defaultValue={config?.name || "none"}
                onChange={(e) => {
                  if (methods.getValues().setting) {
                    methods.unregister("setting");
                  }
                  const config = configTypes.find((t) => {
                    return t.name === e;
                  });
                  setConfig(config);
                }}
              >
                <MenuItem value={"none"}>Select an option...</MenuItem>
                {configTypes.map((c, i) => {
                  return (
                    <MenuItem key={i} value={c.name}>
                      {c.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        )}
        <div className="advanced-form-section">
          {simpleTemplates.includes(config?.name) && (
            <GatewayProcessorsSimple
              editDetails={settings}
              isEdit={isEdit}
              configName={config?.name}
            />
          )}
          {config?.name === PROCESSOR_CONFIGS.tsysSierra && (
            <GatewayProcessorsSierra
              gatewayInfo={gatewayInfo}
              editDetails={selectedProcessor?.settings?.tsysSierra}
              isEdit={isEdit}
            />
          )}
          {config?.name === PROCESSOR_CONFIGS.sageAch && (
            <GatewayProcessorsSage
              reference={sageRef}
              editDetails={selectedProcessor?.settings?.sageAch?.terminals}
              isEdit={isEdit}
            />
          )}
          {config?.name === PROCESSOR_CONFIGS.fiservv33 && (
            <GatewayProcessorsFiserv
              editDetails={selectedProcessor?.settings?.fiservV33}
              isEdit={isEdit}
            />
          )}
          {config?.name === PROCESSOR_CONFIGS.paya && (
            <GatewayProcessorsPaya
              reference={payaRef}
              editDetails={selectedProcessor?.settings?.paya?.checkTerminals}
              isEdit={isEdit}
            />
          )}
        </div>
        <div className="btn-row">
          <Button
            size="small"
            variant="contained"
            color="neutrals"
            sx={{ marginRight: "10px" }}
            onClick={goBack}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!isEdit}
            onClick={methods.handleSubmit(onSubmit, onError)}
            data-cy="submit-processor"
          >
            Submit
          </Button>
        </div>
      </FormProvider>
    </div>
  );
}
GatewayProcessor.propTypes = {
  goBack: PropTypes.func,
  createProcessor: PropTypes.func,
  editProcessor: PropTypes.func,
  gatewayInfo: PropTypes.object,
  selectedProcessor: PropTypes.object,
  canEdit: PropTypes.bool,
  showError: PropTypes.func,
  configTypes: PropTypes.array,
};

export default GatewayProcessor;
