import React, { useState, useEffect, useImperativeHandle } from "react";
import {
  basysEndpoints,
  endpoints,
  sassEndpoints,
} from "../../../constants/endpoints";
import axios from "axios";
import SearchManager from "../../Search/SearchManager";
import PropTypes from "prop-types";
import SearchTable from "../../Search/SearchTable";
import Grid from "@mui/material/Grid";
import SearchPaging from "../../Search/SearchPaging";
import {
  gatewayLinkSearchFields,
  gatewayLinkedKeyValue,
  gatewayLinkedColumnHeaders,
} from "./gatewayTableHelpers";
import Radio from "@mui/material/Radio";
import { regexPatterns } from "../../../constants/regexPatterns";
import { partnerRoutes } from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { getResponseErrorMessage } from "../../../utils/objectHelpers";
import Typography from "@mui/material/Typography";
import MigrationScheduler from "./MigrationScheduler";
import SuccessDialog from "../../Dialog/AlertDialog";

function GatewayLink({
  gatewayProviderId,
  showSnackbar,
  setShowBackdrop,
  linkGateway,
  displayMessages,
}) {
  useImperativeHandle(linkGateway, () => ({
    onSubmit: () => {
      if (selectedRow) {
        setIsOpen(true);
      } else {
        displayMessages(["Select a Gateway"]);
      }
    },
  }));
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [searchPayload, setSearchPayload] = useState(null);
  const [forceUpdateResults, setForceUpdateResults] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [gateways, setGateways] = useState([]);

  const [selectedRow, setSelectedRow] = useState();
  const history = useHistory();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  useEffect(() => {
    if (searchPayload !== null) {
      retrieveGateways();
    }
  }, [searchPayload, resultsPerPage, forceUpdateResults]);

  const transformToUI = (data) => {
    return {
      name: data.name,
      status: data.status,
      company: data.contact_company,
      contact: data.contact_name,
      phone: data.contact_phone_number,
      email: data.contact_email,
      created: data.created_date_time,
      gateway_id: data.gateway_native_unique_key,
      phone_ext: data.contact_phone_ext,
      disabled: data.status === "disabled" || data.is_linked,
    };
  };

  const retrieveGateways = () => {
    let url = `${basysEndpoints.gateway_prodivders}/${gatewayProviderId}/gateways/search`;
    let payload = searchPayload;
    if (searchPayload.created_at) {
      searchPayload.created_at.start_date =
        searchPayload.created_at.startDate.split(".")[0] + "Z";
      searchPayload.created_at.end_date =
        searchPayload.created_at.endDate.split(".")[0] + "Z";
      delete searchPayload.created_at.startDate;
      delete searchPayload.created_at.endDate;
    }
    payload.limit = resultsPerPage;
    payload.offset = resultOffset;
    payload.filter_by = "";

    setShowBackdrop(true);
    axios
      .post(url, payload)
      .then(function (response) {
        if (response?.status === 200) {
          setSelectedRow(null);
          setResultsTotal(response.data.total_count);
          let tableGateways = [];
          if (response.data.total_count > 0) {
            tableGateways = response.data.data.map((gateway) => {
              return transformToUI(gateway);
            });
          }

          setGateways(tableGateways);
        }
      })
      .catch(function (error) {
        displayMessages(error?.response?.data);
        console.log("Failed to get gateway details", error);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const searchGateway = (form) => {
    setResultOffset(0);
    setSearchPayload(form);
  };

  const handleResultsPerPageOnChange = (event) => {
    setResultOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultOffset(pageIndex * resultsPerPage);
    setForceUpdateResults(Date.now());
  };

  const handleChange = (event, data) => {
    setSelectedRow(data);
  };

  const generateUsername = (company) => {
    let randomSuffix = Math.random().toString(36).substr(2, 8);

    let companyScrubbed = company
      .replace(regexPatterns.alphaNumericOnly, "")
      .substr(0, 30);

    let username = "basys_api_" + companyScrubbed + "_" + randomSuffix;

    return username.toLowerCase();
  };
  // eslint-disable-next-line no-unused-vars
  const assemblePayload = () => {
    let generatedUsername = generateUsername(selectedRow.name);
    const user = {
      username: generatedUsername,
      name: generatedUsername,
      phone: "8883661325",
      email: "product.development@basyspro.com",
      status: "active",
      role: "admin",
      send_welcome: false,
      create_api_key: true,
      create_pub_api_key: true,
    };
    setShowBackdrop(true);
    axios
      .post(`${endpoints.merchant}/${selectedRow.gateway_id}/user`, user)
      .then((res) => {
        const gatewayPayload = {
          name: selectedRow.name,
          contact_company: selectedRow.company,
          contact_name: selectedRow.contact,
          contact_phone_number: selectedRow.phone,
          contact_phone_ext: selectedRow.phone_ext,
          contact_email: selectedRow.email,
          gateway_provider_id: gatewayProviderId,
          gateway_provider_gateway: {
            gateway_apikey: res.data.data.pub_api_key,
            gateway_native_unique_key: selectedRow.gateway_id,
          },
          gateway_provider_user: {
            user_native_apikey: res.data.data.api_key,
            user_native_unique_key: res.data.data.id,
            user_native_user_name: generatedUsername,
            user_native_role: "admin",
          },
        };
        createGateway(gatewayPayload);
      })
      .catch((err) => {
        setShowBackdrop(false);
        displayMessages([getResponseErrorMessage(err.errors)]);
      });
  };
  const createGateway = async (payload) => {
    await axios
      .post(basysEndpoints.gateway, payload)
      .then(function (response) {
        if (response.status === 200) {
          showSnackbar("Gateway has been created!");

          history.push(
            `${partnerRoutes.gateway_view.replace(
              ":id",
              response.data.data.gateway_id,
            )}`,
          );
        }
      })
      .catch(function (error) {
        displayMessages(error?.response?.data);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const getActionsComponent = (row) => {
    return (
      <div>
        {!row.disabled && (
          <Radio
            checked={selectedRow?.name === row?.name}
            onChange={(e) => handleChange(e, row)}
            value={row}
            name={`${row.name}-button`}
          />
        )}
      </div>
    );
  };

  const handleMigration = (utcDate, recipientEmail) => {
    setShowBackdrop(true);
    const payload = {
      MigrationDateTime: utcDate,
      Recipients: [recipientEmail, "product.development@basyspro.com"],
      SourceGatewayInfo: {
        gatewayId: selectedRow.gateway_id,
        gatewayName: selectedRow.name,
        contactName: selectedRow.contact,
        contactPhone: selectedRow.phone,
        contactPhoneExt: selectedRow.phone_ext,
        contactEmail: selectedRow.email,
        contactCompany: selectedRow.company,
      },
    };
    axios
      .post(sassEndpoints.migrationQueue, payload)
      .then(() => {
        setErrorMessage(null);
        setIsOpen(false);
        setIsSuccessOpen(true);
      })
      .catch(() => {
        setErrorMessage("Error queueing migration process.");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const handleClose = () => {
    setErrorMessage(null);
    setIsOpen(false);
  };

  return (
    <Grid item xs={12} className="gateway-link-wrapper">
      <SearchManager
        searchButtonOnClick={searchGateway}
        showAlertDialogError={displayMessages}
        entitySearchFilterFields={gatewayLinkSearchFields}
        savedSearches={false}
        sectionName={"Link Gateways"}
        showSnackBar={showSnackbar}
      />
      <div>
        <Typography sx={{ mt: 2, fontSize: "14px", fontWeight: "600" }}>
          Select an Existing Gateway
        </Typography>
        <SearchTable
          getActionsComponent={getActionsComponent}
          searchResults={gateways?.length > 0 ? gateways : null}
          getEntityKeyValue={gatewayLinkedKeyValue}
          fieldList={gatewayLinkedColumnHeaders}
        />
      </div>

      <SearchPaging
        handleResultsPerPageOnChange={handleResultsPerPageOnChange}
        resultsPerPage={resultsPerPage}
        resultsTotal={resultsTotal}
        handleChange={handlePageChange}
      />
      <MigrationScheduler
        onMigration={handleMigration}
        handleClose={handleClose}
        isOpen={isOpen}
        errorMessage={errorMessage}
      />
      <SuccessDialog
        alertTitle=""
        alertLevel=""
        alertMessages={[
          "Your request is currently in progress, and it's anticipated to be completed within the next 30 minutes. Kindly revisit in half an hour to check on its status.",
        ]}
        alertOpen={isSuccessOpen}
        closeButtonText="Ok"
        onCloseButtonClick={() => history.push(partnerRoutes.gateway)}
      />
    </Grid>
  );
}
GatewayLink.propTypes = {
  gatewayProviderId: PropTypes.string,
  showSnackbar: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  linkGateway: PropTypes.object,
  displayMessages: PropTypes.func,
};

export default GatewayLink;
