import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import { Link as RouterLink } from "react-router-dom";
import { formatCurrency, formatDateTime } from "../../../utils/stringHelpers";
import IconCell from "../../Table/IconCell";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AchIcon from "@mui/icons-material/AccountBalance";
import { sassFilterOperators } from "../../Search/searchHelpers";
import { merchantRoutes } from "../../../constants/routes";
import { Link } from "@mui/material";

export const getCustomerKeyValue = (key, data) => {
  let addressData;

  switch (key) {
    case "payment_method": {
      const defaultPayment = data?.paymentMethods?.find((payment) => {
        return payment?.isDefault;
      });
      if (defaultPayment) {
        if (defaultPayment?.card)
          return (
            <div className="customer-table-icon">
              <IconCell
                Icon={CreditCardIcon}
                text={defaultPayment?.card?.maskedCard}
                helper={defaultPayment?.card?.expirationDate}
              />
            </div>
          );
        else if (defaultPayment?.ach)
          return (
            <div className="customer-table-icon">
              <IconCell
                Icon={AchIcon}
                text={defaultPayment?.ach?.maskedAccount}
                helper={`Type: ${defaultPayment?.ach?.accountType}`}
                className="customer-table-icon"
              />
            </div>
          );
        else return "";
      } else return "";
    }
    case "billing_address":
    case "shipping_address":
      if (key === "billing_address")
        addressData = data?.addresses.find((value) => {
          return value?.isBilling;
        });
      else
        addressData = data?.addresses.find((value) => {
          return value?.isShipping;
        });

      if (addressData) {
        return (
          <>
            {(addressData.firstName || addressData.lastName) && (
              <div>
                {(addressData.firstName || "") +
                  " " +
                  (addressData.lastName || "")}
              </div>
            )}
            {handleNullOrUndefined(addressData.company) && (
              <div>{addressData.company}</div>
            )}
            {handleNullOrUndefined(addressData.addressLine1) && (
              <div>{addressData.addressLine1}</div>
            )}
            {handleNullOrUndefined(addressData.addressLine2) && (
              <div>{addressData.addressLine2}</div>
            )}
            {formatCity(addressData.city) +
              addressData.state +
              " " +
              handleNullOrUndefined(addressData.postalCode)}
            {addressData.email && (
              <div className="customer-table-icon">
                <IconCell
                  Icon={EmailIcon}
                  text={addressData.email}
                  className="customer-table-icon"
                />
              </div>
            )}
            {addressData.phone && (
              <div className="customer-table-icon">
                <IconCell
                  Icon={PhoneIcon}
                  text={addressData.phone}
                  className="customer-table-icon"
                />
              </div>
            )}
            {addressData.fax && (
              <div className="customer-table-icon">
                <IconCell
                  Icon={FaxIcon}
                  text={addressData.fax}
                  className="customer-table-icon"
                />
              </div>
            )}
          </>
        );
      } else {
        return <></>;
      }

    case "masked_account":
      return data.payment_method === "card"
        ? data.response_body.card.masked_card
        : data.response_body.ach.masked_account_number;

    case "amount":
      //Check if the amount authorized not equal to the amount captured or if the status is unknown
      if (
        (data.amount_captured &&
          data.amount_authorized !== data.amount_captured) ||
        data.status === "unknown"
      )
        return (
          formatCurrency(data.amount) +
          " (" +
          formatCurrency(data.amount_captured) +
          ")"
        );

      return formatCurrency(data.amount);

    case "created_date":
      return formatDateTime(data?.createdDateTime);

    case "updated_date":
      return formatDateTime(data.updated_at);

    case "name": {
      const displayName =
        data.name.length >= 24 ? `${data.name.substring(0, 21)}...` : data.name;

      return (
        <Link
          color="secondary"
          component={RouterLink}
          to={`${merchantRoutes.customer.base}/${data.customerId}`}
        >
          {displayName}
        </Link>
      );
    }

    case "company": {
      const defaultBillingAddress = data?.addresses?.find((address) => {
        return address.isBilling;
      });
      return defaultBillingAddress?.company;
    }

    default:
      return data[key];
  }
};

const formatCity = (city) => {
  return city ? `${city}, ` : "";
};

const handleNullOrUndefined = (value) => {
  return value === null ? null : value ?? "";
};

const generalGroup = "General";

export const customerSearchFields = [
  {
    group: generalGroup,
    displayName: "Global Search",
    fieldName: "search_in_everything",
    operators: [sassFilterOperators.is],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Customer Id",
    fieldName: "customerId",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Name",
    fieldName: "name",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Description",
    fieldName: "description",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Address Id",
    fieldName: "addressId",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "First Name",
    fieldName: "firstName",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Last Name",
    fieldName: "lastName",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Company",
    fieldName: "company",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Address Line 1",
    fieldName: "addressLine1",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Address Line 2",
    fieldName: "addressLine2",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "City",
    fieldName: "city",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Country",
    fieldName: "country",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "country",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "State",
    fieldName: "state",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "state",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Postal Code",
    fieldName: "postalCode",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Email",
    fieldName: "email",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Phone",
    fieldName: "phone",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "phone",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Fax",
    fieldName: "fax",
    operators: [sassFilterOperators.isLike, sassFilterOperators.isNotLike],
    fieldType: "phone",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Payment Type",
    fieldName: "paymentType",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "payment_method_type",
    customerPayments: [
      { value: "Card", display: "Card" },
      { value: "Ach", display: "Ach" },
    ],
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Created Date",
    fieldName: "createdDate",
    operators: [sassFilterOperators.between],
    fieldType: "date_range",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Updated Date",
    fieldName: "updatedDate",
    operators: [sassFilterOperators.between],
    fieldType: "date_range",
    selected: false,
  },
];
