import PropTypes from "prop-types";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import BasicCard from "../../Cards/BasicCard";
import { validationRules } from "../../../constants/validationRules";
import { CustomFields } from "../CustomFields/GlobalCustomFields";

const CVCreateAdvancedOptions = ({ customFieldGroups, chosenCustomFields }) => {
  return (
    <BasicCard title="Customer Details">
      <div className="advanced-options-wrapper">
        <RegisteredTextField
          id="name"
          name={"name"}
          label="Name"
          fullWidth
          rules={{
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9 ]{0,50}$/,
              message: "Invalid name (Alphanumeric and max 50 characters)",
            },
          }}
        />
        <RegisteredTextField
          id="description"
          name={"description"}
          label="Description"
          fullWidth
          autoComplete="description"
          rules={{
            maxLength: validationRules.maxLength255,
          }}
        />

        <CustomFields
          customFieldGroups={customFieldGroups}
          chosenCustomFields={chosenCustomFields}
        />
      </div>
    </BasicCard>
  );
};

CVCreateAdvancedOptions.propTypes = {
  activeTab: PropTypes.string,
  isSurcharge: PropTypes.bool,
  chosenCustomFields: PropTypes.array,
  customFieldGroups: PropTypes.array,
};
export default CVCreateAdvancedOptions;
