import { useEffect } from "react";
import { pageViewCountIncrement } from "../services/mixpanel/merchant/userProperties";

export function usePageViews(location) {
  useEffect(() => {
    pageViewCountIncrement(location.pathname);
  }, [location]);
}

export default usePageViews;
