import { useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import { generalError, partnerRoutes } from "../../constants/routes";
import Unauthorized from "../Routes/Unauthorized";
import HelpTOSContainer from "../Help/HelpTOSContainer";
import PartnerHelpContactUsContainer from "../Partner/HelpContactUs/HelpContactUsContainer";
import ResetTOSContainer from "../Partner/ResetTOS/ResetTOSContainer";
import PartnerManageUsersContainer from "../Partner/ManageUsers/ManageUsersContainer";
import UserCreateContainer from "../Partner/ManageUsers/CreateUsers/UserCreateContainer";
import GatewayContainer from "../Partner/Gateway/GatewayContainer";
import GatewayCreate from "../Partner/Gateway/GatewayCreate";
import GatewayView from "../Partner/Gateway/GatewayView";
import PartnersContainer from "../Partner/Partners/PartnersContainer";
import EditUsersContainer from "../Partner/ManageUsers/EditUser/EditUserContainer";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { useHistory } from "react-router";
import ErrorContainer from "../Error/ErrorContainer";
import { userHasPermission } from "../../utils/objectHelpers";
import { SYSTEM_PERMISSION_CODES } from "../../constants/global";
export const PartnerSwitch = (error) => {
  const history = useHistory();
  const { userSettings } = useContext(UserSettingsContext);

  useEffect(() => {
    if (error?.error?.message) {
      history.push({
        pathname: `/error`,
        error: error,
      });
    }
  }, []);
  return (
    <Switch>
      <Route path={partnerRoutes.partners} component={PartnersContainer} />
      {userHasPermission(SYSTEM_PERMISSION_CODES.create, userSettings) && (
        <Route path={partnerRoutes.gateway_create} component={GatewayCreate} />
      )}
      <Route path={partnerRoutes.gateway_view} component={GatewayView} />
      <Route path={partnerRoutes.gateway} component={GatewayContainer} />
      <Route
        path={partnerRoutes.manage.users_create}
        component={UserCreateContainer}
      />
      <Route
        exact
        path={partnerRoutes.manage.edit}
        component={EditUsersContainer}
      />
      <Route
        exact
        path={partnerRoutes.manage.users}
        component={PartnerManageUsersContainer}
      />
      {/* help */}
      <Route
        path={partnerRoutes.help.contact_us}
        component={PartnerHelpContactUsContainer}
      />
      <Route
        path={partnerRoutes.help.current_tos}
        component={HelpTOSContainer}
      />
      <Route
        path={partnerRoutes.help.terms_of_service}
        component={ResetTOSContainer}
      />
      <Route path={"/error"} component={ErrorContainer} />
      <Redirect exact from="/" to={partnerRoutes.gateway} />
      <Route path={generalError} component={Unauthorized} />
      <Redirect to={generalError} />
    </Switch>
  );
};

export default PartnerSwitch;
