import { useEffect, useState } from "react";

const useScript = (resourceUrl, cleanupElements) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.async = true;
    script.onload = () => {
      setIsLoading(false);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      if (cleanupElements) {
        cleanupElements.forEach((id) =>
          document.body.removeChild(document.getElementById(id)),
        );
      }
    };
  }, [resourceUrl, cleanupElements]);

  return [isLoading];
};
export default useScript;
