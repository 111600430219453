import { formatDateTime } from "../../../../utils/stringHelpers";
import "./gatewayUser.scss";
export const userListColumnHeaders = [
  {
    default_field: true,
    key: "user status",
    sortable: false,
  },
  {
    default_field: true,
    key: "gateway user status",
    sortable: false,
  },
  {
    default_field: true,
    key: "name",
    sortable: false,
  },
  {
    default_field: true,
    key: "email",
    sortable: false,
  },
  {
    default_field: true,
    key: "role",
    sortable: false,
  },
  {
    default_field: true,
    key: "created",
    sortable: false,
  },
];

export const userListKeyValue = (key, data) => {
  switch (key) {
    case "created": {
      return formatDateTime(data.createdDateTime).split(" ")[0];
    }
    case "role": {
      return data.role.description.split(" ")[1];
    }
    case "name": {
      return data.user.firstName + " " + data.user.lastName;
    }
    case "email": {
      return data.user.email;
    }
    case "user status": {
      let mapping = {
        InvitePending: "invite_pending",
        PendingTOS: "pending_tos",
        Active: "active",
        Disabled: "disabled",
      };
      let userStatus = mapping[data.user.status] || data.user.status;

      return (
        <div className={`${mapping[data.user.status]} gateway-badge`}>
          {userStatus === "invite_pending"
            ? "invite pending"
            : userStatus === "pending_tos"
              ? "pending tos"
              : userStatus}
        </div>
      );
    }
    case "gateway user status": {
      return (
        <div
          className={`${data.gatewayUserStatus.toLowerCase()} gateway-badge`}
        >
          {data.gatewayUserStatus.toLowerCase()}
        </div>
      );
    }
    default:
      //Lookup CustomField
      return data[key];
  }
};
