import React, { useContext, useState, useEffect } from "react";
import { partnerRoutes } from "../../../../constants/routes";
import ContentComponent from "../../../Content/Content";
import "./editUsers.scss";
import { Link, useParams, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FormProvider, useForm } from "react-hook-form";
import UserDetails from "./UserDetails";
import { basysEndpoints, sassEndpoints } from "../../../../constants/endpoints";
import axios from "axios";
import { useSnackbar } from "../../../../hooks/index";
import { getResponseErrorMessage } from "../../../../utils/objectHelpers";
import { RolesAndPermissionContext } from "../../../../contexts/RolesAndPermissionContext";

export const USER_STATUS_CODES = {
  invite_pending: 0,
  pending_tos: 1,
  active: 2,
  disabled: 3,
};

const EditUsersContainer = () => {
  const { snackbarOpen, snackbarProps, showSnackbar } = useSnackbar();
  const [userDetails, setUserDetails] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const { ...methods } = useForm();
  const location = useLocation();
  const [isView, setView] = useState(!location?.state?.editMode);
  const { id } = useParams();
  const { rolesAndPermissions } = useContext(RolesAndPermissionContext);

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleStatus = (userStatus) => {
    if (userStatus === "InvitePending") {
      return "invite_pending";
    } else if (userStatus === "Active") {
      return "active";
    } else if (userStatus === "Disabled") {
      return "disabled";
    }
    return "";
  };

  const handlePermissions = (permissionsList) => {
    let newPermissionsList = [];
    rolesAndPermissions.permissions.map((permission) => {
      const foundToggle = permissionsList.find((userPermission) => {
        return userPermission.permissionId === permission.permissionId;
      });
      if (foundToggle) {
        newPermissionsList.push(foundToggle);
      }
    });
    return newPermissionsList;
  };

  const getUserDetails = () => {
    let payload = {
      userId: {
        operator: "Equal", // Guid => Operators = Equal
        value: id,
      },
      includeUserPermissions: true,
      includeUserRole: true,
    };

    const url = `${sassEndpoints.users.viewUser}`;
    setShowBackdrop(true);
    axios
      .post(url, payload)
      .then(function (response) {
        if (response.status === 204) {
          showAlertDialogError("Failed to fetch user details");
        } else {
          let userData = response.data.data.results[0];
          let userDetailBody = {
            user_id: userData.userId,
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            status: handleStatus(userData.status),
            created_date_time: userData.createdDateTime,
            role: userData.userRole,
            permissions: handlePermissions(userData.userPermissions),
          };
          setUserDetails(userDetailBody);
        }
      })
      .catch(function () {
        showAlertDialogError("Failed to fetch user details");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const handleIsEditState = (state) => {
    setView(state);
  };
  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const onError = () => {
    showAlertDialogError("Please correct form.");
  };

  const editUser = () => {
    setShowBackdrop(true);
    let data = methods.getValues();
    const url = sassEndpoints.users.user + `/${id}`;
    if (!data.permissions) {
      data.permissions = userDetails.permissions;
    }
    let validPermissions = [];
    Object.entries(data.permissions).forEach((p) => {
      if (p[1]) {
        validPermissions.push(p[0]);
      }
    });
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      status: USER_STATUS_CODES[data.status],
      roleId: data.role_id,
      permissionIds: validPermissions,
    };

    axios
      .put(url, payload)
      .then(() => {
        getUserDetails();
        showSnackbar("Success");
        setView(true);
      })
      .catch((err) => {
        showAlertDialogError(getResponseErrorMessage(err));
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const resendEmail = () => {
    let url = `${basysEndpoints.users}/${id}/invite`;
    axios
      .get(url)
      .then(() => {
        showSnackbar("Invite resent");
      })
      .catch((err) => {
        showAlertDialogError("Failed to resend invite email", err);
      });
  };

  return (
    <div>
      <FormProvider {...methods}>
        <ContentComponent
          spinnerOpen={showBackdrop}
          title={isView ? "View User" : "Edit User"}
          headerContent={
            <div>
              {" "}
              <Link to={partnerRoutes.manage.users} className="back-arrow">
                <ChevronLeftIcon />
                Back to List
              </Link>
            </div>
          }
          bodyContent={
            <div>
              <div>
                {userDetails && rolesAndPermissions?.permissions && (
                  <UserDetails
                    userDetails={userDetails}
                    viewType={handleIsEditState}
                    viewState={isView}
                    resendEmail={resendEmail}
                    methods={methods}
                    editUser={editUser}
                    onError={onError}
                    roles={rolesAndPermissions?.roles.filter((role) => {
                      return !role.isGatewayRole;
                    })}
                    permissions={rolesAndPermissions?.permissions}
                  />
                )}
              </div>
            </div>
          }
          alertDialogOpen={alertDialogOpen}
          alertDialogProps={alertDialogProps}
          snackbarOpen={snackbarOpen}
          snackbarProps={snackbarProps}
        />
      </FormProvider>
    </div>
  );
};
export default EditUsersContainer;
