import Button from "@mui/material/Button";
import "./cvCustomerDetail.scss";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import { validationRules } from "../../../../constants/validationRules";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import CustomFields from "../../CustomFields/GlobalCustomFields";
import CustomFieldDetails from "../../CustomFields/CustomFieldDetails";

const CustomerDetails = ({
  customerDetails,
  onError,
  onSubmit,
  isEdit,
  customFieldGroups,
  chosenCustomFields,
}) => {
  const { formState } = useFormContext();

  const handleUpdate = (name, description) => {
    Object.keys(formState?.errors).length > 0
      ? onError()
      : onSubmit(name, description);
  };

  let customFieldCustomerValues = customerDetails.customFieldEntries;

  return (
    <div className="customer-details-wrapper">
      {isEdit ? (
        <div>
          <div className="details-row">
            <div className="view-only-field">
              <span>
                {" "}
                <Typography sx={{ fontSize: "12px" }} color="textSecondary">
                  Customer ID
                </Typography>
              </span>
              <p>{customerDetails.customerId}</p>
            </div>
          </div>
          <div className="details-row">
            <RegisteredTextField
              className="view-only-field"
              name="name"
              label="Name"
              defaultValue={customerDetails.name}
              rules={{
                required: true,
                maxLength: validationRules.maxLength50,
              }}
            />
          </div>
          <div className="details-row">
            <RegisteredTextField
              className="form-md"
              name="description"
              label="Description"
              defaultValue={customerDetails.description}
              rules={{
                maxLength: validationRules.maxLength255,
              }}
            />
          </div>
          {customerDetails?.customFieldEntries.length > 0 && (
            <div className="details-row">
              <div className="custom-fields-customer-info">
                <CustomFields
                  customFieldGroups={customFieldGroups}
                  chosenCustomFields={chosenCustomFields}
                  customerFieldNameAndValues={[
                    customerDetails?.customFieldEntries[0]
                      ?.customFieldCategoryName,
                    customFieldCustomerValues,
                    isEdit,
                  ]}
                />
              </div>
            </div>
          )}

          <Button
            sx={{ paddingLeft: "40px", paddingRight: "40px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              handleUpdate();
            }}
          >
            Update
          </Button>
        </div>
      ) : (
        <div>
          <div className="details-row">
            <div className="view-only-field">
              <span>
                {" "}
                <Typography sx={{ fontSize: "12px" }} color="textSecondary">
                  Customer ID
                </Typography>
              </span>
              <p>{customerDetails.customerId}</p>
            </div>
          </div>
          <div className="details-row">
            <div className="view-only-field">
              <span>
                {" "}
                <Typography sx={{ fontSize: "12px" }} color="textSecondary">
                  Name
                </Typography>
              </span>
              <p>{customerDetails.name}</p>
            </div>
          </div>
          <div className="details-row">
            <div className="view-only-field">
              <span>
                {" "}
                <Typography sx={{ fontSize: "12px" }} color="textSecondary">
                  Description
                </Typography>
              </span>
              <p>{customerDetails.description || "N/A"}</p>
            </div>
          </div>
          {customerDetails?.customFieldEntries.length > 0 && (
            <Grid item container xs={12} sm={9}>
              <CustomFieldDetails
                data={customerDetails}
                fieldBoldness={"600"}
              />
            </Grid>
          )}
        </div>
      )}
    </div>
  );
};
CustomerDetails.propTypes = {
  customerDetails: PropTypes.object,
  isSurcharge: PropTypes.bool,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  isEdit: PropTypes.bool,
  chosenCustomFields: PropTypes.array,
  customFieldGroups: PropTypes.array,
};

export default CustomerDetails;
