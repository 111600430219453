import React, { useEffect, useState, useContext } from "react";
import ContentComponent from "../../../Content/Content";
import CustomFieldsList from "./CustomFieldsList";
import { stringFormat } from "../../../../utils/stringHelpers";
import { sassEndpoints } from "../../../../constants/endpoints";
import axios from "axios";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import Button from "@mui/material/Button";
import { merchantRoutes } from "../../../../constants/routes";
import { useHistory } from "react-router";
const CustomFieldsListContainer = () => {
  const [customFieldGroups, setCustomFieldGroups] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const { userSettings } = useContext(UserSettingsContext);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});

  const history = useHistory();

  useEffect(() => {
    loadCustomFields();
  }, []);

  const loadCustomFields = () => {
    let url = stringFormat(sassEndpoints.customFields.category, [
      userSettings.gatewayId,
    ]);
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (res) {
        if (res?.data?.data?.length > 0) {
          setCustomFieldGroups(
            res.data?.data.map((c) => {
              c.isExpanded = false;
              return c;
            }),
          );
        } else {
          setCustomFieldGroups(null);
        }
      })
      .catch(function (error) {
        showAlertDialogError(
          error?.response?.data?.statusDetails || "Failed to get custom fields",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const toggleExpandState = (rowData) => {
    const updatedState = customFieldGroups.map((c) => {
      if (c.name === rowData.name) {
        c.isExpanded = !c.isExpanded;
      }
      return c;
    });
    setCustomFieldGroups(updatedState);
  };
  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const warnCustomFieldDelete = (id) => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: ["You are permanently deleting the category."],
      actionButtons: [
        {
          text: "Yes, Delete This Category!",
          color: "secondary",
          onclick: () => {
            deleteCustomCategory(id);
            setAlertDialogOpen(false);
          },
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: () => {
        setAlertDialogOpen(false);
      },
    });
    setAlertDialogOpen(true);
  };

  const deleteCustomCategory = (id) => {
    let url = stringFormat(sassEndpoints.customFields.category + `/${id}`, [
      userSettings.gatewayId,
    ]);
    setShowBackdrop(true);

    axios
      .delete(url)
      .then(() => {
        loadCustomFields();
      })
      .catch((err) => {
        showAlertDialogError(
          err?.response?.data?.statusDetails || "Failed to delete field.",
        );
      });
  };

  return (
    <ContentComponent
      title={"Custom Fields"}
      spinnerOpen={showBackdrop}
      headerContent={
        <Button
          size="small"
          variant="contained"
          color="primary"
          data-cy="create_cf_category"
          onClick={() =>
            history.push(merchantRoutes.manage.custom_fields.create)
          }
        >
          Create Category
        </Button>
      }
      bodyContent={
        <CustomFieldsList
          expandRow={toggleExpandState}
          customFields={customFieldGroups}
          deleteCategory={warnCustomFieldDelete}
        />
      }
      alertDialogOpen={alertDialogOpen}
      alertDialogProps={alertDialogProps}
    />
  );
};

export default CustomFieldsListContainer;
