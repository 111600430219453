import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useMsal } from "@azure/msal-react";
import { logout } from "../../../utils/logout";
import "./gatewayModalSelector.scss";

const GatewaySelectorModal = ({
  onOpen,
  onClose,
  gateways,
  loadGatewayDetails,
}) => {
  const { instance } = useMsal();
  const [selectedGateway, setSelectedGateway] = useState(null);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const formatAuto = () => {
    let options = [];
    gateways.forEach((gatewayObj) => {
      options.push(gatewayObj?.gateway?.name);
    });

    return options;
  };

  const loginGateway = () => {
    window.sessionStorage.setItem(
      "gateway",
      selectedGateway?.gateway?.gatewayId,
    );
    loadGatewayDetails(selectedGateway);
    onClose();
  };

  const selectOption = (val) => {
    setSelectedGateway(
      val
        ? gateways.find((gatewayObj) => {
            return gatewayObj?.gateway?.name === val;
          })
        : null,
    );
  };

  return (
    <Dialog onClose={handleClose} open={onOpen}>
      <div className="gateway-selector-wrapper">
        <div>
          <DialogTitle>Select a Gateway</DialogTitle>
          <div className="auto-select-dropdown">
            {" "}
            <Autocomplete
              id="combo-box-demo"
              options={formatAuto()}
              sx={{ width: 300 }}
              onChange={(event, value) => selectOption(value)}
              renderInput={(params) => (
                <TextField {...params} label="Gateways" />
              )}
            />
          </div>
          <Button
            fullWidth
            size="small"
            sx={{ marginTop: "5px" }}
            variant="contained"
            color="primary"
            disabled={!selectedGateway}
            onClick={() => {
              loginGateway();
            }}
            data-cy="select-gateway-continue-button"
          >
            Continue
          </Button>
          <Button
            fullWidth
            size="small"
            variant="contained"
            sx={{ marginTop: "5px" }}
            color="neutrals"
            onClick={() => {
              logout(instance);
            }}
            data-cy="select-gateway-cancel-button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

GatewaySelectorModal.propTypes = {
  onClose: PropTypes.func,
  onOpen: PropTypes.bool,
  gateways: PropTypes.array,
  loadGatewayDetails: PropTypes.func,
};

export default GatewaySelectorModal;
