import React, { useContext } from "react";
import { Drawer as MuiDrawer } from "@mui/material/";
import darkLogo from "../../components/Drawer/iqpro-logo-dark.svg";
import logo from "../../components/Drawer/iqpro-logo.png";
import "./drawer.scss";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness3";
import LightModeIcon from "@mui/icons-material/LightMode";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PropTypes from "prop-types";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { isGatewayUser } from "../../utils/user";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "../../contexts/ColorModeContext";
import { Environments } from "../../constants/global";
const UserDrawer = ({ open, handleUserProfileToggle, handleLogout }) => {
  const { userSettings } = useContext(UserSettingsContext);
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const name = isGatewayUser(userSettings)
    ? userSettings?.user?.firstName + " " + userSettings?.user?.lastName
    : userSettings?.firstName + " " + userSettings?.lastName;
  const toggleTheme = () => {
    localStorage.setItem(
      "iqPro-theme",
      theme.palette.mode === "light" ? "dark" : "light",
    );

    isGatewayUser(userSettings)
      ? window.location.reload()
      : colorMode.toggleColorMode();
  };
  return (
    <MuiDrawer
      className={`user-profile-container ${theme.palette.mode}`}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={handleUserProfileToggle}
      PaperProps={{
        sx: {
          width: "256px",
        },
      }}
    >
      <div className="header-row">
        <img
          src={theme.palette.mode === "light" ? darkLogo : logo}
          alt="Logo"
        />
        <IconButton onClick={handleUserProfileToggle} color="inherit">
          <CloseIcon />
        </IconButton>
      </div>
      <div className="user-details-block">
        <div className="user-badge">
          {" "}
          {userSettings.impersonator ? (
            <span>
              {userSettings?.impersonator?.split(" ")[0].charAt(0) +
                userSettings?.impersonator?.split(" ")[1].charAt(0)}
            </span>
          ) : (
            <span>
              {name?.split(" ")[0].charAt(0) + name?.split(" ")[1].charAt(0)}
            </span>
          )}
        </div>
        <div className="user-details-col">
          <Typography sx={{ fontSize: "16px" }} color="textSecondary">
            {userSettings?.impersonator ? userSettings?.impersonator : name}
          </Typography>
          {userSettings?.impersonator && (
            <div className="impersonator-block">
              <Typography sx={{ fontSize: "12px" }} color="textSecondary">
                Logged in as:
              </Typography>

              <span>{name}</span>
            </div>
          )}
          {process.env.REACT_APP_ENVIRONMENT !== Environments.Production && (
            <div className="environment-block">
              <Typography sx={{ fontSize: "12px" }} color="textSecondary">
                Environment:
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
                color="textSecondary"
              >
                Development
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className="drawer-menu-btn">
        <button onClick={toggleTheme}>
          Change mode{" "}
          {theme.palette.mode === "dark" ? (
            <LightModeIcon />
          ) : (
            <Brightness4Icon />
          )}
        </button>
      </div>
      <div className="drawer-menu-btn">
        <button onClick={handleLogout}>
          Log off <ExitToAppIcon />
        </button>
      </div>
    </MuiDrawer>
  );
};

UserDrawer.propTypes = {
  open: PropTypes.bool,
  handleUserProfileToggle: PropTypes.func,
  userRole: PropTypes.string.isRequired,
  handleLogout: PropTypes.func,
};

export default UserDrawer;
