import React, { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import "./simplePay.scss";
import "../../../assets/v2/main.scss";
import { styled } from "@mui/material/styles";
import UploadImageOutline from "../../../assets/images/UploadPhotoOutline.png";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileUploader = ({ name, acceptedTypes, disabled }) => {
  const { ...methods } = useFormContext();
  const dataURL = methods.watch(name);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => {
        methods.setValue(name, undefined);
      };
      reader.onerror = () => {
        methods.setValue(name, undefined);
      };
      reader.onload = () => {
        const binaryStr = reader.result;
        methods.setValue(name, binaryStr);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  // eslint-disable-next-line no-unused-vars
  const { getRootProps, acceptedFiles, getInputProps, isDragActive } =
    useDropzone({ onDrop, accept: acceptedTypes, disabled: disabled });

  useEffect(() => {
    methods.register(name);
    return () => {
      methods.unregister(name);
    };
  }, [methods.register, methods.unregister, name]);
  return (
    <div className="file-upload-wrapper">
      <div
        className={"file-upload" + (disabled ? " disabled" : "")}
        {...getRootProps()}
      >
        {!disabled ? (
          dataURL !== "" ? (
            <>
              <img
                src={dataURL}
                alt={"Uploaded Image"}
                className="uploaded-logo"
              />
              <p>Drag new logo here (only .png or .jpg file types)</p>
            </>
          ) : (
            <>
              <img
                src={UploadImageOutline}
                alt={"Upload Image Icon"}
                className="upload-icon"
              />
              <p>Drag logo here to upload (only .png or .jpg file types)</p>
            </>
          )
        ) : dataURL === "" ? (
          <img
            src={UploadImageOutline}
            alt={"Upload Image Icon"}
            className="upload-icon"
          />
        ) : (
          <img src={dataURL} alt={"Uploaded Image"} className="uploaded-logo" />
        )}
        <VisuallyHiddenInput {...getInputProps()} />
      </div>
      {!disabled && (
        <Button
          variant="Text"
          endIcon={<DeleteOutlinedIcon />}
          className="remove-logo-button"
          onClick={() => {
            methods.setValue(name, "");
          }}
        >
          Remove Logo
        </Button>
      )}
    </div>
  );
};

export default FileUploader;

FileUploader.propTypes = {
  name: PropTypes.string.isRequired,
  acceptedTypes: PropTypes.object.isRequired, //ex: {"image/png": ['.png'], "image/jpeg": ['.jpg']}
  disabled: PropTypes.bool,
};
