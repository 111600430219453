import "./App.css";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import getTheme from "./assets/theme";
import SessionTimeoutCheck from "../src/services/SessionTimeoutCheck.js";
import mixpanel from "mixpanel-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import LayoutSkeleton from "./components/Layout/LayoutSkeleton";
import { useState, useMemo } from "react";
import { ColorModeContext } from "./contexts/ColorModeContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";
import { CONTACT_SUPPORT } from "./constants/global";
import LayoutContainer from "./components/Layout/LayoutContainer";
import authError from "./assets/images/auth-error.png";
import Typography from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const Error = ({ error }) => {
  console.log("B2C Error: ", error);
  return <p>B2C Error Occurred</p>;
};

Error.propTypes = {
  error: PropTypes.object,
};

function App({ instance }) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [],
  );
  const theme = useMemo(() => getTheme(mode), [mode]);
  useEffect(() => {
    localStorage.getItem("iqPro-theme")
      ? setMode(localStorage.getItem("iqPro-theme"))
      : setMode(prefersDarkMode ? "dark" : "light");
  }, [prefersDarkMode]);

  // init mixpanel on mount
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

  const authRequest = { ...loginRequest };

  return (
    <StyledEngineProvider injectFirst>
      <DndProvider backend={HTML5Backend}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <Router>
              <MsalProvider instance={instance}>
                <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                  authenticationRequest={authRequest}
                  errorComponent={({ error }) => (
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      spacing={3}
                      sx={{ marginTop: 1, fontWeight: 600 }}
                    >
                      <Grid item>
                        <Typography
                          color="primary.main"
                          sx={{
                            fontSize: "25px",
                            fontWeight: 700,
                          }}
                        >
                          Authentication Error
                        </Typography>
                      </Grid>
                      <Grid item>
                        <img src={authError} style={{ height: 200 }} />
                      </Grid>
                      <Grid item>{error?.errorMessage}</Grid>
                      <Grid item>{CONTACT_SUPPORT}</Grid>
                    </Grid>
                  )}
                  loadingComponent={LayoutSkeleton}
                >
                  <SessionTimeoutCheck />
                  <LayoutContainer />
                </MsalAuthenticationTemplate>
              </MsalProvider>
            </Router>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </DndProvider>
    </StyledEngineProvider>
  );
}

App.propTypes = {
  instance: PropTypes.object,
};

export default App;
