import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import Grid from "@mui/material/Grid";
import "./AlertDialog.scss";
import partiallyProcessedImg from "../../assets/images/partially-processed-transaction.png";
export default function AlertDialog({
  alertTitle,
  alertMessages,
  alertLevel,
  alertOpen,
  contentElement,
  actionButtons,
  onCloseButtonClick,
  closeButtonColor,
  closeButtonVariant,
  closeButtonText,
  showXCloseButton,
  partiallyProcessed,
}) {
  const [buttonBreakPoint, setButtonBreakPoint] = useState(12);

  useEffect(() => {
    if (!actionButtons) {
      setButtonBreakPoint(12);
    } else {
      switch (actionButtons.length) {
        case 1:
          setButtonBreakPoint(6);
          break;
        default:
          setButtonBreakPoint(4);
          break;
      }
    }
  }, [actionButtons]);

  return (
    <Dialog
      open={alertOpen}
      onClose={onCloseButtonClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      transitionDuration={100}
      sx={{
        textAlign: "center",
        container: { margin: 0, padding: 0 },
      }}
      className="alert-wrapper"
    >
      {partiallyProcessed && (
        <img
          style={{
            margin: "auto",
            marginTop: "50px",
            maxWidth: "200px",
            maxHeight: "100px",
          }}
          src={partiallyProcessedImg}
        ></img>
      )}
      <Grid container sx={{ padding: 1.5 }}>
        <Grid
          item
          xs
          sx={{
            fontSize: "1.85em",
            color: "alertDialog.main",
            textAlign: showXCloseButton ? "left" : "center",
            wordBreak: "keep-all",
          }}
        >
          {alertTitle}
        </Grid>
      </Grid>
      <Grid container sx={{ paddingTop: 2, display: !alertLevel && "none" }}>
        <Grid item xs={12}>
          <div>
            <CancelIcon
              color="error"
              sx={{
                fontSize: "3.5em",
                display: alertLevel !== "error" && "none",
              }}
            />
            <WarningIcon
              color="warning"
              sx={{
                fontSize: "3.5em",
                display: alertLevel !== "warning" && "none",
              }}
            />
            <InfoIcon
              color="warning"
              sx={{
                fontSize: "3.5em",
                display: alertLevel !== "info" && "none",
              }}
            />
            <InfoIcon
              color="info"
              sx={{
                fontSize: "3.5em",
                display: alertLevel !== "success" && "none",
              }}
            />
          </div>
        </Grid>
      </Grid>
      <DialogContent
        sx={{
          display:
            alertMessages === undefined && contentElement === undefined
              ? "none"
              : "",
          p: contentElement !== undefined ? 1.5 : "",
        }}
      >
        {alertMessages &&
          alertMessages.map((alertMessage, index) => (
            <DialogContentText
              sx={{ color: "alertDialog.main", fontWeight: "bold" }}
              key={`alert-dialog-description-${index}`}
            >
              {alertMessage}
            </DialogContentText>
          ))}

        {/* Content Element */}
        {contentElement && <>{contentElement}</>}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="column"
          justifyContent="center"
          spacing={1.5}
          sx={{ mb: 3 }}
        >
          {actionButtons &&
            actionButtons.map((button, index) => (
              <Grid
                item
                xs={buttonBreakPoint}
                key={`alert-dialog-actions-${index}`}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(event) => button.onclick(event)}
                  data-cy="alert-dialog-confirm"
                >
                  {button.text}
                </Button>
              </Grid>
            ))}
          <Grid
            item
            xs={buttonBreakPoint}
            key={`alert-dialog-actions-close-button`}
          >
            <Button
              variant={closeButtonVariant}
              color={closeButtonColor}
              onClick={onCloseButtonClick}
              data-cy="alert-dialog-cancel"
            >
              {closeButtonText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
AlertDialog.defaultProps = {
  closeButtonText: "Ok",
  closeButtonVariant: "contained",
  showXCloseButton: false,
};

AlertDialog.propTypes = {
  alertTitle: PropTypes.string,
  alertMessages: PropTypes.array,
  alertLevel: PropTypes.string,
  alertOpen: PropTypes.bool,
  contentElement: PropTypes.element,
  actionButtons: PropTypes.array,
  onCloseButtonClick: PropTypes.func,
  closeButtonColor: PropTypes.string,
  closeButtonVariant: PropTypes.string,
  closeButtonText: PropTypes.string,
  showXCloseButton: PropTypes.bool,
  partiallyProcessed: PropTypes.bool,
};
