import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import styles from "../../Content/style";

function HelpContactUs(props) {
  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Typography color="textSecondary" align="center">
          Help Contact Us
        </Typography>
      </div>
    </Paper>
  );
}

HelpContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HelpContactUs);
