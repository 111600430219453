import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@mui/styles/withStyles";
import { Drawer as MuiDrawer } from "@mui/material/";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import styles from "./style";
import logo from "../../components/Drawer/iqpro-logo.png";
import darkLogo from "../../components/Drawer/iqPro-dark-mode.png";
import miniLogoL from "./DrawerIcons/mini-logo-light.png";
import miniLogoD from "./DrawerIcons/mini-logo-dark.png";
import { useHistory } from "react-router";
import { partnerRoutes, merchantRoutes } from "../../constants/routes";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { LayoutContext } from "../Layout/Layout";
import { ColorModeContext } from "../../contexts/ColorModeContext";
import { useTheme } from "@mui/material/styles";
import { isGatewayUser } from "../../utils/user";
import { Typography } from "@mui/material";
import axios from "axios";
import { regexPatterns } from "../../constants/regexPatterns";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import { ExpandCollapseIcon } from "./DrawerIcons/MiniMenuExpand";
const Drawer = (props) => {
  const { userSettings } = useContext(UserSettingsContext);
  const { isMiniMenu, setMiniMenu } = useContext(LayoutContext);
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const name = isGatewayUser(userSettings)
    ? userSettings.user.firstName + " " + userSettings.user.lastName
    : userSettings?.firstName + " " + userSettings?.lastName;
  const { handleClick, categories, classes, handleLogout, ...other } = props;
  const [open, setOpen] = useState({});
  const history = useHistory();

  const [gateways, setGateways] = useState([]);

  const [subMenu, setSubMenuProps] = useState(null);

  const openSubMenu = Boolean(subMenu?.anchorEl);

  const handleClose = () => {
    setSubMenuProps(null);
  };

  useEffect(() => {
    if (isGatewayUser(userSettings?.role?.code)) {
      axios.get(`${process.env.REACT_APP_SASS_API_URL}/gateway`).then((res) => {
        const validGateways = res?.data?.data?.gateways?.filter(
          (gatewayObj) => {
            return gatewayObj?.gateway?.status?.toLowerCase() !== "disabled";
          },
        );
        setGateways(formatAuto(validGateways));
      });
    }
  }, []);

  const formatAuto = (gateways) => {
    let options = [];
    gateways?.forEach((gatewayObj) => {
      let obj = {
        label: gatewayObj?.gateway?.name,
        id: gatewayObj?.gateway.gatewayId,
      };
      options.push(obj);
    });

    return options;
  };
  const selectOption = (val) => {
    if (val) {
      window.sessionStorage.setItem("gateway", val.id);
      userSettings.gatewayId = val.id;

      window.location.reload(false);
      changeUrl(window.location.href);
    }
  };

  const handleOpen = (id) => {
    setOpen((prevState) => ({ ...{}, [id]: !prevState[id] }));
  };

  const logoButtonRoute = () => {
    categories[0].id === "Main"
      ? history.push(partnerRoutes.gateway)
      : history.push(merchantRoutes.virtual_terminal);
  };

  const changeUrl = (link) => {
    let page = null;
    if (regexPatterns.invoice.detailRegex.test(link)) {
      page = "invoice";
    } else if (regexPatterns.transaction.detailRegex.test(link)) {
      page = "transaction";
    } else if (regexPatterns.customer.detailRegex.test(link)) {
      page = "customer";
    } else if (regexPatterns.customField.detailRegex.test(link)) {
      page = "customField";
    }

    if (page !== null) {
      window.location.replace(
        link.replace(
          link.match(regexPatterns[page].detailRegex)[0],
          regexPatterns[page].link,
        ),
      );
    }
  };

  const toggleTheme = (event, value) => {
    localStorage.setItem("iqPro-theme", value);

    isGatewayUser(userSettings)
      ? window.location.reload()
      : colorMode.toggleColorMode();
  };

  const checkActiveChild = (route, children) => {
    return children.some((c) => {
      return c.route === route;
    });
  };

  const onHover = (e, children) => {
    setSubMenuProps({ anchorEl: e?.target, children: children });
  };

  const SubMenu = (children) => {
    return (
      <>
        {children?.map(
          ({ id: childId, route, isVisible }) =>
            isVisible && (
              <ListItem
                key={childId}
                button
                className={clsx(
                  classes.item,
                  props.location.pathname.startsWith(route) &&
                    classes.itemActiveItem + " active",
                )}
                onClick={() => {
                  handleClick(route);
                  if (isMiniMenu) {
                    handleClose();
                  }
                }}
                data-cy={`drawer-child-${childId}`}
              >
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ),
        )}
      </>
    );
  };

  const UserProfile = () => {
    return (
      <>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <div className="user-details-container">
            <div className="user-details-block">
              <div className="user-badge">
                {" "}
                {userSettings.impersonator ? (
                  <span>
                    {userSettings?.impersonator?.split(" ")[0].charAt(0) +
                      userSettings?.impersonator?.split(" ")[1].charAt(0)}
                  </span>
                ) : (
                  <span>
                    {name?.split(" ")[0].charAt(0) +
                      name?.split(" ")[1].charAt(0)}
                  </span>
                )}
              </div>
              <div className="user-details-col">
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                  {userSettings?.impersonator
                    ? userSettings?.impersonator
                    : name}
                </Typography>
                {userSettings?.impersonator && (
                  <div className="impersonator-block">
                    <Typography sx={{ fontSize: "12px" }}>
                      Logged in as: {name}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
            <div className="logout-btn">
              <Button
                onClick={handleLogout}
                variant="contained"
                startIcon={<LogoutIcon />}
              >
                Sign out
              </Button>
            </div>
          </div>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <div className="theme-toggle-container">
            <ToggleButtonGroup
              className="theme-toggle"
              value={theme.palette.mode}
              onChange={toggleTheme}
              aria-label="color-switch"
              exclusive
            >
              <ToggleButton value={"light"} aria-label="light">
                <WbSunnyOutlinedIcon />
                Light
              </ToggleButton>
              <ToggleButton value={"dark"} aria-label="dark">
                <DarkModeOutlinedIcon />
                Dark
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (!isMiniMenu) {
      handleClose();
    }
  }, [isMiniMenu]);

  return (
    <MuiDrawer
      variant="permanent"
      {...other}
      className={`side-nav-container ${theme.palette.mode} ${
        isMiniMenu ? "collapsed" : "expanded"
      }`}
      PaperProps={{
        sx: {
          width: isMiniMenu ? "80px" : "275px",
          transition: "width .5s",
        },
      }}
    >
      <div className="top-section">
        <div className="button-wrapper">
          {!isMiniMenu ? (
            <Button
              onClick={() => {
                logoButtonRoute();
              }}
              sx={{ padding: "0px" }}
              className="logo-btn"
            >
              <img
                src={theme.palette.mode === "light" ? miniLogoL : miniLogoD}
                alt="Logo"
                className="mini-logo"
              />
              <img
                src={theme.palette.mode === "light" ? logo : darkLogo}
                alt="Logo"
                className="logo"
              />
            </Button>
          ) : (
            <Button
              onClick={() => {
                logoButtonRoute();
              }}
              sx={{ padding: "0px" }}
              className="logo-btn__mini"
            >
              <img
                src={theme.palette.mode === "light" ? miniLogoL : miniLogoD}
                alt="Logo"
                className={classes.logo}
              />
            </Button>
          )}

          <IconButton
            variant="container"
            className="mobile-expand-nav expand-collapse-btn"
            sx={{ padding: 0.5, transform: !isMiniMenu && "rotate(180deg)" }}
            onClick={() => {
              other.onClose();
            }}
            fontSize="small"
            disableRipple={true}
          >
            <ExpandCollapseIcon />
          </IconButton>
          <IconButton
            variant="container"
            className="expand-collapse-btn"
            sx={{ padding: "10px", transform: !isMiniMenu && "rotate(180deg)" }}
            onClick={() => {
              setMiniMenu(!isMiniMenu);
            }}
            fontSize="small"
            disableRipple={true}
          >
            <ExpandCollapseIcon />
          </IconButton>
        </div>

        {gateways.length > 0 && (
          <div className="gateway-wrapper">
            <div className="gateway-block">
              {gateways.length > 1 ? (
                <Grid sx={{ alignSelf: "self-end" }} item>
                  {" "}
                  <Autocomplete
                    className="autocomplete-sidenav"
                    id="header-gateway-select"
                    options={gateways}
                    popupIcon={<KeyboardArrowDownIcon />}
                    defaultValue={gateways.find((gateway) => {
                      return (
                        gateway.id ===
                        window?.sessionStorage?.getItem("gateway")
                      );
                    })}
                    onChange={(event, value) => selectOption(value)}
                    renderInput={(params) => <TextField {...params} />}
                    data-cy="multi-gateway-menu"
                  />
                </Grid>
              ) : (
                <Grid item>
                  <p>{gateways[0]?.label}</p>
                </Grid>
              )}
            </div>
          </div>
        )}
        <List disablePadding>
          {categories.map(
            ({ id, children, isVisible, route, icon }) =>
              isVisible &&
              (children ? (
                <React.Fragment key={id}>
                  <ListItemButton
                    className={
                      checkActiveChild(props.location.pathname, children) &&
                      classes.selectedCategoryHeader + " selected"
                    }
                    onClick={() => {
                      handleOpen(id);
                      if (isMiniMenu) {
                        setMiniMenu(false);
                      }
                    }}
                    data-cy={`drawer-button-${id}`}
                  >
                    <ListItemIcon
                      className={clsx(
                        open[id] === true && classes.selectedItemIcon,
                      )}
                      onMouseEnter={(e) => {
                        onHover(e, children);
                      }}
                      onMouseLeave={(e) => {
                        if (
                          e.relatedTarget?.className?.split(" ")[0] !==
                          "sub-item-menu"
                        ) {
                          handleClose();
                        }
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <div className="button-wrapper">
                      <ListItemText
                        classes={{
                          primary: classes.categoryHeaderPrimary,
                        }}
                      >
                        {id}
                      </ListItemText>
                      {open[id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  </ListItemButton>
                  {!isMiniMenu && (
                    <Collapse in={open[id]} timeout="auto" unmountOnExit>
                      {SubMenu(children)}
                    </Collapse>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment key={id}>
                  <ListItemButton
                    className={clsx(
                      classes.categoryHeader,
                      props.location.pathname.startsWith(route) &&
                        classes.selectedCategoryHeader + " selected",
                    )}
                    onClick={() => {
                      setOpen({});
                      handleClick(route);
                    }}
                    data-cy={`drawer-button-${id}`}
                  >
                    {isMiniMenu ? (
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor:
                                theme.palette.mode === "dark"
                                  ? "#1C7BC0"
                                  : "#323741",
                              "& .MuiTooltip-arrow": {
                                color:
                                  theme.palette.mode === "dark"
                                    ? "#1C7BC0"
                                    : "#323741",
                              },
                            },
                          },
                        }}
                        title={id}
                        placement={"right"}
                        arrow
                      >
                        <ListItemIcon
                          className={clsx(
                            props.location.pathname.startsWith(route) &&
                              classes.selectedItemIcon,
                          )}
                        >
                          {icon}
                        </ListItemIcon>
                      </Tooltip>
                    ) : (
                      <ListItemIcon
                        className={clsx(
                          props.location.pathname.startsWith(route) &&
                            classes.selectedItemIcon,
                        )}
                      >
                        {icon}
                      </ListItemIcon>
                    )}

                    <ListItemText
                      className={clsx(
                        classes.categoryHeaderPrimary,
                        props.location.pathname.startsWith(route) &&
                          classes.selectedCategoryHeaderPrimary +
                            " button-text",
                      )}
                    >
                      {id}
                    </ListItemText>
                  </ListItemButton>
                </React.Fragment>
              )),
          )}
        </List>
      </div>
      <div className="bottom-section">{UserProfile()}</div>
      {isMiniMenu && (
        <div className="collapsed-badge">
          <div
            className="user-badge"
            onMouseEnter={(e) => {
              onHover(e, null);
            }}
            onClick={() => {
              setMiniMenu(false);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                setMiniMenu(false);
              }
            }}
            onMouseLeave={(e) => {
              if (
                e.relatedTarget?.className?.split(" ")[0] !== "sub-item-menu"
              ) {
                handleClose();
              }
            }}
            role="button"
            type={"button"}
            tabIndex={0}
          >
            {" "}
            {userSettings.impersonator ? (
              <span>
                {userSettings?.impersonator?.split(" ")[0].charAt(0) +
                  userSettings?.impersonator?.split(" ")[1].charAt(0)}
              </span>
            ) : (
              <span>
                {name?.split(" ")[0].charAt(0) + name?.split(" ")[1].charAt(0)}
              </span>
            )}
          </div>
        </div>
      )}

      {isMiniMenu && subMenu?.anchorEl && (
        <Popper
          anchorEl={subMenu?.anchorEl}
          open={openSubMenu}
          onMouseLeave={handleClose}
          placement={"right"}
          className={
            "sub-item-menu" +
            (theme.palette.mode === "dark" ? " dark" : " light")
          }
          role="menu"
        >
          <div className="sub-menu-wrapper" tabIndex={0}>
            {subMenu?.children?.length > 0 ? (
              <> {SubMenu(subMenu?.children)}</>
            ) : (
              <>{UserProfile()}</>
            )}
          </div>
        </Popper>
      )}
    </MuiDrawer>
  );
};

Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  route: PropTypes.object,
};

export default withStyles(styles)(Drawer);
