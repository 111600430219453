function stringToBoolean(str) {
  return str.toLowerCase() === "true";
}

const getMetaValueStr = (res, name, prefName) => {
  const setting = res?.simplePaySettings?.find((c) => {
    return c.metadataName === name && c.preferenceName === prefName;
  });
  if (!setting?.metadataValue) return "";
  return setting.metadataValue;
};

const getMetaValueBool = (res, name, prefName) => {
  const setting = res?.simplePaySettings?.find((c) => {
    return c.metadataName === name && c.preferenceName === prefName;
  });
  if (!setting?.metadataValue) return false;
  return stringToBoolean(setting.metadataValue);
};

const getMetaValueNum = (res, name, prefName) => {
  const setting = res?.simplePaySettings?.find((c) => {
    return c.metadataName === name && c.preferenceName === prefName;
  });
  if (!setting?.metadataValue) return 0;
  return parseInt(setting.metadataValue);
};

export { getMetaValueStr, getMetaValueBool, getMetaValueNum };
