import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import {
  formatCurrency,
  formatTransactionCurrency,
  formatDateTime,
} from "../../../utils/stringHelpers";
import {
  getTransactionStatusDisplayInfo,
  getTransactionTypeDisplayInfo,
} from "./transactionHelpers";
import Chip from "@mui/material/Chip";
import SearchTable from "../../Search/SearchTable";
import SearchResultsActions from "./SearchResultsActions";
import logo from "../../Drawer/iqpro-logo.png";
import SearchPaging from "../../Search/SearchPaging";
import IconCell from "../../Table/IconCell";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AchIcon from "@mui/icons-material/AccountBalance";
import { capitalize } from "../../../utils/stringHelpers";

export const keyInfoLookup = [
  { key: "billing_name", sortable: false, default_field: true },
  { key: "type", sortable: false, default_field: true },
  { key: "masked_account", sortable: true, default_field: true },
  { key: "amount", sortable: true, default_field: true },
  { key: "status", sortable: true, default_field: true },
  { key: "created_date", sortable: true, default_field: true },
];

export const getTransactionKeyValue = (key, data, isInt) => {
  let typeInfo;
  // remove old fp interface after saas work has been refactored into settlements
  switch (key) {
    case "billing_name":
      return (
        capitalize(
          data?.billingFirstName || data?.billing_address?.first_name || "",
        ) +
        " " +
        capitalize(
          data?.billingLastName || data?.billing_address?.last_name || "",
        )
      );

    case "masked_account":
      return data.paymentMethod === "Card" ||
        data?.payment_type === "card" ||
        data?.paymentMethod === "Terminal" ? (
        <div className="customer-table-icon">
          <IconCell
            Icon={CreditCardIcon}
            text={data.maskedCard || data?.response_body?.card?.masked_card}
          />
        </div>
      ) : (
        <div className="customer-table-icon">
          <IconCell
            Icon={AchIcon}
            text={
              data.maskedAccount ||
              data?.response_body?.ach?.masked_account_number
            }
            className="customer-table-icon"
          />
        </div>
      );

    case "amount":
      //Check if the amount authorized not equal to the amount captured or if the status is unknown
      if (
        (data.amount_captured &&
          data.amount_authorized !== data.amount_captured) ||
        data.status === "unknown"
      )
        return isInt
          ? formatCurrency(data.amount)
          : formatTransactionCurrency(data.amount) + " (" + isInt
            ? formatCurrency(data.amount_captured)
            : formatTransactionCurrency(data.amount_captured) + ")";

      return isInt
        ? formatCurrency(data.amount)
        : formatTransactionCurrency(data.amount);

    case "type":
      typeInfo = getTransactionTypeDisplayInfo(data.type);
      return (
        <Chip size="small" label={typeInfo.display} color={typeInfo.color} />
      );

    case "status":
      return (
        <Typography
          variant="h5"
          color={getTransactionStatusDisplayInfo(data.status).color}
          sx={{ fontWeight: 600 }}
        >
          {getTransactionStatusDisplayInfo(data.status).display}
        </Typography>
      );

    case "created_date":
      return (
        <div>
          <span className="force-break">
            {" "}
            {
              formatDateTime(data.createdDateTime || data?.created_at).split(
                " ",
              )[0]
            }{" "}
          </span>
          <span>
            {" "}
            {
              formatDateTime(data.createdDateTime || data?.created_at).split(
                " ",
              )[1]
            }
          </span>
          <span>
            {" "}
            {
              formatDateTime(data.createdDateTime || data?.created_at).split(
                " ",
              )[2]
            }
          </span>
        </div>
      );

    case "updated_date":
      return formatDateTime(data.updated_at);

    default:
      //Lookup CustomField
      return data[key];
  }
};

const SearchResultsTable = ({
  transactionSearchResults,
  fieldBoldness,
  handleSortOnClick,
  userSettings,
  refundTransaction,
  captureTransaction,
  vaultTransaction,
  emailTransaction,
  voidTransaction,
  incrementAuth,
  handleChangeAlertDialogProps,
  handleChangePromptDialogProps,
  showAlertDialogError,
  isPDF,
  resultsPerPage,
  resultsTotal,
  handleChange,
  handleResultsPerPageOnChange,
  resetPage,
  setResetPage,
  setSearchClicked,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [currentFieldList, setCurrentFieldList] = useState(
    keyInfoLookup.filter((row) => row.default_field === true),
  );

  const getSortableTableCell = (keyData) => {
    var cellSx = { sx: { fontWeight: fieldBoldness, whiteSpace: "nowrap" } };
    if (keyData.sortable) {
      cellSx.sx.cursor = "pointer";
      cellSx.onClick = () => handleSortOnClick(keyData.key);
    }

    return cellSx;
  };

  const getActionsComponent = (row) => {
    return (
      <SearchResultsActions
        transactionData={row}
        userSettings={userSettings}
        handleChangeAlertDialogProps={handleChangeAlertDialogProps}
        handleChangePromptDialogProps={handleChangePromptDialogProps}
        refundTransaction={refundTransaction}
        captureTransaction={captureTransaction}
        vaultTransaction={vaultTransaction}
        emailTransaction={emailTransaction}
        voidTransaction={voidTransaction}
        incrementAuth={incrementAuth}
        showAlertDialogError={showAlertDialogError}
      />
    );
  };

  return (
    <>
      {!isPDF ? (
        <>
          <SearchTable
            getEntityKeyValue={getTransactionKeyValue}
            fieldList={currentFieldList}
            searchResults={transactionSearchResults}
            getActionsComponent={getActionsComponent}
            getSortableTableCell={getSortableTableCell}
            handleSortOnClick={handleSortOnClick}
          />
          <SearchPaging
            resultsPerPage={resultsPerPage}
            resultsTotal={resultsTotal}
            handleChange={handleChange}
            handleResultsPerPageOnChange={handleResultsPerPageOnChange}
            resetPage={resetPage}
            setResetPage={setResetPage}
            setSearchClicked={setSearchClicked}
          />
        </>
      ) : (
        <div>
          <div className="header-row">
            <div>
              <img src={logo} />
            </div>

            <h2>Transactions</h2>
          </div>
          <SearchTable
            getEntityKeyValue={getTransactionKeyValue}
            fieldList={currentFieldList}
            searchResults={transactionSearchResults}
            getSortableTableCell={getSortableTableCell}
            handleSortOnClick={handleSortOnClick}
          />
        </div>
      )}
    </>
  );
};

SearchResultsTable.propTypes = {
  transactionSearchResults: PropTypes.array,
  fieldBoldness: PropTypes.string.isRequired,
  handleSortOnClick: PropTypes.func.isRequired,
  userSettings: PropTypes.object,
  refundTransaction: PropTypes.func,
  captureTransaction: PropTypes.func,
  vaultTransaction: PropTypes.func,
  emailTransaction: PropTypes.func,
  voidTransaction: PropTypes.func,
  incrementAuth: PropTypes.func,
  handleChangeAlertDialogProps: PropTypes.func,
  handleChangePromptDialogProps: PropTypes.func,
  showAlertDialogError: PropTypes.func,
  isPDF: PropTypes.bool,
  resultsPerPage: PropTypes.number,
  resultsTotal: PropTypes.number,
  handleChange: PropTypes.func,
  handleResultsPerPageOnChange: PropTypes.func,
  resetPage: PropTypes.bool,
  setResetPage: PropTypes.func,
  setSearchClicked: PropTypes.func,
};

export default SearchResultsTable;
