export const CARD_FORM_VALIDATION = {
  card: true,
  exp: true,
  cvv: true,
};

export const correctInput = (event) => {
  var target = event.target,
    value = target.value,
    key = event.key,
    typeKey,
    monthMin = "01",
    monthMax = "12",
    delimiter = "/";
  if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
    if (/(\d)/.test(key)) {
      typeKey = "number";
    } else {
      typeKey = "specSymbol";
    }

    if (value.length === 0 && key > 1) {
      // 2 -> 02/
      target.value = "0" + key + delimiter;
      event.preventDefault();
    }

    if (value.length === 1 && value[0] !== "0" && key > 2) {
      // 16 -> 12/
      target.value = monthMax + delimiter;
      event.preventDefault();
      return;
    }

    if (value.length === 1 && typeKey !== "specSymbol") {
      //12 backspace -> 1
      target.value = target.value + key + delimiter;
      event.preventDefault();
    }

    if (value.length === 2 && typeKey !== "specSymbol") {
      // 2 -> 02/
      target.value = target.value + delimiter + key;
      event.preventDefault();
      return;
    }

    if (value === "0" && key === "0") {
      // 00 -> 01
      target.value = monthMin + delimiter;
      event.preventDefault();
      return;
    }
  } else {
    event.preventDefault();
    return;
  }
};
