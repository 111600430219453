import React from "react";
import { useState, useEffect, useContext } from "react";
import ContentComponent from "../../Content/Content";
import { useHistory, useLocation } from "react-router-dom";
import { merchantRoutes } from "../../../constants/routes";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import axios from "axios";
import { sassEndpoints } from "../../../constants/endpoints";
import { stringFormat } from "../../../utils/stringHelpers";
import AlertDialog from "../../Dialog/AlertDialog";
import InvoicesSearchResults from "./InvoicesSearchResults";
import Button from "@mui/material/Button";
import GlobalSearch from "../../Search/GlobalSearch";
import { Grid, Link, Tooltip } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

const InvoicesSearchContainer = () => {
  const { userSettings } = useContext(UserSettingsContext);

  const [showBackdrop, setShowBackdrop] = useState(true);

  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [sortField, setSortField] = useState("created_on");
  const [sortDirection, setSortDirection] = useState("desc");
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [invoiceResults, setInvoiceResults] = useState(null);
  const [globalFilters, setGlobalFilters] = useState({ globalSearch: "" });
  const [filterChecked, setFilterChecked] = useState(null);
  const [searchByDate, setSearchByDate] = useState("dueDate");
  const [dates, setDates] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const history = useHistory();
  const location = useLocation();
  const methods = useForm();

  useEffect(() => {
    if (location.state?.success) {
      showSnackBar(location.state.success);
      history.replace({ state: {} });
    } else if (location.state?.subscriptionId) {
      setGlobalFilters((prevFilters) => ({
        ...prevFilters,
        subscriptionId: {
          operator: "Equal",
          value: location.state.subscriptionId,
        },
      }));
      history.replace({ state: {} });
    }
  }, []);

  const [keyInfoLookup, setKeyInfoLookup] = useState([
    { key: "invoice_id", sortable: false, default_field: true },
    { key: "email", sortable: true, default_field: true },
    {
      key: "status",
      sortable: false,
      default_field: true,
      filterable: true,
      filtered: false,
    },
    {
      key: "frequency",
      sortable: false,
      default_field: true,
      filterable: true,
      filtered: false,
    },
    { key: "total_amount", sortable: true, default_field: true },
    { key: "remaining_balance", sortable: true, default_field: true },
    { key: "due_date", sortable: true, default_field: true },
    { key: "created_on", sortable: true, default_field: true },
  ]);
  const keyEnums = {
    invoice_id: "InvoiceId",
    customer_name: "CustomerName",
    captured_amount: "CapturedAmount",
    status: "Status",
    remaining_balance: "RemainingBalance",
    fees: "Fees",
    due_date: "DueDate",
    created_on: "CreatedDateTime",
  };

  const handleSortOnClick = (key) => {
    resetSorting();
    if (key !== sortField) setSortDirection("desc");
    else {
      if (sortDirection === "asc") setSortDirection("desc");
      else setSortDirection("asc");
      keyInfoLookup.map((keyInfo) => {
        return (keyInfo.sortType = keyInfo.key === key ? sortDirection : null);
      });
    }
    setSortField(key);
  };

  const resetSorting = () => {
    keyInfoLookup.map((key) => {
      return (key.sortType = null);
    });
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const handleChangeAlertDialogProps = (props, alertOpen) => {
    if (alertOpen === false) {
      setAlertDialogOpen(false);
    } else {
      setAlertDialogProps(props);
      setAlertDialogOpen(true);
    }
  };

  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultOffset(pageIndex * resultsPerPage);
  };

  const handleResultsPerPageOnChange = (event) => {
    setResultOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setGlobalFilters({
      ...globalFilters,
      globalSearch: methods.getValues("global_search_input"),
    });
  };

  const handleReset = () => {
    setGlobalFilters({ globalSearch: "" });
    methods.resetField("global_search_input");
    setFilterChecked(null);
    setSearchByDate("dueDate");
    setDates([
      {
        startDates: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };

  const handleViewAsPDF = (invoiceId) => {
    const url = stringFormat(sassEndpoints.invoices.pdf, [
      userSettings.gatewayId,
      invoiceId,
    ]);

    setShowBackdrop(true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        window.open(url);
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails.join(", ") ||
            "Failed to retrieve invoice PDF",
        );
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const handleDelete = (invoice) => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: [
        // eslint-disable-next-line prettier/prettier
        `Once this invoice has been ${
          invoice.invoiceStatus.name === "Draft" ? "deleted" : "cancelled"
        } it cannot be undone.`,
      ],
      actionButtons: [
        {
          // eslint-disable-next-line prettier/prettier
          text: `Yes, ${
            invoice.invoiceStatus.name === "Draft" ? "Delete" : "Cancel"
            // eslint-disable-next-line prettier/prettier
          } Invoice!`,
          color: "primary",
          onclick: () => deleteInvoice(invoice.invoiceId),
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const deleteInvoice = (invoiceId) => {
    const url = stringFormat(sassEndpoints.invoices.delete, [
      userSettings.gatewayId,
      invoiceId,
    ]);

    setShowBackdrop(true);

    axios
      .delete(url)
      .then(() => {
        setGlobalFilters({ ...globalFilters });
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails.join(", ") ||
            "Failed to delete invoice",
        );
      })
      .finally(() => {
        setAlertDialogOpen(false);
        setShowBackdrop(false);
        setGlobalFilters({ ...globalFilters });
      });
  };

  useEffect(() => {
    let newKeyInfo = [...keyInfoLookup];
    let statusKeyInfo = newKeyInfo.find((obj) => obj.key === "status");
    let frequencyKeyInfo = newKeyInfo.find((obj) => obj.key === "frequency");

    statusKeyInfo.filtered = Boolean(globalFilters.status);
    frequencyKeyInfo.filtered = Boolean(globalFilters.type);
    setKeyInfoLookup(newKeyInfo);

    if (globalFilters.globalSearch === "")
      methods.resetField("global_search_input");
  }, [globalFilters]);

  useEffect(() => {
    if (location.state?.subscriptionId) {
      (globalFilters.subscriptionId = {
        operator: "Equal",
        value: location.state.subscriptionId,
      }),
        history.replace({ state: {} });
    }

    setShowBackdrop(true);

    globalFilters.limit = resultsPerPage;
    globalFilters.offset =
      globalFilters.globalSearch?.length > 0 && globalFilters.offset > 0
        ? 0
        : resultOffset;
    globalFilters.sortColumn = keyEnums[sortField];
    globalFilters.sortDirection = sortDirection.toUpperCase();

    let url = stringFormat(sassEndpoints.invoices.search, [
      userSettings.gatewayId,
    ]);
    axios
      .post(url, globalFilters)
      .then((resp) => {
        if (resp.status === 204) {
          setInvoiceResults(null);
          setResultsTotal(0);
        } else {
          setInvoiceResults(resp.data.data.results);
          setResultsTotal(resp.data.data.rowCount);
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to retrieve invoices.",
        );
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  }, [
    userSettings,
    resultOffset,
    resultsPerPage,
    sortField,
    sortDirection,
    globalFilters,
  ]);

  return (
    <>
      <AlertDialog
        alertOpen={alertDialogOpen}
        alertTitle="Error!"
        alertLevel="error"
        closeButtonText="Ok"
        closeButtonColor="secondary"
        onCloseButtonClick={() => setAlertDialogOpen(false)}
      />
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="Invoices"
        headerContent={
          <FormProvider {...methods}>
            <form onSubmit={handleSearch}>
              <Grid container className="create-search-div">
                <Grid item xs={12} sm={4}>
                  <Button
                    className="create-invoice-btn"
                    variant="contained"
                    color="primary"
                    disabled={
                      !userSettings?.gatewayUserPermissions["CREATE_INVOICE"]
                    }
                    onClick={() => history.push(merchantRoutes.invoices.create)}
                    data-cy="create-invoice"
                  >
                    Create Invoice +
                  </Button>
                </Grid>
                <GlobalSearch
                  globalFilters={globalFilters}
                  setGlobalFilters={setGlobalFilters}
                  dates={dates}
                  setDates={setDates}
                  searchByDate={searchByDate}
                  setSearchByDate={setSearchByDate}
                  onSearch={handleSearch}
                />
              </Grid>
              <Tooltip
                title="Clears all applied filters and dates"
                placement="left"
              >
                <Link
                  component="button"
                  type="reset"
                  color="secondary"
                  className="reset-filter-button"
                  onClick={handleReset}
                >
                  Reset
                </Link>
              </Tooltip>
            </form>
          </FormProvider>
        }
        bodyContent={
          <>
            <InvoicesSearchResults
              invoiceResults={invoiceResults}
              handleSortOnClick={handleSortOnClick}
              userSettings={userSettings}
              handleChangeAlertDialogProps={handleChangeAlertDialogProps}
              showSnackBar={showSnackBar}
              showAlertDialogError={showAlertDialogError}
              resultsPerPage={resultsPerPage}
              resultsTotal={resultsTotal}
              handleChange={handlePageChange}
              handleResultsPerPageOnChange={handleResultsPerPageOnChange}
              keyInfoLookup={keyInfoLookup}
              globalFilters={globalFilters}
              setGlobalFilters={setGlobalFilters}
              filterChecked={filterChecked}
              setFilterChecked={setFilterChecked}
              onViewAsPDF={handleViewAsPDF}
              onDelete={handleDelete}
            />
          </>
        }
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
      />
    </>
  );
};

export default InvoicesSearchContainer;
