export const regexPatterns = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //comma seperated list of email addresses
  multipleEmail:
    /^(\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/,
  nonNumeric: /\D/g,
  nonNumericAuth: /[^0-9-]+/g,
  plusMinus: /\+|-/,
  charMax100: /\\A([-a-zA-Z0-9\\xC0-\\xFF \\.#,'&]{1,100})\\z/,
  alphaNumericOnly: /[^a-z0-9]/gi,
  numeric: /^\d+$/,
  numericNoLeadingZero: /^[1-9]\d*$/,
  alphaNoSpace: /^[a-zA-Z]+$/,
  alphaSpace: /^[a-zA-Z ]+$/,
  numericSpace: /^[0-9 ]+$/,
  alphanumericSpace: /^[a-zA-Z0-9 ]+$/,
  alphanumericNoSpace: /^[a-zA-Z0-9\-_]{1,255}$/,
  alphanumeric: /^[a-zA-Z0-9]+$/,
  name: /^[a-zA-Z '-]+$/,
  nameSpecialChar:
    /^(?![\s]+$)[a-zA-Z0-9\s'!@#$%^&*()_+=\-[\]{}|:;"'<>,.?\\/]{1,100}$/,
  city: /^[a-zA-Z\u0080-\u024F\s/\-)(`."']+$/,
  website:
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi, //eslint-disable-line
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/,
  transaction: {
    detailRegex: /\/merchant\/transaction\/detail\/[^}]+/,
    link: "/merchant/transaction",
  },
  invoice: {
    detailRegex: /\/merchant\/invoices\/[^}]+/,
    link: "/merchant/invoices/search",
  },
  customer: {
    detailRegex: /\/merchant\/customer\/[^}]+\/?payments/,
    link: "/merchant/customer/search",
  },
  customField: {
    detailRegex: /\/merchant\/manage\/custom-fields\/update\/[^}]+/,
    link: "/merchant/manage/custom-fields",
  },
};
