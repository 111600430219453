import React, { useState, useContext, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import { regexPatterns } from "../../../constants/regexPatterns";
import { HexColorPicker, HexColorInput } from "react-colorful";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import { ManageSettingsContext } from "./ManageSettings";
const ColorPickerModal = ({ currentValue, onSelection, type, open }) => {
  const [color, setColor] = useState(currentValue ? currentValue : "#000");
  const onClose = () => {
    onSelection(color, type);
  };
  return (
    <Dialog className="color-picker-modal" onClose={onClose} open={open}>
      <div>
        <HexColorPicker color={color} onChange={setColor} />
        <div className="input-container">
          <HexColorInput prefixed={true} color={color} onChange={setColor} />
        </div>
      </div>
    </Dialog>
  );
};
ColorPickerModal.propTypes = {
  currentValue: PropTypes.string,
  onSelection: PropTypes.func,
  type: PropTypes.string,
  open: PropTypes.bool,
};

const PayablesTemplates = () => {
  const baseModalState = { isOpen: false, type: null, color: null };
  const methods = useFormContext();
  const [modalProps, setModalProps] = useState(baseModalState);
  const settingsContext = useContext(ManageSettingsContext);
  const [templateSettings, setTemplates] = useState(null);

  useEffect(() => {
    const category = settingsContext?.categories?.find((c) => {
      return c?.code === "templates";
    });
    if (category) {
      setTemplates(category.groups[0].fields);
    }
  }, [settingsContext]);

  const onSelection = (value, type) => {
    methods.setValue(type, value);
    setModalProps(baseModalState);
  };

  const openModal = (type, color) => {
    setModalProps({
      isOpen: true,
      type: type,
      color: color,
    });
  };
  return (
    <div className="payables-templates">
      {templateSettings !== null && (
        <div>
          <Select
            control={methods.control}
            name="template-type"
            fullWidth
            defaultValue={"customerPayables"}
            disabled={true}
            sx={{ width: "30%" }}
          >
            <MenuItem value={"customerPayables"}>Customer Payables</MenuItem>
          </Select>

          <div className="template-block">
            <div className="header-block">
              <h2>Add Logo</h2>
            </div>
            <p>
              Maximum size: 20MB.{" "}
              <span>
                Logo should be saved as a transparent PNG with a width of 350
                pixels and height of 100 pixels.
              </span>
            </p>
            <RegisteredTextField
              id="header-logo"
              name={"add_logo_url"}
              fullWidth
              defaultValue={
                typeof templateSettings?.find((s) => {
                  return s.code === "add_logo_url";
                })?.value === "object"
                  ? templateSettings?.find((s) => {
                      return s.code === "add_logo_url";
                    })?.value[0]
                  : templateSettings?.find((s) => {
                      return s.code === "add_logo_url";
                    })?.value || ""
              }
              rules={{
                pattern: {
                  value: regexPatterns.website,
                  message: "Please enter a valid url.",
                },
              }}
            />
          </div>
          <div className="template-block">
            <div className="header-block">
              <h2>Add Custom Colors</h2>
            </div>
            <div className="color-picker-row">
              <div className="color-picker-title">
                <span>Main Color</span>
              </div>
              <div className="color-picker-output">
                <Controller
                  control={methods.control}
                  name="add_customer_colors_main_color"
                  defaultValue={
                    typeof templateSettings?.find((s) => {
                      return s?.code === "add_customer_colors_main_color";
                    })?.value === "object"
                      ? templateSettings?.find((s) => {
                          return s?.code === "add_customer_colors_main_color";
                        })?.value[0]
                      : templateSettings?.find((s) => {
                          return s?.code === "add_customer_colors_main_color";
                        })?.value || "#000"
                  }
                  render={({ field }) => (
                    <>
                      <button
                        onClick={() =>
                          openModal(
                            field.name,
                            typeof field.value === "object"
                              ? field.value[0]
                              : field.value,
                          )
                        }
                        type="button"
                        className="color-picker-preview"
                      >
                        <div
                          style={{
                            background:
                              typeof field.value === "object"
                                ? field.value[0]
                                : field.value,
                          }}
                        ></div>
                      </button>
                      <HexColorInput
                        prefixed={true}
                        color={
                          typeof field.value === "object"
                            ? field.value[0]
                            : field.value
                        }
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <div className="color-picker-row">
              <div className="color-picker-title">
                <span>Secondary Color</span>
              </div>
              <div className="color-picker-output">
                <Controller
                  control={methods.control}
                  name="add_customer_colors_secondary_color"
                  defaultValue={
                    typeof templateSettings?.find((s) => {
                      return s?.code === "add_customer_colors_secondary_color";
                    }).value === "object"
                      ? templateSettings?.find((s) => {
                          return (
                            s?.code === "add_customer_colors_secondary_color"
                          );
                        }).value[0]
                      : templateSettings?.find((s) => {
                          return (
                            s?.code === "add_customer_colors_secondary_color"
                          );
                        }).value || "#000"
                  }
                  render={({ field }) => (
                    <>
                      <button
                        onClick={() => {
                          openModal(
                            field.name,
                            typeof field.value === "object"
                              ? field.value[0]
                              : field.value,
                          );
                        }}
                        type="button"
                        className="color-picker-preview"
                      >
                        <div
                          style={{
                            background:
                              typeof field.value === "object"
                                ? field.value[0]
                                : field.value,
                          }}
                        ></div>
                      </button>
                      <HexColorInput
                        prefixed={true}
                        color={
                          typeof field.value === "object"
                            ? field.value[0]
                            : field.value
                        }
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="template-block">
            <div className="header-block">
              <h2>Add Footer Image</h2>
            </div>
            <p>
              Maximum size: 20MB.{" "}
              <span>
                Image should be saved as a transparent PNG and will be
                positioned at the center of the footer. Images wider than 1200
                pixels will likely be stretched to span the full page width.
              </span>
            </p>
            <RegisteredTextField
              id="footer-logo"
              name={"add_footer_image_url"}
              fullWidth
              defaultValue={
                typeof templateSettings?.find((s) => {
                  return s?.code === "add_footer_image_url";
                })?.value === "object"
                  ? templateSettings?.find((s) => {
                      return s?.code === "add_footer_image_url";
                    })?.value[0]
                  : templateSettings?.find((s) => {
                      return s?.code === "add_footer_image_url";
                    })?.value || ""
              }
              rules={{
                pattern: {
                  value: regexPatterns.website,
                  message: "Please enter a valid url.",
                },
              }}
            />
          </div>
          {modalProps.isOpen && (
            <ColorPickerModal
              onSelection={onSelection}
              open={modalProps.isOpen}
              type={modalProps.type}
              currentValue={modalProps.color}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PayablesTemplates;
