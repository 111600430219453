import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Button,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PropTypes from "prop-types";
import { capitalize } from "../../utils/stringHelpers";
import RegisteredTextField from "../ReactHookForm/RegisteredTextField";
import { validationRules } from "../../constants/validationRules";
import { useFormContext } from "react-hook-form";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { formatDateTime } from "../../utils/stringHelpers";

const ApplicationDetails = ({
  data,
  setAlertDialogProps,
  setIsAlertOpen,
  callCreateSecret,
  callDeleteSecret,
}) => {
  const notExpiredSecretsLength = data.secrets.filter((s) => {
    return s.status !== "expired";
  }).length;

  const methods = useFormContext();
  const [addSecret, setAddSecret] = useState(false);
  const [secretNameError, setSecretNameError] = useState(true);

  const handleClickDelete = (appId, secretId) => {
    setAlertDialogProps({
      alertLevel: "info",
      alertMessages: [
        " Are you sure you want to delete this secret? Deleting this secret will cause the any integration using it to no longer function.",
      ],
      actionButtons: [
        {
          text: "Yes, Delete ",
          color: "secondary",
          onclick: () => {
            callDeleteSecret(appId, secretId);
            setIsAlertOpen(false);
          },
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: () => {
        setIsAlertOpen(false);
      },
    });
    setIsAlertOpen(true);
  };
  return (
    <div className="app-details-div">
      <div
        className="application-detail-bold"
        style={{ fontSize: "20px", marginBottom: "20px" }}
      >
        Details
      </div>

      <Grid container flexDirection={"column"} className="create-edit-divs">
        <Grid item className="application-detail-bold">
          Application Name
        </Grid>
        <Grid item>{data.microsoftDisplayName}</Grid>
        <br />
        <Grid item className="application-detail-bold">
          Client ID: {data.microsoftAppId}
        </Grid>
        <br />
        <Grid item className="application-detail-bold">
          Role:
        </Grid>
        <Grid item>{data.role.description}</Grid>
        <div style={{ fontSize: "12px" }}>
          {data.role.description === "Integrator Service Account"
            ? "Selecting this role allows your application to process and view transactions of all types in the gateway, manage the customer vault, and batched settlements. As well as being able to view, create, edit, and delete all invoices and products in the gateway, and view and manage customer payable tokens."
            : data.role.description === "Integrator Gateway Standard"
              ? "Selecting this role allow your application to process all transaction types, manage the customer vault, and view invoices and batched settlements"
              : ""}
        </div>
      </Grid>
      <div
        className="application-detail-bold"
        style={{
          marginTop: "20px",
          fontSize: "16px",
        }}
      >
        Secrets
        {data.secrets.length > 0 && (
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            Configure up to two active or scheduled secrets for your
            application. Only one secret can be created at a time.{" "}
          </div>
        )}
        <Grid
          container
          flexDirection={"column"}
          className="create-edit-divs"
          style={{ padding: "0px", overflowX: "scroll" }}
        >
          <Grid item className="application-detail-bold">
            <div>
              {data.secrets.length === 0 && !addSecret ? (
                <div
                  style={{
                    padding: "16px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  No secrets have been configured for this application.
                </div>
              ) : (
                <>
                  <Table>
                    <TableHead data-cy="secret-table-header">
                      <TableRow>
                        {" "}
                        <TableCell>Secret Name</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.secrets.length > 0 &&
                        data.secrets.map((s, i) => (
                          <TableRow key={i}>
                            <TableCell style={{ wordBreak: "break-all" }}>
                              {" "}
                              {s.displayName}
                            </TableCell>
                            <TableCell>
                              {formatDateTime(s.startDateTime).split(" ")[0]}
                            </TableCell>
                            <TableCell>
                              {formatDateTime(s.endDateTime).split(" ")[0]}
                            </TableCell>
                            <TableCell>
                              {s.status.toLowerCase() === "expired" ? (
                                <p style={{ display: "flex" }}>
                                  {capitalize(s.status)}
                                  <Tooltip title="Inactive due to lapsed end date">
                                    <ErrorOutlineIcon
                                      variant="container"
                                      fontSize="small"
                                      className="expired-secret"
                                    />
                                  </Tooltip>
                                </p>
                              ) : (
                                capitalize(s.status)
                              )}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  variant="outlined"
                                  className="delete-button"
                                  sx={{ padding: 0.5 }}
                                  onClick={() =>
                                    handleClickDelete(
                                      s.appRegistrationId,
                                      s.appSecretId,
                                    )
                                  }
                                  fontSize="small"
                                >
                                  <HighlightOffIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      {addSecret && (
                        <TableRow>
                          <TableCell>
                            <Grid container flexDirection={"row"}>
                              <Grid item xs={8}>
                                <RegisteredTextField
                                  name={`secret`}
                                  placeholder={"Enter Secret Name"}
                                  fullWidth
                                  onBlur={async () => {
                                    let secretError =
                                      await methods.trigger("secret");
                                    if (secretError) {
                                      setSecretNameError(false);
                                    } else {
                                      setSecretNameError(true);
                                    }
                                  }}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "required",
                                    },
                                    pattern: {
                                      value: /^[a-zA-Z0-9\-. ]+$/,
                                      message:
                                        "Only alphanumeric and the following special characters are valid: - .",
                                    },
                                    maxLength: validationRules.maxLength50,
                                  }}
                                ></RegisteredTextField>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  className="clear-button"
                                  onClick={() => {
                                    setAddSecret(false);
                                    setSecretNameError(true);
                                    methods.setValue("secret", "");
                                  }}
                                  data-cy="clear-secret-name"
                                >
                                  X Clear
                                </Button>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell> -- </TableCell>
                          <TableCell> -- </TableCell>
                          <TableCell> -- </TableCell>
                          <TableCell>
                            <Tooltip
                              data-cy="secret-create-tooltip"
                              title={
                                secretNameError ? "Enter valid secret name" : ""
                              }
                            >
                              <div>
                                <Button
                                  variant="contained"
                                  style={{ padding: "0px" }}
                                  className={
                                    secretNameError
                                      ? "create-button disabled"
                                      : "create-button"
                                  }
                                  disabled={secretNameError}
                                  onClick={() => {
                                    callCreateSecret(data.appRegistrationId, {
                                      displayName: methods.getValues("secret"),
                                    });
                                  }}
                                  data-cy="create-secret"
                                >
                                  Create
                                </Button>
                              </div>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </>
              )}
            </div>
          </Grid>
          {notExpiredSecretsLength < 2 &&
            !(notExpiredSecretsLength === 1 && addSecret) && (
              <Grid item>
                <Button
                  className={
                    addSecret
                      ? "add-secret-button disabled"
                      : "add-secret-button"
                  }
                  disabled={addSecret}
                  onClick={() => {
                    setAddSecret(true);
                  }}
                  data-cy="add-secret-button"
                >
                  + Add New Secret
                </Button>
              </Grid>
            )}
          {notExpiredSecretsLength >= 2 && (
            <p style={{ fontSize: "16px", fontWeight: "500", padding: "16px" }}>
              The maximum number of two active or scheduled secrets per
              application has been reached.{" "}
            </p>
          )}
        </Grid>
      </div>
    </div>
  );
};
ApplicationDetails.propTypes = {
  data: PropTypes.object,
  setAlertDialogProps: PropTypes.func,
  setIsAlertOpen: PropTypes.func,
  callCreateSecret: PropTypes.func,
  callDeleteSecret: PropTypes.func,
};

export default ApplicationDetails;

//CHECK IF CREATE BUTTON IS DISABLED EVERY TIME WE CLICK ADD SECRET
