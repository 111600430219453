import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import "../simplePay.scss";
import Switch from "../../../ReactHookForm/Switch";
import Grid from "@mui/material/Grid";
import "../../../../assets/v2/main.scss";
import FormLabel from "@mui/material/FormLabel";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import PropTypes from "prop-types";
import { HexColorPicker, HexColorInput } from "react-colorful";
import Dialog from "@mui/material/Dialog";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel } from "@mui/material";
import FileUploader from "../FileUploader";
import UncustomizedSimplepay from "../../../../assets/images/uncustomized-simplepay.png";
import CustomizedSimplepay from "../../../../assets/images/customized-simplepay.png";
import TwoTabsInput from "../TwoTabsInput";

const ColorPickerModal = ({
  currentValue,
  onSelection,
  type,
  open,
  canEditSimplePay,
}) => {
  const [color, setColor] = useState(currentValue ? currentValue : "#000");
  const onClose = () => {
    onSelection(color, type);
  };

  return (
    <Dialog className="color-picker-modal" onClose={onClose} open={open}>
      <div>
        <HexColorPicker color={color} onChange={setColor} />
        <div className="input-container">
          <HexColorInput
            prefixed={true}
            color={color}
            onChange={setColor}
            disabled={!canEditSimplePay}
          />
        </div>
      </div>
    </Dialog>
  );
};

ColorPickerModal.propTypes = {
  currentValue: PropTypes.string,
  onSelection: PropTypes.func,
  type: PropTypes.string,
  open: PropTypes.bool,
  canEditSimplePay: PropTypes.bool.isRequired,
};

const bannerTypeOptions = ["Warning", "Regular", "Success", "Dangerous"];

const CustomizePage = ({ canEditSimplePay }) => {
  const methods = useFormContext();
  const baseModalState = { isOpen: false, type: null, color: null };
  const [modalProps, setModalProps] = useState(baseModalState);
  const watchDisplayBanner = methods.watch("display_banner");

  const openModal = (type, color) => {
    if (!canEditSimplePay) return; // Prevent opening modal if no edit permission
    setModalProps({
      isOpen: true,
      type: type,
      color: color,
    });
  };

  const onSelection = (value, type) => {
    methods.setValue(type, value);
    setModalProps(baseModalState);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} className="left-grid">
          <FormLabel component="legend">Banner</FormLabel>
          <div className="input-block column-switches">
            <Switch
              name={"display_banner"}
              label={"Display Banner Message"}
              control={methods.control}
              disabled={!canEditSimplePay}
            />
          </div>
        </Grid>
        <Grid item xs={6} />
        {watchDisplayBanner && (
          <>
            <Grid item xs={3} className="left-grid">
              <Select
                control={methods.control}
                name="banner_type_id"
                label="Banner Type"
                fullWidth
                v2={true}
                disabled={!canEditSimplePay}
              >
                {bannerTypeOptions.map((bannerType) => (
                  <MenuItem
                    key={bannerTypeOptions.indexOf(bannerType) + 1}
                    value={bannerTypeOptions.indexOf(bannerType) + 1}
                  >
                    {bannerType}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={8}>
              <RegisteredTextField
                name={"banner_message"}
                label={"Banner Message"}
                v2={true}
                disabled={!canEditSimplePay}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6} className="left-grid">
          <FormLabel component="legend">Header Content</FormLabel>
          <TwoTabsInput
            name={"logo_align"}
            defaultValue="left"
            leftValue="left"
            rightValue="center"
            leftLabel="Aligned Left"
            rightLabel="Aligned Center"
            leftHelperText="(Height is 100px or less)"
            rightHelperText="(Any logo over 100px in height)"
            disabled={!canEditSimplePay}
          />
          <FileUploader
            name={"logo"}
            acceptedTypes={{ "image/png": [".png"], "image/jpeg": [".jpg"] }}
            disabled={!canEditSimplePay}
          />
          <div className={"column-switches"}>
            <RegisteredTextField
              name={"header_name"}
              label={"Header Name"}
              v2={true}
              disabled={!canEditSimplePay}
              showRequired={true}
              rules={{ required: true }}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="color-picker-row">
            <div className="color-picker-title">
              <InputLabel>Primary Color:</InputLabel>
            </div>
            <div className="color-picker-output">
              <Controller
                control={methods.control}
                name="primary_color"
                render={({ field }) => (
                  <>
                    <button
                      onClick={() =>
                        openModal(
                          field.name,
                          typeof field.value === "object"
                            ? field.value[0]
                            : field.value,
                        )
                      }
                      type="button"
                      className="color-picker-preview"
                      disabled={!canEditSimplePay}
                    >
                      <div
                        style={{
                          background:
                            typeof field.value === "object"
                              ? field.value[0]
                              : field.value,
                        }}
                      ></div>
                    </button>
                    <HexColorInput
                      prefixed={true}
                      color={
                        typeof field.value === "object"
                          ? field.value[0]
                          : field.value
                      }
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      disabled={!canEditSimplePay}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="color-picker-row">
            <div className="color-picker-title">
              <InputLabel>Background Color:</InputLabel>
            </div>
            <div className="color-picker-output">
              <Controller
                control={methods.control}
                name="background_color"
                render={({ field }) => (
                  <>
                    <button
                      onClick={() =>
                        openModal(
                          field.name,
                          typeof field.value === "object"
                            ? field.value[0]
                            : field.value,
                        )
                      }
                      type="button"
                      className="color-picker-preview"
                      disabled={!canEditSimplePay}
                    >
                      <div
                        style={{
                          background:
                            typeof field.value === "object"
                              ? field.value[0]
                              : field.value,
                        }}
                      ></div>
                    </button>
                    <HexColorInput
                      prefixed={true}
                      color={
                        typeof field.value === "object"
                          ? field.value[0]
                          : field.value
                      }
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      disabled={!canEditSimplePay}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="color-picker-row">
            <div className="color-picker-title">
              <InputLabel>Payment Block Color:</InputLabel>
            </div>
            <div className="color-picker-output">
              <Controller
                control={methods.control}
                name="payment_color"
                render={({ field }) => (
                  <>
                    <button
                      onClick={() =>
                        openModal(
                          field.name,
                          typeof field.value === "object"
                            ? field.value[0]
                            : field.value,
                        )
                      }
                      type="button"
                      className="color-picker-preview"
                      disabled={!canEditSimplePay}
                    >
                      <div
                        style={{
                          background:
                            typeof field.value === "object"
                              ? field.value[0]
                              : field.value,
                        }}
                      ></div>
                    </button>
                    <HexColorInput
                      prefixed={true}
                      color={
                        typeof field.value === "object"
                          ? field.value[0]
                          : field.value
                      }
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      disabled={!canEditSimplePay}
                    />
                  </>
                )}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="img-example-grid">
            <div>
              <FormLabel component="legend">Standard</FormLabel>
              <img src={UncustomizedSimplepay} alt="Uncustomized Simplepay" />
            </div>
            <div>
              <FormLabel component="legend">
                Example of a customized page
              </FormLabel>
              <img src={CustomizedSimplepay} alt="Customized Simplepay" />
            </div>
          </div>
        </Grid>
      </Grid>
      {modalProps.isOpen && (
        <ColorPickerModal
          onSelection={onSelection}
          open={modalProps.isOpen}
          type={modalProps.type}
          currentValue={modalProps.color}
          canEditSimplePay={canEditSimplePay}
        />
      )}
    </>
  );
};

CustomizePage.propTypes = {
  canEditSimplePay: PropTypes.bool.isRequired,
};

export default CustomizePage;
