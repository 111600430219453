import React from "react";
import PropTypes from "prop-types";
import { Drawer, Grid, Typography, IconButton } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CustomerSearch from "../../VirtualTerminal/CustomerSearch";

const SelectCustomerDrawer = ({
  open,
  setOpenDrawer,
  showCreateCustomer,
  onCreateCustomer,
  onCustomerSelect,
}) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpenDrawer(false)}
      PaperProps={{
        className: "remittance-drawer",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant="h2" align="left" className="remittance-heading">
            Select a Customer
          </Typography>
        </Grid>
        <Grid item container xs justifyContent="flex-end">
          <IconButton
            aria-label="close"
            className="remittance-close-button"
            onClick={() => setOpenDrawer(false)}
          >
            <CancelOutlinedIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
      <CustomerSearch
        showAdvancedSearch={false}
        showDivider={false}
        showCancelButton
        resultCount={10}
        onCustomerSelect={onCustomerSelect}
        showCreateCustomer={showCreateCustomer}
        onCreateCustomer={onCreateCustomer}
        onCancel={() => setOpenDrawer(false)}
      />
    </Drawer>
  );
};

SelectCustomerDrawer.propTypes = {
  open: PropTypes.bool,
  showCreateCustomer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  onCustomerSelect: PropTypes.func,
  onCreateCustomer: PropTypes.func,
};

export default SelectCustomerDrawer;
