import React, { useState } from "react";
import PropTypes from "prop-types";
import ConfirmationDialog from "./ConfirmationDialog";
import { FormControlLabel, Grid, Radio } from "@mui/material";

const ResumeDialog = ({ open, onConfirm, onClose }) => {
  const [prorate, setProrate] = useState(true);

  const handleConfirm = () => {
    const payload = {
      prorateRemainingDays: prorate,
    };

    onConfirm(payload);
  };

  return (
    <ConfirmationDialog
      alertTitle="Please select an option:"
      alertLevel="warning"
      open={open}
      onConfirm={handleConfirm}
      onClose={onClose}
      okButtonClass="btn--primary__danger"
      okButtonText="Resume Subscription"
      closeButtonClass="btn--secondary__danger"
      closeButtonText="Cancel"
      content={
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              label="Resume subscription and charge for the remaining billing period"
              labelPlacement="end"
              checked={prorate}
              onChange={() => setProrate(true)}
              control={<Radio />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Resume subscription and do not charge for the remaining billing period"
              labelPlacement="end"
              checked={!prorate}
              onChange={() => setProrate(false)}
              control={<Radio />}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

ResumeDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default ResumeDialog;
