import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import IncreaseAuthForm from "./IncreaseAuthForm";
import { useForm, FormProvider } from "react-hook-form";

const IncreaseAuthDialog = ({
  open,
  onClose,
  transactionData,
  onProcess,
  gatewayInfo,
}) => {
  const methods = useForm();
  const [error, setError] = useState("");

  useEffect(() => {
    if (open) {
      methods.reset();
    }
  }, [open, methods]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box width="100%" textAlign="center" mt={1} mb={1}>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ fontSize: "2rem", mt: 1, mb: 0.5 }}
          >
            Add Tip Amount
          </Typography>
        </Box>
      </DialogTitle>
      <FormProvider {...methods}>
        <form>
          <IncreaseAuthForm
            transactionData={transactionData}
            setError={setError}
            gatewayInfo={gatewayInfo}
          />
        </form>
      </FormProvider>
      <DialogActions
        style={{
          justifyContent: "center",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
        }}
      >
        <Box
          width="calc(100% - 32px)"
          display="flex"
          justifyContent="center"
          px={2}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => onProcess(methods.getValues("increaseAmount") / 100)}
            disabled={!!error}
            style={{ boxSizing: "border-box" }}
          >
            Process
          </Button>
        </Box>
        <Box
          width="calc(100% - 32px)"
          display="flex"
          justifyContent="center"
          px={2}
          pb={3}
          paddingLeft="8px"
        >
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={onClose}
            style={{ boxSizing: "border-box" }}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

IncreaseAuthDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transactionData: PropTypes.object.isRequired,
  onProcess: PropTypes.func.isRequired,
  gatewayInfo: PropTypes.object.isRequired,
};

export default IncreaseAuthDialog;
