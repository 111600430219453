const getProperty = (path, obj, separator = ".") => {
  var properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

const getResponseErrorMessage = (error) =>
  error?.response?.data?.msg ||
  error?.message ||
  error?.response?.data ||
  "Request failed";

const deepCopyFunction = (inObject) => {
  let outObject, value, key;
  if (typeof inObject !== "object" || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }
  outObject = Array.isArray(inObject) ? [] : {};
  for (key in inObject) {
    value = inObject[key];
    outObject[key] = deepCopyFunction(value);
  }
  return outObject;
};

const userHasPermission = (permissionName, userSettings) => {
  return userSettings?.userPermissions?.find((p) => p?.code === permissionName);
};

//Query a Gateway Configuration object for a particular field's value
const getGatewayConfigFieldVal = (
  gatewayConfig,
  categoryCode,
  groupName,
  fieldCode,
) => {
  const valueField = gatewayConfig.categories
    .find((c) => c.code === categoryCode)
    ?.groups.find((g) => g.name === groupName)
    ?.fields.find((f) => f.code === fieldCode)?.value;
  if (typeof valueField === "object") {
    return valueField[0];
  }
  return valueField;
};

export {
  getProperty,
  getResponseErrorMessage,
  deepCopyFunction,
  getGatewayConfigFieldVal,
  userHasPermission,
};
