import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import PaymentLabel from "./PaymentLabel";
import FormattedTextField from "../../ReactHookForm/FormattedTextField";
import BasicCard from "../../Cards/BasicCard";
import { useFormContext, useWatch } from "react-hook-form";
import Select from "../../ReactHookForm/Select";
import { useTokenizer } from "../../../hooks/useTokenizer";
import { calculate } from "../../../utils/dineroCalculator";
import { sassEndpoints } from "../../../constants/endpoints";
import PaymentTabs, { PAYMENT_TYPE, LABEL } from "./PaymentTabs";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { TokenizerConfigContext } from "../../../contexts/TokenizerConfigContext";
import { stringFormat } from "../../../utils/stringHelpers";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";
import { iqProVer } from "../../../constants/global";

const amountValidationRules = {
  default: {
    min: { value: 1, message: "Amount must be greater than $0.00" },
    max: {},
    required: true,
  },
  verification: {
    min: {
      value: 0,
      message: "Verification amount must be equal to $0.00",
    },
    max: {
      value: 0,
      message: "Verification amount must be equal to $0.00",
    },
    required: false,
  },
};

const getPaymentAdjustmentLabel = (type) =>
  type === "Flat" ? "Convenience Fee:" : "Service Fee:";

const useTaxRate = (taxLocked, taxExempt) => taxLocked && !taxExempt;

const getRequiredSECFields = (secCode) => {
  let requiredFields = [
    "firstName",
    "lastName",
    "address1",
    "country",
    "state",
    "city",
    "postalCode",
  ];
  if (secCode === "ccd") requiredFields.push("company");
  else if (secCode === "web") requiredFields.push("email");
  else if (secCode === "tel") requiredFields.push("phone");
  return requiredFields;
};
function PaymentTypeSelection({
  types,
  control,
  handlePaymentTypeChange,
  gatewayInfo,
  hideTypes,
}) {
  const { userSettings } = useContext(UserSettingsContext);
  const determineDefault = () => {
    let foundDefault = types.find((t) => {
      return userSettings?.gatewayUserPermissions[t.code.toUpperCase()];
    });
    return foundDefault ? foundDefault.value : "";
  };

  return (
    <Grid item xs={12} sm={5} md={4} lg={3}>
      <Select
        control={control}
        name={"type"}
        label="Type"
        defaultValue={determineDefault()}
        onChange={handlePaymentTypeChange}
        fullWidth
        data-cy="transaction-type"
      >
        {userSettings?.gatewayUserPermissions["PROCESS_SALE"] && (
          <MenuItem value={"sale"}>Sale</MenuItem>
        )}
        {userSettings?.gatewayUserPermissions["PROCESS_AUTHORIZATION"] &&
          !hideTypes && <MenuItem value={"authorize"}>Auth</MenuItem>}
        {userSettings?.gatewayUserPermissions["PROCESS_CREDIT"] &&
          gatewayInfo.gatewaySettings
            .find((s) => s.code === "SUPPORT_BLIND_CREDITS")
            ?.value.toLowerCase() === "true" && (
            <MenuItem value={"credit"}>Credit</MenuItem>
          )}
        {userSettings?.gatewayUserPermissions["PROCESS_VERIFICATION"] &&
          !hideTypes && (
            <MenuItem value={"verification"}>Verification</MenuItem>
          )}
      </Select>
    </Grid>
  );
}

PaymentTypeSelection.propTypes = {
  types: PropTypes.array,
  control: PropTypes.object.isRequired,
  handlePaymentTypeChange: PropTypes.func.isRequired,
  gatewayInfo: PropTypes.object,
  hideTypes: PropTypes.bool,
};

const Payment = ({
  paymentOption,
  setPaymentOption,
  useLineItems,
  setRequiredContactFields,
  setTokenizer,
  tokenizerApiKey,
  handleTokenizerSubmission,
  gatewayConfig,
  gatewayInfo,
  isCustomerLoading,
  customerInfo,
  setDefaultAddresses,
  selectedPaymentTab,
  setSelectedPaymentTab,
  selectedProcessorId,
  achToken,
  setAchToken,
  achSubmit,
  setAchSubmit,
  setShowSurchargeMsg,
  tokenizerV2,
  handleSaasTokenizerSubmission,
  setIsAlertOpen,
}) => {
  const {
    control,
    setValue,
    unregister,
    register,
    getValues,
    clearErrors,
    formState: { isSubmitted, errors },
    trigger,
  } = useFormContext();
  const paymentInfoRef = useRef({});
  const surchargeSettingEnabled =
    gatewayInfo.gatewaySettings
      .find((s) => s.code === "ALLOW_SURCHARGE")
      ?.value.toLowerCase() === "true";
  const [isCardSurchargeable, setIsCardSurchargeable] = useState(false);
  const [surchargeRsp, setSurchargeRsp] = useState(null);
  const [amountVerification, setAmountVerification] = useState(
    amountValidationRules.default,
  );
  const { userSettings } = useContext(UserSettingsContext);

  const [cardBinNumber, setCardBinNumber] = useState(null);
  const [cardToken, setCardToken] = useState(null);
  const types = [
    { code: "process_sale", value: "sale" },
    { code: "process_authorization ", value: "authorize" },
    { code: "process_credit", value: "credit" },
    { code: "process_verification", value: "verification" },
  ];
  const [paymentType, setPaymentType] = useState(
    types.find((t) => {
      return userSettings?.gatewayUserPermissions[t.code.toUpperCase()];
    })
      ? types.find((t) => {
          return userSettings?.gatewayUserPermissions[t.code.toUpperCase()];
        }).value
      : "",
  );

  const [customerSecCode, setCustomerSecCode] = useState("ppd");
  const [hideTypes, setHideTypes] = useState(false);
  const [atAchTab, setAtAchTab] = useState(false);
  const tokenizerSettings = useContext(TokenizerConfigContext);
  const [timer, setTimer] = useState(null);
  const defaultTaxRate = getGatewayConfigFieldVal(
    gatewayConfig,
    "default_values",
    "Merchant",
    "tax_rate",
  );

  const cardBin = useRef("");
  const billing_state = useWatch({ control, name: "billing_address.state" });
  const amount = useWatch({ control, name: "amount", defaultValue: 0 });
  const paymentAdjustment = useWatch({
    control,
    name: "payment_adjustment",
    defaultValue: null,
  });
  const taxAmount = useWatch({ control, name: "tax_amount", defaultValue: 0 });
  const taxLocked = useWatch({
    control,
    name: "tax_locked",
    defaultValue: true,
  });
  const addTaxToTotal = useWatch({
    control,
    name: "add_tax_to_total",
    defaultValue:
      getGatewayConfigFieldVal(
        gatewayConfig,
        "default_values",
        "Virtual Terminal",
        "add_tax_to_total",
      )?.toLowerCase() === "true",
  });
  const taxExempt = useWatch({
    control,
    name: "tax_exempt",
    defaultValue: false,
  });
  const secCode = useWatch({
    control,
    name: "sec_code",
    defaultValue: "ppd",
  });

  let isSurchargeAllowed =
    paymentOption !== PAYMENT_TYPE.ACH &&
    isCardSurchargeable &&
    paymentAdjustment?.type === "Percentage";

  const [results, setResults] = useState({
    amount: 0,
    taxAmount: 0,
    serviceFeeAmount: 0,
    total: 0,
  });

  useEffect(() => {
    setResults(
      calculate({
        amount: amount,
        serviceFee: !surchargeSettingEnabled ? paymentAdjustment?.value : 0,
        serviceFeeType: paymentAdjustment?.type || "NoAdj",
        tax: {
          type: useTaxRate(taxLocked, taxExempt) ? "Percentage" : "Flat",
          value: useTaxRate(taxLocked, taxExempt)
            ? defaultTaxRate || 0
            : taxAmount,
          addToTotal: addTaxToTotal,
        },
        surchargeApplied: surchargeSettingEnabled && isSurchargeAllowed,
        surchargeRsp: surchargeRsp,
      }),
    );
  }, [
    amount,
    paymentAdjustment,
    surchargeSettingEnabled,
    isSurchargeAllowed,
    taxLocked,
    taxExempt,
    addTaxToTotal,
    taxAmount,
    surchargeRsp,
  ]);

  const handlePaymentTypeChange = (paymentType) => {
    clearErrors("amount");
    setPaymentType(paymentType);
    setAmountVerification(
      paymentType === "verification"
        ? amountValidationRules.verification
        : amountValidationRules.default,
    );
  };

  const handleOnMagStripeSwipe = (data) => {
    setValue("billing_address.first_name", data.first_name);
    setValue("billing_address.last_name", data.last_name);
  };

  const handleOnValidCard = (card) => {
    if (card?.bin?.bin?.length === 6) {
      setCardBinNumber(card?.bin?.bin);
    }
    const isSurchargeable = card?.bin?.is_surchargeable || false;
    if (getValues().bin) {
      setValue("bin", card?.bin?.bin);
    } else {
      register("bin");
      setValue("bin", card?.bin?.bin);
    }
    if (isSurchargeable && surchargeSettingEnabled) {
      cardBin.current = card?.bin?.bin;
      binLookup(cardBin.current, billing_state);
    } else {
      setIsCardSurchargeable(false);
      unregister("surcharge");
    }
  };

  //tokenex providing 6 card digits
  const onBinChange = (token) => {
    setCardToken(token);
  };

  const handleAchChange = (data) => {
    if (tokenizerSettings.achTokenizer === iqProVer.v1) {
      setValue("sec_code", data.sec_code);
    } else {
      setValue("sec_code", data);
    }
  };

  useEffect(() => {
    if (atAchTab === true) {
      setHideTypes(true);
    } else {
      setHideTypes(false);
    }
  }, [atAchTab]);

  useEffect(() => {
    setRequiredContactFields(
      paymentOption === PAYMENT_TYPE.ACH
        ? getRequiredSECFields(
            selectedPaymentTab === LABEL.CUSTOMER ? customerSecCode : secCode,
          )
        : [],
    );
  }, [paymentOption, selectedPaymentTab, secCode, customerSecCode]);

  useEffect(() => {
    if (
      results.surchargeAmount &&
      parseFloat(results.surchargeAmount) !== 0 &&
      surchargeSettingEnabled
    ) {
      setShowSurchargeMsg((paymentAdjustment?.value / 1000).toFixed(3));
    } else setShowSurchargeMsg(null);
    setValue("total", results.total);
    if (taxLocked && taxAmount !== results.taxAmount)
      setValue("tax_amount", Number(results.taxAmount));
    if (surchargeSettingEnabled) {
      setValue("surcharge", results.serviceFeeAmount);
    }
  }, [results]);

  useEffect(() => {
    if (taxAmount !== results.taxAmount && taxAmount !== 0)
      setValue(
        "tax_amount",
        useTaxRate(taxLocked, taxExempt) ? results.taxAmount : 0,
      );
  }, [taxLocked, taxExempt]);

  useEffect(() => {
    if (isSubmitted) trigger("amount");
  }, [amountVerification, isSubmitted, trigger]);

  // handle tax amount validation when amount changes after submission
  useEffect(() => {
    if (isSubmitted) {
      if ("tax_amount" in errors) {
        if (amount >= taxAmount) clearErrors("tax_amount");
      } else if (amount < results.taxAmount) {
        trigger("tax_amount");
      }
    }
  }, [amount]);

  const cardTokenizer =
    getGatewayConfigFieldVal(
      gatewayConfig,
      "required_fields",
      "Default",
      "payment_method_card",
    ) !== "hidden" && tokenizerSettings.cardTokenizer !== iqProVer.v2
      ? useTokenizer({
          paymentType: PAYMENT_TYPE.CARD,
          apikey: tokenizerApiKey,
          onSubmit: handleTokenizerSubmission,
          onMagStripeSwipe: handleOnMagStripeSwipe,
          onValidCard: handleOnValidCard,
          container: "#tokenizer-card",
        })
      : null;

  const achTokenizer =
    getGatewayConfigFieldVal(
      gatewayConfig,
      "required_fields",
      "Default",
      "payment_method_ach",
    ) !== "hidden" && tokenizerSettings.achTokenizer !== iqProVer.v2
      ? useTokenizer({
          paymentType: PAYMENT_TYPE.ACH,
          apikey: tokenizerApiKey,
          onSubmit: handleTokenizerSubmission,
          onAchChange: handleAchChange,
          container: "#tokenizer-ach",
        })
      : null;

  const swipeTokenizer =
    getGatewayConfigFieldVal(
      gatewayConfig,
      "required_fields",
      "Default",
      "payment_method_card",
    ) !== "hidden" && tokenizerSettings.cardTokenizer === iqProVer.v1
      ? useTokenizer({
          paymentType: PAYMENT_TYPE.CARD,
          apikey: tokenizerApiKey,
          onSubmit: handleTokenizerSubmission,
          onMagStripeSwipe: handleOnMagStripeSwipe,
          onValidCard: handleOnValidCard,
          container: "#tokenizer-swipe",
        })
      : null;

  useEffect(() => {
    switch (selectedPaymentTab) {
      case "Card":
        setTokenizer(cardTokenizer);
        break;
      case "ACH":
        setTokenizer(achTokenizer);
        break;
      case "Swipe":
        setTokenizer(swipeTokenizer);
        break;
      default:
        setTokenizer(null);
        break;
    }
  }, [
    cardTokenizer,
    achTokenizer,
    swipeTokenizer,
    selectedPaymentTab,
    setTokenizer,
  ]);

  useEffect(() => {
    setTimer(
      setTimeout(() => {
        if (
          surchargeSettingEnabled &&
          paymentOption === PAYMENT_TYPE.CARD &&
          cardBinNumber?.length === 6 &&
          paymentAdjustment.type === "Percentage"
        ) {
          binLookup(cardBinNumber, billing_state);
        } else {
          binLookup(null, billing_state);
        }
      }, 2000),
    );
    return () => {
      clearTimeout(timer);
    };
  }, [amount, taxAmount]);

  useEffect(() => {
    paymentInfoRef.current.surchargeSettingEnabled = surchargeSettingEnabled;
    paymentInfoRef.current.paymentOption = paymentOption;
    paymentInfoRef.current.cardBinNumber = cardBinNumber;
    paymentInfoRef.current.paymentAdjustment = paymentAdjustment;
    paymentInfoRef.current.billing_state = billing_state;
  }, [
    surchargeSettingEnabled,
    paymentOption,
    cardBinNumber,
    paymentAdjustment,
    billing_state,
  ]);

  useEffect(() => {
    if (
      surchargeSettingEnabled &&
      paymentOption === PAYMENT_TYPE.CARD &&
      cardBinNumber?.length === 6 &&
      paymentAdjustment.type === "Percentage"
    ) {
      binLookup(cardBinNumber, billing_state);
    } else if (
      tokenizerSettings.cardTokenizer === iqProVer.v2 &&
      cardToken !== null
    ) {
      binLookup(cardToken, billing_state);
    } else {
      binLookup(null, billing_state);
    }
  }, [
    billing_state,
    surchargeSettingEnabled,
    cardBinNumber,
    paymentAdjustment,
    taxExempt,
    addTaxToTotal,
    cardToken,
  ]);

  const binLookup = (bin, state = "") => {
    if (bin === null) {
      setIsCardSurchargeable(false);
    } else {
      const payload = {
        token: null,
        creditCardBin: bin,
        state: state,
        baseAmount: (amount / 100).toFixed(2),
        paymentAdjustmentType: paymentAdjustment.type,
        paymentAdjustmentValue: (paymentAdjustment.value / 1000).toFixed(3),
        addTaxToTotal: addTaxToTotal,
        taxAmount: (results.taxAmount / 100).toFixed(2),
        processorId: selectedProcessorId,
      };

      if (tokenizerSettings.cardTokenizer === iqProVer.v2) {
        payload.token = bin;
        payload.creditCardBin = null;
      }

      let url = stringFormat(sassEndpoints.transactions.calculateFees, [
        userSettings.gatewayId,
      ]);
      axios
        .post(url, payload)
        .then((response) => {
          setIsCardSurchargeable(response.data.data.isSurchargeable || false);
          if (response.data.data.isSurchargeable) {
            setSurchargeRsp(response.data.data);
          }
        })
        .catch(() => {
          setIsCardSurchargeable(false);
        });
    }
  };
  return (
    <BasicCard title="Payment">
      <Grid container spacing={0.5}>
        <PaymentTypeSelection
          types={types}
          control={control}
          handlePaymentTypeChange={handlePaymentTypeChange}
          gatewayInfo={gatewayInfo}
          hideTypes={hideTypes}
        />
        <Grid item xs={12} sm={7} md={8} lg={9}>
          {useLineItems ? (
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography variant="h4" align="left" noWrap={true}>
                ${amount}
              </Typography>
            </Box>
          ) : (
            <FormattedTextField
              type="currency"
              name="amount"
              label="Amount"
              defaultValue={0}
              rules={amountVerification}
            />
          )}
        </Grid>
        {results.amount !== 0 && (
          <PaymentLabel
            variant="subtitle1"
            label="Subtotal:"
            value={results.amount}
            datacy="subtotal-value"
          />
        )}
        {results.taxAmount !== 0 && addTaxToTotal && (
          <PaymentLabel
            variant="subtitle1"
            label={
              taxLocked && defaultTaxRate ? `Tax %${defaultTaxRate}:` : "Tax:"
            }
            value={results.taxAmount}
            datacy="tax-amount-value"
          />
        )}
        {(results.serviceFeeAmount && results.serviceFeeAmount !== 0) ||
        (results.surchargeAmount && results.surchargeAmount !== 0) ? (
          <PaymentLabel
            variant="subtitle1"
            label={
              results.surchargeAmount && results.surchargeAmount !== 0
                ? "Surcharge:"
                : getPaymentAdjustmentLabel(paymentAdjustment?.type)
            }
            value={
              results.surchargeAmount && results.surchargeAmount !== 0
                ? results.surchargeAmount
                : results.serviceFeeAmount
            }
            datacy={
              results.serviceFeeAmount && results.serviceFeeAmount !== 0
                ? "service-fee-total"
                : "surcharge-total"
            }
          />
        ) : (
          <></>
        )}
        <PaymentLabel variant="h6" label="Total:" value={results.total} />
        <PaymentTabs
          paymentType={paymentType}
          selectedPaymentTab={selectedPaymentTab}
          setSelectedPaymentTab={setSelectedPaymentTab}
          paymentOption={paymentOption}
          setPaymentOption={setPaymentOption}
          isCustomerLoading={isCustomerLoading}
          customerInfo={customerInfo}
          setDefaultAddresses={setDefaultAddresses}
          surchargeSettingEnabled={surchargeSettingEnabled}
          setCustomerSecCode={setCustomerSecCode}
          gatewayConfig={gatewayConfig}
          handleAchChange={handleAchChange}
          achSubmit={achSubmit}
          achToken={achToken}
          setAchToken={setAchToken}
          setAchSubmit={setAchSubmit}
          tokenizerSettings={tokenizerSettings}
          tokenizerV2={tokenizerV2}
          handleSaasTokenizerSubmission={handleSaasTokenizerSubmission}
          setAtAchTab={setAtAchTab}
          onBinChange={onBinChange}
          setCardBinNumber={setCardBinNumber}
          setIsAlertOpen={setIsAlertOpen}
        />
      </Grid>
    </BasicCard>
  );
};

Payment.propTypes = {
  paymentOption: PropTypes.string.isRequired,
  setPaymentOption: PropTypes.func.isRequired,
  useLineItems: PropTypes.bool.isRequired,
  setRequiredContactFields: PropTypes.func.isRequired,
  setTokenizer: PropTypes.func.isRequired,
  tokenizerApiKey: PropTypes.string.isRequired,
  handleTokenizerSubmission: PropTypes.func.isRequired,
  gatewayConfig: PropTypes.object.isRequired,
  gatewayInfo: PropTypes.object.isRequired,
  isCustomerLoading: PropTypes.bool,
  customerInfo: PropTypes.object,
  setDefaultAddresses: PropTypes.func,
  selectedPaymentTab: PropTypes.string,
  setSelectedPaymentTab: PropTypes.func,
  selectedProcessorId: PropTypes.string,
  achToken: PropTypes.string,
  setAchToken: PropTypes.func,
  achSubmit: PropTypes.bool,
  setAchSubmit: PropTypes.func,
  setShowSurchargeMsg: PropTypes.func,
  tokenizerV2: PropTypes.any,
  handleSaasTokenizerSubmission: PropTypes.func,
  setIsAlertOpen: PropTypes.func,
};

export default Payment;
