import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import "./globalSearch.scss";
import SearchIcon from "@mui/icons-material/Search";
import RegisteredTextField from "../ReactHookForm/RegisteredTextField";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DateRangePicker from "./DateRangePicker";

const GlobalSearch = ({
  globalFilters,
  setGlobalFilters,
  dates,
  setDates,
  searchByDate,
  setSearchByDate,
  onSearch,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const defaultDateObj = [
    { label: "Last 7 Days", value: 0 },
    { label: "Last 30 Days", value: 1 },
    { label: "Last Year", value: 2 },
    { label: "YTD", value: 3 },
  ];

  return (
    <>
      <Grid item xs={12} sm={8}>
        <Grid item container className="global-search-div">
          <Grid item className="global-search-input">
            <RegisteredTextField
              className="global-search-input"
              placeholder="Global Search..."
              name="global_search_input"
              autoComplete="off"
            />
            <Button
              name="global_search_button"
              variant="contained"
              color="primary"
              className="global-search-button"
              onClick={onSearch}
            >
              <SearchIcon />
              Search
            </Button>
          </Grid>
          <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
            <DateRangeIcon />
          </Button>
        </Grid>
      </Grid>

      <DateRangePicker
        defaultDateObj={defaultDateObj}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        globalFilters={globalFilters}
        setGlobalFilters={setGlobalFilters}
        dates={dates}
        setDates={setDates}
        searchByDate={searchByDate}
        setSearchByDate={setSearchByDate}
      />
    </>
  );
};

GlobalSearch.propTypes = {
  setGlobalFilters: PropTypes.func,
  onSearch: PropTypes.func,
  globalFilters: PropTypes.any,
  dates: PropTypes.arrayOf(PropTypes.object),
  setDates: PropTypes.func,
  searchByDate: PropTypes.string,
  setSearchByDate: PropTypes.func,
};

export default GlobalSearch;
