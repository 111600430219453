import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormattedTextField from "../../ReactHookForm/FormattedTextField";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import { useFormContext } from "react-hook-form";
import Switch from "../../ReactHookForm/Switch";
import { useEffect } from "react";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { useContext, useState } from "react";
import axios from "axios";
import { stringFormat } from "../../../utils/stringHelpers";
import { sassEndpoints } from "../../../constants/endpoints";
import { useAlertDialog } from "../../../hooks/useAlertDialog";
import AlertDialog from "../..//Dialog/AlertDialog";
import { CONTACT_SUPPORT } from "../../../constants/global";

const DefaultValues = () => {
  const { control, errors } = useFormContext();
  const { userSettings } = useContext(UserSettingsContext);
  const [configDefault, setConfigDefault] = useState(null);
  const [emptyData, setEmptyData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { isAlertOpen, setIsAlertOpen, alertMessages, displayMessages } =
    useAlertDialog(["Error"]);
  useEffect(() => {
    if (!configDefault && emptyData === null) {
      setLoading(true);
      const configUrl = stringFormat(sassEndpoints.gatewayUsers.configuration, [
        userSettings.gatewayId,
      ]);
      axios
        .get(configUrl)
        .then((response) => {
          const valueField = response.data.data.categories
            .find((obj) => obj.displayName === "Default Values")
            .groups.find((obj) => obj.name === "Line Items")
            .fields.find((field) => field.code === "taxable").value;
          if (
            (valueField === "object" && valueField[0].length !== 0) ||
            (valueField !== "object" && valueField.length !== 0)
          ) {
            setEmptyData(false);
            setConfigDefault(
              response.data.data.categories.find(
                (obj) => obj.displayName === "Default Values",
              ),
            );
            setLoading(false);
          } else {
            setLoading(false);
            setEmptyData(true);
          }
        })
        .catch((error) => {
          setIsAlertOpen(true);
          displayMessages([
            error?.response?.data?.statusDetails || CONTACT_SUPPORT,
          ]);
          setLoading(false);
          setEmptyData(true);
          setConfigDefault(false);
        });
    }
  }, []);

  const getDefaultValue = (groupName, fieldCode, defaultValue) => {
    const valueField = configDefault?.groups
      .find((obj) => obj.name === groupName)
      .fields.find((field) => field.code === fieldCode).value;

    if (
      groupName === "Virtual Terminal" ||
      (groupName === "Line Items" && fieldCode === "taxable")
    ) {
      if (typeof valueField === "object") {
        return valueField[0].toLowerCase() === "true";
      }
      return valueField.toLowerCase() === "true" || (emptyData && true);
    } else {
      if (typeof valueField === "object") {
        return valueField[0];
      }
      return valueField || defaultValue;
    }
  };

  return isLoading || emptyData === null ? (
    <></>
  ) : isAlertOpen ? (
    <AlertDialog
      alertTitle="Error"
      alertMessages={alertMessages}
      alertLevel="error"
      alertOpen={isAlertOpen}
      onCloseButtonClick={() => setIsAlertOpen(false)}
    />
  ) : (
    configDefault && (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "1em",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", pb: "1em" }}>
            Virtual Terminal
          </Typography>
          <Switch
            control={control}
            name="virtual_terminal.bypass_rule_engine"
            label="Bypass Rule Engine - Affects Virtual Terminal Only"
            defaultValue={getDefaultValue(
              "Virtual Terminal",
              "bypass_rule_engine",
            )}
          />
          <Switch
            control={control}
            name="virtual_terminal.email_receipt_vt"
            label="Email Receipt"
            defaultValue={getDefaultValue(
              "Virtual Terminal",
              "email_receipt_vt",
            )}
          />
          <Switch
            control={control}
            name="virtual_terminal.allow_partial"
            label="Allow Partial Payment"
            defaultValue={getDefaultValue("Virtual Terminal", "allow_partial")}
          />
          <Switch
            control={control}
            name="virtual_terminal.vault_record"
            label="Vault Record"
            defaultValue={getDefaultValue("Virtual Terminal", "vault_record")}
          />
          <Switch
            control={control}
            name="virtual_terminal.add_tax_to_total"
            label="Add Tax to Total - Affects Virtual Terminal Only"
            defaultValue={getDefaultValue(
              "Virtual Terminal",
              "add_tax_to_total",
            )}
          />
          <Switch
            control={control}
            name="virtual_terminal.add_shipping_to_total"
            label="Add Shipping to Total - Affects Virtual Terminal Only"
            defaultValue={getDefaultValue(
              "Virtual Terminal",
              "add_shipping_to_total",
            )}
          />
          <Switch
            control={control}
            name="virtual_terminal.calculate_total_from_line_items"
            label="Calculate Total From Line Items - Affects Virtual Terminal Only"
            defaultValue={getDefaultValue(
              "Virtual Terminal",
              "calculate_total_from_line_items",
            )}
          />
          <Switch
            defaultChecked
            control={control}
            name="virtual_terminal.enable_advanced_fields"
            label="Enable Advanced Fields"
            defaultValue={getDefaultValue(
              "Virtual Terminal",
              "enable_advanced_fields",
            )}
          />
        </Box>
        <Box sx={{ p: "1em" }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", pb: "1em" }}>
            Line Items
          </Typography>
          <Switch
            defaultChecked
            control={control}
            name="line_items.taxable"
            label="Taxable"
            defaultValue={getDefaultValue("Line Items", "taxable")}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              py: "1em",
              "& .MuiInputBase-root": {
                width: "185px",
                mb: "1em",
              },
              "& .MuiTextField-root": {
                width: "185px",
              },
              "& .MuiFormHelperText-root": {
                width: "300px",
              },
            }}
          >
            <InputLabel
              required={
                configDefault?.groups
                  .find((obj) => obj.name === "Line Items")
                  .fields.find((field) => field.code === "local_tax_rate")
                  .isRequired
              }
            >
              Local Tax Rate
            </InputLabel>
            <FormattedTextField
              required
              rules={{
                required: configDefault?.groups
                  .find((obj) => obj.name === "Line Items")
                  .fields.find((field) => field.code === "local_tax_rate")
                  .isRequired,
              }}
              type="percentage"
              name="line_items.local_tax_rate"
              transformOutput={false}
              defaultValue={getDefaultValue(
                "Line Items",
                "local_tax_rate",
                "0.000",
              )}
            />
            <InputLabel
              required={
                configDefault?.groups
                  .find((obj) => obj.name === "Line Items")
                  .fields.find((field) => field.code === "national_tax_rate")
                  .isRequired
              }
            >
              National Tax Rate
            </InputLabel>
            <FormattedTextField
              type="percentage"
              name="line_items.national_tax_rate"
              transformOutput={false}
              defaultValue={getDefaultValue(
                "Line Items",
                "national_tax_rate",
                "0.000",
              )}
            />
            <InputLabel
              required={
                configDefault?.groups
                  .find((obj) => obj.name === "Line Items")
                  .fields.find((field) => field.code === "unit_of_measure")
                  .isRequired
              }
            >
              Unit of Measure
            </InputLabel>
            <Select
              control={control}
              name="line_items.unit_of_measure"
              defaultValue={getDefaultValue(
                "Line Items",
                "unit_of_measure",
                "NMB",
              )}
            >
              <MenuItem value={"NMB"}>Number</MenuItem>
              <MenuItem value={"ONZ"}>Ounces</MenuItem>
            </Select>
            <InputLabel
              required={
                configDefault?.groups
                  .find((obj) => obj.name === "Line Items")
                  .fields.find((field) => field.code === "commodity_code")
                  .isRequired
              }
            >
              Commodity Code
            </InputLabel>
            <FormattedTextField
              rules={{
                required: configDefault?.groups
                  .find((obj) => obj.name === "Line Items")
                  .fields.find((field) => field.code === "commodity_code")
                  .isRequired,
                minLength: {
                  value: 5,
                  message: "Commodity Code must be 5 digits",
                },
              }}
              maxLength={5}
              type="number"
              name="line_items.commodity_code"
              transformOutput={false}
              defaultValue={getDefaultValue(
                "Line Items",
                "commodity_code",
                "00000",
              )}
            />
            <InputLabel
              required={
                configDefault?.groups
                  .find((obj) => obj.name === "Line Items")
                  .fields.find(
                    (field) => field.code === "alternate_tax_identifier",
                  ).isRequired
              }
            >
              Alternate Tax Identifier
            </InputLabel>
            <FormattedTextField
              type="number"
              name="line_items.alternate_tax_identifier"
              transformOutput={false}
              defaultValue={
                configDefault
                  ? getDefaultValue(
                      "Line Items",
                      "alternate_tax_identifier",
                      "na",
                    )
                  : "na"
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "1em",
            "& .MuiTextField-root": {
              width: "185px",
              mb: ".5em",
            },
            "& .MuiInputBase-root": {
              width: "185px",
              mb: "1em",
            },
            "& .MuiFormHelperText-root": {
              width: "300px",
            },
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", pb: "1em" }}>
            Merchant
          </Typography>
          <InputLabel
            required={
              configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find((field) => field.code === "currency_merchant")
                .isRequired
            }
          >
            Currency
          </InputLabel>
          <Select
            disabled
            control={control}
            name="merchant.currency_merchant"
            defaultValue={"USD"}
          >
            <MenuItem value={"USD"}>USD (United States Dollar)</MenuItem>
            <MenuItem value={"CAD"}>CAD (Canadian Dollar)</MenuItem>
            <MenuItem value={"MXN"}>MXN (Mexican Peso)</MenuItem>
            <MenuItem value={"AUD"}>AUD (Australian Dollar)</MenuItem>
            <MenuItem value={"EUR"}>EUR (Euro)</MenuItem>
            <MenuItem value={"GBP"}>GBP (British Pound)</MenuItem>
            <MenuItem value={"SEK"}>SEK (Swedish Krona)</MenuItem>
            <MenuItem value={"PHP"}>PHP (Philippine Peso)</MenuItem>
            <MenuItem value={"NOK"}>NOK (Norwegian Kroner)</MenuItem>
            <MenuItem value={"NOK"}>NOK (Danish Krone)</MenuItem>
            <MenuItem value={"JPY"}>JPY (Japanese Yen)</MenuItem>
            <MenuItem value={"RUB"}>RUB (Russian Ruble)</MenuItem>
            <MenuItem value={"PLN"}>PLN (Poland zloty)</MenuItem>
            <MenuItem value={"INR"}>INR (Indian Rupee)</MenuItem>
            <MenuItem value={"CNY"}>CNY (Chinese Yuan)</MenuItem>
          </Select>
          <InputLabel
            required={
              configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find((field) => field.code === "tax_rate").isRequired
            }
          >
            Tax Rate
          </InputLabel>
          <FormattedTextField
            rules={{
              required: configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find((field) => field.code === "tax_rate").isRequired,
            }}
            errors={errors}
            type="percentage"
            name="merchant.tax_rate"
            transformOutput={false}
            defaultValue={getDefaultValue("Merchant", "tax_rate", "0.000")}
          />
          <InputLabel
            required={
              configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find((field) => field.code === "tax_identifier")
                .isRequired
            }
          >
            Tax Identifier
          </InputLabel>
          <FormattedTextField
            rules={{
              required: configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find((field) => field.code === "tax_identifier")
                .isRequired,
              minLength: {
                value: 9,
                message: "Tax Identifier must be 9 digits",
              },
            }}
            maxLength={9}
            errors={errors}
            type="number"
            name="merchant.tax_identifier"
            transformOutput={false}
            defaultValue={getDefaultValue(
              "Merchant",
              "tax_identifier",
              "000000000",
            )}
          />
          <InputLabel
            required={
              configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find(
                  (field) => field.code === "ship_from_postal_code_merchant",
                ).isRequired
            }
          >
            Ship From Postal Code
          </InputLabel>
          <FormattedTextField
            rules={{
              required: configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find(
                  (field) => field.code === "ship_from_postal_code_merchant",
                ).isRequired,
              minLength: {
                value: 5,
                message: "Postal Code must be 5 digits",
              },
            }}
            errors={errors}
            type="number"
            name="merchant.ship_from_postal_code_merchant"
            transformOutput={false}
            defaultValue={getDefaultValue(
              "Merchant",
              "ship_from_postal_code_merchant",
              "00000",
            )}
            maxLength={5}
          />
          <InputLabel
            required={
              configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find(
                  (field) =>
                    field.code === "surcharge_additional_state_blacklist",
                ).isRequired
            }
          >
            Surcharge Additional State Blacklist (comma separated, no space)
          </InputLabel>
          <RegisteredTextField
            rules={{
              required: configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find(
                  (field) =>
                    field.code === "surcharge_additional_state_blacklist",
                ).isRequired,
            }}
            name="merchant.surcharge_additional_state_blacklist"
            defaultValue={getDefaultValue(
              "Merchant",
              "surcharge_additional_state_blacklist",
              "na",
            )}
          />

          <InputLabel
            required={
              configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find(
                  (field) => field.code === "summary_commodity_code_merchant",
                ).isRequired
            }
          >
            Summary Commodity Code
          </InputLabel>
          <FormattedTextField
            rules={{
              required: configDefault?.groups
                .find((obj) => obj.name === "Merchant")
                .fields.find(
                  (field) => field.code === "summary_commodity_code_merchant",
                ).isRequired,
              minLength: {
                value: 4,
                message: "Summary Commodity Code must be 4 digits",
              },
            }}
            maxLength={4}
            type="number"
            name="merchant.summary_commodity_code_merchant"
            transformOutput={false}
            defaultValue={getDefaultValue(
              "Merchant",
              "summary_commodity_code_merchant",
              "0000",
            )}
          />
        </Box>
      </>
    )
  );
};

DefaultValues.propTypes = {
  classes: PropTypes.object,
};

export default DefaultValues;
