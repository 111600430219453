import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import RadioGroup from "@mui/material/RadioGroup";
import MUIRadio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getProperty } from "../../utils/objectHelpers";
import { isRequired } from "./formHelpers";

export const Radio = ({
  name,
  label,
  defaultValue,
  control,
  rules,
  errors,
  options,
}) => {
  const error = getProperty(name, errors);

  return (
    <>
      <FormControl
        required={isRequired(rules)}
        error={error !== undefined}
        fullWidth
        component="fieldset"
      >
        <FormLabel component="legend">{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field }) => (
            <RadioGroup
              row
              aria-label="radio-buttons"
              onChange={(e) => {
                field.onChange([e.target.value]);
              }}
              {...field}
            >
              {options.map((option, index) => (
                <FormControlLabel
                  key={`${name}-${option.name}-${index}`}
                  label={option.name}
                  labelPlacement="top"
                  value={option.value}
                  control={<MUIRadio />}
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormHelperText sx={{ ml: 0 }} error>
        {error && error.message}
      </FormHelperText>
    </>
  );
};

export default Radio;

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  errors: PropTypes.object,
  options: PropTypes.array.isRequired,
};

Radio.defaultProps = {
  defaultValue: "",
};
