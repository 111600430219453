import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { sassEndpoints } from "../../../constants/endpoints";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../constants/routes";
import SearchPaging from "../../Search/SearchPaging";
import useSearchPaging from "../../../hooks/useSearchPaging";
import { getCustomerKeyValue } from "../CVSearch/customerHelpers";
import SearchTable from "../../Search/SearchTable";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { stringFormat } from "../../../utils/stringHelpers";
const fields = [
  { key: "payment_method", sortable: false },
  { key: "name", sortable: false },
  { key: "company", sortable: false },
];

const CustomerSearch = ({
  paymentType,
  resultCount,
  showAdvancedSearch,
  showDivider,
  showCancelButton,
  showCreateCustomer,
  onCreateCustomer,
  onCustomerSelect,
  onCancel,
}) => {
  const history = useHistory();
  const searchRef = useRef();
  const { userSettings } = useContext(UserSettingsContext);
  const [searchValue, setSearchValue] = useState("");
  const [customers, setCustomers] = useState(null);
  const {
    resultOffset,
    setResultOffset,
    resultsPerPage,
    resultsTotal,
    setResultsTotal,
    handlePageChange,
  } = useSearchPaging(resultCount);

  const handleSearch = () => {
    setResultOffset(0);
    setSearchValue(searchRef.current.value);
  };

  useEffect(() => {
    if (searchValue) loadSearchResults(resultOffset);
    else loadCustomerResults(resultOffset);
  }, [resultOffset, searchValue, paymentType]);

  const loadCustomerResults = async (offset) => {
    const payload = {
      limit: resultsPerPage,
      offset: offset,
      includeDefaultAddresses: true,
      includeDefaultPayment: true,
    };
    if (paymentType === "authorize" || paymentType === "verification") {
      payload.paymentType = {
        operator: "Equal",
        value: "Card",
      };
    }
    let url = stringFormat(sassEndpoints.customers.customer, [
      userSettings.gatewayId,
    ]);
    await axios
      .post(url + "/Search", payload)
      .then(function (response) {
        if (paymentType === "authorize" || paymentType === "verification") {
          let filteredCustomers = response?.data?.data?.results?.filter(
            (obj) => {
              if (obj?.paymentMethods.length > 0) {
                return !Object.keys(obj?.paymentMethods[0]).includes("ach");
              } else {
                return obj;
              }
            },
          );
          setCustomers(filteredCustomers || []);
        } else {
          setCustomers(response?.data?.data?.results || []);
        }
        setResultsTotal(response?.data?.data?.rowCount || 0);
      })
      .catch(function (error) {
        console.log("Failed to get customer results", error);
      });
  };

  const loadSearchResults = async (offset) => {
    const payload = {
      limit: resultsPerPage,
      offset: offset,
      includeDefaultAddresses: true,
      includeDefaultPayment: true,
      globalSearch: searchValue,
    };
    let url = stringFormat(sassEndpoints.customers.customer, [
      userSettings.gatewayId,
    ]);

    await axios
      .post(url + "/Search", payload)
      .then(function (response) {
        setCustomers(response?.data?.data?.results || []);
        setResultsTotal(response?.data?.data?.rowCount || 0);
      })
      .catch(function (error) {
        console.log("Failed to get customer results", error);
      });
  };

  return (
    <Grid
      container
      alignItems="top"
      spacing={0.5}
      className="table-customer-wrapper"
    >
      <Grid item xs={10} className="customer-search-field">
        <TextField
          InputLabelProps={{ style: { marginTop: -2 } }}
          size="small"
          fullWidth
          label="Search"
          variant="outlined"
          inputRef={searchRef}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSearch();
          }}
          inputProps={{
            "data-cy": "vt_customer_search",
          }}
          helperText={
            showAdvancedSearch && (
              <Link
                sx={{ cursor: "pointer" }}
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(merchantRoutes.customer.search);
                }}
              >
                Advanced Search
              </Link>
            )
          }
          FormHelperTextProps={{
            sx: { alignSelf: "end", m: 0 },
          }}
        />
      </Grid>
      <Grid item xs={2} className="customer-search-button">
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 0, height: 32, p: 0 }}
          onClick={handleSearch}
          fullWidth
          data-cy="vt_customer_search_button"
        >
          Search
        </Button>
      </Grid>
      {showCreateCustomer && (
        <Grid item xs className="v2" marginBottom={2}>
          <Button
            className="btn--primary"
            color="primary"
            variant="contained"
            fullWidth
            onClick={onCreateCustomer}
          >
            Create New Customer
          </Button>
        </Grid>
      )}
      <Grid item xs={12} minHeight={215}>
        <SearchTable
          rowSx={{
            whiteSpace: "nowrap",
            "& .MuiTableCell-root": {
              pl: "4px",
              pr: "32px",
              pb: "4px",
              pt: "0",
              fontWeight: 600,
            },
          }}
          componentCellSx={{
            padding: 0.5,
            width: 100,
          }}
          fieldCellSx={{
            padding: 0.5,
            width: 100,
            overflowWrap: "anywhere",
            fontSize: 11,
          }}
          getEntityKeyValue={getCustomerKeyValue}
          fieldList={fields}
          searchResults={customers}
          getActionsComponent={(row) => (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() =>
                onCustomerSelect
                  ? onCustomerSelect(row?.customerId)
                  : history.push({
                      pathname: `${merchantRoutes.virtual_terminal}/token/${row?.customerId}`,
                    })
              }
              data-cy="vt_customer_search_select_button"
            >
              Select
            </Button>
          )}
        />
      </Grid>
      {showDivider && (
        <Grid item xs={12}>
          <Divider
            variant="middle"
            sx={{ m: 0, mb: 1, backgroundColor: "whitesmoke" }}
          />
        </Grid>
      )}
      <Grid item xs={12} className="customer-pagination-vt">
        <SearchPaging
          resultsPerPage={resultsPerPage}
          resultsTotal={resultsTotal}
          handleChange={handlePageChange}
          showCancelButton={showCancelButton}
          onCancel={onCancel}
        />
      </Grid>
    </Grid>
  );
};

CustomerSearch.propTypes = {
  paymentType: PropTypes.string,
  showAdvancedSearch: PropTypes.bool,
  showDivider: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  showCreateCustomer: PropTypes.bool,
  resultCount: PropTypes.number,
  onCancel: PropTypes.func,
  onCustomerSelect: PropTypes.func,
  onCreateCustomer: PropTypes.func,
};

CustomerSearch.defaultProps = {
  showAdvancedSearch: true,
  showDivider: true,
  showCancelButton: false,
  showCreateCustomer: false,
  resultCount: 4,
};

export default CustomerSearch;
