import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ConfirmationDialog from "./ConfirmationDialog";
import {
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
} from "@mui/material";
import { getLastInterval, getNextDate } from "../subscriptionHelpers";
import { differenceInDays, parseISO } from "date-fns";

const CancelDialog = ({ subscription, open, onConfirm, onClose }) => {
  const [cancel, setCancel] = useState("now");
  const [refund, setRefund] = useState("none");
  const [lastInterval, setLastInterval] = useState(null);

  useEffect(() => {
    if (subscription && subscription.recurrence.billingPeriod) {
      if (
        differenceInDays(
          parseISO(subscription.recurrence.billingStartDate),
          new Date(),
        ) < 0
      )
        setLastInterval(
          getLastInterval(
            subscription.recurrence.billingPeriod.name,
            subscription.recurrence.billingStartDate,
          ),
        );
      else
        setLastInterval({
          start: parseISO(subscription.recurrence.billingStartDate),
          end: getNextDate(
            subscription.recurrence.billingPeriod.name,
            subscription.recurrence.billingStartDate,
          ),
        });
    }
  }, [subscription]);

  const handleConfirm = () => {
    const payload = {
      cancel: {
        now: cancel === "now" ? true : null,
        endOfBillingPeriod: cancel === "next" ? true : null,
      },
      ...(refund !== "none" &&
        cancel === "now" && {
          refund: {
            lastPaidAmount: refund === "last" ? true : null,
            proratedAmount: refund === "prorated" ? true : null,
          },
        }),
    };
    onConfirm(payload);
  };

  return (
    <ConfirmationDialog
      alertTitle="Please select an option:"
      alertLevel="warning"
      open={open}
      onConfirm={handleConfirm}
      onClose={onClose}
      okButtonClass="btn--primary__danger"
      okButtonText="Cancel Subscription"
      closeButtonClass="btn--secondary__danger"
      closeButtonText="Cancel"
      content={
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              label="Cancel immediately"
              labelPlacement="end"
              checked={cancel === "now"}
              onChange={() => setCancel("now")}
              control={<Radio />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Cancel at the end of current billing period"
              labelPlacement="end"
              checked={cancel === "next"}
              onChange={() => setCancel("next")}
              control={<Radio />}
            />
          </Grid>
          {subscription?.invoices?.filter((i) => i.status.name === "Paid")
            ?.length > 0 && (
            <Collapse
              in={cancel === "now"}
              className="cancel-dialog-refund-container"
            >
              <Grid item xs={12} marginY={1}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="No refund"
                  labelPlacement="end"
                  checked={refund === "none"}
                  onChange={() => setRefund("none")}
                  control={<Radio />}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label={`Refund last payment for ${lastInterval?.start.toLocaleDateString()} - ${lastInterval?.end.toLocaleDateString()}`}
                  labelPlacement="end"
                  checked={refund === "last"}
                  onChange={() => setRefund("last")}
                  control={<Radio />}
                />
              </Grid>
              {/* {lastInterval?.start.toLocaleDateString() !==
                new Date().toLocaleDateString() && (
                <Grid item xs={12}>
                  <FormControlLabel
                    label={`Refund prorated amount for ${new Date().toLocaleDateString()} - ${lastInterval?.end.toLocaleDateString()}`}
                    labelPlacement="end"
                    checked={refund === "prorated"}
                    onChange={() => setRefund("prorated")}
                    control={<Radio />}
                  />
                </Grid>
              )} */}
            </Collapse>
          )}
        </Grid>
      }
    />
  );
};

CancelDialog.propTypes = {
  subscription: PropTypes.object,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default CancelDialog;
