import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, IconButton, TextField, Grid, Tooltip } from "@mui/material";
import emptyLogo from "../../../../assets/images/empty-state.png";
import PropTypes from "prop-types";

const CVCustomerPayables = ({
  userSettings,
  createUrl,
  generatedToken,
  copyLink,
}) => {
  return (
    <div>
      {" "}
      {userSettings?.gatewayUserPermissions["MANAGE_CUST_PAYABLE_TOKEN"] && (
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={11}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              data-cy="create-customer-payable"
              onClick={() => createUrl()}
            >
              {generatedToken
                ? "Create New URL"
                : "Create Customer Payables URL"}
            </Button>
          </Grid>
        </Grid>
      )}
      {generatedToken ? (
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={11}>
            <TextField
              variant="outlined"
              fullWidth
              disabled
              value={generatedToken}
              className="payable-url-box"
              margin="normal"
            ></TextField>
          </Grid>

          <Grid item xs={1}>
            <Tooltip title={"Copy URL to clipboard"}>
              <IconButton onClick={() => copyLink()}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        !userSettings?.gatewayUserPermissions["MANAGE_CUST_PAYABLE_TOKEN"] && (
          <>
            {" "}
            <img src={emptyLogo} style={{ height: 100 }} />
            No Customer Payables URL has been created or you do not have access
            to view. <br />
            Please reach out to your Administrator for assistance.
          </>
        )
      )}
    </div>
  );
};
CVCustomerPayables.propTypes = {
  userSettings: PropTypes.object,
  createUrl: PropTypes.func,
  copyLink: PropTypes.func,
  generatedToken: PropTypes.string,
};
export default CVCustomerPayables;
