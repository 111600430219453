import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Tab,
  Tabs,
  Checkbox,
  FormControlLabel,
  Link,
  Collapse,
  IconButton,
  Tooltip,
  Alert,
} from "@mui/material";
import InvoiceContactForm from "./ContactForm";
import { Link as RouterLink } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { merchantRoutes } from "../../../../constants/routes";
import SelectAlternateAddress from "./SelectAlternateAddress";
import { useFormContext } from "react-hook-form";

const TabbedContactForm = ({
  defaultValues,
  activeTab,
  copyBillingAddress,
  onTabChange,
  countries,
  mode,
  customer,
  displayCustomerWarning,
  onChange,
  onCopyChange,
  onCustomerChange,
  onAlternateAddressChange,
  requiredFields,
  addAdditionalRequiredFields,
}) => {
  const methods = useFormContext();
  const billingAddress = defaultValues?.find((a) => a?.isBilling);
  const shippingAddress = defaultValues?.find((a) => a?.isShipping);

  const addressesAreEqual = (address1, address2) => {
    return Object.keys(requiredFields.billing).every((key) => {
      return (address1?.[key] || "") === (address2?.[key] || "");
    });
  };

  const tabs = [
    <>
      {customer && customer.addresses.length > 0 && mode !== "view" && (
        <Grid item container xs={12} sm={4} marginTop={2} marginBottom={4}>
          <SelectAlternateAddress
            key={0}
            type="billing"
            addresses={customer.addresses}
            onChange={onAlternateAddressChange}
            defaultValue={
              customer.addresses?.find((a) =>
                addressesAreEqual(
                  a,
                  // eslint-disable-next-line prettier/prettier
                  methods.getValues("billing") ||
                    defaultValues?.find((a) => a?.isBilling),
                ),
              )?.customerAddressId ||
              customer.addresses?.find((a) => a?.isBilling)
                ?.customerAddressId ||
              customer.addresses[0]?.customerAddressId ||
              ""
            }
          />
        </Grid>
      )}
      <InvoiceContactForm
        key={0}
        type="billing"
        countries={countries}
        onChange={onChange}
        requiredFields={requiredFields.billing}
        defaultValues={billingAddress}
        viewOnly={mode === "view"}
        addAdditionalRequiredFields={addAdditionalRequiredFields}
      />
    </>,
    <>
      {customer && customer.addresses.length > 0 && mode !== "view" && (
        <Grid item container xs={12} sm={4} marginTop={2} marginBottom={4}>
          <SelectAlternateAddress
            key={1}
            type="shipping"
            addresses={customer.addresses}
            onChange={onAlternateAddressChange}
            defaultValue={
              customer.addresses?.find((a) =>
                addressesAreEqual(
                  a,
                  // eslint-disable-next-line prettier/prettier
                  methods.getValues("shipping") ||
                    defaultValues?.find((a) => a?.isShipping),
                ),
              )?.customerAddressId ||
              customer.addresses?.find((a) => a?.isShipping)
                ?.customerAddressId ||
              ""
            }
          />
        </Grid>
      )}
      <InvoiceContactForm
        key={1}
        type="shipping"
        countries={countries}
        onChange={onChange}
        requiredFields={requiredFields.shipping}
        defaultValues={shippingAddress}
        viewOnly={mode === "view"}
      />
    </>,
  ];

  return (
    <Grid item container className="invoice-tabs">
      <Grid item container xs={12} sm={8} marginBottom={2}>
        {customer && (
          <Grid item xs={12}>
            <p className="invoice-customer-name">
              <strong>{customer.name}</strong>
            </p>
            {mode !== "view" && (
              <Tooltip title="Clear customer information" placement="top">
                <IconButton onClick={() => onCustomerChange(null)}>
                  <CancelIcon fontSize="small" color="error" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Tabs value={activeTab} onChange={onTabChange}>
            <Tab
              label="Bill To"
              id="billing-tab"
              aria-controls="billing-tab"
              disabled={mode === "view"}
            />
            <Tab
              label="Ship To"
              id="shipping-tab"
              aria-controls="shipping-tab"
              disabled={mode === "view"}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} className="copy-address-container">
        <FormControlLabel
          checked={copyBillingAddress}
          onChange={onCopyChange}
          control={<Checkbox color="primary" />}
          label="Shipping address same as billing"
          disabled={mode === "view"}
          className="invoice-checkbox"
        />
      </Grid>
      {tabs[activeTab]}
      <Grid item xs={12} marginTop={2} paddingRight={1}>
        <Collapse in={displayCustomerWarning}>
          <Alert severity="info">
            Changing address details on an Invoice does not update the customer
            record. Please make any permanent changes through the{" "}
            <Link
              component={RouterLink}
              to={`${merchantRoutes.customer.base}/${customer?.customerId}?address`}
            >
              Customer tab
            </Link>
            .
          </Alert>
        </Collapse>
      </Grid>
    </Grid>
  );
};

TabbedContactForm.propTypes = {
  defaultValues: PropTypes.arrayOf(PropTypes.object),
  activeTab: PropTypes.number,
  copyBillingAddress: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.object),
  mode: PropTypes.string,
  customer: PropTypes.object,
  displayCustomerWarning: PropTypes.bool,
  onChange: PropTypes.func,
  onCopyChange: PropTypes.func,
  onTabChange: PropTypes.func,
  onCustomerChange: PropTypes.func,
  onAlternateAddressChange: PropTypes.func,
  requiredFields: PropTypes.object,
  addAdditionalRequiredFields: PropTypes.bool,
};

export default TabbedContactForm;
