import Dinero from "dinero.js";
import {
  removeNonNumeric,
  removePlusMinus,
  removeNonNumericAuth,
} from "./stringHelpers";

const precision = (amount) => {
  let amountStr = removePlusMinus(String(amount));
  if (amountStr.includes(".")) {
    return amountStr.split(".")[1].length;
  } else {
    return 0;
  }
};

const convertToInt = (amount, defaultValue = 0) => {
  if (Number.isInteger(amount)) return amount;
  let converted = parseInt(removeNonNumeric(String(amount)));
  return isNaN(converted) ? defaultValue : converted;
};

const convertToIntAuth = (amount, defaultValue = 0) => {
  if (Number.isInteger(amount)) return amount;
  let converted = parseInt(removeNonNumericAuth(String(amount)));
  return isNaN(converted) ? defaultValue : converted;
};

const convertToFloat = (amount, defaultValue = 0) => {
  let converted = parseFloat(String(amount));
  return isNaN(converted) ? defaultValue : converted;
};

const formatCurrency = (amount, format = "0,0.00") =>
  formatNumber(amount, 2, format);

const formatPercentage = (amount, format = "0,0.000") =>
  formatNumber(amount, 3, format);

const formatNumber = (amount, precision = 2, format = "0,0.00") =>
  Dinero({ amount, precision: precision }).setLocale("en-US").toFormat(format);

const updateFormattedCurrency = (amount, format = "0,0.00") => {
  let unformattedIntAmount = convertToInt(amount);
  return formatCurrency(unformattedIntAmount, format);
};

const updateFormattedCurrencyAuth = (amount, format = "0,0.00") => {
  let unformattedIntAmount = convertToIntAuth(amount);
  return formatCurrency(unformattedIntAmount, format);
};

const updateFormattedPercentage = (amount, format = "0,0.000") => {
  let unformattedIntAmount = convertToInt(amount);
  return formatPercentage(unformattedIntAmount, format);
};

const dineroFormatter = (type) => {
  if (type === "currency") {
    return updateFormattedCurrency;
  } else if (type === "percentage") {
    return updateFormattedPercentage;
  } else if (type === "currencyAuth") {
    return updateFormattedCurrencyAuth;
  } else {
    return (amount) => amount;
  }
};

export {
  convertToInt,
  convertToIntAuth,
  convertToFloat,
  formatCurrency,
  dineroFormatter,
  precision,
};
