import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GatewayUserEditDropdowns from "./GatewayUserEditDropdowns";
import { Grid, Menu, MenuItem, Button } from "@mui/material";
import { Tooltip, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
import { b2cPolicies } from "../../../../../authConfig";
import { useMsal } from "@azure/msal-react";
import { UserSettingsContext } from "../../../../../contexts/UserSettingsContext";
import { GatewayUserDetailContext } from "../../../../../contexts/GatewayUserDetailContext";

const GatewayUserInfo = ({
  resendEmail,
  gatewayProps,
  deleteUser,
  setGatewayProps,
}) => {
  const { userData, triggerAlert, setAlertDialogOpen, setAlertDialogProps } =
    useContext(GatewayUserDetailContext);

  const msal = useMsal();
  const userSettings = useContext(UserSettingsContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [detectClose, setDetectClose] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setDetectClose(true);
  };

  useEffect(() => {
    if (detectClose) {
      setAnchorEl(null);
      setDetectClose(false);
    }
  }, [detectClose]);

  const loginAs = (userData) => {
    const user_id = userData.user.userId;
    msal.instance
      .logout({
        onRedirectNavigate: () => {
          return false;
        },
      })
      .then(() => {
        msal.instance.loginRedirect({
          authority: b2cPolicies.authorities.signInOBO.authority,
          scopes: b2cPolicies.scopes,
          prompt: "select_account",
          extraQueryParameters: {
            impersonatedUserId: user_id,
            impersonatorEmail: userSettings.email,
          },
        });
      });
  };

  const handleDeleteClick = (data) => {
    triggerAlert(["You are permanently deleting this user"], {
      text: "Yes, delete user",
      color: "secondary",
      onclick: () => {
        deleteUser(data.gatewayUserId);
        setAlertDialogProps({});
        setAlertDialogOpen(false);
      },
    });
  };

  return (
    <Grid item xs={12} md={5.7}>
      <Grid
        container
        flexDirection={"column"}
        alignItems="center"
        justifyContent="space-between"
        className="card-obj"
        textAlign={"center"}
      >
        <Grid item style={{ width: "100%" }}>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Tooltip title="Return to List">
                <IconButton
                  variant="container"
                  color="primary"
                  fontSize="small"
                  onClick={() =>
                    setGatewayProps({
                      ...gatewayProps,
                      usersState: "DEFAULT",
                    })
                  }
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button
                className={"tag tag__grey"}
                style={{ float: "right" }}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
              >
                ACTIONS <KeyboardArrowDownIcon />
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => {
                    handleClose();
                  }}
                >
                  {userData?.gatewayUserStatus?.toLowerCase() === "active" && (
                    <MenuItem
                      onClick={() => {
                        setGatewayProps({
                          ...gatewayProps,
                          usersState: "CREATE",
                          gatewayRoles: gatewayProps.gatewayRoles,
                          copySettings: {
                            permissions: userData.gatewayUserPermissions,
                            role: userData.role.roleId,
                          },
                        });
                      }}
                    >
                      Copy User
                    </MenuItem>
                  )}
                  {userData?.user?.status.toLowerCase() === "invitepending" && (
                    <MenuItem onClick={() => resendEmail(userData.user.userId)}>
                      Resend Welcome Email
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      loginAs(userData);
                    }}
                  >
                    Login As User
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDeleteClick(userData);
                    }}
                  >
                    Delete User
                  </MenuItem>
                </Menu>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="user-profile">
          <div style={{ textAlign: "center", lineHeight: "50px" }}>
            {userData?.user?.lastName?.length > 0
              ? userData?.user?.firstName[0] + userData?.user?.lastName[0]
              : userData?.user?.firstName[0]}
          </div>
        </Grid>
        <Grid item className="user-detail-text">
          {userData?.user?.lastName?.length > 0
            ? userData?.user?.firstName + " " + userData?.user?.lastName
            : userData?.user?.firstName}
        </Grid>
        <Grid item className="user-detail-text">
          {userData?.user?.email}
        </Grid>
        <Grid item>{`(${userData?.phone?.substring(
          0,
          3,
        )}) ${userData?.phone?.substring(3, 6)}-${userData?.phone?.substring(
          6,
        )}`}</Grid>
        <GatewayUserEditDropdowns />
      </Grid>
    </Grid>
  );
};

GatewayUserInfo.propTypes = {
  resendEmail: PropTypes.func,
  gatewayProps: PropTypes.object,
  deleteUser: PropTypes.func,
  setGatewayProps: PropTypes.func,
};
export default GatewayUserInfo;
