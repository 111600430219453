import React from "react";
import PropTypes from "prop-types";
import { Grid, MenuItem } from "@mui/material";
import RegisteredTextField from "../ReactHookForm/RegisteredTextField";
import Select from "../ReactHookForm/Select";
import { useFormContext } from "react-hook-form";
import { regexPatterns } from "../../constants/regexPatterns";
import { validationRules } from "../../constants/validationRules";

const WebhooksEditOrCreate = ({
  data,
  editCard,
  webhookType,
  setWebhookType,
  typeError,
  setUrlValue,
}) => {
  const methods = useFormContext();
  return (
    <>
      <Grid item>
        <div style={{ marginBottom: "5px" }}>Type</div>
        <Select
          data-cy="webhook-type-select"
          control={methods.control}
          name={"type"}
          value={webhookType}
          label="Select webhook type"
          defaultValue={editCard !== null ? Object.keys(data)[0] : ""}
          fullWidth
          onChange={(e) => {
            setWebhookType(e);
          }}
          rules={{
            required: { value: true, message: "required" },
          }}
        >
          <MenuItem value={"Transaction"}>Transaction</MenuItem>
          <MenuItem value={"Settlement"}>Settlement</MenuItem>
          <MenuItem value={"Invoice"}>Invoice</MenuItem>
        </Select>
        {typeError && (
          <div className="maximum-type-error">
            You’ve reached the maximum number of Webhooks for this type
          </div>
        )}
      </Grid>

      <Grid item>
        <>URL</>
        <RegisteredTextField
          data-cy="webhook-url"
          name={`URL`}
          fullWidth
          defaultValue={editCard !== null ? Object.values(data)[0] : ""}
          onBlur={(e) => {
            setUrlValue(e.target.value);
          }}
          rules={{
            required: { value: true, message: "required" },
            pattern: {
              value: regexPatterns.url,
              message: "Please enter a valid URL",
            },
            maxLength: validationRules.maxLength100,
          }}
        ></RegisteredTextField>
      </Grid>
    </>
  );
};

WebhooksEditOrCreate.propTypes = {
  data: PropTypes.object,
  editCard: PropTypes.object,
  webhookType: PropTypes.string,
  setWebhookType: PropTypes.func,
  typeError: PropTypes.bool,
  setUrlValue: PropTypes.func,
};

export default WebhooksEditOrCreate;
