import { React } from "react";
import { useFormContext } from "react-hook-form";
import { ControllerTextField } from "../ReactHookForm/ControllerTextField";
import { removeNonNumeric } from "../../utils/stringHelpers";
import PropTypes from "prop-types";

function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = removeNonNumeric(value);

  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
}

export default function PhoneNumber({
  id,
  name,
  label,
  defaultValue,
  required,
  viewOnly,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <ControllerTextField
      control={control}
      errors={errors}
      rules={{
        required: required,
        minLength: { value: 10, message: "Requires 10 digits" },
      }}
      transform={{
        input: (value) => formatPhoneNumber(value),
        output: (value) => removeNonNumeric(value).slice(0, 10),
      }}
      id={id}
      name={name}
      label={label}
      defaultValue={defaultValue}
      fullWidth
      autoComplete={id}
      viewOnly={viewOnly}
      datacy={label}
    />
  );
}

PhoneNumber.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

PhoneNumber.defaultProps = {
  defaultValue: "",
  required: false,
  viewOnly: false,
};
