import React from "react";
import { useFormContext } from "react-hook-form";
import "../simplePay.scss";
import Checkbox from "../../../ReactHookForm/Checkbox";
import Switch from "../../../ReactHookForm/Switch";
import StyledTabs from "../../../Tabs/StyledTabs";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import Grid from "@mui/material/Grid";
import "../../../../assets/v2/main.scss";
import FormLabel from "@mui/material/FormLabel";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import PropTypes from "prop-types";

const FormSettings = ({
  canEditSimplePay,
  gatewaySurchargeable,
  processors,
  isCreatingNew,
}) => {
  const methods = useFormContext();

  const handleRequireChange = (requireField, includeField) => (event) => {
    const isRequired = event.target.checked;

    methods.setValue(includeField, isRequired);
  };

  const requireAddressFields = methods.watch("card.require_address_fields");
  const requireEmailField = methods.watch("card.require_email_field");
  const requirePhoneField = methods.watch("card.require_phone_field");
  const requireOrderIdField = methods.watch("card.require_order_id_field");
  const requirePoNumberField = methods.watch("card.require_po_number_field");
  const requireCompanyField = methods.watch("card.require_company_field");
  const requireAchCompanyField = methods.watch("ach.require_company_field");
  const requireAchOrderIdField = methods.watch("ach.require_order_id_field");
  const requireAchPoNumberField = methods.watch("ach.require_po_number_field");

  const hasDefaultCard = processors.some(
    (processor) => processor.isDefaultCard === true,
  );
  const hasDefaultAch = processors.some(
    (processor) => processor.isDefaultAch === true,
  );

  const achSecIsCcd = processors.some(
    (processor) =>
      processor.isDefaultAch === true &&
      processor.settings?.sageAch?.terminals?.some(
        (terminal) => terminal.secCode === "CCD",
      ),
  );

  const selectedPaymentTypes = methods.watch(
    "payment_type_selector",
    isCreatingNew
      ? ["card"]
      : [
          methods.getValues("card.enable_card") && "card",
          methods.getValues("ach.enable_ach") && "ach",
        ],
  );

  return (
    <>
      <div className="row-checkboxes">
        <Checkbox
          name={"payment_type_selector"}
          label={"Select Payment Types"}
          control={methods.control}
          options={
            [
              hasDefaultCard && { name: "Card", value: "card" },
              hasDefaultAch && { name: "ACH", value: "ach" },
            ].filter(Boolean) // Remove any falsy values
          }
          defaultValues={[
            methods.getValues("card.enable_card") && "card",
            methods.getValues("ach.enable_ach") && "ach",
          ]}
          rules={{ required: true }}
          disabled={!canEditSimplePay} // Disable based on permission
        />
      </div>
      <StyledTabs
        label="payment"
        tabs={[
          hasDefaultCard &&
            selectedPaymentTypes.includes("card") && {
              props: { label: "Card", icon: <CreditCardIcon /> },
              panel: (
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6} className="left-grid">
                      <div className="column-checkboxes">
                        <Checkbox
                          name={"card.accepted_cards"}
                          label={"Accepted Cards"}
                          control={methods.control}
                          options={[
                            { name: "Visa", value: "visa" },
                            { name: "MasterCard", value: "mastercard" },
                            { name: "Discover", value: "discover" },
                            { name: "American Express", value: "amex" },
                          ]}
                          defaultValues={[
                            methods.getValues("card.accept_visa") && "visa",
                            methods.getValues("card.accept_mastercard") &&
                              "mastercard",
                            methods.getValues("card.accept_discover") &&
                              "discover",
                            methods.getValues("card.accept_amex") && "amex",
                          ].filter(Boolean)} // Filter out any falsy values
                          disabled={!canEditSimplePay}
                        />
                      </div>
                      <FormLabel component="legend">Company Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"card.include_company_field"}
                          label={"Company Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={!canEditSimplePay || requireCompanyField}
                        />
                        <Switch
                          name={"card.require_company_field"}
                          label={"Company Field Required"}
                          control={methods.control}
                          v2={true}
                          onChange={handleRequireChange(
                            "card.require_company_field",
                            "card.include_company_field",
                          )}
                          disabled={!canEditSimplePay}
                        />
                        <RegisteredTextField
                          name={"card.company_field_label"}
                          label={"Company Field Label"}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                      <FormLabel component="legend">Address Fields</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"card.include_address_fields"}
                          label={"Address Fields Included"}
                          control={methods.control}
                          v2={true}
                          disabled={
                            !canEditSimplePay ||
                            gatewaySurchargeable ||
                            requireAddressFields
                          }
                        />
                        <Switch
                          name={"card.require_address_fields"}
                          label={"Address Fields Required"}
                          control={methods.control}
                          v2={true}
                          onChange={handleRequireChange(
                            "card.require_address_fields",
                            "card.include_address_fields",
                          )}
                          disabled={!canEditSimplePay || gatewaySurchargeable}
                        />
                      </div>
                      <FormLabel component="legend">Email Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"card.include_email_field"}
                          label={"Email Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={!canEditSimplePay || requireEmailField}
                        />
                        <Switch
                          name={"card.require_email_field"}
                          label={"Email Field Required"}
                          control={methods.control}
                          v2={true}
                          onChange={handleRequireChange(
                            "card.require_email_field",
                            "card.include_email_field",
                          )}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} className="left-grid">
                      <FormLabel component="legend">Phone Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"card.include_phone_field"}
                          label={"Phone Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={!canEditSimplePay || requirePhoneField}
                        />
                        <Switch
                          name={"card.require_phone_field"}
                          label={"Phone Field Required"}
                          control={methods.control}
                          v2={true}
                          onChange={handleRequireChange(
                            "card.require_phone_field",
                            "card.include_phone_field",
                          )}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                      <FormLabel component="legend">
                        Additional Information Header
                      </FormLabel>
                      <div className={"column-switches"}>
                        <RegisteredTextField
                          name={"card.additional_info_header"}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                      <FormLabel component="legend">Order ID Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"card.include_order_id_field"}
                          label={"Order ID Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={!canEditSimplePay || requireOrderIdField}
                        />
                        <Switch
                          name={"card.require_order_id_field"}
                          label={"Order ID Field Required"}
                          control={methods.control}
                          v2={true}
                          onChange={handleRequireChange(
                            "card.require_order_id_field",
                            "card.include_order_id_field",
                          )}
                          disabled={!canEditSimplePay}
                        />
                        <RegisteredTextField
                          name={"card.order_id_field_label"}
                          label={"Order ID Field Label"}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                      <FormLabel component="legend">PO Number Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"card.include_po_number_field"}
                          label={"PO Number Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={!canEditSimplePay || requirePoNumberField}
                        />
                        <Switch
                          name={"card.require_po_number_field"}
                          label={"PO Number Field Required"}
                          control={methods.control}
                          v2={true}
                          onChange={handleRequireChange(
                            "card.require_po_number_field",
                            "card.include_po_number_field",
                          )}
                          disabled={!canEditSimplePay}
                        />
                        <RegisteredTextField
                          name={"card.po_number_field_label"}
                          label={"PO Number Field Label"}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                      <FormLabel component="legend">Note Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"card.include_note_field"}
                          label={"Note Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ),
            },
          hasDefaultAch &&
            selectedPaymentTypes.includes("ach") && {
              props: { label: "ACH", icon: <AccountBalanceOutlinedIcon /> },
              panel: (
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormLabel component="legend">Company Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"ach.include_company_field"}
                          label={"Company Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={
                            !canEditSimplePay ||
                            requireAchCompanyField ||
                            achSecIsCcd
                          }
                        />
                        <Switch
                          name={"ach.require_company_field"}
                          label={"Company Field Required"}
                          control={methods.control}
                          v2={true}
                          onChange={handleRequireChange(
                            "ach.require_company_field",
                            "ach.include_company_field",
                          )}
                          disabled={!canEditSimplePay || achSecIsCcd}
                        />
                        <RegisteredTextField
                          name={"ach.company_field_label"}
                          label={"Company Field Label"}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                      <FormLabel component="legend">Order ID Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"ach.include_order_id_field"}
                          label={"Order ID Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={!canEditSimplePay || requireAchOrderIdField}
                        />
                        <Switch
                          name={"ach.require_order_id_field"}
                          label={"Order ID Field Required"}
                          control={methods.control}
                          v2={true}
                          onChange={handleRequireChange(
                            "ach.require_order_id_field",
                            "ach.include_order_id_field",
                          )}
                          disabled={!canEditSimplePay}
                        />
                        <RegisteredTextField
                          name={"ach.order_id_field_label"}
                          label={"Order ID Field Label"}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel component="legend">
                        Additional Information Header
                      </FormLabel>
                      <div className={"column-switches"}>
                        <RegisteredTextField
                          name={"ach.additional_info_header"}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                      <FormLabel component="legend">PO Number Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"ach.include_po_number_field"}
                          label={"PO Number Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={
                            !canEditSimplePay || requireAchPoNumberField
                          }
                        />
                        <Switch
                          name={"ach.require_po_number_field"}
                          label={"PO Number Field Required"}
                          control={methods.control}
                          v2={true}
                          onChange={handleRequireChange(
                            "ach.require_po_number_field",
                            "ach.include_po_number_field",
                          )}
                          disabled={!canEditSimplePay}
                        />

                        <RegisteredTextField
                          name={"ach.po_number_field_label"}
                          label={"PO Number Field Label"}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                      <FormLabel component="legend">Note Field</FormLabel>
                      <div className={"column-switches"}>
                        <Switch
                          name={"ach.include_note_field"}
                          label={"Note Field Included"}
                          control={methods.control}
                          v2={true}
                          disabled={!canEditSimplePay}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ),
            },
        ].filter(Boolean)}
      />
    </>
  );
};

FormSettings.propTypes = {
  simplePayResponse: PropTypes.object,
  canEditSimplePay: PropTypes.bool.isRequired,
  gatewaySurchargeable: PropTypes.bool,
  processors: PropTypes.array,
  isCreatingNew: PropTypes.bool,
};

export default FormSettings;
