import { useState } from "react";
import PropTypes from "prop-types";

export const useSearchPaging = (resultPerPage) => {
  const [resultsPerPage, setResultsPerPage] = useState(resultPerPage);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultOffset(pageIndex * resultsPerPage);
  };

  return {
    resultsPerPage,
    setResultsPerPage,
    resultOffset,
    setResultOffset,
    resultsTotal,
    setResultsTotal,
    handlePageChange,
  };
};

useSearchPaging.propTypes = {
  resultPerPage: PropTypes.number,
};

useSearchPaging.defaultProps = {
  resultPerPage: 10,
};

export default useSearchPaging;
