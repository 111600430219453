import { trackUserSessionLengthEvent } from "../services/mixpanel/merchant/userProperties";

// msal instance
export const logout = (msalInstance) => {
  if (window.sessionStorage.getItem("gateway")) {
    window.sessionStorage.removeItem("gateway");
  }
  trackUserSessionLengthEvent();
  msalInstance
    .logoutRedirect({
      postLogoutRedirectUri: "/",
    })
    .catch((e) => console.error(e));
};
