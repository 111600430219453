import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import StyledTabs from "../../Tabs/StyledTabs";
import Customer from "./Customer";
import CustomerSearch from "./CustomerSearch";
import CustomerSkeleton from "./CustomerSkeleton";
import "./virtualTerminal.scss";
import ACHTokenizerComponent from "../../Tokenizer/ACHTokenizerCmp";
import TokenExComponent from "../../Tokenizer/TokenExCmp";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";
import { iqProVer } from "../../../constants/global";

export const PAYMENT_TYPE = {
  CARD: "card",
  ACH: "ach",
};

const TAB_INDEX = {
  CARD: 0,
  ACH: 1,
  CUSTOMER: 2,
  SWIPE: 3,
};

export const LABEL = {
  CARD: "Card",
  ACH: "ACH",
  CUSTOMER: "Customer",
  SWIPE: "Swipe",
};

const tabDetails = [
  {
    props: { label: LABEL.CARD, icon: <CreditCardIcon /> },
    paymentOption: PAYMENT_TYPE.CARD,
  },
  {
    props: { label: LABEL.ACH, icon: <AccountBalanceOutlinedIcon /> },
    paymentOption: PAYMENT_TYPE.ACH,
  },
  {
    props: { label: LABEL.CUSTOMER, icon: <PersonSearchIcon /> },
    paymentOption: "",
  },
  {
    props: { label: LABEL.SWIPE, icon: <DragHandleIcon /> },
    paymentOption: PAYMENT_TYPE.CARD,
  },
];

const PaymentTabs = ({
  paymentType,
  selectedPaymentTab,
  setSelectedPaymentTab,
  setPaymentOption,
  isCustomerLoading,
  customerInfo,
  setDefaultAddresses,
  surchargeSettingEnabled,
  setCustomerSecCode,
  gatewayConfig,
  handleAchChange,
  achSubmit,
  setAchSubmit,
  achToken,
  setAchToken,
  tokenizerSettings,
  tokenizerV2,
  handleSaasTokenizerSubmission,
  setAtAchTab,
  onBinChange,
  setCardBinNumber,
  setIsAlertOpen,
}) => {
  useEffect(() => {
    if (
      selectedPaymentTab !== "Customer" &&
      getGatewayConfigFieldVal(
        gatewayConfig,
        "required_fields",
        "Default",
        "payment_method_card",
      ) === "hidden" &&
      getGatewayConfigFieldVal(
        gatewayConfig,
        "required_fields",
        "Default",
        "payment_method_ach",
      ) !== "hidden"
    ) {
      setSelectedPaymentTab("ACH");
    }
  }, []);
  useEffect(() => {
    if (selectedPaymentTab === "ACH") {
      setAtAchTab(true);
    } else {
      setAtAchTab(false);
    }
  }, [selectedPaymentTab]);
  const [customerPaymentOption, setCustomerPaymentOption] = useState("");

  const handleCustomerPaymentOptionChange = (paymentOption) => {
    setPaymentOption(paymentOption);
    setCustomerPaymentOption(paymentOption);
  };

  const handleTabChange = (tabIndex) => {
    const tabList = tabDetails.filter((details) => {
      return paymentTabs.find((tab) => {
        return details.props.label === tab.props.label;
      });
    });

    setSelectedPaymentTab(tabList[tabIndex].props.label);
    setPaymentOption(tabList[tabIndex].paymentOption);

    if (tabIndex === TAB_INDEX.CUSTOMER) {
      setPaymentOption(customerPaymentOption);
    }
  };

  const getPaymentTabs = () => {
    let tabsList = [];
    if (
      getGatewayConfigFieldVal(
        gatewayConfig,
        "required_fields",
        "Default",
        "payment_method_card",
      ) !== "hidden"
    ) {
      tabsList.push({
        props: tabDetails[TAB_INDEX.CARD].props,
        panel:
          tokenizerSettings.achTokenizer === iqProVer.v1 ? (
            <Box sx={{ width: "100%" }} id="tokenizer-card" />
          ) : tokenizerSettings.achTokenizer === iqProVer.v2 ? (
            <div className="vt-tokenizer">
              <TokenExComponent
                tokenizerRef={tokenizerV2}
                handleSubmit={handleSaasTokenizerSubmission}
                tokenConfig={tokenizerSettings?.iframeConfiguration}
                cvvState={getGatewayConfigFieldVal(
                  gatewayConfig,
                  "required_fields",
                  "Default",
                  "payment_method_card_cvc",
                )}
                validCard={onBinChange}
              />
            </div>
          ) : (
            <></>
          ),
      });
    }
    if (
      getGatewayConfigFieldVal(
        gatewayConfig,
        "required_fields",
        "Default",
        "payment_method_ach",
      ) !== "hidden"
    ) {
      tabsList.push({
        props: tabDetails[TAB_INDEX.ACH].props,
        panel:
          tokenizerSettings.achTokenizer === iqProVer.v1 ? (
            <Box sx={{ width: "100%" }} id="tokenizer-ach" />
          ) : tokenizerSettings.achTokenizer === iqProVer.v2 ? (
            <div>
              {selectedPaymentTab === "ACH" && (
                <ACHTokenizerComponent
                  handleAchChange={handleAchChange}
                  achSubmit={achSubmit}
                  setAchSubmit={setAchSubmit}
                  achToken={achToken}
                  setAchToken={setAchToken}
                  setIsAlertOpen={setIsAlertOpen}
                />
              )}
            </div>
          ) : (
            <></>
          ),
      });
    }
    tabsList.push(
      {
        props: tabDetails[TAB_INDEX.CUSTOMER].props,
        panel: isCustomerLoading ? (
          <CustomerSkeleton />
        ) : customerInfo ? (
          <Customer
            customerInfo={customerInfo}
            paymentOption={customerPaymentOption}
            setPaymentOption={handleCustomerPaymentOptionChange}
            setDefaultAddresses={setDefaultAddresses}
            surchargeSettingEnabled={surchargeSettingEnabled}
            setSecCode={setCustomerSecCode}
            setCardBinNumber={setCardBinNumber}
          />
        ) : (
          <CustomerSearch paymentType={paymentType} />
        ),
      },
      {
        props: tabDetails[TAB_INDEX.SWIPE].props,
        panel: (
          <>
            <Alert severity="info">
              Please place cursor within card number field before swiping card.
            </Alert>
            <Box sx={{ width: "100%", mt: 2 }} id="tokenizer-swipe" />
          </>
        ),
      },
    );
    return tabsList;
  };

  const hideVTTabs = () => {
    let noCardTab = !paymentTabs.find((tab) => tab.props.label === "Card");

    if (paymentType !== "authorize" && paymentType !== "verification") {
      if (tokenizerSettings.cardTokenizer === iqProVer.v2) {
        if (noCardTab) {
          return "no-card-hide-swipe";
        } else {
          return "hide-swipe";
        }
      } else {
        return "";
      }
    } else {
      if (tokenizerSettings.cardTokenizer === iqProVer.v2) {
        if (noCardTab) {
          return "no-card-hide-swipe no-card-hide-ach";
        } else {
          return "hide-swipe hide-ach";
        }
      } else {
        if (noCardTab) {
          return "no-card-hide-ach";
        } else {
          return "hide-ach";
        }
      }
    }
  };

  const paymentTabs = getPaymentTabs();

  return (
    <Grid item xs={12} className="vt-payment-options">
      <StyledTabs
        hidePanels={true}
        label="payment"
        defaultTabIndex={
          selectedPaymentTab === LABEL.CUSTOMER ? paymentTabs.length - 2 : 0
        }
        onChange={handleTabChange}
        paymentType={paymentType}
        tabs={paymentTabs}
        tokenizerSettings={tokenizerSettings}
        vtClass={hideVTTabs()}
      />
    </Grid>
  );
};

PaymentTabs.propTypes = {
  paymentType: PropTypes.string,
  selectedPaymentTab: PropTypes.string.isRequired,
  setSelectedPaymentTab: PropTypes.func.isRequired,
  paymentOption: PropTypes.string.isRequired,
  setPaymentOption: PropTypes.func.isRequired,
  isCustomerLoading: PropTypes.bool,
  customerInfo: PropTypes.object,
  setDefaultAddresses: PropTypes.func,
  surchargeSettingEnabled: PropTypes.bool.isRequired,
  setCustomerSecCode: PropTypes.func.isRequired,
  gatewayConfig: PropTypes.object,
  handleAchChange: PropTypes.func,
  achSubmit: PropTypes.bool,
  setAchSubmit: PropTypes.func,
  achToken: PropTypes.any,
  setAchToken: PropTypes.func,
  tokenizerSettings: PropTypes.object,
  handleTokenizerSubmission: PropTypes.func,
  tokenizerV2: PropTypes.any,
  handleSaasTokenizerSubmission: PropTypes.func,
  setAtAchTab: PropTypes.func,
  onBinChange: PropTypes.func,
  setCardBinNumber: PropTypes.func.isRequired,
  setIsAlertOpen: PropTypes.func,
};

export default React.memo(PaymentTabs);
