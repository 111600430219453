import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const LayoutSkeleton = () => {
  return (
    <Stack spacing={1} direction="row">
      <Skeleton variant="rectangular" width={275} height={"100vh"} />
      <Stack spacing={1}>
        <Skeleton variant="rectangular" height={"100vh"} width={"80vw"} />
      </Stack>
    </Stack>
  );
};

export default LayoutSkeleton;
