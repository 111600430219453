import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import SearchTable from "../../../Search/SearchTable";
import { addressTableHeaders } from "./cvCustomerTableHelpers";
import Button from "@mui/material/Button";
import ContactContainer from "../../../Contact/ContactContainer";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { useFormContext } from "react-hook-form";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import Tooltip from "@mui/material/Tooltip";
import { CountriesContext } from "../../../../contexts/CountriesContext";
const addressStates = {
  CREATE: "create",
  UPDATE: "update",
  VIEW: "view",
};

const CustomerAddress = ({
  addresses,
  createAddress,
  deleteAddress,
  editAddress,
  requiredDefaultFields,
  defaultAddress,
  permissions,
  showAlertDialogError,
}) => {
  const [rowData, setRowData] = useState(null);
  const [newPanel, setNewPanel] = useState(addressStates.VIEW);
  const [selectedAddress, setSelectedAddress] = useState();
  const [contact, setContact] = useState([]);
  const form = useFormContext();
  const countries = useContext(CountriesContext);
  useEffect(() => {
    setContact([]);
    setNewPanel(addressStates.VIEW);
    setRowData(addresses);
  }, [addresses]);

  const handleSubmit = () => {
    form.trigger().finally(() => {
      let formValues = form.getValues().address;
      let validForm = true;
      Object.keys(formValues).forEach((key) => {
        if (form.getFieldState(`address.${key}`).invalid) {
          validForm = false;
        }
      });
      if (validForm) {
        let nonNullable = {
          isShipping: {
            path: "/isShipping",
            op: "replace",
            value: true,
          },
          isBilling: {
            path: "/isBilling",
            op: "replace",
            value: true,
          },
        };

        let updatePayload = [
          {
            path: "/addressLine1",
            op: "replace",
            value: formValues?.address_line_1,
          },
          {
            path: "/firstName",
            op: "replace",
            value: formValues?.first_name,
          },
          {
            path: "/lastName",
            op: "replace",
            value: formValues?.last_name || "",
          },
          {
            path: "/addressLine2",
            op: "replace",
            value: formValues?.address_line_2 || "",
          },
          {
            path: "/city",
            op: "replace",
            value: formValues?.city || "",
          },
          {
            path: "/state",
            op: "replace",
            value: formValues?.state || "",
          },
          {
            path: "/postalCode",
            op: "replace",
            value: formValues?.postal_code || "",
          },
          {
            path: "/country",
            op: "replace",
            value: formValues?.country || "",
          },

          {
            path: "/email",
            op: "replace",
            value: formValues?.email || "",
          },
          {
            path: "/phone",
            op: "replace",
            value: formValues?.phone || "",
          },
          {
            path: "/fax",
            op: "replace",
            value: formValues?.fax || "",
          },
          {
            path: "/company",
            op: "replace",
            value: formValues?.company || "",
          },
        ];

        if (contact?.includes("isShipping") && { isShipping: true }) {
          updatePayload.push(nonNullable.isShipping);
        }
        if (contact?.includes("isBilling") && { isBilling: true }) {
          updatePayload.push(nonNullable.isBilling);
        }

        let createPayload = {
          addressLine1: formValues?.address_line_1 || "",
          addressLine2: formValues?.address_line_2 || "",
          city: formValues?.city || "",
          state: formValues?.state || "",
          postalCode: formValues?.postal_code || "",
          country: formValues?.country || "",
          firstName: formValues?.first_name || "",
          lastName: formValues?.last_name || "",
          email: formValues?.email || "",
          phone: formValues?.phone || "",
          fax: formValues?.fax || "",
          company: formValues?.company || "",
          ...(contact?.includes("isShipping") && { isShipping: true }),
          ...(contact?.includes("isBilling") && { isBilling: true }),
        };
        newPanel === addressStates.CREATE
          ? createAddress(createPayload)
          : editAddress(selectedAddress, updatePayload, contact);
      } else {
        showAlertDialogError("Please fix all required fields.");
      }
    });
  };

  const getRequiredFields = () => {
    const obj = {
      address1: true,
      address2: requiredDefaultFields.billing_address_line_2 === "required",
      city: requiredDefaultFields.billing_city === "required",
      email: requiredDefaultFields.billing_email === "required",
      fax: requiredDefaultFields.billing_fax === "required",
      firstName: true,
      lastName: requiredDefaultFields.billing_last_name === "required",
      phone: requiredDefaultFields.billing_phone === "required",
      postalCode: requiredDefaultFields.billing_postal_code === "required",
    };
    return parseToArray(obj);
  };

  const parseToArray = (requiredObject) => {
    let arr = [];
    Object.entries(requiredObject).forEach((key) => {
      if (key[1]) {
        arr.push(key[0]);
      }
    });
    return arr;
  };

  const formatAddress = (address) => {
    if (address) {
      address.address_line_1 = address.addressLine1;
      address.address_line_2 = address.addressLine2;
      address.postal_code = address.postalCode;
      address.first_name = address.firstName;
      address.last_name = address.lastName;
      return address;
    }
  };

  const handleDefaultChange = (val, id) => {
    defaultAddress(id, val);
  };

  const handleFormDefault = (e, newContact) => {
    setContact(newContact);
  };

  const getAddressValue = (key, data) => {
    switch (key) {
      case "default": {
        let buttonStates = setUpDefaults(data);

        return (
          <div className="address-button-toggles">
            {" "}
            <ToggleButtonGroup
              exclusive
              onChange={(e, val) => {
                buttonStates.push(val);
                handleDefaultChange(val, data?.customerAddressId);
              }}
              id={data?.id}
              value={buttonStates}
            >
              <ToggleButton
                disabled={data?.isBilling || !permissions["VAULT_UPDATE"]}
                value="isBilling"
              >
                <Tooltip title="Click to set Default Billing">
                  <FeedOutlinedIcon />
                </Tooltip>
              </ToggleButton>

              <ToggleButton
                disabled={data?.isShipping || !permissions["VAULT_UPDATE"]}
                value="isShipping"
              >
                <Tooltip title="Click to set Default Shipping">
                  <LocalShippingIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <div className="label-text label-text__table">
              <span>Billing</span> <div>|</div> <span>Shipping</span>
            </div>
          </div>
        );
      }
      case "name": {
        return data?.firstName + " " + data.lastName;
      }
      case "company": {
        return data.company;
      }
      case "address": {
        return (
          <div className="address-block">
            <p>{data.addressLine1}</p>
            <p>{data.addressLine2}</p>
            <p>
              {data.city && <span>{data.city + ","} </span>} {data.state}{" "}
              {data.postalCode}
            </p>
          </div>
        );
      }
      case "contact": {
        return (
          <div className="contact-block">
            {data.email && (
              <div>
                <EmailIcon />
                <p>{data.email}</p>
              </div>
            )}
            {data.phone && (
              <div>
                <PhoneIcon />
                <p>{data.phone}</p>
              </div>
            )}
            {data.fax && (
              <div>
                <FaxIcon />
                <p>{data.fax}</p>
              </div>
            )}
          </div>
        );
      }
      default:
        return data[key];
    }
  };

  const setUpDefaults = (data) => {
    let buttonStates = [];
    if (data?.isBilling) {
      buttonStates.push("isBilling");
    }
    if (data?.isShipping) {
      buttonStates.push("isShipping");
    }
    return buttonStates;
  };
  const getActionsComponent = (row) => {
    return (
      <ButtonGroup>
        {permissions["VAULT_UPDATE"] && (
          <IconButton
            title={"Edit"}
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => {
              setSelectedAddress(row);
              setContact(setUpDefaults(row));
              setNewPanel(addressStates.UPDATE);
            }}
            color={"warning"}
            fontSize="small"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        {permissions["VAULT_DELETE"] && (
          <IconButton
            disabled={row?.isBilling || row?.isShipping}
            title={"Delete"}
            variant="container"
            sx={{ padding: 0.5 }}
            onClick={() => deleteAddress(row)}
            color={"error"}
            fontSize="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </ButtonGroup>
    );
  };
  return (
    <div>
      <div
        className={
          "screen-wrapper" +
          (newPanel !== addressStates.VIEW ? " new-panel" : "")
        }
      >
        <div className="origin-panel">
          {permissions["VAULT_CREATE"] && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setContact([]);
                setNewPanel(addressStates.CREATE);
              }}
              data-cy="create-address-button"
            >
              Create An Address
            </Button>
          )}

          <div className="sticky-table">
            <SearchTable
              searchResults={rowData}
              getEntityKeyValue={getAddressValue}
              fieldList={addressTableHeaders}
              getActionsComponent={getActionsComponent}
            />
          </div>
        </div>
        <div className="panel-details">
          <div className="address-header">
            <h3>
              {newPanel === addressStates.CREATE ? "Create" : "Update"} Address
            </h3>{" "}
          </div>
          <div className="default-row">
            <span>Set Your Default Address:</span>
            <div className="address-button-toggles">
              <ToggleButtonGroup value={contact} onChange={handleFormDefault}>
                <ToggleButton
                  disabled={selectedAddress && selectedAddress?.isBilling}
                  value="isBilling"
                >
                  <FeedOutlinedIcon />
                </ToggleButton>
                <ToggleButton
                  disabled={selectedAddress && selectedAddress?.isShipping}
                  value="isShipping"
                >
                  <LocalShippingIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              <div className="label-text">
                <span>Billing</span> | <span>Shipping</span>
              </div>
            </div>
          </div>

          <div>
            {newPanel === addressStates.CREATE ? (
              <div>
                <ContactContainer
                  type="address"
                  title="Address"
                  countries={countries}
                  country="US"
                  required={getRequiredFields()}
                />
              </div>
            ) : (
              <ContactContainer
                type="address"
                title="Address"
                countries={countries}
                country="US"
                required={getRequiredFields()}
                defaultValues={formatAddress(selectedAddress)}
              />
            )}
          </div>

          <div className="address-button-group">
            <Button
              size="small"
              variant="contained"
              color="neutrals"
              sx={{ marginRight: "10px" }}
              onClick={() => {
                setSelectedAddress();
                form.reset();
                setNewPanel(addressStates.VIEW);
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
CustomerAddress.propTypes = {
  addresses: PropTypes.array,
  defaults: PropTypes.object,
  permissions: PropTypes.object,
  merchantDetails: PropTypes.object,
  requiredDefaultFields: PropTypes.object,
  loadCustomerData: PropTypes.func,
  createAddress: PropTypes.func,
  deleteAddress: PropTypes.func,
  editAddress: PropTypes.func,
  defaultAddress: PropTypes.func,
  showAlertDialogError: PropTypes.func,
};
export default CustomerAddress;
