import React from "react";
import PropTypes from "prop-types";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AchIcon from "@mui/icons-material/AccountBalance";
import { Grid, Typography } from "@mui/material";

const CustomerPaymentMethod = ({ paymentMethod }) => {
  const maskedCard = (card) => (
    <Grid container>
      <CreditCardIcon />
      <Typography alignContent="flex-end" variant="body1">
        {card.maskedCard}
      </Typography>
    </Grid>
  );

  const maskedAccount = (ach) => (
    <Grid container>
      <AchIcon />
      <Typography alignContent="flex-end" variant="body1">
        {ach.maskedAccount}
      </Typography>
    </Grid>
  );

  return (
    <Grid container className="customer-payment-method-details">
      <Grid item xs={4}>
        <Typography>
          <strong>
            {paymentMethod.card ? "Card Number" : "Account Number"}
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>
          <strong>
            {paymentMethod.card ? "Card Expiration" : "Account Type"}
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>
          <strong>Default</strong>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {paymentMethod.card
          ? maskedCard(paymentMethod.card)
          : maskedAccount(paymentMethod.ach)}
      </Grid>
      <Grid item xs={4}>
        <Typography>
          {paymentMethod.card
            ? paymentMethod.card.expirationDate
            : paymentMethod.ach.accountType}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>{paymentMethod.isDefault ? "Yes" : "No"}</Typography>
      </Grid>
    </Grid>
  );
};

CustomerPaymentMethod.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
};

export default CustomerPaymentMethod;
