import React, { useEffect, useState, useRef, useContext } from "react";
import SearchManager from "../../Search/SearchManager";
import { sassEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import "./reportingsSettlements.scss";
import SaasSettlementResultsTable from "./SettlementTables/SaasSettlementResultsTable";
import ContentComponent from "../../Content/Content";
import { transformToPDF } from "../../../utils/pdfGenerator";
import DownloadButton from "../../Buttons/DownloadButton";
import {
  downloadSettlementsEvent,
  settlementsSearched,
} from "../../../services/mixpanel/merchant/settlementEvents";
import { saasSettlementSearchFields } from "./reportingSettlementFields";
import { stringFormat } from "../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { saasSortingHelper } from "./SettlementTables/settlementTableHelpers";

const getOutputFileName = () => {
  let date = new Date();
  let dateFormatted = date.toLocaleDateString("en-us", {
    year: "numeric",
    day: "numeric",
    month: "short",
  });
  let fileName = "settlements-{0}.csv";
  dateFormatted = dateFormatted.replaceAll(",", "").replaceAll(" ", "-");
  fileName = fileName.replace("{0}", dateFormatted);
  return fileName;
};

const SaasReportingSettlementsContainer = () => {
  const { userSettings } = useContext(UserSettingsContext);
  const [saasSettlements, setSaasSettlements] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [formData, setFormData] = useState(null);
  const [resultsOffset, setResultsOffset] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [sortObject, setSortObject] = useState({
    sortField: "batchDate",
    sortDirection: "desc",
  });

  const resultsRef = useRef();
  useEffect(() => {
    if (formData) {
      loadSaasSettlements();
    }
  }, [formData, resultsOffset, resultsPerPage, sortObject]);

  const loadSaasSettlements = async () => {
    let url = stringFormat(sassEndpoints.settlement.search, [
      userSettings.gatewayId,
    ]);
    let payload = {
      offset: resultsOffset,
      limit: resultsPerPage,
      sortColumn: saasSortingHelper(sortObject.sortField),
      sortDirection: sortObject.sortDirection.toUpperCase(),
    };
    if (formData) {
      if (Object.keys(formData).length > 0) {
        Object.keys(formData).forEach((key) => {
          if (key === "batchDate") {
            payload[key] = {
              operator: "Between",
              startDate: formData[key].startDate,
              endDate: formData[key].endDate,
            };
          } else {
            payload[key] = {
              operator: formData[key].operator,
              value: formData[key].value,
            };
          }
        });
      }
      settlementsSearched(formData, "results");
    }

    setShowBackdrop(true);

    axios
      .post(url, payload)
      .then(function (response) {
        if (response.status === 200) {
          setSaasSettlements(response.data.data);
          setResultsTotal(response.data.data.rowCount);
        } else if (response.status === 204) {
          setSaasSettlements(null);
          setResultsTotal(0);
        }
      })
      .catch(function (error) {
        showAlertDialogError(error?.response?.data?.statusDetails);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const handleSearchButtonOnClick = (form) => {
    setResultsOffset(0);
    setFormData(form);
  };

  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };
  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const downloadCSV = () => {
    let url = stringFormat(sassEndpoints.settlement.searchCSV, [
      userSettings.gatewayId,
    ]);
    let payload = {
      offset: resultsOffset,
      limit: resultsPerPage,
      sortColumn: saasSortingHelper(sortObject.sortField),
      sortDirection: sortObject.sortDirection.toUpperCase(),
    };
    if (formData) {
      if (Object.keys(formData).length > 0) {
        Object.keys(formData).forEach((key) => {
          if (key === "batchDate") {
            payload[key] = {
              operator: "Between",
              startDate: formData[key].startDate,
              endDate: formData[key].endDate,
            };
          } else {
            payload[key] = {
              operator: formData[key].operator,
              value: formData[key].value,
            };
          }
        });
      }
      settlementsSearched(formData, "results");
    }

    setShowBackdrop(true);
    axios
      .post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", getOutputFileName());
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        showAlertDialogError(error?.response?.data?.statusDetails);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const exportTableData = (val) => {
    if (saasSettlements?.settlements?.length > 0) {
      val === "CSV"
        ? downloadCSV(false)
        : transformToPDF(resultsRef, "settlement-results.pdf", 2000);
    } else {
      showAlertDialogError("No available rows to download.");
    }
  };

  const handleResultsPerPageOnChange = (event) => {
    setResultsOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultsOffset(pageIndex * resultsPerPage);
  };

  const handleSelection = (fileType) => {
    exportTableData(fileType);
    downloadSettlementsEvent(fileType);
  };

  return (
    <div className="reporting-settlements-container">
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="Settlements List"
        headerContent={
          <>
            <div>
              <SearchManager
                tableFormatAllowed={false}
                sectionName={"Settlement"}
                resultsPerPage={resultsPerPage}
                resultsTotal={resultsTotal}
                searchButtonOnClick={handleSearchButtonOnClick}
                showSnackBar={showSnackBar}
                showAlertDialogError={showAlertDialogError}
                entitySearchFilterFields={saasSettlementSearchFields}
              />
            </div>
            <div className="settlements-dl-button">
              <DownloadButton
                handleSelection={handleSelection}
                types={["CSV", "PDF"]}
              />
            </div>
          </>
        }
        bodyContent={
          <div>
            <SaasSettlementResultsTable
              data={saasSettlements ? saasSettlements?.settlements : null}
              isPDF={false}
              summaryData={saasSettlements?.acceptedSettlementsStats}
              resultsPerPage={resultsPerPage}
              resultsTotal={resultsTotal}
              handlePageChange={handlePageChange}
              handleResultsPerPageOnChange={handleResultsPerPageOnChange}
              sortObject={sortObject}
              setSortObject={setSortObject}
            />
            <div className="pdf-table" ref={resultsRef}>
              <SaasSettlementResultsTable
                data={saasSettlements ? saasSettlements?.settlements : null}
                isPDF={true}
                summaryData={saasSettlements?.acceptedSettlementsStats}
                sortObject={sortObject}
                setSortObject={setSortObject}
              />
            </div>
          </div>
        }
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
      />
    </div>
  );
};

export default SaasReportingSettlementsContainer;
