export const PROCESSOR_CONFIGS = {
  rapidOtc: "rapidotc",
  tsysSierra: "tsys_sierra",
  sageAch: "sage_ach",
  rapidHc: "rapidhc",
  fiservv33: "fiserv_v33",
  paya: "paya",
  rapidConnectBasysCardPresent: "rapid_connect_basys_card_present",
};

export const simpleTemplates = ["rapidotc", "rapidhc"];

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const processorListColumnHeaders = [
  {
    default_field: true,
    key: "id",
    sortable: false,
  },
  {
    default_field: true,
    key: "status",
    sortable: false,
  },
  {
    default_field: true,
    key: "type",
    sortable: false,
  },
  {
    default_field: true,
    key: "basys mid",
    sortable: false,
  },
  {
    default_field: true,
    key: "name",
    sortable: false,
  },
  {
    default_field: true,
    key: "defaults",
    sortable: false,
  },
];

export const processorListKeyValue = (key, data) => {
  switch (key) {
    case "id": {
      return data.processorId;
    }
    case "status": {
      return <div className={`active gateway-badge`}>active</div>;
    }
    case "type": {
      return data?.processorType?.name;
    }
    case "basys mid": {
      return data?.merchantId || "";
    }
    case "name": {
      return data.name;
    }
    case "defaults": {
      const defaultKeys = ["isDefaultAch", "isDefaultCard"];
      let defaultsArr = [];
      defaultKeys.forEach((key) => {
        if (data[key]) {
          defaultsArr.length > 0
            ? defaultsArr.push(", " + key.split("t")[1]?.toUpperCase())
            : defaultsArr.push(key.split("t")[1]?.toUpperCase());
        }
      });
      return defaultsArr;
    }
    default:
      return data[key];
  }
};
