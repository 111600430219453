import colors from "../../assets/colors.module.scss";
export const drawerWidth = 275;

export const styles = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: theme.palette.neutrals.light,
  },
  drawer: {
    display: "none",
    "@media screen and (min-width:768px)": {
      flexShrink: 0,
      display: "block",
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    padding: theme.spacing(2),
    background: colors.footer_background,
  },
});
