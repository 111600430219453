export const dateSince = [
  { value: "today", display: "Today" },
  { value: "yesterday", display: "Yesterday" },
  { value: "monday", display: "This Week" },
  { value: "last week starting on monday", display: "Last Week" },
  { value: "this_month", display: "This Month" },
  { value: "last_month", display: "Last Month" },
  { value: "this_year", display: "This Year" },
  { value: "last_year", display: "Last Year" },
];
//CHANGE BACK N MODIFY IN PAYLOAD
export const paymentType = [
  { value: "Card", display: "card" },
  { value: "Ach", display: "ach" },
  { value: "Token", display: "token" },
  { value: "Terminal", display: "terminal" },
];

export const gatewayStatus = [
  { value: "active", display: "Active" },
  { value: "disabled", display: "Disabled" },
];

export const systemUserStatus = [
  { value: "2", display: "Active" },
  { value: "0", display: "Pending" },
  { value: "3", display: "Disabled" },
];

export const basysRoles = [
  { value: "SU", display: "Super User" },
  { value: "SA", display: "System Admin" },
];

export const transactionSource = [
  { value: "Api", display: "api" },
  { value: "Batch", display: "batch" },
  { value: "Cp", display: "cp" },
  { value: "Invoice", display: "invoice" },
  { value: "Webhook", display: "webhook" },
  { value: "Cart", display: "cart" },
  { value: "Recurring", display: "recurring" },
];

export const transactionType = [
  { value: "Sale", display: "sale" },
  { value: "Authorize", display: "authorize" },
  { value: "Capture", display: "capture" },
  { value: "Credit", display: "credit" },
  { value: "Refund", display: "refund" },
  //Verification in backend
];

export const transactionStatus = [
  { value: "Unknown", display: "unknown" },
  { value: "Declined", display: "declined" },
  { value: "Authorized", display: "authorized" },
  { value: "PendingSettlement", display: "pending settlement" },
  { value: "Settled", display: "settled" },
  { value: "Voided", display: "voided" },
  { value: "Refunded", display: "refunded" },
  { value: "Returned", display: "returned" },
  { value: "LateReturn", display: "late return" },
  { value: "Pending", display: "pending" },
  { value: "PartiallyRefunded", display: "partially refunded" },
];

export const timezones = [
  { value: "UTC", display: "UTC" },
  { value: "US/Alaska", display: "US/Alaska" },
  { value: "US/Aleutian", display: "US/Aleutian" },
  { value: "US/Arizona", display: "US/Arizona" },
  { value: "US/Central", display: "US/Central" },
  { value: "US/Eastern", display: "US/Eastern" },
  { value: "US/East-Indiana", display: "US/East-Indiana" },
  { value: "US/Hawaii", display: "US/Hawaii" },
  { value: "US/Indiana-Starke", display: "US/Indiana-Starke" },
  { value: "US/Michigan", display: "US/Michigan" },
  { value: "US/Mountain", display: "US/Mountain" },
  { value: "US/Pacific", display: "US/Pacific" },
  { value: "US/Samoa", display: "US/Samoa" },
];
