import { USER_ROLES } from "../constants/global";

const isGatewayUser = (userSettings) => !userSettings?.userRole;
// role === USER_ROLES.GATEWAY_ADMIN || role === USER_ROLES.GATEWAY_STANDARD;

const isSystemUser = (role) =>
  role === USER_ROLES.SUPER_USER || role === USER_ROLES.SYSTEM_ADMIN;

const isValidRole = (role) =>
  role === USER_ROLES.SYSTEM_ADMIN ||
  role === USER_ROLES.SUPER_USER ||
  role === USER_ROLES.GATEWAY_ADMIN ||
  role === USER_ROLES.GATEWAY_STANDARD;

export { isGatewayUser, isSystemUser, isValidRole };
