import { Typography } from "@mui/material";
import React from "react";
import ReportingTransactionsContainer from "../../ReportingTransactions/ReportingTransactionsContainer";
import PropTypes from "prop-types";
const CustomerTransactions = ({ customerId }) => {
  return (
    <div>
      <Typography sx={{ fontWeight: "bold" }} color="textSecondary">
        {" "}
        *Showing most recent 50 transactions
      </Typography>
      <ReportingTransactionsContainer customerId={customerId} />
    </div>
  );
};
CustomerTransactions.propTypes = {
  customerId: PropTypes.string,
};
export default CustomerTransactions;
