import { useState, useEffect } from "react";
import AlertDialog from "../components/Dialog/AlertDialog.js";
import { logout } from "../utils/logout";
import { useMsal } from "@azure/msal-react";

export default function SessionTimeoutCheck() {
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [lastSessionActiveTime, setLastSessionActiveTime] = useState(
    Date.now(),
  );
  const { instance } = useMsal();

  let timeoutWarning = process.env.REACT_APP_SESSION_TIMEOUT_WARNING; //Seconds
  let timeoutFinal = process.env.REACT_APP_SESSION_TIMEOUT_FINAL; //Seconds
  let timeoutWarningTimeDiff = (timeoutFinal - timeoutWarning) / 60; //Difference output should be whole numbers after division (for minutes)

  //Timeout Warning - Dialog Box
  useEffect(() => {
    let firstTimer = setTimeout(() => {
      setShowAlertDialog(true);
    }, timeoutWarning * 1000);
    return () => {
      clearTimeout(firstTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSessionActiveTime]);

  //Timeout Final - Log user out
  useEffect(() => {
    let finalTimer = setTimeout(() => {
      logout(instance);
    }, timeoutFinal * 1000);
    return () => {
      clearTimeout(finalTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSessionActiveTime]);

  const handleAlertDialogCloseButtonClick = async () => {
    setLastSessionActiveTime(Date.now());
    setShowAlertDialog(false);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouse);
    document.addEventListener("mousedown", handleMouse);
    document.addEventListener("mouseup", handleMouse);
    document.addEventListener("wheel", handleMouse);
    document.addEventListener("keydown", handleMouse);
    document.addEventListener("keyup", handleMouse);

    return () => {
      window.removeEventListener("mousemove", handleMouse);
      window.removeEventListener("mousedown", handleMouse);
      window.removeEventListener("mouseup", handleMouse);
      window.removeEventListener("wheel", handleMouse);
      document.removeEventListener("keydown", handleMouse);
      document.removeEventListener("keyup", handleMouse);
    };
  }, []);

  function handleMouse() {
    setLastSessionActiveTime(Date.now());
  }

  const handleAlertDialogLogMeOut = async (event, reason) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return;

    setShowAlertDialog(false);
    logout(instance);
  };

  return (
    <AlertDialog
      alertOpen={showAlertDialog}
      alertTitle="Are you still there?"
      alertMessages={[
        "You will be logged out in " +
          timeoutWarningTimeDiff +
          " minutes if no activity",
      ]}
      alertLevel="info"
      actionButtons={[
        {
          text: "Keep me logged in",
          color: "secondary",
          onclick: (event) => handleAlertDialogCloseButtonClick(event),
        },
      ]}
      closeButtonText="Log me out"
      closeButtonColor="neutrals"
      onCloseButtonClick={handleAlertDialogLogMeOut}
    />
  );
}
