import { useState } from "react";

export const useAlertDialog = (initialMessages) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessages, setAlertMessages] = useState(initialMessages);

  const displayMessages = (messages) => {
    if (messages) setAlertMessages(messages);
    setIsAlertOpen(true);
  };

  return {
    isAlertOpen,
    setIsAlertOpen,
    alertMessages,
    setAlertMessages,
    displayMessages,
  };
};
export default useAlertDialog;
