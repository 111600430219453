import React, { useContext, useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import SearchTable from "../../../Search/SearchTable";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { subscriptionsTableHeaders } from "./cvCustomerTableHelpers";
import { statusTag } from "../../../Merchant/Recurring/Subscriptions/subscriptionHelpers";
import { formatDateTime } from "../../../../utils/stringHelpers";
import { merchantRoutes } from "../../../../constants/routes";
import { useHistory } from "react-router-dom";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { SUBSCRIPTION_STATUSES } from "../../../../constants/global";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import ResumeDialog from "../../Recurring/Subscriptions/Dialogs/ResumeDialog";
import ConfirmationDialog from "../../Recurring/Subscriptions/Dialogs/ConfirmationDialog";
import CancelDialog from "../../Recurring/Subscriptions/Dialogs/CancelDialog";
import emptyState from "../../../../assets/images/empty-state.png";

const CustomerSubscriptions = ({
  subscriptions,
  onPause,
  onResume,
  onCancel,
}) => {
  const { userSettings } = useContext(UserSettingsContext);
  const [rowData, setRowData] = useState(null);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openResume, setOpenResume] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState({
    open: false,
  });
  const canEditSubscription =
    userSettings.gatewayUserPermissions["EDIT_SUBSCRIPTION"];

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const getSortableTableCell = (keyData) => {
    var cellSx = { sx: { whiteSpace: "nowrap" } };
    if (keyData.sortable) {
      cellSx.sx.cursor = "pointer";
    }
    return cellSx;
  };

  useEffect(() => {
    setRowData(subscriptions);
  }, [subscriptions]);

  const handlePause = () => {
    if (selectedRow) {
      setConfirmationDialogProps({
        alertTitle: "Do you wish to pause this subscription?",
        alertLevel: "warning",
        open: true,
        content:
          "Unpaid invoices will remain open and future invoices will not be generated until the Subscription is resumed.",
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            open: false,
          }),
        onConfirm: () => {
          onPause(selectedRow.subscriptionId);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            open: false,
          });
        },
        okButtonClass: "btn--primary__danger",
        okButtonText: "Pause Subscription",
        closeButtonClass: "btn--secondary__danger",
        closeButtonText: "Cancel",
      });
    }
  };

  const handleResume = () => {
    if (selectedRow) {
      setOpenResume(true);
    }
  };

  const handleCancel = () => {
    if (selectedRow) {
      setOpenCancel(true);
    }
  };

  const handleConfirmCancel = (payload) => {
    if (selectedRow) {
      onCancel(payload, selectedRow.subscriptionId);
      setOpenCancel(false);
    }
  };

  const handleConfirmResume = (payload) => {
    if (selectedRow) {
      onResume(payload, selectedRow.subscriptionId);
      setOpenResume(false);
    }
  };

  const getSubscriptionValue = (key, data) => {
    switch (key) {
      case "Subscription Name": {
        return data.name;
      }
      case "status": {
        return statusTag(data.subscriptionStatus?.name);
      }
      case "Amount Per Period": {
        return (
          "$" +
          data.totalAmount.toFixed(2) +
          " / " +
          (data.billingPeriod?.name || "Custom Period")
        );
      }
      case "Remaining Periods": {
        return data.recurrence?.invoicesRemaining;
      }
      case "billing": {
        if (data.isAutoCharged) {
          if (data.paymentMethod?.customerPaymentMethod?.card) {
            const maskedCard =
              data.paymentMethod?.customerPaymentMethod?.card.maskedCard;
            const lastFourDigits = maskedCard.slice(-4); // Extract the last 4 digits
            return (
              <>
                Automatically charge
                <br />
                <CreditCardIcon style={{ verticalAlign: "middle" }} />
                &nbsp;**** {lastFourDigits}
              </>
            );
          } else if (data.paymentMethod?.customerPaymentMethod?.ach) {
            const maskedAccount =
              data.paymentMethod?.customerPaymentMethod?.ach.maskedAccount;
            return (
              <>
                Automatically charge
                <br />
                <AccountBalanceIcon style={{ verticalAlign: "middle" }} />
                &nbsp; {maskedAccount}
              </>
            );
          } else {
            return "none";
          }
        } else {
          return "Send Invoice";
        }
      }

      case "Created Date": {
        return (
          <div>
            <span className="force-break">
              {" "}
              {formatDateTime(data.createdDateTime).split(" ")[0]}{" "}
            </span>
            <span> {formatDateTime(data.createdDateTime).split(" ")[1]}</span>
            <span> {formatDateTime(data.createdDateTime).split(" ")[2]}</span>
          </div>
        );
      }
      default:
        return data[key];
    }
  };

  const getActionsComponent = (row) => (
    <Grid className="subscription-details-container">
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event) => handleClick(event, row)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && selectedRow === row}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            history.push(merchantRoutes.recurring_billing.subscriptions, {
              subscriptionId: row?.subscriptionId,
            })
          }
        >
          View Subscription
        </MenuItem>
        {row?.subscriptionStatus?.name === SUBSCRIPTION_STATUSES.PAUSED &&
          canEditSubscription && (
            <MenuItem onClick={handleResume}>Resume Subscription</MenuItem>
          )}
        {row?.subscriptionStatus?.name === SUBSCRIPTION_STATUSES.ACTIVE &&
          canEditSubscription && (
            <MenuItem onClick={handlePause}>Pause Subscription</MenuItem>
          )}
        {(row?.subscriptionStatus?.name === SUBSCRIPTION_STATUSES.ACTIVE ||
          row?.subscriptionStatus?.name === SUBSCRIPTION_STATUSES.OVERDUE ||
          row?.subscriptionStatus?.name === SUBSCRIPTION_STATUSES.PAUSED ||
          row?.subscriptionStatus?.name === SUBSCRIPTION_STATUSES.TRIALING) &&
          canEditSubscription && (
            <MenuItem onClick={handleCancel}>Cancel Subscription</MenuItem>
          )}
        <MenuItem
          onClick={() => {
            history.push(merchantRoutes.invoices.search, {
              subscriptionId: row?.subscriptionId,
            });
          }}
        >
          View Invoices(s)
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push(merchantRoutes.reporting.transactions, {
              subscriptionId: row?.subscriptionId,
            })
          }
        >
          View Transaction(s)
        </MenuItem>
      </Menu>
      <ConfirmationDialog {...confirmationDialogProps} />
      <ResumeDialog
        subscription={row}
        open={openResume}
        onConfirm={handleConfirmResume} // Pass the subscriptionId back up to the parent
        onClose={() => setOpenResume(false)}
      />
      <CancelDialog
        subscription={row}
        open={openCancel}
        onConfirm={handleConfirmCancel}
        onClose={() => setOpenCancel(false)}
      />
    </Grid>
  );

  return rowData?.length === 0 ? (
    <Grid
      container
      height="100%"
      justifyContent="center"
      alignItems="center"
      sx={{
        marginTop: 15,
        border: "1px solid #ccc",
        borderRadius: 2,
        padding: 3,
      }}
    >
      <Grid
        container
        textAlign="center"
        flexDirection="column"
        alignItems="center"
      >
        <Grid item>
          <img src={emptyState} alt="Empty state" />
        </Grid>
        <Grid item sx={{ marginTop: 0 }}>
          <Typography variant="body1">
            <strong>No Subscriptions Found</strong>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <div className="sticky-table v2">
      <SearchTable
        searchResults={rowData}
        getEntityKeyValue={getSubscriptionValue}
        fieldList={subscriptionsTableHeaders}
        getActionsComponent={getActionsComponent}
        getSortableTableCell={getSortableTableCell}
      />
    </div>
  );
};

CustomerSubscriptions.propTypes = {
  subscriptions: PropTypes.array,
  onPause: PropTypes.func.isRequired,
  onResume: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CustomerSubscriptions;
