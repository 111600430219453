import React from "react";
import HelpContactUs from "./HelpContactUs";
import ContentComponent from "../../Content/Content";

const HelpContactUsContainer = () => {
  return (
    <ContentComponent title="Contact Us" bodyContent={<HelpContactUs />} />
  );
};

export default HelpContactUsContainer;
