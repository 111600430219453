import React, { useState } from "react";
import "./application.scss";
import { Button, Grid, Modal, Box, Tooltip, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@mui/styles";
import WarningIcon from "@mui/icons-material/Warning";

const RecordSecretModal = ({ openSecretModal, setOpenSecretModal }) => {
  const theme = useTheme();
  const [secretCopied, setSecretCopied] = useState(false);
  return (
    <Modal
      className={
        theme?.palette?.mode === "dark" ? "secret-modal dark" : "secret-modal"
      }
      open={openSecretModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-cy="record-your-secret-modal"
    >
      <Box>
        <Grid container flexDirection={"column"}>
          <Grid item style={{ margin: "auto" }}>
            <WarningIcon
              style={{ color: "f5c71a", fontSize: "xxx-large" }}
              fontSize="large"
            />
          </Grid>
          <Grid item>
            <p
              style={{
                fontSize: "32px",
                fontWeight: "700",
                margin: "auto",
                width: "fit-content",
              }}
            >
              Record Your Secret
            </p>
          </Grid>
          <Grid item>
            <p id="modal-modal-description" style={{ textAlign: "center" }}>
              For security reasons, this is the{" "}
              <span style={{ fontWeight: "600" }}>
                only opportunity to copy or record your secret.
              </span>{" "}
              It will not be displayed again and cannot be retrieved at a later
              time.{" "}
            </p>
          </Grid>
          <Grid item>
            <p
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Secret Name: {openSecretModal?.displayName}
            </p>
          </Grid>
          <Grid item>
            <Grid container flexDirection={"row"} className="copy-secret-div">
              <Grid item xs={10.5} style={{ padding: "8px 12px 8px 12px" }}>
                {openSecretModal?.value}
              </Grid>
              <Grid item xs={1.5} className="copy-button">
                <Tooltip title={secretCopied ? "Copied!" : "Copy Secret"}>
                  <IconButton
                    variant="container"
                    fontSize="small"
                    onClick={() => {
                      setSecretCopied(true);
                      navigator.clipboard.writeText(openSecretModal?.value);
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ margin: "auto" }}>
            <Button
              className="recorded-secret-button"
              onClick={() => {
                setOpenSecretModal(false);
              }}
              data-cy="recorded-my-secret"
            >
              <p
                style={{
                  margin: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" I've Recorded My Secret"}
                <CheckCircleOutlineIcon style={{ padding: "5px" }} />
              </p>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

RecordSecretModal.propTypes = {
  openSecretModal: PropTypes.obj,
  setOpenSecretModal: PropTypes.func,
};

export default RecordSecretModal;
