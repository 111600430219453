import { useRef } from "react";

const useIsInitialRender = () => {
  const isInitial = useRef(true);
  if (isInitial.current) {
    isInitial.current = false;

    return true;
  }

  return isInitial.current;
};

export default useIsInitialRender;
