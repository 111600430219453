import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SearchTable from "../../../Search/SearchTable";
import {
  getSaasKeyValue,
  saasResultsColumnHeaders,
} from "./settlementTableHelpers";
import { formatTransactionCurrency } from "../../../../utils/stringHelpers";
import logo from "../../../Drawer/iqpro-logo.png";
import SearchPaging from "../../../Search/SearchPaging";
const SaasSettlementResultsTable = ({
  data,
  summaryData,
  isPDF,
  resultsTotal,
  resultsPerPage,
  handlePageChange,
  handleResultsPerPageOnChange,
  sortObject,
  setSortObject,
}) => {
  const [resultsFooter, setResultsFooter] = useState([]);
  const getSummaryInfo = () => {
    if (summaryData) {
      let footerRow = [];
      if (!isPDF) {
        footerRow.push("");
      }
      footerRow.push(
        "",
        "",
        "",
        "Cumulative Total:",
        summaryData?.transactionsCount || 0,
        formatTransactionCurrency(summaryData?.netDepositAmount) || "$0.00",
        formatTransactionCurrency(summaryData?.capturedAmount) || "$0.00",
        formatTransactionCurrency(summaryData?.baseAmount) || "$0.00",
      );
      if (isPDF) {
        footerRow.push(
          formatTransactionCurrency(summaryData?.surchargeAmount),
          formatTransactionCurrency(summaryData?.paymentAdjustmentAmount),
        );
      } else {
        if (summaryData?.surchargeAmount > 0) {
          footerRow.push(
            formatTransactionCurrency(summaryData?.surchargeAmount),
          );
        }
        if (summaryData?.paymentAdjustmentAmount > 0) {
          footerRow.push(
            formatTransactionCurrency(summaryData?.paymentAdjustmentAmount),
          );
        }
      }

      footerRow.push(
        formatTransactionCurrency(summaryData?.creditAmount) || "$0.00",
      );

      setResultsFooter(footerRow);
    } else {
      setResultsFooter([]);
    }
  };

  const resetSorting = () => {
    saasResultsColumnHeaders.map((key) => {
      return (key.sortType = null);
    });
  };

  const handleSortOnClick = (key) => {
    resetSorting();
    let newSortDirection;
    let newSortField;
    if (key !== sortObject.sortField) newSortDirection = "desc";
    else {
      if (sortObject.sortDirection === "asc") newSortDirection = "desc";
      else newSortDirection = "asc";
      saasResultsColumnHeaders.map((keyInfo) => {
        return (keyInfo.sortType =
          keyInfo.key === key ? newSortDirection : null);
      });
    }
    newSortField = key;
    setSortObject({ sortField: newSortField, sortDirection: newSortDirection });
  };

  useEffect(() => {
    getSummaryInfo();
  }, [data]);

  return (
    <>
      <div className="summary-table-wrapper">
        {!isPDF ? (
          <div className="sticky-table">
            <SearchTable
              searchResults={data}
              handleSortOnClick={handleSortOnClick}
              getEntityKeyValue={getSaasKeyValue}
              fieldList={saasResultsColumnHeaders.filter(
                (c) =>
                  !(
                    (c.key === "surcharge" &&
                      summaryData?.surchargeAmount === 0) ||
                    (c.key === "payment_adj" &&
                      summaryData?.paymentAdjustmentAmount === 0)
                  ),
              )}
              footerList={resultsFooter}
            />
          </div>
        ) : (
          <div>
            <div className="header-row">
              <div>
                <img src={logo} />
              </div>

              <h2>Results Summary</h2>
            </div>

            <SearchTable
              searchResults={data}
              handleSortOnClick={handleSortOnClick}
              getEntityKeyValue={getSaasKeyValue}
              fieldList={saasResultsColumnHeaders.filter((col) => {
                return col.key !== "action";
              })}
              footerList={resultsFooter}
            />
          </div>
        )}
      </div>
      {resultsTotal > 0 && (
        <div>
          {" "}
          <SearchPaging
            resultsTotal={resultsTotal}
            handleChange={handlePageChange}
            resultsPerPage={resultsPerPage}
            handleResultsPerPageOnChange={handleResultsPerPageOnChange}
          />
        </div>
      )}
    </>
  );
};

SaasSettlementResultsTable.defaultProps = {
  data: [],
};

SaasSettlementResultsTable.propTypes = {
  data: PropTypes.array,
  isPDF: PropTypes.bool,
  summaryData: PropTypes.object,
  resultsTotal: PropTypes.number,
  resultsPerPage: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleResultsPerPageOnChange: PropTypes.func,
  sortObject: PropTypes.object,
  setSortObject: PropTypes.func,
};

export default SaasSettlementResultsTable;
