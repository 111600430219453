import React from "react";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import IconButton from "@mui/material/IconButton";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatDateTime } from "../../../utils/stringHelpers";
import "../../../assets/v2/main.scss";
import "./terminal.scss";
import TerminalMenu from "./TerminalMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Switch from "@mui/material/Switch";
import { validationRules } from "../../../constants/validationRules";
import { regexPatterns } from "../../../constants/regexPatterns";
import { stringFormat } from "../../../utils/stringHelpers";
import { sassEndpoints } from "../../../constants/endpoints";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import { useFormContext } from "react-hook-form";

const TerminalDetails = ({
  activeTerminalObj,
  gatewayInfo,
  showError,
  showSnackbar,
  setShowBackdrop,
  setAlertDialogOpen,
  setAlertDialogProps,
  getTerminalData,
}) => {
  const methods = useFormContext();
  const [tablePage, setTablePage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditingReceiptTimeoutDelay, setIsEditingReceiptTimeoutDelay] =
    useState(false);
  const [tipEnabled, setTipEnabled] = useState(
    activeTerminalObj?.tipEnabled || false,
  );
  const [printingEnabled, setPrintingEnabled] = useState(
    activeTerminalObj?.printingEnabled || false,
  );
  const [isEditingTipPreference, setIsEditingTipPreference] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [tipPreferences, setTipPreferences] = useState({
    tipPreference1: null,
    tipPreference2: null,
    tipPreference3: null,
  });

  useEffect(() => {
    setTipEnabled(activeTerminalObj?.tipEnabled || false);
    setPrintingEnabled(activeTerminalObj?.printingEnabled || false);
  }, [activeTerminalObj]);

  useEffect(() => {
    if (activeTerminalObj) {
      setTipPreferences({
        tipPreference1:
          activeTerminalObj.tipPreference?.tipPreference1 * 100 || "",
        tipPreference2:
          activeTerminalObj.tipPreference?.tipPreference2 * 100 || "",
        tipPreference3:
          activeTerminalObj.tipPreference?.tipPreference3 * 100 || "",
      });
      setIsEditingTipPreference(false); // Exit editing mode on new terminal selection
    } else {
      setTipPreferences({
        tipPreference1: "",
        tipPreference2: "",
        tipPreference3: "",
      }); // Clear preferences
    }
  }, [activeTerminalObj]);

  const handleTipPreferenceChange = (tipNumber, value) => {
    setTipPreferences((prev) => ({
      ...prev,
      [`tipPreference${tipNumber}`]: value > 100 ? 100 : value < 0 ? 0 : value, // Keep within range
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (e, newPage) => {
    setTablePage(newPage);
  };

  const handleIntegerInput = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    if (parseInt(value, 10) > 100) {
      value = "100";
    }
    e.target.value = value;
  };

  const toggleTip = async (tip) => {
    setShowBackdrop(true);
    let payload = {
      tipEnabled: tip,
    };

    let url = stringFormat(sassEndpoints.terminal.updateTerminal, [
      gatewayInfo.gatewayId,
      activeTerminalObj.processor.processorId,
      activeTerminalObj.terminalId,
    ]);
    axios
      .put(url, payload)
      .then(() => {
        showSnackbar('Succesfully updated "Tip Enabled" setting.');
        setTipEnabled(tip); // Update state only after successful API call
      })
      .catch(() => {
        showError('Failed to update "Tip Enabled" setting.');
        getTerminalData(true, activeTerminalObj?.terminalId);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const togglePrintingEnabled = async (printingEnabled) => {
    setShowBackdrop(true);
    let payload = {
      printingEnabled: printingEnabled,
    };

    let url = stringFormat(sassEndpoints.terminal.updateTerminal, [
      gatewayInfo.gatewayId,
      activeTerminalObj.processor.processorId,
      activeTerminalObj.terminalId,
    ]);
    axios
      .put(url, payload)
      .then(() => {
        showSnackbar('Succesfully updated "Printing Enabled" setting.');
        setPrintingEnabled(printingEnabled); // Update state after successful API call
      })
      .catch(() => {
        showError('Failed to update "Printing Enabled" setting.');
        getTerminalData(true, activeTerminalObj?.terminalId);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const updateReceiptTimeoutDelay = async (delayString) => {
    setShowBackdrop(true);
    let payload = {
      receiptTimeoutDelay: parseInt(delayString) * 1000, // SaaS accept milliseconds
    };

    let url = stringFormat(sassEndpoints.terminal.updateTerminal, [
      gatewayInfo.gatewayId,
      activeTerminalObj.processor.processorId,
      activeTerminalObj.terminalId,
    ]);
    axios
      .put(url, payload)
      .then(() => {
        showSnackbar('Succesfully updated "Receipt Timeout Delay" setting.');
      })
      .catch(() => {
        showError('Failed to update "Receipt Timeout Delay" setting.');
        getTerminalData(true, activeTerminalObj?.terminalId);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const updateTipPreferences = async () => {
    setShowBackdrop(true);

    const payload = {
      tipPreference: {
        tipPreference1: parseFloat(tipPreferences.tipPreference1) / 100 || null,
        tipPreference2: parseFloat(tipPreferences.tipPreference2) / 100 || null,
        tipPreference3: parseFloat(tipPreferences.tipPreference3) / 100 || null,
      },
    };

    const url = stringFormat(sassEndpoints.terminal.updateTerminal, [
      gatewayInfo.gatewayId,
      activeTerminalObj.processor.processorId,
      activeTerminalObj.terminalId,
    ]);

    axios
      .put(url, payload)
      .then(() => {
        showSnackbar("Successfully updated Tip Preferences");
        setIsEditingTipPreference(false);
      })
      .catch(() => showError("Failed to update Tip Preferences"))
      .finally(() => setShowBackdrop(false));
  };

  return (
    <Grid container flexDirection={"column"} rowSpacing={"16px"}>
      <Grid item>
        <Grid
          container
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item sx={9} className="h5">
            {activeTerminalObj?.name}
          </Grid>
          <Grid item sx={3}>
            <Grid container>
              {" "}
              <Grid
                item
                className={
                  activeTerminalObj?.status?.toLowerCase() === "active"
                    ? "tag sm tag__success"
                    : activeTerminalObj?.status?.toLowerCase() === "pending"
                      ? "tag sm tag__warning"
                      : "tag sm tag__failed"
                }
              >
                {activeTerminalObj?.status?.toUpperCase()}
              </Grid>
              <Grid item style={{ marginLeft: "8px" }}>
                <MoreVertIcon
                  aria-controls={open ? "positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                />
                <TerminalMenu
                  open={open}
                  terminalObj={activeTerminalObj}
                  gatewayInfo={gatewayInfo}
                  setShowBackdrop={setShowBackdrop}
                  showError={showError}
                  anchorEl={anchorEl}
                  setAlertDialogOpen={setAlertDialogOpen}
                  setAlertDialogProps={setAlertDialogProps}
                  handleClose={handleClose}
                  showSnackbar={showSnackbar}
                  getTerminalData={getTerminalData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container flexDirection={"column"} className="card-obj">
          <Grid item>
            <Grid
              container
              flexDirection={"row"}
              className="caption"
              justifyContent={"space-between"}
              columns={4}
            >
              <Grid item sm={1}>
                Terminal ID
              </Grid>
              <Grid item sm={1}>
                Serial Number
              </Grid>
              <Grid item sm={1}>
                Source ID
              </Grid>
              <Grid item sm={1}>
                Model Name
              </Grid>
            </Grid>
          </Grid>
          <Grid item marginBottom={7}>
            <Grid
              container
              flexDirection={"row"}
              className="sub-2"
              justifyContent={"space-between"}
              columns={4}
            >
              <Grid item sm={1}>
                {activeTerminalObj?.terminalId?.substring(
                  activeTerminalObj?.terminalId?.length - 6,
                )}
              </Grid>
              <Grid item sm={1}>
                {activeTerminalObj?.serialNumber}
              </Grid>
              <Grid item sm={1}>
                {activeTerminalObj?.sourceTerminalId}
              </Grid>
              <Grid item sm={1}>
                {activeTerminalObj?.terminalType?.model}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              flexDirection={"row"}
              className="caption"
              justifyContent={"space-between"}
              columns={4}
            >
              <Grid item sm={1}>
                Tip Enabled
              </Grid>
              <Grid item sm={1}>
                Printing Enabled
              </Grid>
              <Grid item sm={1}>
                Receipt Timeout Delay (s)
              </Grid>
              <Grid item sm={1}></Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              flexDirection={"row"}
              className="sub-2"
              justifyContent={"space-between"}
            >
              <Grid item sm={1} style={{ position: "relative", top: "-10px" }}>
                <Switch
                  checked={tipEnabled}
                  onChange={(e) => {
                    toggleTip(e.target.checked);
                  }}
                  color="primary"
                />
              </Grid>
              <Grid item sm={1} style={{ position: "relative", top: "-10px" }}>
                <Switch
                  checked={printingEnabled}
                  disabled={!activeTerminalObj.terminalType.hasPrinter}
                  onChange={(e) => {
                    togglePrintingEnabled(e.target.checked);
                  }}
                  color="primary"
                />
              </Grid>
              <Grid item sm={2.9}>
                <Grid
                  container
                  direction="row"
                  height="fit-content"
                  width="fit-content"
                  alignItems="center"
                  className="save-cancel-container"
                >
                  <Grid item sm={3}>
                    <RegisteredTextField
                      name="receipt_timeout_delay"
                      label=""
                      defaultValue={
                        activeTerminalObj.receiptTimeoutDelay / 1000
                      }
                      viewOnly={!isEditingReceiptTimeoutDelay}
                      rules={{
                        pattern: {
                          value: regexPatterns.numericNoLeadingZero,
                          message:
                            "Receipt timeout delay may only contain a number greater than 0",
                        },
                        required:
                          "Please enter a receipt timeout delay between 1 and 999",
                        maxLength: validationRules.maxlength3,
                        minLength: {
                          value: 1,
                          message:
                            "Please enter a receipt timeout delay between 1 and 999",
                        },
                      }}
                    />
                  </Grid>
                  {isEditingReceiptTimeoutDelay ? (
                    <React.Fragment>
                      <Grid item>
                        <IconButton
                          id="save-setting"
                          title={"Save"}
                          variant="container"
                          onClick={() => {
                            updateReceiptTimeoutDelay(
                              methods.getValues().receipt_timeout_delay,
                            );
                            setIsEditingReceiptTimeoutDelay(false);
                          }}
                          color={"primary"}
                        >
                          <CheckBoxIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          id="save-setting"
                          title={"Save"}
                          variant="container"
                          onClick={() => {
                            setIsEditingReceiptTimeoutDelay(false);
                          }}
                          sx={{ color: "#9e9e9e" }}
                        >
                          <DisabledByDefaultIcon />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item>
                      <IconButton
                        id="edit-setting"
                        title={"Edit"}
                        variant="container"
                        onClick={() => {
                          setIsEditingReceiptTimeoutDelay(true);
                        }}
                        color={"warning"}
                        fontSize="small"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item sm={1}></Grid>
            </Grid>
          </Grid>
          {tipEnabled && (
            <>
              <Grid item>
                <Grid
                  container
                  flexDirection={"row"}
                  className="caption"
                  justifyContent={"space-between"}
                  columns={4}
                >
                  <Grid item sm={1}>
                    Tip Preferences
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container spacing={2} justifyContent="flex-start">
                  {[1, 2, 3].map((tipNumber) => (
                    <Grid item xs={3} md={1} key={tipNumber}>
                      <Grid
                        container
                        flexDirection={"row"}
                        className="caption"
                        justifyContent={"space-between"}
                        columns={4}
                      >
                        <Grid item sm={1}>
                          {`Preference${tipNumber}`}
                        </Grid>
                      </Grid>
                      <RegisteredTextField
                        name={`tipPreference${tipNumber}`}
                        className="tip-preference-input"
                        fullWidth
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            max: 100,
                            step: 1,
                          },
                          inputMode: "numeric",
                        }}
                        value={
                          tipPreferences[`tipPreference${tipNumber}`] || ""
                        }
                        disabled={!isEditingTipPreference}
                        onChange={(e) =>
                          handleTipPreferenceChange(tipNumber, e.target.value)
                        }
                        onInput={handleIntegerInput}
                      />
                    </Grid>
                  ))}
                  <Grid
                    item
                    sm={1}
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    {isEditingTipPreference ? (
                      <>
                        <IconButton
                          id="edit-setting"
                          title="Save"
                          onClick={updateTipPreferences}
                          color="primary"
                        >
                          <CheckBoxIcon />
                        </IconButton>
                        <IconButton
                          title="Cancel"
                          onClick={() => setIsEditingTipPreference(false)}
                          color={"default"}
                          sx={{ color: "#9e9e9e" }}
                        >
                          <DisabledByDefaultIcon />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        title="Edit"
                        onClick={() => setIsEditingTipPreference(true)}
                        fontSize="small"
                        variant="container"
                        color={"warning"}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent={"space-between"}>
          <Grid item className="h5">
            Installed Firmware
          </Grid>
          <Grid item>
            {/* <Button className="btn--link sm">
                              Upgrade Firmware +
                            </Button> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {activeTerminalObj?.providerTerminal?.installedFirmware ? (
          <Grid container flexDirection={"column"} className="card-obj">
            <Grid item>
              {
                activeTerminalObj?.providerTerminal?.installedFirmware
                  ?.firmwareName
              }
            </Grid>
            {activeTerminalObj?.providerTerminal?.installedFirmware
              ?.installTime && (
              <Grid item>
                {
                  activeTerminalObj?.providerTerminal?.installedFirmware
                    ?.installTime
                }
              </Grid>
            )}
          </Grid>
        ) : (
          <div className="card-obj">
            Installed Firmware is currently unavailable. Please reload the page
            to try again.
          </div>
        )}
      </Grid>
      <Grid item>
        <Grid container justifyContent={"space-between"}>
          <Grid item className="h5">
            Installed Apps
          </Grid>
          <Grid item>
            {/* <Button className="btn--link sm">
                              Install App +
                            </Button> */}
          </Grid>
        </Grid>
      </Grid>
      {activeTerminalObj?.providerTerminal?.installedApks?.length > 0 ? (
        <Grid
          item
          style={{
            width: "100%",
            height: "300px",
          }}
          className="table app-table"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>App</TableCell>
                <TableCell>Package</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Installation Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeTerminalObj?.providerTerminal?.installedApks
                .slice(tablePage * 3, tablePage * 3 + 3)
                .map((app, i) => (
                  <TableRow key={i}>
                    <TableCell>{app.appName}</TableCell>
                    <TableCell>{app.packageName}</TableCell>
                    <TableCell>{app.versionName}</TableCell>
                    <TableCell>{formatDateTime(app.installTime)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[3]}
            component="div"
            count={activeTerminalObj?.providerTerminal?.installedApks?.length}
            rowsPerPage={3}
            page={tablePage}
            onPageChange={handlePageChange}
          />
        </Grid>
      ) : (
        <Grid item>
          <div className="card-obj">
            Installed Apps is currently unavailable. Please reload the page to
            try again.
          </div>
        </Grid>
      )}
    </Grid>
  );
};

TerminalDetails.propTypes = {
  activeTerminalObj: PropTypes.object,
  gatewayInfo: PropTypes.object,
  showError: PropTypes.func,
  showSnackbar: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  setAlertDialogOpen: PropTypes.func,
  setAlertDialogProps: PropTypes.func,
  getTerminalData: PropTypes.func,
};
export default TerminalDetails;
