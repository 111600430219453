export const validationMessages = {
  alpha_only: "Alpha only",
  numeric_only: "Numeric only",
  alphanumeric_only: "Alphanumeric only",
  city: "Invalid city",
  invalid_email: "Invalid email address",
  invalid_email_or_address: "Invalid email address or addresses",
  name_field_req: "Name is a required field",
  value_field_req: "Value is a required field",
  vt_onsubmit_validation_error:
    "Please correct validation errors highlighted in red.",
};
