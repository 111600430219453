import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import withStyles from "@mui/styles/withStyles";
import styles from "../../Content/style";
import { SUPPORT_NUMBER } from "../../../constants/global";

const Address = () => {
  return (
    <Typography variant="body1" color="textSecondary" align="left">
      <b>{"Mailing Address: "}</b>
      <Link
        underline="hover"
        color="inherit"
        href="https://g.page/BASYSProcessing?share"
      >
        15300 W 105th Terr, Lenexa KS 66219 US
      </Link>
    </Typography>
  );
};

const Email = () => {
  return (
    <Typography variant="body1" color="textSecondary" align="left">
      <b>{"Support Email: "}</b>
      <Link underline="hover" color="inherit" href="mailto:iqpro@basyspro.com">
        iqpro@basyspro.com
      </Link>
    </Typography>
  );
};

const Phone = () => {
  return (
    <Typography variant="body1" color="textSecondary" align="left">
      <b>{"Phone: "}</b>
      <Link underline="hover" color="inherit" href="tel:+8883661325">
        {SUPPORT_NUMBER}
      </Link>
    </Typography>
  );
};

function HelpContactUs({ classes }) {
  return (
    <div className={classes.contentWrapper}>
      <Address />
      <Email />
      <Phone />
    </div>
  );
}

HelpContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HelpContactUs);
