import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "@mui/material/Modal";
import SearchTable from "../../../Search/SearchTable";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
  getKeyValue,
  resultsColumnHeaders,
  addTableHeaders,
} from "./settlementTableHelpers";
import { endpoints } from "../../../../constants/endpoints";
import { merchantRoutes } from "../../../../constants/routes";
import axios from "axios";
import SearchPaging from "../../../Search/SearchPaging";
import DownloadButton from "../../../Buttons/DownloadButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import {
  keyInfoLookup,
  getTransactionKeyValue,
} from "../../ReportingTransactions/SearchResults";
import { getOutputFileName } from "../../ReportingTransactions/ReportingTransactionsContainer";
import Tooltip from "@mui/material/Tooltip";
import { transformToPDF } from "../../../../utils/pdfGenerator";
import logo from "../../../../assets/images/clear-background-basys-logo.png";
import "../../ReportingTransactions/reportingTransactions.scss";

const ResultsDetailsModal = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const [transactionResults, setTransactionResults] = useState([]);
  const [pdfTransactionResults, setPDFTransactionResults] = useState([]);
  const [resultsOffset, setResultsOffset] = useState(0);
  const handleOpen = () => {
    setOpen(true);
    retreiveTransactions(false);
  };
  const history = useHistory();
  const tableRef = useRef();

  useEffect(() => {
    if (open) {
      retreiveTransactions(false);
    }
  }, [resultsOffset, resultsPerPage]);

  const retreiveTransactions = async (isPDF) => {
    setIsLoading(!isPDF);
    let url =
      endpoints.transaction_search +
      `/${data.merchant_id}?sortfield=created_date&sortdirection=desc`;
    let payLoad = {
      settlement_batch_id: { operator: "=", value: data.id },
      limit: isPDF ? 100 : resultsPerPage,
      offset: isPDF ? 0 : resultsOffset,
    };
    await axios
      .post(url, payLoad)
      .then((val) => {
        if (isPDF) {
          setPDFTransactionResults(val.data.data);
          transformToPDF(tableRef, "Transactions.pdf", 1350);
        } else {
          setTotalResults(val.data.total_count);
          setTransactionResults(val.data.data);
        }
      })
      .catch((error) => {
        console.log("Failed to get transaction details", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTransSettlement = () => {
    history.push({
      pathname: `${merchantRoutes.reporting.transactions}`,
      state: { settlement_batch_id: data.id },
    });
  };

  const downloadTransactionsCSV = async () => {
    let url = endpoints.transaction_search + `${data.merchant_id}?output=csv`;
    let payLoad = { settlement_batch_id: { operator: "=", value: data.id } };
    await axios.post(url, payLoad).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data.output_blob]),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", getOutputFileName());
      document.body.appendChild(link);
      link.click();
    });
  };
  const handleResultsPerPageOnChange = (event) => {
    setResultsOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };

  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultsOffset(pageIndex * resultsPerPage);
  };

  const handleSelection = (event) => {
    event === "CSV" ? downloadTransactionsCSV() : retreiveTransactions(true);
  };
  return (
    <div>
      <Tooltip title="View Details">
        <SearchIcon className="settlements-open-icon" onClick={handleOpen} />
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Settlement Details"
        aria-describedby="View Settlement Details"
        className="settlements-modal-wrapper"
      >
        <Box
          className="box-style"
          sx={{
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="modal-header">
            <h2>Settlement Details</h2>
            <CloseIcon className="close-icon" onClick={handleClose} />
          </div>
          <div className="modal-button-nav">
            <button onClick={handleClose} className="transaction-modal-button">
              <ChevronLeftIcon />
              <span>Back To Settlements</span>
            </button>
            <button
              onClick={handleTransSettlement}
              className="transaction-modal-button"
            >
              <span>Transaction Search</span>
              <ChevronRightIcon />
            </button>
          </div>

          <div className="table-margin-bottom">
            <SearchTable
              searchResults={[data]}
              getEntityKeyValue={getKeyValue}
              fieldList={addTableHeaders(
                resultsColumnHeaders,
                data.surcharge_amount,
                data.payment_adj_amount,
              ).filter((column) => {
                return column.key !== "action";
              })}
            />
          </div>
          {transactionResults.length > 0 && (
            <div className="csv-button" data-cy="download-button-modal-parent">
              <DownloadButton
                handleSelection={handleSelection}
                types={["CSV", "PDF"]}
              />
            </div>
          )}

          {transactionResults.length > 0 && (
            <div className="scroll-table">
              {isLoading ? (
                <div className="circular-spinner">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <SearchTable
                    searchResults={transactionResults}
                    getEntityKeyValue={getTransactionKeyValue}
                    fieldList={keyInfoLookup}
                    settlements
                  />
                  <div className="transaction-pdf-table" ref={tableRef}>
                    <div className="header-row">
                      <div>
                        <img src={logo} />
                      </div>

                      <h2>Transactions</h2>
                    </div>
                    <SearchTable
                      searchResults={pdfTransactionResults}
                      getEntityKeyValue={getTransactionKeyValue}
                      fieldList={keyInfoLookup}
                      settlements
                    />
                  </div>
                </>
              )}
            </div>
          )}

          {transactionResults.length > 0 && (
            <div>
              <SearchPaging
                resultsPerPage={resultsPerPage}
                resultsTotal={totalResults}
                handleChange={handlePageChange}
                handleResultsPerPageOnChange={handleResultsPerPageOnChange}
              />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};
ResultsDetailsModal.defaultProps = { data: {} };
ResultsDetailsModal.propTypes = { data: PropTypes.object };
export default ResultsDetailsModal;
