import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Select from "../../ReactHookForm/Select";
import { useFormContext, useWatch } from "react-hook-form";
import { PAYMENT_TYPE } from "./PaymentTabs";
import useIsInitialRender from "../../../hooks/useIsInitialRender";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../constants/routes";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import AlertDialog from "../../Dialog/AlertDialog";

const getPaymentDetails = (paymentId, payments) => {
  let payment = payments?.find((payment) => {
    return paymentId === payment?.paymentMethodId;
  });
  if (payment?.card) {
    return {
      type: PAYMENT_TYPE.CARD,
      maskedNumber: payment?.card?.maskedCard,
    };
  } else if (payment?.ach) {
    return { type: PAYMENT_TYPE.ACH, secCode: payment.secCode };
  }
  return { type: "" };
};

export const clearedContactAddress = {
  first_name: "",
  last_name: "",
  company: "",
  address_line_1: "",
  address_line_2: "",
  country: "US",
  state: "",
  city: "",
  postal_code: "",
  email: "",
  phone: "",
  fax: "",
};

export const formatAsContactAddress = (address) => {
  return {
    first_name: address?.firstName || "",
    last_name: address?.lastName || "",
    company: address?.company || "",
    address_line_1: address?.addressLine1 || "",
    address_line_2: address?.addressLine2 || "",
    country: address?.country || "",
    state: address?.state || "",
    city: address?.city || "",
    postal_code: address?.postalCode || "",
    email: address?.email || "",
    phone: address?.phone || "",
    fax: address?.fax || "",
  };
};

const getBin = (cardNumber) => {
  if (cardNumber.length < 6) return cardNumber;
  return cardNumber.substring(0, 6);
};

const Customer = ({
  customerInfo,
  // paymentOption,
  setPaymentOption,
  setDefaultAddresses,
  // surchargeSettingEnabled,
  setSecCode,
  setCardBinNumber,
}) => {
  const { control, setValue, resetField } = useFormContext();
  const history = useHistory();
  // let bin = useRef("");

  // let surchargeExempt = useRef(
  //   !customerInfo?.data?.customer?.flags?.includes("surcharge_exempt")
  // );
  const billing_state = useWatch({ control, name: "billing_address.state" });
  let isInitialRender = useIsInitialRender();
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const setAddress = (type, id) => {
    const address = customerInfo?.addresses?.find(
      (address) => address.customerAddressId === id,
    );
    setDefaultAddresses((addresses) => ({
      ...addresses,
      [type]: formatAsContactAddress(address),
    }));
  };

  // const requiresBinLookup = (paymentType) =>
  //   surchargeExempt.current &&
  //   surchargeSettingEnabled &&
  //   paymentType === PAYMENT_TYPE.CARD;

  useEffect(() => {
    if (isInitialRender) {
      setValue("payment_method.customer.id", customerInfo?.customerId);
      handlePaymentChange(
        customerInfo?.paymentMethods.find((method) => {
          return method.isDefault;
        })?.paymentMethodId ||
          customerInfo?.paymentMethods.find((method) => {
            return method.isDefault;
          })?.paymentMethodId,
      );
    }
    // else if (requiresBinLookup(paymentOption)) {
    //   binLookup(bin.current, billing_state);
    // }
  }, [billing_state]);

  const handleClearCustomer = () => {
    setPaymentOption("");
    resetField("payment_method.customer");
    setDefaultAddresses({
      billing: clearedContactAddress,
      shipping: clearedContactAddress,
    });
    history.push(merchantRoutes.virtual_terminal);
  };

  const handlePaymentChange = (id) => {
    const paymentDetails = getPaymentDetails(id, customerInfo?.paymentMethods);

    setPaymentOption(paymentDetails.type);
    setValue(
      "payment_method.customer.payment_method_type",
      paymentDetails.type,
    );

    if (paymentDetails.type === PAYMENT_TYPE.ACH) {
      setSecCode(paymentDetails.secCode);
    } else if (paymentDetails.type === PAYMENT_TYPE.CARD) {
      setCardBinNumber(getBin(paymentDetails.maskedNumber));
    }
  };

  // const binLookup = (bin, state = "") => {
  //   const params = {
  //     source: "vt",
  //     bin: bin,
  //     state: state,
  //   };
  //   axios
  //     .post(endpoints.lookup_bin_protected, params)
  //     .then((response) => {
  //       setIsCustomerSurchargeable(
  //         response?.data?.data?.is_surchargeable || false
  //       );
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsCustomerSurchargeable(false);
  //     });
  // };

  return (
    <Grid container spacing={2}>
      <AlertDialog
        alertOpen={isAlertOpen}
        alertTitle="Are you sure?"
        alertLevel="info"
        alertMessages={["Remove customer and display search view"]}
        actionButtons={[
          {
            text: "Yes, remove them!",
            color: "secondary",
            onclick: handleClearCustomer,
          },
        ]}
        closeButtonText="Cancel"
        closeButtonColor="neutrals"
        onCloseButtonClick={() => setIsAlertOpen(false)}
        data-cy="vt_customer_search_customer_id"
      />
      <Grid item xs={5}>
        <Typography color="rgba(0, 0, 0, 0.6)" variant="subtitle1">
          Customer Id
        </Typography>
        <Typography data-cy="vt_customer_search_customer_id" variant="body">
          {customerInfo.customerId}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography color="rgba(0, 0, 0, 0.6)" variant="subtitle1">
          Customer Name
        </Typography>
        <Typography variant="body">{customerInfo.name}</Typography>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: "right" }}>
        <Tooltip title="Remove customer">
          <IconButton
            variant="container"
            color="primary"
            fontSize="small"
            onClick={() => setIsAlertOpen(true)}
          >
            <PersonRemoveIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      {customerInfo?.description && (
        <Grid item xs={12}>
          <Typography color="rgba(0, 0, 0, 0.6)" variant="subtitle1">
            Customer Description
          </Typography>
          <Typography variant="body">{customerInfo?.description}</Typography>
        </Grid>
      )}
      <Grid item xs={12} mt={1}>
        <Select
          control={control}
          name="payment_method.customer.payment_method_id"
          label="Payment Option"
          fullWidth
          onChange={handlePaymentChange}
          defaultValue={
            customerInfo?.paymentMethods.find((method) => {
              return method.isDefault;
            })?.paymentMethodId
          }
          data-cy="vt_customer_search_payment_option_dropdown"
        >
          {customerInfo?.paymentMethods.map((method) => {
            return (
              <MenuItem
                key={method.paymentMethodId}
                value={method.paymentMethodId}
                data-cy="vt_customer_search_payment_option_select"
              >
                {method.card ? (
                  <>
                    {" "}
                    {method?.card?.cardType} / {method?.card?.maskedCard}
                  </>
                ) : (
                  <>ach / {method?.ach?.maskedAccount}</>
                )}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Select
          control={control}
          name="payment_method.customer.billing_address_id"
          label="Billing Address"
          fullWidth
          onChange={(id) => setAddress("billing", id)}
          defaultValue={
            customerInfo?.addresses.find((address) => {
              return address.isBilling;
            })?.customerAddressId
          }
          data-cy="vt_customer_search_billing_address_dropdown"
        >
          {customerInfo?.addresses?.map((address) => (
            <MenuItem
              key={address.customerAddressId}
              value={address.customerAddressId}
              data-cy="vt_customer_search_billing_address_select"
            >
              {address.addressLine2 ? (
                <>
                  {" "}
                  {address.addressLine1}, {address.addressLine2}
                </>
              ) : (
                <>{address.addressLine1}</>
              )}
              {address.city ? `, ${address.city}` : ""}{" "}
              {address.state ? `, ${address.state}` : ""}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Select
          control={control}
          name="payment_method.customer.shipping_address_id"
          label="Shipping Address"
          fullWidth
          onChange={(id) => setAddress("shipping", id)}
          defaultValue={
            customerInfo?.addresses.find((address) => {
              return address.isShipping;
            })?.customerAddressId
          }
          data-cy="vt_customer_search_shipping_address_dropdown"
        >
          {customerInfo?.addresses?.map((address) => (
            <MenuItem
              key={address.customerAddressId}
              value={address.customerAddressId}
              data-cy="vt_customer_search_shipping_address_select"
            >
              {address.addressLine2 ? (
                <>
                  {" "}
                  {address.addressLine1}, {address.addressLine2}
                </>
              ) : (
                <>{address.addressLine1}</>
              )}
              {address.city ? `, ${address.city}` : ""}{" "}
              {address.state ? `, ${address.state}` : ""}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

Customer.propTypes = {
  customerInfo: PropTypes.object.isRequired,
  paymentOption: PropTypes.string.isRequired,
  setPaymentOption: PropTypes.func.isRequired,
  setDefaultAddresses: PropTypes.func.isRequired,
  surchargeSettingEnabled: PropTypes.bool.isRequired,
  setIsCustomerSurchargeable: PropTypes.func.isRequired,
  setSecCode: PropTypes.func.isRequired,
  setCardBinNumber: PropTypes.func.isRequired,
};

export default Customer;
