import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";

const SearchPaging = ({
  handleChange,
  resultsPerPage,
  resultsTotal,
  handleResultsPerPageOnChange,
  defaultPerPage,
  resetPage,
  setResetPage,
  setSearchClicked,
  showCancelButton,
  onCancel,
}) => {
  const totalPages = Math.ceil(resultsTotal / resultsPerPage);

  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
  }, [totalPages]);

  useEffect(() => {
    if (resetPage === true) {
      setPage(1);
      setResetPage(false);
      setSearchClicked(false);
    }
  }, [resetPage]);

  return (
    <Grid container className="search-paging">
      {showCancelButton && (
        <Grid item>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
      )}
      <Pagination
        count={totalPages}
        page={page}
        shape="rounded"
        color="primary"
        onChange={(_e, value) => {
          setPage(value);
          handleChange(value);
        }}
        data-cy="mui-pagination-buttons"
      />
      <Select
        size="small"
        defaultValue={defaultPerPage}
        className="pagination-results-dropdown"
        sx={{ p: 0, m: 0, fontSize: 12, width: 125 }}
        inputProps={{ padding: 0 }}
        onChange={handleResultsPerPageOnChange}
      >
        <MenuItem value="10">10 per page</MenuItem>
        <MenuItem value="20">20 per page</MenuItem>
        <MenuItem value="50">50 per page</MenuItem>
        <MenuItem value="100">100 per page</MenuItem>
      </Select>
    </Grid>
  );
};

SearchPaging.propTypes = {
  handleChange: PropTypes.func,
  handleResultsPerPageOnChange: PropTypes.func,
  resultsPerPage: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  defaultPerPage: PropTypes.number,
  autoSize: PropTypes.bool,
  resetPage: PropTypes.bool,
  setResetPage: PropTypes.func,
  setSearchClicked: PropTypes.func,
  showCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
};

SearchPaging.defaultProps = {
  autoSize: false,
  showCancelButton: false,
  defaultPerPage: 10,
};

export default SearchPaging;
