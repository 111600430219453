import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { differenceInDays } from "../../../../../utils/dateHelpers";

const SubscriptionSummary = ({ subscription }) => {
  return (
    <Grid container>
      <Grid item marginY={2}>
        <Typography variant="h3">
          <strong>Subscription Details</strong>
        </Typography>
      </Grid>
      <Grid
        item
        container
        className="subscription-details"
        rowSpacing={2}
        columnSpacing={1}
      >
        <Grid
          item
          container
          xs={12}
          sm={3}
          className="subscription-summary-field"
        >
          <Grid item xs={12}>
            <Typography variant="body1">Created Date</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <strong>
                {parseISO(subscription.createdDateTime).toLocaleDateString()}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={3}
          className="subscription-summary-field"
        >
          <Grid item xs={12}>
            <Typography variant="body1">Start Date</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <strong>
                {parseISO(
                  subscription.recurrence.termStartDate,
                ).toLocaleDateString()}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        {differenceInDays(
          subscription.recurrence.termStartDate,
          subscription.recurrence.billingStartDate,
        ) !== 0 && (
          <Grid
            item
            container
            xs={12}
            sm={3}
            className="subscription-summary-field"
          >
            <Grid item xs={12}>
              <Typography variant="body1">Billing Start Date</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                <strong>
                  {parseISO(
                    subscription.recurrence.billingStartDate,
                  ).toLocaleDateString()}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          sm={3}
          className="subscription-summary-field"
        >
          <Grid item xs={12}>
            <Typography variant="body1">
              {(!subscription.recurrence.termEndDate &&
                !subscription.recurrence.numberOfBillingPeriods) ||
              subscription.recurrence.termEndDate
                ? "End Date"
                : "Total Billing Periods"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <strong>
                {subscription.recurrence.termEndDate
                  ? parseISO(
                      subscription.recurrence.termEndDate,
                    ).toLocaleDateString()
                  : subscription.recurrence.numberOfBillingPeriods
                    ? subscription.recurrence.numberOfBillingPeriods
                    : "Never"}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={3}
          className="subscription-summary-field"
        >
          {(subscription.recurrence.numberOfBillingPeriods ||
            subscription.recurrence.termEndDate) && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Remaining Billing Periods
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <strong>{subscription.recurrence.invoicesRemaining}</strong>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={3}
          className="subscription-summary-field"
        >
          <Grid item xs={12}>
            <Typography variant="body1">Subscription ID</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <strong>{subscription.subscriptionId}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={3}
          className="subscription-summary-field"
        >
          <Grid item xs={12}>
            <Typography variant="body1">Subscription Number</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <strong>{subscription.number}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className="subscription-summary-field">
          <Grid>
            <Typography variant="body1">Note</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className="invoice-message">
              <strong>{subscription?.note}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SubscriptionSummary.propTypes = {
  subscription: PropTypes.object,
};

export default SubscriptionSummary;
