import mixpanel from "mixpanel-browser";
import { flattenedObject } from "../../../utils/flattenedObject";

// called when the user does not successfully either : captures, refunds, voids,
// emails or vaults the transaction list and details view
export function reportingTransactionsActionFailed(payload) {
  const flattenedPayload = flattenedObject(payload);
  mixpanel.track(
    `Request cannot be ${payload.action_type} from ${payload.page}`,
    flattenedPayload,
  );
}

// called when the user successfully either : captures, refunds, voids,
// emails or vaults the transaction list and details view
export function reportingTransactionsActionSuccess(payload) {
  const flattenedPayload = flattenedObject(payload);
  mixpanel.track(
    `Request has been ${payload.action_type} from ${payload.page}`,
    flattenedPayload,
  );
}

/*export function viewTransactionDetails(payload) {
  const processor = Object.values(payload.response_body).find((body) => {
    return body;
  });
  let customFields = [];
  if (payload.custom_fields) {
    Object.values(payload.custom_fields).forEach((field) => {
      field?.forEach((val) => {
        customFields.push(val);
      });
    });
    customFields = customFields.join(", ");
  }
  const transactionData = {
    status: payload.status,
    transaction_id: payload.id,
    order_id: payload.order_id,
    po_number: payload.po_number,
    type: payload.type,
    response: payload.response,
    response_code: payload.response_code,
    payment_method: payload.payment_method,
    tax_exempt: payload.tax_exempt,
    source: payload.transaction_source,
    username: payload.user_name,
    processor_type: payload.processor_type,
    processor_id: payload.processor_id,
    processor_name: payload.processor_name,
    created_at: payload.created_at,
    updated_at: payload.updated_at,
    total_amount: payload.amount,
    payment_adjustment: payload.payment_adjustment,
    amount_authorized: payload.amount_authorized,
    amount_captured: payload.amount_captured,
    auth_code: payload.response_code,
    processor_response_text: processor ? processor.processor_response_text : "",
    cvv_response_code: processor?.cvv_response_code
      ? processor?.cvv_response_code
      : "",
    avs_response_code: processor?.avs_response_code
      ? processor?.avs_response_code
      : "",
    processor_transaction_id: processor
      ? processor.processor_transaction_id
      : "",
    customer_id: payload.customer_id,
    custom_fields: customFields,
    transaction_features:
      payload.features?.length > 0 ? payload.features.join(", ") : "",
    billing: payload.billing_address,
    shipping: payload.shipping_address,
  };
  const flattenedPayload = flattenedObject(transactionData);
  mixpanel.track(
    "Reporting - Transactions - Transaction Details Viewed",
    flattenedPayload
  );
}*/
export function viewTransactionDetails(payload) {
  let features = [];
  if (payload.transactionFeatures) {
    Object.values(payload.transactionFeatures).forEach((feature) => {
      features.push(feature.code);
    });
    features = features.join(", ");
  }
  let billingAddress = "";
  let shippingAddress = "";
  for (const address of payload.addresses) {
    if (address.isShipping) shippingAddress = address;
    if (address.isBilling) billingAddress = address;
  }
  const transactionData = {
    status: payload.status,
    transaction_id: payload.transactionId,
    order_id: payload?.orderId ?? "",
    po_number: payload.poNumber,
    type: payload.type,
    response: payload.response,
    response_code: payload.responseCode,
    payment_method: payload.paymentMethod,
    tax_exempt: payload.remit.taxExempt,
    source: payload.transactionSource,
    username: payload?.createdByUser?.gatewayUserId ?? "",
    processor_id: payload.processor.processorId,
    processor_name: payload.processor.name,
    created_at: payload.createdDateTime,
    updated_at: payload.modifiedDateTime,
    total_amount: payload.remit.amount,
    payment_adjustment: payload.remit.paymentAdjustmentValue,
    amount_authorized: payload.remit.amountAuthorized,
    amount_captured: payload.remit.amountCaptured,
    auth_code: payload.authCode,
    processor_response_text: payload.processorResponseText,
    cvv_response_code: payload.transactionPaymentMethod.cvvResponse ?? "",
    avs_response_code: payload.transactionPaymentMethod.avsResponse ?? "",
    customer_id: payload.customer?.customerId ?? "",
    transaction_features: features,
    billing: billingAddress,
    shipping: shippingAddress,
  };
  const flattenedPayload = flattenedObject(transactionData);
  mixpanel.track(
    "Reporting - Transactions - Transaction Details Viewed",
    flattenedPayload,
  );
}
