import colors from "../assets/colors.module.scss";

const light = {
  mode: "light",
  primary: {
    light: colors.blue200,
    main: colors.blue300,
    dark: colors.blue400,
    contrastText: colors.system_white,
    headerBackground: colors.light_blue250,
  },
  secondary: {
    light: colors.blue200,
    main: colors.blue600,
    dark: colors.blue800,
    headerText: colors.blue500,
    headerBlue: colors.header_blue,
  },
  neutrals: {
    light: colors.system_white,
    main: colors.system_white,
    drawer: colors.drawer,
    button: colors.system_white,
    tableHead: colors.tableHeader,
    item: colors.grey600,
    dark: colors.grey800,
  },
  switch: {
    disabled: {
      checked: {
        track: colors.grey650,
        base: colors.grey200,
      },
      unchecked: {
        track: colors.grey650,
        base: colors.grey200,
      },
    },
    enabled: {
      checked: {
        track: colors.light_blue350,
        base: colors.blue300,
      },
      unchecked: {
        track: colors.grey450,
        base: colors.grey200,
      },
    },
  },
  warning: {
    light: colors.yellow200,
    main: colors.yellow300,
    dark: colors.warning_dark,
  },
  alertDialog: {
    main: colors.grey700,
  },
};

const dark = {
  mode: "dark",
  primary: {
    light: colors.blue400,
    main: colors.blue550,
    dark: colors.blue575,
    hover: colors.blue575,
    contrastText: colors.system_white,
    headerBackground: colors.blue250,
  },
  secondary: {
    light: colors.blue200,
    main: colors.blue550,
    dark: colors.blue575,
    headerText: colors.grey600,
    headerBlue: colors.grey800,
  },
  tertiary: {
    light: colors.light_blue300,
    main: colors.light_blue400,
    dark: colors.light_blue500,
  },
  neutrals: {
    light: colors.grey850,
    main: colors.system_dark,
    button: colors.grey750,
    drawer: colors.grey900,
    tableHead: colors.grey850,
    item: colors.grey850,
    dark: colors.drawer,
  },
  switch: {
    disabled: {
      checked: {
        track: colors.grey775,
        base: colors.grey450,
      },
      unchecked: {
        track: colors.grey775,
        base: colors.grey450,
      },
    },
    enabled: {
      checked: {
        track: colors.light_blue350,
        base: colors.blue300,
      },
      unchecked: {
        track: colors.grey450,
        base: colors.grey100,
      },
    },
  },
  warning: {
    light: colors.yellow100,
    main: colors.yellow100,
    dark: colors.warning_dark,
  },
  alertDialog: {
    main: colors.grey600,
  },
  error: {
    main: colors.error,
  },
};

export const getPalette = (mode) => ({
  palette: {
    mode,
    ...(mode === "light" ? light : dark),
  },
});

export default getPalette;
