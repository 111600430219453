import React, { useState, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import "../simplePay.scss";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography } from "@mui/material";
import "../../../../assets/v2/main.scss";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CustomizePage from "./Theme";
import FormSettings from "./CardSettings";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";
import {
  getMetaValueBool,
  getMetaValueStr,
  getMetaValueNum,
} from "../../../../utils/simplePayHelpers";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const SimplePayForm = ({
  simplePayResponse,
  onSave,
  canEditSimplePay,
  gatewaySurchargeable,
  isCreatingNew,
  processors,
}) => {
  const [expandedPanel1, setExpandedPanel1] = useState(
    isCreatingNew ? "panel1" : false,
  );
  const [expandedPanel2, setExpandedPanel2] = useState("panel2");
  const theme = useTheme();

  const achSecIsCcd = processors.some(
    (processor) =>
      processor.isDefaultAch === true &&
      processor.settings?.sageAch?.terminals?.some(
        (terminal) => terminal.secCode === "CCD",
      ),
  );

  const handleChange = useCallback(
    (panel) => (event, isExpanded) => {
      if (panel === "panel1") {
        setExpandedPanel1(isExpanded ? "panel1" : false); // Allow Panel 1 to toggle
      } else if (panel === "panel2") {
        setExpandedPanel2(isExpanded ? "panel2" : false); // Allow Panel 2 to toggle
      }
    },
    [],
  );

  const { ...methods } = useForm({
    defaultValues: {
      header_name: simplePayResponse?.name,
      display_banner: getMetaValueBool(
        simplePayResponse,
        "DISPLAY_BANNER",
        "SimplePay Branding",
      ),
      banner_type_id:
        getMetaValueNum(
          simplePayResponse,
          "BANNER_TYPE_ID",
          "SimplePay Branding",
        ) || 2,
      banner_message: getMetaValueStr(
        simplePayResponse,
        "BANNER_MESSAGE",
        "SimplePay Branding",
      ),
      logo: getMetaValueStr(simplePayResponse, "LOGO", "SimplePay Branding"),
      logo_align:
        getMetaValueStr(
          simplePayResponse,
          "LOGO_ALIGN",
          "SimplePay Branding",
        ) || "left",
      primary_color: isCreatingNew
        ? theme.palette.primary.main
        : getMetaValueStr(
            simplePayResponse,
            "PRIMARY_COLOR",
            "SimplePay Branding",
          ),
      background_color: isCreatingNew
        ? "#fff"
        : getMetaValueStr(
            simplePayResponse,
            "BACKGROUND_COLOR",
            "SimplePay Branding",
          ),
      payment_color: isCreatingNew
        ? "#d4effa"
        : getMetaValueStr(
            simplePayResponse,
            "PAYMENT_COLOR",
            "SimplePay Branding",
          ),
      card: {
        enable_card: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "ENABLE_CARD",
              "SimplePay Card Form Settings",
            ),
        accept_amex: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "ACCEPT_AMEX",
              "SimplePay Card Form Settings",
            ),
        accept_discover: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "ACCEPT_DISCOVER",
              "SimplePay Card Form Settings",
            ),
        accept_visa: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "ACCEPT_VISA",
              "SimplePay Card Form Settings",
            ),
        accept_mastercard: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "ACCEPT_MASTERCARD",
              "SimplePay Card Form Settings",
            ),
        include_company_field: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "INCLUDE_COMPANY_FIELD",
              "SimplePay Card Form Settings",
            ),
        require_company_field: getMetaValueBool(
          simplePayResponse,
          "REQUIRE_COMPANY_FIELD",
          "SimplePay Card Form Settings",
        ),
        company_field_label: getMetaValueStr(
          simplePayResponse,
          "COMPANY_FIELD_LABEL",
          "SimplePay Card Form Settings",
        ),
        include_order_id_field: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "INCLUDE_ORDER_ID_FIELD",
              "SimplePay Card Form Settings",
            ),
        require_order_id_field: getMetaValueBool(
          simplePayResponse,
          "REQUIRE_ORDER_ID_FIELD",
          "SimplePay Card Form Settings",
        ),
        order_id_field_label: getMetaValueStr(
          simplePayResponse,
          "ORDER_ID_FIELD_LABEL",
          "SimplePay Card Form Settings",
        ),
        include_po_number_field: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "INCLUDE_PO_NUMBER_FIELD",
              "SimplePay Card Form Settings",
            ),
        require_po_number_field: getMetaValueBool(
          simplePayResponse,
          "REQUIRE_PO_NUMBER_FIELD",
          "SimplePay Card Form Settings",
        ),
        po_number_field_label: getMetaValueStr(
          simplePayResponse,
          "PO_NUMBER_FIELD_LABEL",
          "SimplePay Card Form Settings",
        ),
        include_address_fields:
          gatewaySurchargeable || isCreatingNew
            ? true
            : getMetaValueBool(
                simplePayResponse,
                "INCLUDE_ADDRESS_FIELDS",
                "SimplePay Card Form Settings",
              ),
        require_address_fields: gatewaySurchargeable
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "REQUIRE_ADDRESS_FIELDS",
              "SimplePay Card Form Settings",
            ),
        include_email_field: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "INCLUDE_EMAIL_FIELD",
              "SimplePay Card Form Settings",
            ),
        require_email_field: getMetaValueBool(
          simplePayResponse,
          "REQUIRE_EMAIL_FIELD",
          "SimplePay Card Form Settings",
        ),
        include_phone_field: isCreatingNew
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "INCLUDE_PHONE_FIELD",
              "SimplePay Card Form Settings",
            ),
        require_phone_field: getMetaValueBool(
          simplePayResponse,
          "REQUIRE_PHONE_FIELD",
          "SimplePay Card Form Settings",
        ),
        include_note_field: getMetaValueBool(
          simplePayResponse,
          "INCLUDE_NOTE_FIELD",
          "SimplePay Card Form Settings",
        ),
        additional_info_header: getMetaValueStr(
          simplePayResponse,
          "ADDITIONAL_INFO_HEADER",
          "SimplePay Card Form Settings",
        ),
      },
      ach: {
        enable_ach: getMetaValueBool(
          simplePayResponse,
          "ENABLE_ACH",
          "SimplePay ACH Form Settings",
        ),
        include_company_field: achSecIsCcd
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "INCLUDE_COMPANY_FIELD",
              "SimplePay ACH Form Settings",
            ),
        require_company_field: achSecIsCcd
          ? true
          : getMetaValueBool(
              simplePayResponse,
              "REQUIRE_COMPANY_FIELD",
              "SimplePay ACH Form Settings",
            ),
        company_field_label: getMetaValueStr(
          simplePayResponse,
          "COMPANY_FIELD_LABEL",
          "SimplePay ACH Form Settings",
        ),
        include_order_id_field: getMetaValueBool(
          simplePayResponse,
          "INCLUDE_ORDER_ID_FIELD",
          "SimplePay ACH Form Settings",
        ),
        require_order_id_field: getMetaValueBool(
          simplePayResponse,
          "REQUIRE_ORDER_ID_FIELD",
          "SimplePay ACH Form Settings",
        ),
        order_id_field_label: getMetaValueStr(
          simplePayResponse,
          "ORDER_ID_FIELD_LABEL",
          "SimplePay ACH Form Settings",
        ),
        include_po_number_field: getMetaValueBool(
          simplePayResponse,
          "INCLUDE_PO_NUMBER_FIELD",
          "SimplePay ACH Form Settings",
        ),
        require_po_number_field: getMetaValueBool(
          simplePayResponse,
          "REQUIRE_PO_NUMBER_FIELD",
          "SimplePay ACH Form Settings",
        ),
        po_number_field_label: getMetaValueStr(
          simplePayResponse,
          "PO_NUMBER_FIELD_LABEL",
          "SimplePay ACH Form Settings",
        ),
        include_note_field: getMetaValueBool(
          simplePayResponse,
          "INCLUDE_NOTE_FIELD",
          "SimplePay ACH Form Settings",
        ),
        additional_info_header: getMetaValueStr(
          simplePayResponse,
          "ADDITIONAL_INFO_HEADER",
          "SimplePay ACH Form Settings",
        ),
      },
    },
  });

  return (
    <>
      <FormProvider {...methods}>
        <Accordion
          expanded={expandedPanel1 === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Theme</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomizePage canEditSimplePay={canEditSimplePay} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedPanel2 === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Payment Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormSettings
              simplePayResponse={simplePayResponse}
              canEditSimplePay={canEditSimplePay}
              gatewaySurchargeable={gatewaySurchargeable}
              processors={processors}
              isCreatingNew={isCreatingNew}
            />
          </AccordionDetails>
        </Accordion>
        {canEditSimplePay && (
          <Button
            className="btn--primary"
            onClick={methods.handleSubmit(onSave)}
          >
            Save
          </Button>
        )}
      </FormProvider>
    </>
  );
};

SimplePayForm.propTypes = {
  formKey: PropTypes.string,
  simplePayResponse: PropTypes.object,
  onSave: PropTypes.func,
  generateUrl: PropTypes.func,
  handleCopy: PropTypes.func,
  canEditSimplePay: PropTypes.bool,
  gatewaySurchargeable: PropTypes.bool,
  isCreatingNew: PropTypes.bool,
  processors: PropTypes.array,
};

export default SimplePayForm;
