import { formatDateTime } from "../../../utils/stringHelpers";

export const manageUserColumnHeaders = [
  {
    default_field: true,
    key: "status",
    sortable: false,
  },
  {
    default_field: true,
    key: "name",
    sortable: false,
  },
  {
    default_field: true,
    key: "email",
    sortable: false,
  },
  {
    default_field: true,
    key: "role",
    sortable: false,
  },
  {
    default_field: true,
    key: "created",
    sortable: false,
  },
];

export const manageUserKeyValue = (key, data) => {
  switch (key) {
    case "created": {
      return formatDateTime(data.createdDateTime).split(" ")[0];
    }
    case "role": {
      return data.role.description.split(" ")[1];
    }
    case "name": {
      return data?.user.firstName + " " + data.user.lastName;
    }
    case "email": {
      return data.user.email;
    }
    case "status": {
      let mapping = {
        InvitePending: "invite_pending",
        PendingTOS: "pending_tos",
        Active: "active",
        Disabled: "disabled",
      };
      let userStatus =
        mapping[data.gatewayUserStatus] || data.gatewayUserStatus;

      return (
        <div className={`${data.gatewayUserStatus} status-badge`}>
          {userStatus === "invite_pending" ? "invite pending" : userStatus}
        </div>
      );
    }

    default:
      return data[key];
  }
};
