import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import { sassEndpoints } from "../../../../constants/endpoints";
import { stringFormat, generateUUID } from "../../../../utils/stringHelpers";
import TerminalList from "./TerminalList";
import TransactionStepper from "./TransactionStepper";
import PhysicalSummary from "./PhysicalSummary";
import TerminalSuccess from "./PhysicalTerminalSuccess";
import TerminalError from "./PhysicalTerminalError";
import "./physicalTerminal.scss";
import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { TerminalContext } from "../TerminalContext";
import { useFormContext } from "react-hook-form";
import { GatewayConfigContext } from "../../../../contexts/GatewayConfigContext";
import { GatewayContext } from "../../../../contexts/GatewayContext";
import { getGatewayConfigFieldVal } from "../../../../utils/objectHelpers";
import {
  TransactionStatusDescriptions,
  StepperDescription,
  StepperDescriptionSurcharge,
  ModalStatus,
  PaymentTypes,
} from "./physicalTerminalConstants";
import warning from "./assets/warning.png";
import { calculateTotal } from "../PaymentDetails";
const PhysicalTerminalContainer = ({
  token,
  handleClose,
  setFullWidth,
  onSuccess,
}) => {
  const { userSettings } = useContext(UserSettingsContext);
  const { taxRules, paymentType } = useContext(TerminalContext);
  const gatewayConfig = useContext(GatewayConfigContext);
  const gatewayInfo = useContext(GatewayContext);
  const form = useFormContext();

  const amount = form.getValues("amount");
  const taxRate =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Merchant",
      "tax_rate",
    ) || null;

  const isSurcharge =
    gatewayInfo.gatewaySettings
      .find((s) => s.code === "ALLOW_SURCHARGE")
      ?.value.toLowerCase() === "true";

  const stepperFlow =
    isSurcharge && paymentType.toLowerCase() !== "credit"
      ? StepperDescriptionSurcharge
      : StepperDescription;

  const [terminals, setTerminals] = useState([]);
  const [connection, setConnectionRef] = useState(null);
  const [signalRes, setSignalRes] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [connectedTerminal, setConnection] = useState(false);
  const [type, setType] = useState("credit/debit");
  const [stepper, setStepper] = useState(0);
  const [transactionId, setTransactionId] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [endStatus, setEndStatus] = useState(ModalStatus.VIEW);
  const [snackbarProps, setSnackbarProps] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const retryTimes = [0, 3000, 10000, 60000];

  useEffect(() => {
    getTerminals();
    buildConnection();
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        connection.invoke("TerminalStatusRequest", userSettings.gatewayId);
      });
      connection?.on("TerminalStatusResponse", (r, status) => {
        const signalObject = { id: r, isBusy: status };
        setSignalRes((oldArray) => [...oldArray, signalObject]);
      });
      connection?.on("TerminalOnlineResponse", (terminalId, isOnline) => {
        if (!isOnline) {
          setTerminals((res) =>
            res.map((r) => {
              if (r.terminalId === terminalId) {
                r.status = "offline";
              }
              return r;
            }),
          );
        } else {
          connection.invoke("TerminalStatusRequest", userSettings.gatewayId);
        }
      });
      connection.on(
        "StatusUpdateTransaction",
        (transactionId, transactionProcessStatus) => {
          const description =
            TransactionStatusDescriptions[transactionProcessStatus];
          if (!description.errorState) {
            if (description.endProcess) {
              setStepper(Object.keys(stepperFlow).length - 1);
              transactionProcessStatus === 1000
                ? setTimeout(() => {
                    setEndStatus(ModalStatus.SUCCESS);
                  }, 1500)
                : customerCancel();
            } else {
              setStepper((prevCount) => prevCount + 1);
            }
          } else {
            setErrorCode(transactionProcessStatus);
            setEndStatus(ModalStatus.ERROR);
          }
        },
      );
    }
    const statusTimer = setInterval(() => {
      if (connection) {
        connection.invoke("TerminalStatusRequest", userSettings.gatewayId);
      }
    }, 5000);
    return () => {
      connection?.stop();
      clearInterval(statusTimer);
    };
  }, [connection]);

  useEffect(() => {
    if (signalRes.length > 0) {
      signalRes.forEach((s) => {
        // find terminal
        const newTerminals = terminals.map((t) => {
          //check status
          if (t.terminalId === s.id) {
            //if not active make active
            t.status = s.isBusy ? "busy" : "active";
          }
          return t;
        });
        setTerminals(newTerminals);
        const cleanUp = signalRes.filter((c) => {
          return c.id !== s.id;
        });
        setSignalRes(cleanUp);
      });
    }
  }, [signalRes]);

  useEffect(() => {
    if (connectedTerminal && selectedTerminal) {
      const uuid = generateUUID();
      setTransactionId(uuid);
      // mockStream();
    }
  }, [connectedTerminal]);

  useEffect(() => {
    if (transactionId) {
      setStepper(0);
      startTransaction(transactionId);
    }
  }, [transactionId]);

  useEffect(() => {
    setFullWidth(endStatus <= 1);
  }, [endStatus]);

  const buildConnection = () => {
    const connectionRef = new HubConnectionBuilder()
      .withUrl(
        stringFormat(sassEndpoints.signalRHub, [userSettings.gatewayId]),
        {
          accessTokenFactory: () => token,
          withCredentials: false,
          transport: HttpTransportType.LongPolling,
        },
      )
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (context) => {
          const index =
            context.previousRetryCount < retryTimes.length
              ? context.previousRetryCount
              : retryTimes.length - 1;
          return retryTimes[index];
        },
      })
      .build();
    setConnectionRef(connectionRef);
  };

  const startTransaction = (uuid) => {
    setSnackbarProps(null);
    connection.invoke("JoinTransactionGroup", uuid).then(() => {
      setLoading(true);
      const totalAmount =
        taxRules.taxToTotal && type !== "ebt"
          ? formatCurrency(calculateTotal(amount, taxRate))
          : formatCurrency(amount);
      const transactionPayload = {
        transactionId: uuid,
        tenderType: type === "credit/debit" ? [1] : [3],
        transactionType: PaymentTypes[paymentType.toUpperCase()],
        remit: {
          baseAmount: totalAmount,
          taxAmount:
            !taxRules.taxExempt && taxRate && type !== "ebt"
              ? formatCurrency(amount * (taxRate / 100).toFixed(2))
              : 0,
          taxExempt: type === "ebt" ? true : taxRules.taxExempt,
          currencyCode: "USD",
          tip: 0,
          total: totalAmount,
          paymentAdjustmentType:
            selectedTerminal?.processor?.paymentAdjustmentType,
          paymentAdjustmentValue:
            selectedTerminal?.processor?.paymentAdjustmentValue,
          surchargeable: isSurcharge,
        },
      };
      let url = stringFormat(sassEndpoints.terminal.transaction, [
        userSettings.gatewayId,
        selectedTerminal.terminalId,
      ]);
      axios
        .post(url, transactionPayload)
        .then(() => {})
        .catch((error) => {
          setSnackbarProps({
            type: "failed",
            message: error?.response?.data?.statusDetails,
          });
          cancelTransaction(false);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  // const mockStream = () => {
  //   const statuses = [100, 300, 900, 1000];
  //   // const statuses = [100];
  //   const interval = 2000;
  //   statuses.forEach((s, i) => {
  //     setTimeout(function () {
  //       const description = TransactionStatusDescriptions[s];
  //       if (!description.errorState) {
  //         setStepper((prevCount) => prevCount + 1);
  //         // if (description.endProcess) {
  //         //   setTimeout(() => {
  //         //     setEndStatus(2);
  //         //   }, 1500);
  //         // }
  //       } else {
  //         setErrorCode(s);
  //         setEndStatus(3);
  //       }
  //     }, i * interval);
  //   });
  // };

  const getTerminals = () => {
    let url = stringFormat(sassEndpoints.terminal.terminals, [
      userSettings.gatewayId,
    ]);
    axios
      .get(url + "?offset=0&" + "limit=100")
      .then((res) => {
        setTerminals(
          res?.data?.data?.results.map((r) => {
            r.status = "offline";
            return r;
          }) || [],
        );
      })
      .catch((error) => {
        setSnackbarProps({
          type: "failed",
          message: error?.response?.data?.statusDetails,
        });
      });
  };

  const returnToTransaction = () => {
    if (errorCode) {
      setErrorCode(null);
    }
    resetStatus();
  };

  const cancelTransaction = (queue) => {
    queue
      ? setEndStatus(ModalStatus.WARN)
      : connection
          .invoke("RequestCancelTransaction", transactionId.toString())
          .finally(() => {
            resetStatus();
          });
  };

  const resetStatus = () => {
    setConnection(false);
    setSelectedTerminal(null);
    setEndStatus(ModalStatus.VIEW);
    setStepper(0);
    setTransactionId(null);
  };

  const customerCancel = () => {
    resetStatus();
    setSnackbarProps({
      type: "success",
      message: "Successfully cancelled transaction",
    });
  };

  const formatCurrency = (amount) => {
    return Number((amount / 100).toFixed(2));
  };

  const disableCancel = () => {
    return stepper > 2 || stepper === 0 || isLoading;
  };

  const queueCancel = () => {
    return (
      <div>
        <div className="pt-success-wrapper">
          <div>
            <img src={warning} />
            <h2 style={{ marginTop: "20px" }}>
              Are you sure you wish to cancel this transaction?
            </h2>
            <button
              onClick={() => {
                cancelTransaction(false);
                setSnackbarProps({
                  type: "success",
                  message: "Successfully cancelled transaction",
                });
              }}
              className="btn--primary__danger"
            >
              Yes, cancel transaction
            </button>
            <button
              onClick={() => {
                setEndStatus(ModalStatus.VIEW);
              }}
              className="btn--secondary__danger"
            >
              No, go back
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {endStatus === ModalStatus.VIEW && (
        <div className="pt-wrapper">
          {!connectedTerminal ? (
            <TerminalList
              selectTerminal={setSelectedTerminal}
              terminals={terminals}
              selectedTerminal={selectedTerminal}
            />
          ) : (
            <TransactionStepper stepper={stepper} stepperFlow={stepperFlow} />
          )}
          <PhysicalSummary
            type={type}
            setType={setType}
            stepper={stepper}
            selectedTerminal={selectedTerminal}
            connectTerminal={setConnection}
            isConnection={connectedTerminal}
            cancelTransaction={cancelTransaction}
            handleClose={handleClose}
            snackbarProps={snackbarProps}
            disableCancel={disableCancel}
          />
        </div>
      )}
      {endStatus === ModalStatus.WARN && <div>{queueCancel()}</div>}
      {endStatus === ModalStatus.SUCCESS && (
        <TerminalSuccess
          transactionId={transactionId}
          returnToTransaction={onSuccess}
        />
      )}
      {endStatus === ModalStatus.ERROR && (
        <TerminalError
          returnToTransaction={returnToTransaction}
          code={errorCode}
        />
      )}
    </div>
  );
};
PhysicalTerminalContainer.propTypes = {
  token: PropTypes.string,
  handleClose: PropTypes.func,
  setFullWidth: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default PhysicalTerminalContainer;
