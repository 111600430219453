import Dinero from "dinero.js";
import { convertToInt } from "./dineroFormatters";

const calculateTaxDinero = (dineroAmount, taxValue, taxType) => {
  return taxType === "Percentage"
    ? dineroAmount.multiply(
        Dinero({
          amount: convertToInt(taxValue),
          precision: 5,
        }).toUnit(),
      )
    : Dinero({ amount: typeof taxValue === "number" ? taxValue : 0 });
};

const calculatePaymentAdjustmentDinero = (
  total,
  adjustmentValue,
  adjustmentType,
) => {
  if (typeof adjustmentValue === "number") {
    if (adjustmentType === "Flat") return Dinero({ amount: adjustmentValue });
    if (adjustmentType === "Percentage") {
      return total.multiply(
        Dinero({
          amount: adjustmentValue,
          precision: 5,
        }).toUnit(),
      );
    }
  }

  return Dinero({ amount: 0 });
};

const calculate = (inputObj) => {
  const dineroAmount = Dinero({ amount: inputObj?.amount ?? 0 });
  const taxAmountDinero = calculateTaxDinero(
    dineroAmount,
    inputObj?.tax?.value ?? 0,
    inputObj?.tax?.type ?? "Flat",
  );

  if (inputObj.surchargeApplied && inputObj.surchargeRsp !== null) {
    return {
      amount: inputObj.amount.toFixed(2),
      surchargeAmount: (inputObj.surchargeRsp.surchargeAmount * 100).toFixed(2),
      taxAmount: taxAmountDinero.getAmount().toFixed(2),
      total: (inputObj.surchargeRsp.amount * 100).toFixed(2),
    };
  } else {
    const returnObj = {
      amount: 0,
      taxAmount: 0,
      serviceFeeAmount: 0,
      total: 0,
    };
    if (!inputObj) return returnObj;
    if ("amount" in inputObj === false) inputObj.amount = 0;
    if ("serviceFee" in inputObj === false) inputObj.serviceFee = 0;
    if ("serviceFeeType" in inputObj === false)
      inputObj.serviceFeeType = "NoAdj";
    if ("tax" in inputObj === false)
      inputObj.tax = { value: 0, type: "Flat", addToTotal: false };

    returnObj.amount = inputObj.amount;

    returnObj.taxAmount = taxAmountDinero.getAmount();

    let total = inputObj.tax.addToTotal
      ? dineroAmount.add(taxAmountDinero)
      : dineroAmount;

    const paymentAdjustment = calculatePaymentAdjustmentDinero(
      total,
      inputObj.serviceFee,
      inputObj.serviceFeeType,
    );

    total = total.add(paymentAdjustment);
    returnObj.serviceFeeAmount = paymentAdjustment.getAmount();
    returnObj.total = total.getAmount();

    return returnObj;
  }
};

const calculateLineItemTotal = ({ quantity, unitPrice }) =>
  Dinero({ amount: Math.round(unitPrice) })
    .multiply(quantity)
    .getAmount();

export { calculate, calculateLineItemTotal };
