import React, { useState, useEffect, useContext } from "react";
import colors from "../../assets/colors.module.scss";
import PropTypes from "prop-types";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PromptDialog from "../Dialog/AlertDialog";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { sassEndpoints } from "../../constants/endpoints";
import { useForm } from "react-hook-form";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { CountriesContext } from "../../contexts/CountriesContext";
import ListSubheader from "@mui/material/ListSubheader";
import { searchFilterSectionOption } from "./searchHelpers";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { stringFormat } from "../../utils/stringHelpers";
import { State } from "country-state-city";
export default function SearchManager({
  tableFormatAllowed,
  sectionName,
  searchButtonOnClick,
  showSnackBar,
  showAlertDialogError,
  showCreateButton,
  createButtonText,
  createButtonOnClick,
  entitySearchFilterFields,
  savedSearches,
  suppressAdvancedSearch,
  quickActionButtons,
  setSearchClicked,
}) {
  const [promptDialogOpen, setPromptDialogOpen] = useState(false);
  const [contentElement, setContentElement] = useState(null);
  const [savedSearchesData, setSavedSearchesData] = useState([]);
  const [forceUpdateResults, setForceUpdateResults] = useState(null);
  const [showList, setShowList] = useState(false);
  const countriesContext = useContext(CountriesContext);
  const [searchFilterOptions, setSearchFilterOptions] = useState([]);
  const [shippingStates, setShippingStates] = useState([]);
  const [billingStates, setBillingStates] = useState([]);
  const [searchFilterText, setSearchFilterText] = useState("");
  const [searchPayload, setSearchPayload] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(null);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(
    !suppressAdvancedSearch,
  );
  const { userSettings } = useContext(UserSettingsContext);
  const [mobileCollapseState, setMobileCollapseState] = useState({
    quickSearch: false,
    savedSearch: false,
  });

  const {
    register: registerSearch,
    handleSubmit: handleSearchSubmit,
    setValue: setSearchValue,
    reset: resetSearch,
  } = useForm({
    shouldUnregister: true,
  });

  const {
    register: registerCreateSavedSearch,
    handleSubmit: handleCreateSavedSearchSubmit,
    reset: resetCreateSavedSearch,
  } = useForm({
    shouldUnregister: false,
  });

  const SearchFilterMenuProps = {
    PaperProps: {
      style: {
        maxHeight: 320,
      },
    },
  };

  const onSearchSubmit = async (data, e) => {
    let payload = data;
    let error = false;
    Object.keys(payload).every((key) => {
      if (
        typeof payload[key] === "object" &&
        ("startDate" in payload[key] || "endDate" in payload[key]) &&
        payload[key].startDate > payload[key].endDate
      ) {
        showAlertDialogError("Invalid date range");
        error = true;
        return false;
      }

      return true;
    });
    if (!error) {
      //Fix billing state operator missing when filtering by country and state not selected
      if (
        payload.billing_address?.state !== undefined &&
        payload.billing_address?.state.operator === undefined
      )
        delete payload.billing_address.state;

      //Fix shipping state operator missing when filtering by country and state not selected
      if (
        payload.shipping_address?.state !== undefined &&
        payload.shipping_address?.state.operator === undefined
      )
        delete payload.shipping_address.state;

      setSearchPayload(payload);

      if (e?.target?.name === "save-favorite-button") {
        handleFavoriteButtonOnClick(e);
      } else {
        searchButtonOnClick(payload);
      }
    }
  };

  const onCreateSavedSearchSubmit = async (data) => {
    //Handle Create Saved Search
    if (!data.savedSearchName) {
      showAlertDialogError("No name entered");
    } else if (!Object.keys(searchPayload).length) {
      showAlertDialogError("No search filter chosen");
    } else if (
      savedSearchesData.some(({ name }) => name === data.savedSearchName)
    ) {
      showAlertDialogError("Saved search name already exists");
    } else {
      createSavedSearch(data.savedSearchName, searchPayload);
    }
  };

  const onSearchError = (errors, e) => console.log(errors, e);

  const loadSavedSearches = () => {
    const saasSavedSearchUrl = stringFormat(
      sassEndpoints.gatewayUsers.savedSearch,
      [userSettings.gatewayId, userSettings.gatewayUserId],
    );

    if (savedSearches) {
      axios
        .get(saasSavedSearchUrl + `?type=${sectionName}`)
        .then((response) => {
          if (response.status === 200) {
            setSavedSearchesData(response.data.data);
          } else if (response.status === 204) {
            setSavedSearchesData([]);
          }
        })
        .catch((error) => {
          error?.response?.data?.statusDetails
            ? showAlertDialogError(error?.response?.data?.statusDetails)
            : showAlertDialogError("Failed to load saved searches");
        });
    }
  };

  const handleCountryChange = (e, fieldName) => {
    let selectedCountry = e?.target?.value ?? "";

    searchFilterOptions
      .filter((option) => option.fieldName === fieldName)
      .map((item) => {
        if (item.savedValues) item.savedValues.value = "";
      });

    selectedCountry === ""
      ? loadStates("US", fieldName)
      : loadStates(e.target.value, fieldName);
  };

  const loadStates = (country, type) => {
    if (type.toString().startsWith("shipping") || type === "all")
      setShippingStates(
        State.getStatesOfCountry(country).map((s) => {
          s.code = s.isoCode;
          return s;
        }) || [],
      );
    if (type.toString().startsWith("billing") || type === "all")
      setBillingStates(
        State.getStatesOfCountry(country).map((s) => {
          s.code = s.isoCode;
          return s;
        }) || [],
      );
  };

  //Pre-req object to load
  useEffect(() => {
    loadStates("US", "all");
    loadSavedSearches();

    return () => {
      resetCreateSavedSearch();
    };
  }, [forceUpdateResults]);

  const actionButtonsArray = () => {
    let showCloseFavoritesListButtonText = "Show List";
    let showCloseFavoritesListButtonOnClick = () =>
      handleShowFavoritesListButtonOnClick();

    if (showList) {
      showCloseFavoritesListButtonText = "Close List";
      showCloseFavoritesListButtonOnClick = () =>
        handleCloseListButtonOnClick();
    }

    if (savedSearchesData?.length === 0)
      return [
        {
          text: "Save",
          color: "primary",
          onclick: handleCreateSavedSearchSubmit(onCreateSavedSearchSubmit),
        },
      ];
    else
      return [
        {
          text: "Save",
          color: "primary",
          onclick: handleCreateSavedSearchSubmit(onCreateSavedSearchSubmit),
        },
        {
          text: showCloseFavoritesListButtonText,
          color: "secondary",
          onclick: showCloseFavoritesListButtonOnClick,
        },
      ];
  };

  const handleFavoriteButtonOnClick = () => {
    setContentElement(renderCreateSavedSearch());
    setPromptDialogOpen(true);
  };

  const handleShowFavoritesListButtonOnClick = () => {
    setShowList(true);
    setContentElement(renderShowFavoritesList());
  };

  const handleCloseListButtonOnClick = () => {
    setShowList(false);
    setContentElement(renderCreateSavedSearch());
  };

  const handleCancelButtonClick = () => {
    setShowList(false);
    setPromptDialogOpen(false);
  };

  const deleteSavedSearch = async (savedSearchId) => {
    const url =
      stringFormat(sassEndpoints.gatewayUsers.savedSearch, [
        userSettings.gatewayId,
        userSettings.gatewayUserId,
      ]) + `/${savedSearchId}`;
    await axios
      .delete(url)
      .then(function () {
        handleCancelButtonClick();
        showSnackBar("Successfully removed saved search");
        setForceUpdateResults(Date.now());
      })
      .catch(function (error) {
        error?.response?.data?.statusDetails
          ? showAlertDialogError(error?.response?.data?.statusDetails)
          : showAlertDialogError("Failed to delete saved search");
      });
  };

  const createSavedSearch = async (savedSearchName, searchPayload) => {
    const url = stringFormat(sassEndpoints.gatewayUsers.savedSearch, [
      userSettings.gatewayId,
      userSettings.gatewayUserId,
    ]);

    let payload = {
      name: savedSearchName,
      schemaVersion: "v1",
      type: sectionName,
      value: JSON.stringify(searchPayload),
    };

    await axios
      .post(url, payload)
      .then(function () {
        handleCancelButtonClick();
        showSnackBar("Successfully added " + savedSearchName);
        setForceUpdateResults(Date.now());
      })
      .catch(function (error) {
        error.response.data.statusDetails
          ? showAlertDialogError(error?.response?.data?.statusDetails)
          : showAlertDialogError("Failed to create saved search");
      });
  };

  const handleAddFilterOption = (fieldName, savedValues, instance) => {
    let modifiedSearchFilterOptions = structuredClone(searchFilterOptions);

    if (fieldName === "search_in_everything") {
      searchFilterOptions.map((item) => {
        item.selected = false;
      });
    } else {
      searchFilterOptions.map((item) => {
        if (item.fieldName === "search_in_everything") item.selected = false;
      });
    }

    modifiedSearchFilterOptions
      .filter(
        (option) =>
          option.fieldName === fieldName ||
          (option.fieldName?.endsWith(fieldName) &&
            option.customField === true),
      )
      .map((item) => {
        item.selected = true;
        item.savedValues = savedValues;
        item.instance = instance ?? "0";
      });
    setSearchFilterOptions(modifiedSearchFilterOptions);
  };

  const handleRemoveFilterOption = (fieldName) => {
    let modifiedSearchFilterOptions = [...searchFilterOptions];

    if (fieldName === "billing_address.country")
      handleCountryChange(null, "billing_address.state");

    if (fieldName === "shipping_address.country")
      handleCountryChange(null, "shipping_address.state");

    modifiedSearchFilterOptions
      .filter((option) => option.fieldName === fieldName)
      .map((item) => {
        item.selected = false;
      });

    setSearchFilterOptions(modifiedSearchFilterOptions);
  };

  const forceUpdateChange = () => {
    setForceUpdate(Date.now());
  };

  //Fix for not re-rendering correctly
  useEffect(() => {
    return null;
  }, [forceUpdate]);

  const applySavedSearch = (savedSearchID) => {
    const url =
      stringFormat(sassEndpoints.gatewayUsers.savedSearch, [
        userSettings.gatewayId,
        userSettings.gatewayUserId,
      ]) + `/${savedSearchID}`;
    axios
      .get(url)
      .then((response) => {
        searchButtonOnClick(JSON.parse(response.data.data.value));
      })
      .catch((error) => {
        error?.response?.data?.statusDetails
          ? showAlertDialogError(error?.response?.data?.statusDetails)
          : showAlertDialogError("Failed to apply saved search");
      });
  };

  const applyQuickAction = (fieldName, values) => {
    resetSearch();
    searchFilterOptions.forEach((item) => {
      item.selected = false;
      item.savedValues = {};
    });
    if (sectionName !== "Settlement Filter") {
      setSearchClicked(true);
    }
    setTimeout(() => {
      handleAddFilterOption(fieldName, values);
      handleSearchSubmit(onSearchSubmit)(onSearchError);
    });
  };

  const [filterSearchOptionsOpen, setFilterSearchOptionsOpen] = useState(false);
  const handleOpenCloseFilterSearchOptions = (isOpen) => {
    setFilterSearchOptionsOpen(isOpen);
  };

  //Handle First Loading - Search Defaults
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    setSearchFilterOptions(entitySearchFilterFields);
    setFirstLoad(true);
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      handleSearchSubmit(onSearchSubmit)(onSearchError);
    }
  }, [firstLoad]);

  const renderShowFavoritesList = () => {
    return (
      <Grid
        container
        sx={{ width: 350 }}
        alignItems="center"
        justifyContent="center"
      >
        {savedSearchesData?.length > 0 &&
          savedSearchesData.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={8}>
                  <Typography>{item.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <IconButton
                    onClick={() => deleteSavedSearch(item.savedSearchId)}
                  >
                    <CloseIcon
                      fontSize="medium"
                      color="error"
                      sx={{ fontWeight: "800" }}
                    ></CloseIcon>
                  </IconButton>
                </Grid>
              </React.Fragment>
            );
          })}
      </Grid>
    );
  };

  const renderCreateSavedSearch = () => {
    return (
      <>
        <form
          onSubmit={handleCreateSavedSearchSubmit(onCreateSavedSearchSubmit)}
        >
          <Typography sx={{ textAlign: "left" }}>
            Select name for saved search
          </Typography>
          <TextField
            data-cy="save-search-text-input"
            autoFocus
            fullWidth
            size="small"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              type: "text",
              size: "small",
              placeholder: "",
              maxLength: 100,
            }}
            defaultValue={""}
            {...registerCreateSavedSearch("savedSearchName")}
          />
        </form>
      </>
    );
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter") {
      e.preventDefault();
      handleSearchSubmit(onSearchSubmit)(onSearchError);
    }
  };
  return (
    <>
      <form
        onSubmit={handleSearchSubmit(onSearchSubmit, onSearchError)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        {quickActionButtons.length > 0 && (
          <Grid>
            <Typography className="desktop-search-header">
              Quick Report Filters
            </Typography>
            <Button
              className="link-btn mobile-search-header"
              onClick={() =>
                setMobileCollapseState({
                  ...mobileCollapseState,
                  quickSearch: !mobileCollapseState.quickSearch,
                })
              }
            >
              Quick Report Filters {mobileCollapseState.quickSearch ? "-" : "+"}
            </Button>
            <Grid
              container
              overflow="auto"
              wrap="nowrap"
              columnSpacing={1}
              sx={{ pb: 1 }}
              className="search-button-groups search-button-groups__desktop"
            >
              {quickActionButtons.map((quickSearch, i) => {
                return (
                  <Grid item key={i}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        applyQuickAction(
                          quickSearch.fieldName,
                          quickSearch.search,
                        )
                      }
                      data-cy={`quick-${quickSearch.title}`}
                    >
                      <ContentCopyIcon sx={{ paddingRight: "5px" }} />
                      {quickSearch.title}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
            {mobileCollapseState.quickSearch && (
              <Grid
                container
                overflow="auto"
                wrap="nowrap"
                columnSpacing={1}
                className="search-button-groups search-button-groups__mobile"
                sx={{
                  pb: 1,
                  visibility: mobileCollapseState.quickSearch
                    ? "visible"
                    : "hidden",
                  height: mobileCollapseState.quickSearch ? "100%" : "0px",
                }}
              >
                {quickActionButtons.map((quickSearch, i) => {
                  return (
                    <Grid item key={i}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          applyQuickAction(
                            quickSearch.fieldName,
                            quickSearch.search,
                          )
                        }
                      >
                        <ContentCopyIcon sx={{ paddingRight: "5px" }} />
                        {quickSearch.title}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        )}

        {savedSearchesData?.length > 0 && (
          <Grid>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                paddingBottom: "10px",
              }}
              className="desktop-search-header"
            >
              Saved Searches
            </Typography>
            <Button
              className="link-btn mobile-search-header"
              onClick={() =>
                setMobileCollapseState({
                  ...mobileCollapseState,
                  savedSearch: !mobileCollapseState.savedSearch,
                })
              }
            >
              Saved Searches {mobileCollapseState.savedSearch ? "-" : "+"}
            </Button>
            <Grid
              container
              overflow="auto"
              wrap="nowrap"
              columnSpacing={1}
              sx={{ pb: 1 }}
              className="search-button-groups search-button-groups__desktop"
            >
              {savedSearchesData?.length > 0 &&
                savedSearchesData.map((item, index) => {
                  return (
                    <Grid item key={index}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        data-cy={`saved-search-${item.name}`}
                        onClick={() => applySavedSearch(item.savedSearchId)}
                      >
                        <ContentCopyIcon sx={{ paddingRight: "5px" }} />
                        {item.name}
                      </Button>
                    </Grid>
                  );
                })}
            </Grid>
            {mobileCollapseState.savedSearch && (
              <Grid
                container
                overflow="auto"
                wrap="nowrap"
                columnSpacing={1}
                className="search-button-groups search-button-groups__mobile"
                sx={{
                  pb: 1,
                  visibility: mobileCollapseState.savedSearch
                    ? "visible"
                    : "hidden",
                  height: mobileCollapseState.savedSearch ? "100%" : "0px",
                }}
              >
                {savedSearchesData?.length > 0 &&
                  savedSearchesData.map((item, index) => {
                    return (
                      <Grid item key={index}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          data-cy={`saved-search-${item.id}`}
                          onClick={() => applySavedSearch(item.savedSearchId)}
                        >
                          <ContentCopyIcon sx={{ paddingRight: "5px" }} />
                          {item.name}
                        </Button>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Grid>
        )}

        {suppressAdvancedSearch && (
          <div>
            <Button
              className="link-btn"
              sx={{
                fontWeight: "bold",
              }}
              onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
              data-cy="advanced-search"
            >
              Advanced Search {showAdvancedSearch ? "-" : "+"}
            </Button>
          </div>
        )}

        <Grid
          sx={{
            visibility: showAdvancedSearch ? "visible" : "hidden",
            height: showAdvancedSearch ? "100%" : "0px",
          }}
        >
          <Grid
            container
            columnSpacing={1}
            paddingTop={0}
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 1 }}
          >
            <Grid
              item
              display={tableFormatAllowed === true ? "inline-block" : "none"}
              className="table-format-dropdown"
            >
              <Select
                size="small"
                displayEmpty
                defaultValue={""}
                sx={{ p: 0, m: 0, fontSize: 12, width: 200 }}
                inputProps={{ padding: 0 }}
                fullWidth
              >
                <MenuItem value="">Table Format - Standard</MenuItem>
                <MenuItem value="0">Not implemented yet...</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Select
                  sx={{ fontSize: 12 }}
                  fullWidth
                  displayEmpty
                  size="small"
                  value={""}
                  type="submit"
                  input={<OutlinedInput />}
                  open={filterSearchOptionsOpen}
                  onClose={() => handleOpenCloseFilterSearchOptions(false)}
                  onOpen={() => handleOpenCloseFilterSearchOptions(true)}
                  onKeyPress={null}
                  onKeyDown={null}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <div
                          style={{ color: "silver" }}
                          data-cy="search-manager-options"
                        >
                          Filter Search Options
                        </div>
                      );
                    }
                  }}
                  MenuProps={SearchFilterMenuProps}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                >
                  <ListSubheader onKeyDown={(e) => e.stopPropagation()}>
                    <TextField
                      fullWidth
                      placeholder="Search"
                      autoComplete="off"
                      size="small"
                      defaultValue={searchFilterText}
                      InputLabelProps={{ shrink: true }}
                      sx={{ mt: 0.5 }}
                      inputProps={{ style: { fontSize: 12 } }}
                      onChange={(e) => {
                        setSearchFilterText(e.target.value.toLowerCase());
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </ListSubheader>
                  {searchFilterOptions !== undefined &&
                    searchFilterOptions
                      .filter((option) => !option.selected)
                      .filter(
                        (option) =>
                          option.displayName
                            .toLowerCase()
                            .includes(searchFilterText) ||
                          option.fieldType === "group",
                      )
                      .map(function (option, index) {
                        return option.fieldType === "group" ? (
                          <ListSubheader
                            key={index}
                            disableSticky={true}
                            sx={{ fontWeight: "bold", lineHeight: 2 }}
                          >
                            {option.displayName}
                          </ListSubheader>
                        ) : (
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddFilterOption(option.fieldName);
                              e.target.blur();
                            }}
                            key={option.fieldName}
                            value={option.fieldName}
                            sx={{
                              display: "block",
                              fontSize: 12,
                            }}
                          >
                            <Box sx={{ paddingLeft: 1 }}>
                              {option.displayName}
                            </Box>
                          </MenuItem>
                        );
                      })}
                  );
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <ButtonGroup variant="contained" size="small">
                <Button
                  data-cy="search-button"
                  name="search-button"
                  type="submit"
                  color="primary"
                  onClick={handleSearchSubmit(onSearchSubmit, onSearchError)}
                >
                  Search
                </Button>
                <Button
                  data-cy="save-favorite-button"
                  name="save-favorite-button"
                  id="save-favorites"
                  color="primary"
                  sx={{ m: 0 }}
                  onClick={handleSearchSubmit(onSearchSubmit, onSearchError)}
                >
                  <FavoriteIcon
                    name="fav"
                    fontSize="12"
                    sx={{ color: colors.system_white, pointerEvents: "none" }}
                  />
                </Button>
              </ButtonGroup>
              <PromptDialog
                alertOpen={promptDialogOpen}
                alertTitle={"Save Search"}
                alertLevel={null}
                alertMessages={null}
                contentElement={contentElement}
                actionButtons={actionButtonsArray()}
                closeButtonText="Cancel"
                closeButtonColor="neutrals"
                onCloseButtonClick={() => handleCancelButtonClick()}
                showXCloseButton={true}
              />
            </Grid>
          </Grid>
          {/* Start Search Filters */}
          {searchFilterOptions
            .filter((option) => option.selected)
            .map((option) => (
              <React.Fragment key={option.fieldName + option.instance}>
                {searchFilterSectionOption(
                  registerSearch,
                  setSearchValue,
                  option,
                  handleRemoveFilterOption,
                  countriesContext,
                  handleCountryChange,
                  shippingStates,
                  billingStates,
                  forceUpdateChange,
                )}
              </React.Fragment>
            ))}
          {/* End Search Filters */}
        </Grid>
      </form>
      <Grid
        container
        columnSpacing={1}
        paddingTop={0}
        paddingBottom={1.5}
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 1 }}
      >
        <Grid
          item
          xs={12}
          sx={{
            mt: 1,
            display: showCreateButton === true ? "inline-block" : "none",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={createButtonOnClick}
            data-cy={createButtonText}
          >
            {createButtonText}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SearchManager.defaultProps = {
  tableFormatAllowed: false,
  searchButtonOnClick: null,
  showCreateButton: false,
  createButtonOnClick: null,
  savedSearches: true,
  suppressAdvancedSearch: false,
  quickActionButtons: [],
};

SearchManager.propTypes = {
  tableFormatAllowed: PropTypes.bool,
  sectionName: PropTypes.string.isRequired,
  searchButtonOnClick: PropTypes.func,
  savedSearchesData: PropTypes.array,
  showSnackBar: PropTypes.func.isRequired,
  showAlertDialogError: PropTypes.func.isRequired,
  showCreateButton: PropTypes.bool,
  createButtonText: PropTypes.string,
  createButtonOnClick: PropTypes.func,
  entitySearchFilterFields: PropTypes.array,
  savedSearches: PropTypes.bool,
  suppressAdvancedSearch: PropTypes.bool,
  quickActionButtons: PropTypes.array,
  setSearchClicked: PropTypes.func,
};
