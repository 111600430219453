import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import { useFormContext } from "react-hook-form";
import { sassEndpoints } from "../../../constants/endpoints";
import { useState, useEffect } from "react";
import "./manageSettings.scss";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { useContext } from "react";
import axios from "axios";
import { useAlertDialog } from "../../../hooks/useAlertDialog";
import AlertDialog from "../..//Dialog/AlertDialog";
import { CONTACT_SUPPORT } from "../../../constants/global";
import { stringFormat } from "../../../utils/stringHelpers";

const RequiredFields = () => {
  const { setValue } = useFormContext();
  const { userSettings } = useContext(UserSettingsContext);
  const [configRequired, setConfigRequired] = useState(null);
  const [emptyData, setEmptyData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { isAlertOpen, setIsAlertOpen, alertMessages, displayMessages } =
    useAlertDialog(["Error"]);

  useEffect(() => {
    if (!configRequired && emptyData === null) {
      const configUrl = stringFormat(sassEndpoints.gatewayUsers.configuration, [
        userSettings.gatewayId,
      ]);
      axios
        .get(configUrl)
        .then((response) => {
          setConfigRequired(
            response.data.data.categories.find(
              (obj) => obj.displayName === "Required Fields",
            ),
          );
          const valueField = response.data.data.categories
            .find((obj) => obj.displayName === "Required Fields")
            ?.groups.find((obj) => obj.name === "Default")
            ?.fields.find((field) => field.code === "default").value;
          if (
            (valueField === "object" && valueField[0].length !== 0) ||
            (valueField !== "object" && valueField.length !== 0)
          ) {
            setEmptyData(false);
          } else {
            setEmptyData(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          setIsAlertOpen(true);
          displayMessages([
            error?.response?.data?.statusDetails || CONTACT_SUPPORT,
          ]);
          setLoading(false);
          setEmptyData(true);
        });
    }
  }, []);

  const [data, setData] = useState([]);
  useEffect(() => {
    let tempData = [];
    tempData = configRequired
      ? configRequired.groups
          .find((obj) => obj.name === "Default")
          .fields.map((obj) => {
            return {
              name: "default." + obj.code,
              id: obj.code.replace(/_+/g, " "),
              value: !emptyData
                ? typeof obj.value === "object"
                  ? obj.value[0]
                  : obj.value
                : "optional",
            };
          })
      : null;

    setData(tempData);
  }, [configRequired, emptyData]);

  const handleChange = (e, index) => {
    const tempData = [...data];
    tempData[index].value = e.target.value;
    setData(tempData);
    setValue(tempData[index].name, e.target.value);
  };
  const options = ["required", "optional", "hidden"];

  return isLoading || emptyData === null ? (
    <></>
  ) : isAlertOpen ? (
    <AlertDialog
      alertTitle="Error"
      alertMessages={alertMessages}
      alertLevel="error"
      alertOpen={isAlertOpen}
      onCloseButtonClick={() => setIsAlertOpen(false)}
    />
  ) : (
    data && (
      <>
        <Box sx={{ flexGrow: 1, p: "0" }}>
          <TableContainer
            sx={{ pr: ".5em" }}
            component={Paper}
            className="required-fields-wrapper"
          >
            <Table
              stickyHeader={true}
              sx={{ minWidth: 650 }}
              aria-label="required fields table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableCell>Field</TableCell>
                  <TableCell>Required</TableCell>
                  <TableCell>Optional</TableCell>
                  <TableCell>Hidden</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(({ name, id, value }, index) => {
                  return (
                    <TableRow
                      key={id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{id}</TableCell>
                      {options.map((option) => {
                        return (
                          <TableCell key={option}>
                            <Radio
                              disabled={
                                (id === "type" || id === "amount") && true
                              }
                              name={name}
                              onChange={(e) => handleChange(e, index)}
                              value={option}
                              checked={value === option}
                              size="small"
                              data-cy={`${id}-${option}`}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    )
  );
};

export default RequiredFields;
