import {
  isString,
  isNumber,
  isBoolean,
  isArray,
  isPlainObject,
  isEmpty,
  isNull,
} from "lodash";

export function flattenedObject(o, prefix = "", result = {}, keepNull = true) {
  if (isString(o) || isNumber(o) || isBoolean(o) || (keepNull && isNull(o))) {
    result[prefix] = o;
    return result;
  }

  if (isArray(o) || isPlainObject(o)) {
    for (let i in o) {
      let pref = prefix;
      if (isArray(o)) {
        pref = pref + `[${i}]`;
      } else {
        if (isEmpty(prefix)) {
          pref = i;
        } else {
          pref = prefix + "." + i;
        }
      }
      flattenedObject(o[i], pref, result, keepNull);
    }
    return result;
  }
  return result;
}
