import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import InputAdornment from "@mui/material/InputAdornment";
import {
  dateSince,
  paymentType,
  transactionSource,
  transactionType,
  transactionStatus,
  gatewayStatus,
  systemUserStatus,
  basysRoles,
} from "../../constants/general";
import {
  currencyInputOnKeyDown,
  currencyInputOnChange,
} from "../Merchant/ReportingTransactions/transactionHelpers";
import { removeNonNumeric } from "../../utils/stringHelpers";
import { formatCurrency } from "../../utils/dineroFormatters";
import "./searchCmp.scss";

let formRegister;
let formSetValue;
let billingCountry;
let shippingCountry;
let billingStatesData;
let shippingStatesData;

export const filterOperators = {
  is: { value: "=", display: "is" },
  isLike: { value: "%", display: "is like" },
  isNotLike: { value: "!=", display: "is not like" },
  notEquals: { value: "!=", display: "not equals" },
  between: { value: "between", display: "between" },
  since: { value: "duration", display: "since" },
};

export const sassFilterOperators = {
  is: { value: "Equal", display: "is" },
  notEquals: { value: "NotEqual", display: "not equals" },
  isLike: { value: "IsLike", display: "is like" },
  isNotLike: { value: "IsNotLike", display: "is not like" },
  between: { value: "Between", display: "between" },
  isGreaterOrEqual: {
    value: "IsGreaterOrEqual",
    display: "is greater or equal",
  },
};

export const getSortableTableCell = (keyData, onSortClickEvent) => {
  var cellSx = { sx: { fontWeight: "bold", whiteSpace: "nowrap", pl: 1 } };

  if (keyData.sortable) {
    cellSx.sx.cursor = "pointer";
    if (onSortClickEvent !== undefined)
      cellSx.onClick = () => onSortClickEvent(keyData.key);
  }

  return cellSx;
};

export const getEntityKeyTitle = (key) => {
  let returnTitle = key.toString();

  //Remove _date if it ends with it
  if (
    returnTitle.toString().endsWith("_date") &&
    returnTitle.toString() !== "due_date"
  )
    returnTitle = returnTitle.replace(/_date/g, "");

  //Replace underscores with spaces
  returnTitle = returnTitle.replace(/_/g, " ");

  //Uppercase first letter of each word
  returnTitle = returnTitle.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  return returnTitle;
};

export const renderFilterNameOperatorSelector = (filterOption) => {
  let isRegistered = true;
  let defaultValue = "";

  if (filterOption.savedValues && filterOption.savedValues.operator) {
    defaultValue = filterOption.savedValues.operator;
  } else {
    defaultValue = filterOption.operators[0].value;
  }

  if (!defaultValue) defaultValue = "";

  switch (filterOption.fieldType.toLowerCase()) {
    case "date_range":
    case "date_since":
      isRegistered = false;
      break;
  }

  return (
    <>
      <Typography
        variant="h5"
        noWrap={false}
        marginLeft={1}
        marginRight={1}
        sx={{ display: "inline-block" }}
      >
        {filterOption.displayName}
      </Typography>
      <Select
        sx={{ fontSize: 12, padding: 0 }}
        size="small"
        displayEmpty
        defaultValue={defaultValue}
        {...(isRegistered &&
          formRegister(filterOption.fieldName + ".operator", {
            value: defaultValue,
          }))}
        inputProps={{ "data-cy": filterOption.fieldName + ".operator" }}
      >
        {filterOption.operators.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.display}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

function numericInputOnChange(e) {
  e.target.value = removeNonNumeric(e.target.value);
}

function phoneInputOnChange(e) {
  e.target.value = formatPhoneNumber(e.target.value);
}

function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = removeNonNumeric(value);

  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return `(${phoneNumber}`;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
}

const renderInputFilter = (filterOption) => {
  let formFieldName = filterOption.fieldName + ".value";
  let inputMaxLength = 250;
  let inputValueAsNumber = false;
  let inputTextAlign = "left";
  let inputDefaultValue;
  let typeProps;

  if (filterOption.maxLength) inputMaxLength = filterOption.maxLength;

  switch (filterOption.fieldType.toLowerCase()) {
    case "text":
      typeProps = {
        defaultValue: filterOption.savedValues
          ? filterOption.savedValues.value
          : "",
        onChange: (e) => {
          formSetValue(formFieldName, e.target.value.trim());
        },
      };
      break;

    case "numeric":
      inputValueAsNumber = false;
      typeProps = {
        defaultValue: filterOption.savedValues
          ? filterOption.savedValues.value
          : "",
        onChange: (e) => {
          numericInputOnChange(e);
          formSetValue(formFieldName, e.target.value);
        },
        onKeyDown: numericInputOnChange,
      };
      break;

    case "phone":
      inputMaxLength = 14;
      typeProps = {
        defaultValue: filterOption.savedValues
          ? formatPhoneNumber(filterOption.savedValues.value)
          : "",
        onChange: (e) => {
          phoneInputOnChange(e);
          formSetValue(formFieldName, removeNonNumeric(e.target.value));
        },
        onKeyDown: phoneInputOnChange,
      };
      break;

    case "currency":
      inputMaxLength = 12;
      inputTextAlign = "right";
      inputValueAsNumber = true;
      typeProps = {
        defaultValue: filterOption?.savedValues
          ? // ? formatCurrency(filterOption?.savedValues?.value)
            filterOption?.savedValues?.value
          : formatCurrency(0),
        onChange: (e) => {
          currencyInputOnChange(e);
          formSetValue(formFieldName, e.target.value);
        },
        onKeyDown: currencyInputOnKeyDown,
        InputProps: {
          startAdornment: <InputAdornment position="start"> $</InputAdornment>,
        },
      };
      break;
  }

  return (
    <>
      <TextField
        fullWidth
        defaultValue={inputDefaultValue}
        autoComplete="off"
        {...typeProps}
        size="small"
        inputProps={{
          "data-cy": formFieldName,
          placeholder: "",
          maxLength: inputMaxLength,
          style: { fontSize: 12, textAlign: inputTextAlign },
        }}
        sx={{ padding: 0, m: 0, fontSize: 8 }}
      />
      <input
        type="hidden"
        defaultValue={
          filterOption?.savedValues
            ? filterOption?.savedValues?.value
            : filterOption?.fieldType === "currency"
              ? 0
              : ""
        }
        {...formRegister(formFieldName, {
          valueAsNumber: inputValueAsNumber,
        })}
      />
    </>
  );
};

const renderSelectFilter = (filterOption, optionValues) => {
  let defaultValue;
  let registration;

  if (filterOption.fieldType.toLowerCase() === "date_since")
    registration = formRegister(filterOption.fieldName);
  else registration = formRegister(filterOption.fieldName + ".value");

  if (
    filterOption.savedValues &&
    optionValues.find((item) => item.value === filterOption.savedValues.value)
  ) {
    defaultValue = filterOption.savedValues.value;
  } else {
    defaultValue = optionValues[0].value;
  }

  return (
    <Select
      fullWidth
      {...registration}
      sx={{ fontSize: 12 }}
      size="small"
      defaultValue={defaultValue}
      inputProps={{ "data-cy": filterOption.fieldName + ".value" }}
    >
      {optionValues.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.display}
        </MenuItem>
      ))}
    </Select>
  );
};

function formatDateToISO(value) {
  let isoDate = new Date(value).toISOString().split(".")[0] + ".000";

  return isoDate;
}

const renderDateRangeFilter = (filterOption) => {
  let defaultStartDate = new Date().toISOString().split("T")[0];
  let defaultEndDate = new Date().toISOString().split("T")[0];

  if (filterOption.savedValues) {
    if (
      filterOption.savedValues.startDate &&
      filterOption.savedValues.endDate
    ) {
      defaultStartDate = filterOption.savedValues.startDate.split("T")[0];
      defaultEndDate = filterOption.savedValues.endDate.split("T")[0];
    } else {
      defaultStartDate = filterOption.savedValues.value.split(" to ")[0];
      defaultEndDate = filterOption.savedValues.value.split(" to ")[1];
    }
  }

  return (
    <Grid className="date-range-fields">
      <TextField
        size="small"
        defaultValue={defaultStartDate}
        onChange={(e) => {
          formSetValue(
            filterOption.fieldName + ".startDate",
            formatDateToISO(e.target.value + " 00:00:00"),
          );
        }}
        inputProps={{
          "data-cy": filterOption.fieldName + ".startDate",
          type: "date",
          style: { fontSize: 12 },
        }}
      />
      <Typography
        variant="h5"
        noWrap={false}
        marginLeft={1}
        marginRight={1}
        sx={{ display: "inline-block" }}
      >
        to
      </Typography>
      <TextField
        size="small"
        defaultValue={defaultEndDate}
        onChange={(e) => {
          formSetValue(
            filterOption.fieldName + ".endDate",
            formatDateToISO(e.target.value + " 23:59:59"),
          );
        }}
        inputProps={{
          "data-cy": filterOption.fieldName + ".endDate",
          type: "date",
          style: { fontSize: 12 },
        }}
      />
      <input
        {...formRegister(filterOption.fieldName + ".startDate")}
        type="hidden"
        defaultValue={formatDateToISO(defaultStartDate + " 00:00:00")}
      />
      <input
        {...formRegister(filterOption.fieldName + ".endDate")}
        type="hidden"
        defaultValue={formatDateToISO(defaultEndDate + " 23:59:59")}
      />
    </Grid>
  );
};

const renderCountryFilter = (
  filterOption,
  optionValues,
  handleCountryChange,
) => {
  let defaultValue;

  if (
    filterOption.savedValues &&
    optionValues.find((item) => item.value === filterOption.savedValues.value)
  ) {
    defaultValue = filterOption.savedValues.value;
  } else {
    defaultValue = "";
  }

  if (billingCountry && filterOption.fieldName.startsWith("billing"))
    defaultValue = billingCountry;
  if (shippingCountry && filterOption.fieldName.startsWith("shipping"))
    defaultValue = shippingCountry;

  //  str = str.Replace("\\", "");

  return (
    <Select
      fullWidth
      displayEmpty
      {...formRegister(filterOption.fieldName + ".value")}
      sx={{ fontSize: 12 }}
      size="small"
      defaultValue={defaultValue}
      inputProps={{
        "data-cy": filterOption.fieldName + ".value",
      }}
      onChange={(e) => {
        if (filterOption.fieldName.startsWith("billing")) {
          formSetValue("billing_address.state.value", "");
          handleCountryChange(e, "billing_address.state");
        }

        if (filterOption.fieldName.startsWith("shipping")) {
          formSetValue("shipping_address.state.value", "");
          handleCountryChange(e, "shipping_address.state");
        }
      }}
    >
      <MenuItem value="">Select Country</MenuItem>
      {optionValues.map((option) => (
        <MenuItem key={option.code} value={option.code}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const handleStateChange = (e, filterOption) => {
  if (!filterOption.savedValues) filterOption.savedValues = {};

  filterOption.savedValues.value = e.target.value.toString() || "";
};

const renderStateFilter = (filterOption, optionValues, forceUpdateChange) => {
  return (
    <Select
      fullWidth
      displayEmpty
      {...formRegister(filterOption.fieldName + ".value")}
      sx={{ fontSize: 12 }}
      size="small"
      value={filterOption.savedValues?.value || ""}
      inputProps={{
        "data-cy": filterOption.fieldName + ".value",
      }}
      onChange={(e) => {
        handleStateChange(e, filterOption);
        forceUpdateChange();
      }}
    >
      <MenuItem key="state-default" value="">
        Select Region
      </MenuItem>
      {optionValues.map((option) => (
        <MenuItem
          key={`${filterOption.fieldName}-option-value-${option.code}`}
          value={option.code}
          name={`${filterOption.fieldName}.value`}
        >
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const renderFilterOptionControlType = (
  filterOption,
  data,
  handleCountryChange,
  forceUpdateChange,
) => {
  const paymentTypes = filterOption.customerPayments
    ? filterOption.customerPayments
    : paymentType;

  switch (filterOption.fieldType.toLowerCase()) {
    case "date_range":
      return renderDateRangeFilter(filterOption);

    case "date_since":
      return renderSelectFilter(filterOption, dateSince, "duration");

    case "payment_type":
      return renderSelectFilter(filterOption, paymentTypes, "value");

    case "payment_method_type":
      return renderSelectFilter(filterOption, paymentTypes, "value");

    case "transaction_source":
      return renderSelectFilter(filterOption, transactionSource, "value");
    case "gateway_status":
      return renderSelectFilter(filterOption, gatewayStatus, "value");
    case "gateway_linked_status":
      return renderSelectFilter(filterOption, gatewayStatus, "value");
    case "system_user_status":
      return renderSelectFilter(filterOption, systemUserStatus, "value");
    case "basys_user_roles":
      return renderSelectFilter(filterOption, basysRoles, "value");

    case "transaction_type":
      return renderSelectFilter(filterOption, transactionType, "value");

    case "transaction_status":
      return renderSelectFilter(filterOption, transactionStatus, "value");

    case "country":
      return renderCountryFilter(filterOption, data, handleCountryChange);

    case "state":
      return renderStateFilter(
        filterOption,
        filterOption.fieldName.startsWith("shipping")
          ? shippingStatesData
          : billingStatesData,
        forceUpdateChange,
      );

    case "currency":
    case "phone":
    case "text":
    case "numeric":
      return renderInputFilter(filterOption);

    default:
      console.log("Invalid field type");
  }
};

export const searchFilterSectionOption = (
  registerSearch,
  setValueSearch,
  filterOption,
  handleRemoveFilterOption,
  countriesContext,
  handleCountryChange,
  shippingStates,
  billingStates,
  forceUpdateChange,
) => {
  formRegister = registerSearch;
  formSetValue = setValueSearch;
  shippingStatesData = shippingStates;
  billingStatesData = billingStates;

  return (
    <Grid
      container
      columnSpacing={1}
      paddingTop={0}
      alignItems="center"
      sx={{ mt: 1 }}
      key={filterOption.fieldName}
      className={filterOption.fieldType === "date_range" ? "date-search" : ""}
    >
      <Grid item xs={"auto"}>
        {renderFilterNameOperatorSelector(filterOption)}
      </Grid>

      <Grid item xs>
        {renderFilterOptionControlType(
          filterOption,
          countriesContext,
          handleCountryChange,
          forceUpdateChange,
        )}
      </Grid>

      <Grid item xs={"auto"}>
        <IconButton
          data-cy={`delete-filter-${filterOption.fieldName}`}
          title="Delete"
          variant="container"
          color="error"
          sx={{ padding: 0.5 }}
          value={filterOption.fieldName}
          onClick={() => handleRemoveFilterOption(filterOption.fieldName)}
          fontSize="small"
        >
          <CancelIcon
            value={filterOption.fieldName}
            sx={{ ariaValueText: filterOption.fieldName }}
            fontSize="small"
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};
