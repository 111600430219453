import { formatDateTime } from "../../../utils/stringHelpers";
import { sassFilterOperators } from "../../Search/searchHelpers";

export const gatewayUserColumnHeaders = [
  {
    default_field: true,
    key: "username",
    sortable: false,
  },
  {
    default_field: true,
    key: "status",
    sortable: false,
  },
  {
    default_field: true,
    key: "name",
    sortable: false,
  },
  {
    default_field: true,
    key: "role",
    sortable: false,
  },
  {
    default_field: true,
    key: "created",
    sortable: false,
  },
];

export const gatewayUserKeyValue = (key, data) => {
  switch (key) {
    case "created": {
      return formatDateTime(data.created).split(" ")[0];
    }
    case "username": {
      return data.username;
    }
    case "role": {
      return data.role;
    }
    case "name": {
      return data.name;
    }
    case "status": {
      return (
        <div className={`${data.status} status-badge`}>
          {data.status === "InvitePending" ? "invite pending" : data.status}
        </div>
      );
    }

    default:
      return data[key];
  }
};

const generalGroup = "General";

export const userSearchFields = [
  {
    group: generalGroup,
    displayName: "User ID",
    fieldName: "userId",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Username",
    fieldName: "email",
    operators: [
      sassFilterOperators.isLike,
      sassFilterOperators.isNotLike,
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Status",
    fieldName: "status",
    operators: [sassFilterOperators.is, sassFilterOperators.notEquals],
    fieldType: "system_user_status",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "First Name",
    fieldName: "firstName",
    operators: [
      sassFilterOperators.isLike,
      sassFilterOperators.isNotLike,
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Last Name",
    fieldName: "lastName",
    operators: [
      sassFilterOperators.isLike,
      sassFilterOperators.isNotLike,
      sassFilterOperators.is,
      sassFilterOperators.notEquals,
    ],
    fieldType: "text",
    selected: false,
  },
  {
    group: generalGroup,
    displayName: "Created Date",
    fieldName: "created_date_time",
    operators: [sassFilterOperators.between],
    fieldType: "date_range",
    selected: false,
  },
];
